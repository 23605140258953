import React from 'react'
import {
    Grid, Form, Button, Message,
    Container, Segment, Header, Icon
} from 'semantic-ui-react'

import { Parallax, } from 'react-parallax';
// import axios from 'axios'
import { withRouter } from 'react-router-dom'
// import Nav from '../nav/Nav'
import newOTP from 'otp-generators';
import emailjs from 'emailjs-com';
const userLang = navigator.language || navigator.userLanguage



class ChangeEmail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            newpassword: '',
            confirm: '',
            email: '',
            sentEmail: '',
            oldEmail: '',
            emailRepeat: false,
            oldPassword: '',
            password: '',
            passwordCorrect: '',
            loading: true
        }
        // this.onHandleNewPassword=this.onHandleNewPassword.bind(this)
        // this.onHandleConfirm=this.onHandleConfirm.bind(this)
        this.onHandleEmail = this.onHandleEmail.bind(this)
        this.onHandleSubmit = this.onHandleSubmit.bind(this)
        this.onHandlePassword = this.onHandlePassword.bind(this)


    }

    componentDidMount() {

        const self = this
        const token = localStorage.getItem('token')
        const oldEmail = localStorage.getItem('oldEmail')
        const password = localStorage.getItem('password')
        const identifier = localStorage.getItem('identifier')
        self.setState({
            oldEmail: oldEmail,
            oldPassword: password,
            token: token,
            identifier: identifier
        })

        window.onload = (event) => {
            self.setState({
                loading: false
            })
        };

        window.setTimeout(() => {
            if (self.state.loading === true) {
                this.setState({
                    loading: false
                })
            }

        }, 700);
    }



    onHandleEmail(event) {

        this.setState({
            email: event.target.value
        })

    }

    onHandlePassword(event) {
        this.setState({
            password: event.target.value
        })
    }

    onHandleSubmit() {
        const self = this
        const email = self.state.email
        localStorage.setItem('email', email)
        const password = self.state.password

        const oldPassword = self.state.oldPassword
        const oldEmail = this.state.oldEmail
        const verificationCode = newOTP.generate(6, { alphabets: false, upperCase: false, specialChar: false });
        const identifier = this.state.identifier


        if (oldEmail === email) {
            this.setState({
                emailRepeat: true
            })
        } else if (oldPassword !== password) {
            this.setState({
                passwordCorrect: false
            })
        } else {
            emailjs.send('service_45k6plg', 'template_l5xa2ig', {
                'email': JSON.stringify(email),
                'reply_to': '1181456762@qq.com',
                'name': JSON.stringify(identifier),
                'verificationCode': verificationCode
            }, 'user_4rMSnDYBigUutDlR5RSib')
                .then(function (response) {
                    console.log('SUCCESS!', response.status, response.text);
                    localStorage.setItem('verificationCode',verificationCode)
                    self.props.history.push('/verifyEmail')
                }, function (error) {
                    console.log('FAILED...', error);
                });
        }
       
        

    }

    // onHandleDropdown(event,data){
    //   this.setState({
    //     value:data.value
    //   })
    // }

    render() {
        const sentEmail = this.state.sentEmail
        const emailRepeat = this.state.emailRepeat
        const loading = this.state.loading
        const passwordCorrect = this.state.passwordCorrect

        return <div>
            {/* <Nav /> */}


            {loading === true &&

                <Grid >
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>


                    <Grid.Row colums={2}>
                        <Grid.Column width={7}></Grid.Column>
                        <Grid.Column width={6}>
                            <Header size='huge'>
                                <Icon loading name='asterisk' size='massive' color='yellow' />
                                Loading...
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                </Grid>
            }
            {loading === false && <Parallax
                bgImage='https://i.loli.net/2020/11/25/ZHjYm1wL9runqOz.png'
                bgImageAlt=''
                strength={400}>


                <div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    {userLang === 'zh-CN' && (
                        <Container>
                            {sentEmail === '' &&
                                <Segment>
                                    <Form stackable >
                                        <Form.Input onChange={this.onHandlePassword}
                                            label='Password'
                                            placeholder='Password'
                                        />
                                        <Form.Input onChange={this.onHandleEmail}
                                            label='New Email'
                                            placeholder='New Email'
                                        />

                                        {emailRepeat === true &&
                                            <Message negative>
                                                <Message.Header>您输入的新邮箱地址与旧邮箱地址重复</Message.Header>
                                            </Message>}
                                        {passwordCorrect === false &&
                                            <Message negative>
                                                <Message.Header>您的密码错误</Message.Header>
                                            </Message>}


                                    </Form>

                                    <br />
                                    <br />


                                    <Grid columns={4} centered>

                                        <Grid.Column width={4}>
                                            <Button onClick={this.onHandleSubmit} type='submit' fluid size='large' floated='right' content='Continue' color='orange' />
                                        </Grid.Column>

                                    </Grid>
                                </Segment>
                            }

                            {sentEmail === true &&
                                <Segment>

                                    <Grid centered colums={2}>
                                        <Grid.Row></Grid.Row>
                                        <Grid.Row></Grid.Row>
                                        <Grid.Row>
                                            <Header size='huge' color='orange'>
                                                <Icon name='smile outline' />
                                                <Header.Content>重置链接已发送至您的邮箱</Header.Content>

                                            </Header>
                                        </Grid.Row>

                                        <Grid.Row></Grid.Row>
                                        <Grid.Row></Grid.Row>
                                    </Grid>
                                </Segment>

                            }

                            {sentEmail === false &&
                                <Segment>

                                    <Grid centered colums={2}>
                                        <Grid.Row></Grid.Row>
                                        <Grid.Row></Grid.Row>
                                        <Grid.Row>
                                            <Header size='huge' color='orange'>
                                                <Icon name='exclamation triangle' />
                                                <Header.Content>出错了，请重试</Header.Content>

                                            </Header>
                                        </Grid.Row>

                                        <Grid.Row></Grid.Row>
                                        <Grid.Row></Grid.Row>
                                    </Grid>
                                </Segment>
                            }

                        </Container>
                    )
                    }

                    {userLang !== 'zh-CN' && (
                        <Container>

                            {sentEmail === '' &&
                                <Segment>
                                    <Form stackable >
                                        <Form.Input onChange={this.onHandlePassword}
                                            label='Password'
                                            placeholder='Password'
                                        />
                                        <Form.Input onChange={this.onHandleEmail}
                                            label='New Email'
                                            placeholder='New Email'
                                        />

                                        {emailRepeat === true &&
                                            <Message negative>
                                                <Message.Header>Your email is the same as the old one</Message.Header>
                                            </Message>}
                                        {passwordCorrect === false &&
                                            <Message negative>
                                                <Message.Header>Your password is not correct</Message.Header>
                                            </Message>}


                                    </Form>

                                    <br />
                                    <br />


                                    <Grid columns={4} centered>

                                        <Grid.Column width={4}>
                                            <Button onClick={this.onHandleSubmit} type='submit' fluid size='large' floated='right' content='Continue' color='orange' />
                                        </Grid.Column>

                                    </Grid>
                                </Segment>
                            }

                            {sentEmail === true &&
                                <Segment>

                                    <Grid centered colums={2}>
                                        <Grid.Row></Grid.Row>
                                        <Grid.Row></Grid.Row>
                                        <Grid.Row>
                                            <Header size='huge' color='orange'>
                                                <Icon name='smile outline' />
                                                <Header.Content>Rest Link Has Sent To Your Email</Header.Content>

                                            </Header>
                                        </Grid.Row>

                                        <Grid.Row></Grid.Row>
                                        <Grid.Row></Grid.Row>
                                    </Grid>
                                </Segment>

                            }

                            {sentEmail === false &&
                                <Segment>

                                    <Grid centered colums={2}>
                                        <Grid.Row></Grid.Row>
                                        <Grid.Row></Grid.Row>
                                        <Grid.Row>
                                            <Header size='huge' color='orange'>
                                                <Icon name='exclamation triangle' />
                                                <Header.Content>Something Went Wrong, Please Try Again</Header.Content>

                                            </Header>
                                        </Grid.Row>

                                        <Grid.Row></Grid.Row>
                                        <Grid.Row></Grid.Row>
                                    </Grid>
                                </Segment>
                            }

                        </Container>
                    )}   
                </div>

                <div style={{ height: "300px" }} />
            </Parallax>
            }


        </div>
    }

}

export default withRouter(ChangeEmail)









