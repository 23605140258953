import React from 'react'
import {
  Segment,
  Grid,
  Header,
  Divider,
  Container,
  Form,
  Button,
  TransitionablePortal
} from 'semantic-ui-react'
// import {Link} from 'react-router-dom'
import { withRouter } from 'react-router-dom'
// import axios from 'axios'
const userLang = navigator.language || navigator.userLanguage

class Foot extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false
    }
  }

  componentDidMount () {}

  handleOpen = () => this.setState({ open: true })

  handleClose = () => this.setState({ open: false })
  render () {
    const { open } = this.state
    return (
      <>
        {userLang === 'zh-CN' && (
          <Segment placeholder>
            <Container>
              <Grid
                stackable
                columns={2}
                style={{ paddingBottom: 15, paddingTop: 10 }}
              >
                <Grid.Row style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                  <Grid.Column
                    style={{
                      borderRight: '1px solid rgba(0,0,0,0.1)',
                      height: '60px'
                    }}
                  >
                    <Grid>
                      <Grid.Row style={{ paddingBottom: 5 }}>
                        <Header>最新资讯</Header>
                      </Grid.Row>
                      <Grid.Row>
                        <Header as='h5' color='grey'>
                          不想错过有关SHEYOU的任何资讯？订阅加入我们的 mailing
                          list!
                        </Header>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                  <Grid.Column>
                    <Form style={{ position: 'relative', top: 10, right: -50 }}>
                      <Form.Group widths='equal'>
                        <Form.Input fluid placeholder='邮箱地址' />
                        <TransitionablePortal
                          closeOnTriggerClick
                          onOpen={this.handleOpen}
                          onClose={this.handleClose}
                          openOnTriggerClick
                          trigger={<Form.Button color='teal'>订阅</Form.Button>}
                        >
                          <Segment
                            style={{
                              left: '40%',
                              position: 'fixed',
                              top: '50%',
                              zIndex: 1000,
                              paddingTop: 50,
                              paddingBottom: 50
                            }}
                          >
                            <Header>
                              Verfication Code Has Been Sent To Your Email
                            </Header>
                            <Form>
                              <Form.Field>
                                <label>Your Name</label>
                                <input placeholder='Your Name' />
                              </Form.Field>
                              <Form.Field>
                                <label>Verification Code</label>
                                <input placeholder='Verification Code' />
                              </Form.Field>
                            </Form>
                            <Button color='teal'> Confirm Subscription</Button>
                          </Segment>
                        </TransitionablePortal>
                      </Form.Group>
                    </Form>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row
                  centered
                  style={{
                    borderBottom: '1px solid rgba(0,0,0,0.1)',
                    paddingTop: 20,
                    paddingBottom: 30
                  }}
                >
                  <Grid.Column width={6}>
                    <Grid>
                      <Grid.Row>
                        <Header
                          color='teal'
                          name='Home'
                          style={{ fontSize: 40, position: 'relative' }}
                        >
                          SHEYOU
                          <Header.Subheader style={{ color: '#767676' }}>
                            Next Generation Fashion Platform
                            <br />
                            新时代的时尚平台
                          </Header.Subheader>
                        </Header>
                      </Grid.Row>
                      <Grid.Row style={{ paddingBottom: 5, marginbottom: 0 }}>
                        Copyright @四川舍予人玉商贸有限公司
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <Header>联系我们</Header>
                    <p>手机: 17308079166</p>

                    <p>E-mail: 1181456762@qq.com</p>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row
                  centered
                  style={{ paddingBottom: 20, paddingTop: 20 }}
                >
                  <a
                    href='https://beian.miit.gov.cn'
                    style={{ color: 'black' }}
                  >
                    <img
                      alt='...'
                      style={{
                        width: 15,
                        height: 15,
                        position: 'relative',
                        top: 3
                      }}
                      src='https://img.alicdn.com/tfs/TB1..50QpXXXXX7XpXXXXXXXXXX-40-40.png'
                    />
                    蜀ICP备2022012898号
                  </a>
                </Grid.Row>
              </Grid>
            </Container>

            <br />
          </Segment>
        )}

        {userLang !== 'zh-CN' && (
          <Segment placeholder>
            <Container>
              <Grid
                stackable
                columns={2}
                divided
                style={{ height: 100, paddingBottom: 15, paddingTop: 10 }}
              >
                <Grid.Column>
                  <Grid>
                    <Grid.Row style={{ paddingBottom: 5 }}>
                      <Header>Know It All First</Header>
                    </Grid.Row>
                    <Grid.Row>
                      <Header as='h5' color='grey'>
                        Never Miss Anything From SHEYOU By Subscribing.
                      </Header>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
                <Grid.Column>
                  <Form style={{ position: 'relative', top: 10, right: -50 }}>
                    <Form.Group widths='equal'>
                      <Form.Input fluid placeholder='邮箱地址' />
                      <TransitionablePortal
                        closeOnTriggerClick
                        onOpen={this.handleOpen}
                        onClose={this.handleClose}
                        openOnTriggerClick
                        trigger={<Form.Button color='teal'>订阅</Form.Button>}
                      >
                        <Segment
                          style={{
                            left: '40%',
                            position: 'fixed',
                            top: '50%',
                            zIndex: 1000,
                            paddingTop: 50,
                            paddingBottom: 50
                          }}
                        >
                          <Header>
                            Verfication Code Has Been Sent To Your Email
                          </Header>
                          <Form>
                            <Form.Field>
                              <label>Your Name</label>
                              <input placeholder='Your Name' />
                            </Form.Field>
                            <Form.Field>
                              <label>Verification Code</label>
                              <input placeholder='Verification Code' />
                            </Form.Field>
                          </Form>
                          <Button color='teal'> Confirm Subscription</Button>
                        </Segment>
                      </TransitionablePortal>
                    </Form.Group>
                  </Form>
                </Grid.Column>
              </Grid>
            </Container>

            <Divider />
            <br />
            <br />

            <Grid>
              <Grid.Row centered>
                <Grid.Column width={6}>
                  <Grid>
                    <Grid.Row>
                      <Header
                        color='teal'
                        name='Home'
                        style={{ fontSize: 40, position: 'relative' }}
                      >
                        SHEYOU
                        <Header.Subheader style={{ color: '#767676' }}>
                          Next Generation Fashion Platform
                        </Header.Subheader>
                      </Header>
                    </Grid.Row>
                    <Grid.Row>
                      Copyright @Sichuan Sheyu Renyu Trading Co. LTD
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
                <Grid.Column width={6}>
                  <Header>Contact Us</Header>
                  <p>Phone Number:17308079166</p>
                  <p>E-mail:1181456762@qq.com</p>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <br />
            <br />
            <br />
            <br />
          </Segment>
        )}
      </>
    )
  }
}

export default withRouter(Foot)
