import React from 'react'
import axios from 'axios'

import {
  Grid,
  Image,
  Header,
  Icon,
  Segment,
  Button,
  Form,
  Divider,
  Label,
  Dropdown,
  Message
} from 'semantic-ui-react'

import { withRouter } from 'react-router-dom'
import UploadImages from './UploadProductPhoto'
// import reactImageSize from 'react-image-size'
import UploadProductPhoto from './UploadProductPhoto'

class NewProduct extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      token: '',
      log: '',

      name: '',
      price: '',
      stock: '',
      descript: '',
      productPhoto: '',
      descriptPhoto: '',

      accessoryCatOpt: [],
      clothingCatOpt: [],
      genderOpt: [],
      sizeOpt: [],
      occasionOpt: [],
      colorOpt: [],
      styleOpt: [],

      accessoryCat: {},
      clothingCat: {},
      gender: {},
      sizes: [],
      occasions: [],
      colors: [],
      styles: [],

      previewLoading: false,
      back: false,
      loading: true,
      somethingWrong: false
  }
  // this.getTagInfo = this.getTagInfo.bind(this)

    this.onName = this.onName.bind(this)
    this.onPrice = this.onPrice.bind(this)
    this.onStock = this.onStock.bind(this)
    this.onDescript = this.onDescript.bind(this)

    this.handleProductPhoto = this.handleProductPhoto.bind(this)
    this.handleDescriptPhoto = this.handleDescriptPhoto.bind(this)

    this.handleSingleSelect = this.handleSingleSelect.bind(this)
    this.handleChooseTag = this.handleChooseTag.bind(this)
    this.handleDeleteTag = this.handleDeleteTag.bind(this)

    this.handleBackClick = this.handleBackClick.bind(this)
    this.handleYesBack = this.handleYesBack.bind(this)
    this.handleNoBack = this.handleNoBack.bind(this)

    this.handlePreview = this.handlePreview.bind(this)
  }

  componentDidMount () {
    const self = this
    const log = localStorage.getItem('login')
    self.setState({ log: log })
    if (log === 'true') {
      self.getTagInfo()
    }

    const activeItem = this.props.activeItem
    self.setState({ activeItem: activeItem })

    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 700)
  }

  getTagInfo () {
    const self = this
    const token = localStorage.getItem('token')
    self.setState({ token: token })
    var clothingCats = []
    var accessoryCats = []
    var genders = []
    var colors = []
    var sizes = []
    var occasions = []
    var styles = []

    axios('https://sheyou-backend.herokuapp.com/genders', {
      method: 'get'
    }).then(function (res) {
      const data = res.data
      data.map(function (option) {
        const value = option.name.toLowerCase()
        const gender = {
          id: option.id,
          text: option.name,
          value: value,
          key: value
        }
        genders.push(gender)
      })

      self.setState({
        genderOpt: genders
      })
    })

    axios('https://sheyou-backend.herokuapp.com/categories', {
      method: 'get'
    }).then(function (res) {
      const data = res.data
      data.map(function (option) {
        const value = option.name.toLowerCase()
        const clothingCat = {
          id: option.id,
          text: option.name,
          value: value,
          key: value
        }
        clothingCats.push(clothingCat)
      })

      self.setState({
        clothingCatOpt: clothingCats
      })
    })

    axios('https://sheyou-backend.herokuapp.com/accessory-categories', {
      method: 'get'
    }).then(function (res) {
      const data = res.data
      data.map(function (option) {
        const value = option.name.toLowerCase()
        const accessoryCat = {
          id: option.id,
          text: option.name,
          value: value,
          key: value
        }
        accessoryCats.push(accessoryCat)
      })

      self.setState({
        accessoryCatOpt: accessoryCats
      })
    })

    axios('https://sheyou-backend.herokuapp.com/colors', {
      method: 'get'
    }).then(function (res) {
      const data = res.data
      data.map(function (option) {
        const value = option.name.toLowerCase()
        const style = {
          id: option.id,
          text: option.name,
          value: value,
          key: value
        }
        colors.push(style)
      })

      self.setState({
        colorOpt: colors
      })
    })

    axios('https://sheyou-backend.herokuapp.com/sizes', {
      method: 'get'
    }).then(function (res) {
      const data = res.data
      data.map(function (option) {
        const value = option.name.toLowerCase()
        const size = {
          id: option.id,
          text: option.name,
          value: value,
          key: value
        }
        sizes.push(size)
      })

      self.setState({
        sizeOpt: sizes
      })
    })

    axios('https://sheyou-backend.herokuapp.com/ocassions', {
      method: 'get'
    }).then(function (res) {
      const data = res.data
      data.map(function (option) {
        const value = option.name.toLowerCase()
        const occasion = {
          id: option.id,
          text: option.name,
          value: value,
          key: value
        }
        occasions.push(occasion)
      })

      self.setState({
        occasionOpt: occasions
      })
    })

    axios('https://sheyou-backend.herokuapp.com/styles', {
      method: 'get'
    }).then(function (res) {
      const data = res.data
      data.map(function (option) {
        const value = option.name.toLowerCase()
        const style = {
          id: option.id,
          text: option.name,
          value: value,
          key: value
        }
        styles.push(style)
      })

      self.setState({
        styleOpt: styles
      })
    })
  }

  onName (event) {
    this.setState({
      name: event.target.value
    })
  }

  onPrice (event) {
    this.setState({
      price: event.target.value
    })
  }

  onStock (event) {
    this.setState({
      stock: event.target.value
    })
  }

  onDescript (event) {
    this.setState({
      descript: event.target.value
    })
  }

  handleProductPhoto (event, formData, value) {
    console.log(event)
    const productPhoto = event.map((image)=>(
      {
        name: image.name,
        url: URL.createObjectURL(image),
        file: image
      }
    ))
    this.setState({
      productPhoto: productPhoto
    })
  }

  handleDescriptPhoto (event, formData, value) {
    console.log(event)
    const descriptPhoto = event.map((image)=>(
      {
        name: image.name,
        url: URL.createObjectURL(image),
        file: image
      }
    ))    
    this.setState({
      descriptPhoto: descriptPhoto
    })
  }

  handleSingleSelect (event, data) {
    console.log(data)
    const value = data.value
    const placeholder = data.placeholder
    const option = data.options.filter(function (option) {
      return option.value === value
    })

    if (placeholder === 'Clothing category') {
      this.setState({ clothingCat: option[0] })
    } else if (placeholder === 'Accessory category') {
      this.setState({ accessoryCat: option[0] })
    } else if (placeholder === 'Select gender') {
      this.setState({ gender: option[0] })
    }

    console.log(this.state)
  }

  handleChooseTag (event, data) {
    const self = this
    const tagtype = data.tagtype
    var add = true

    if (tagtype === 'size') {
      var tags = this.state.sizes
    } else if (tagtype === 'color') {
      var tags = this.state.colors
    } else if (tagtype === 'occasion') {
      var tags = this.state.occasions
    } else if (tagtype === 'style') {
      var tags = this.state.styles
    }

    const tag = {
      text: data.text,
      value: data.value,
      id: data.id
    }

    for (let i = 0; i < tags.length; i++) {
      if (tag.id === tags[i].id) {
        add = false
      }
    }

    if (add === true) {
      var tagsNew = tags.concat(tag)
      if (tagtype === 'size') {
        self.setState({ sizes: tagsNew })
      } else if (tagtype === 'color') {
        self.setState({ colors: tagsNew })
      } else if (tagtype === 'occasion') {
        self.setState({ occasions: tagsNew })
      } else if (tagtype === 'style') {
        self.setState({ styles: tagsNew })
      }
    }
  }

  handleDeleteTag (event, data) {
    const tagDel = data.id
    const self = this
    const tagtype = data.tagtype

    if (tagtype === 'size') {
      var tagsNew = this.state.sizes.filter(function (size) {
        return size.id !== tagDel
      })
      self.setState({ sizes: tagsNew })
    } else if (tagtype === 'color') {
      var tagsNew = this.state.colors.filter(function (color) {
        return color.id !== tagDel
      })
      self.setState({ colors: tagsNew })
    } else if (tagtype === 'occasion') {
      var tagsNew = this.state.occasions.filter(function (occasion) {
        return occasion.id !== tagDel
      })
      self.setState({ occasions: tagsNew })
    } else if (tagtype === 'style') {
      var tagsNew = this.state.styles.filter(function (style) {
        return style.id !== tagDel
      })
      self.setState({ styles: tagsNew })
    }
  }

  handleBackClick(){
    const self = this
    self.setState({
        back: true
    })
    }

    handleYesBack(){
        const self = this
        const link = '/merchantpage/product'
        window.location.assign('https://sheyoufashion.com/#' + link)
        self.props.history.push(link)
        window.location.reload(true)
    }

    handleNoBack(){
        this.setState({
            back: false
        })
    }

    handlePreview(){
        const self = this
        const s = self.state
        const activeItem = s.activeItem
        localStorage.removeItem('newProduct')

        self.setState({
          somethingWrong: false,
          previewLoading: true
        })

        if(s.name === '' && s.price === '' && s.stock === '' && s.descript === ''){
            self.setState({
              somethingWrong: 'You have not finished the form. Please finish every section before sumbitting your new product.',
              previewLoading: false
            })
            console.log('1')
        }else{
            if(activeItem === 'newAccessory'){
                if(s.accessoryCat !== {} && s.gender !== {} && s.colors[0] !== undefined && s.occasions[0] !== undefined && s.styles[0] !== undefined){
                  const newProduct = {
                    name: s.name,
                    description: s.descript,
                    price: s.price,
                    stock: s.stock,
                    productPhoto: s.productPhoto.file,
                    descriptPhoto: s.descriptPhoto.file,

                    accessoryCat: s.accessoryCat,
                    gender: s.gender,
                    colors: s.colors,
                    occasions: s.occasions,
                    styles: s.styles
                    }
                  // localStorage.setItem('newProduct', newProduct)
                  self.setState({previewLoading: false})
                  console.log(newProduct)
                }else{
                  self.setState({
                    somethingWrong: 'You have not finished selecting all the tags to your new product. Please select at least one tag for each before hitting the add product button.',
                    previewLoading: false
                  })
                  console.log('2')
                }
            }else{
                if(s.clothingCat !== {} && s.gender !== {} && s.sizes[0] !== undefined && s.colors[0] !== undefined && s.occasions[0] !== undefined && s.styles[0] !== undefined){
                  const newProduct = {
                    name: s.name,
                    description: s.descript,
                    price: s.price,
                    stock: s.stock,
                    productPhoto: s.productPhoto,
                    descriptPhoto: s.descriptPhoto,

                    clothingCat: s.clothingCat,
                    gender: s.gender,
                    sizes: s.sizes,
                    colors: s.colors,
                    occasions: s.occasions,
                    stylesL: s.styles
                    }
                  // localStorage.setItem('newProduct', newProduct)
                  self.setState({previewLoading: false})
                  console.log(newProduct)
                }else{
                    self.setState({
                      somethingWrong: 'You have not finished selecting all the tags to your new product. Please select at least one tag for each before hitting the add product button.',
                      previewLoading: false
                    })
                    console.log('3')
                    }
                }
        }
        

        }


    render(){
        const self = this
        const s = self.state
        const activeItem = s.activeItem
        const productPhoto = s.productPhoto
        const descriptPhoto = s.descriptPhoto
        console.log(productPhoto)
        console.log(descriptPhoto)
        // if(productPhoto !== ''){
        //     console.log(productPhoto[0]['data_url'])
        // }

    return (
      <>
        {this.state.loading ? (
          <Grid>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>

            <Grid.Row colums={2}>
              <Grid.Column width={7}></Grid.Column>
              <Grid.Column width={6}>
                <Header size='huge'>
                  <Icon loading name='asterisk' size='massive' color='yellow' />
                  Loading...
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid>
        ) : (
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column width={11}>
                <Segment style={{ paddingLeft: 20, paddingRight: 20 }}>
                  <Header as='h1'>
                    New Product
                    <Header.Subheader>
                      Main Category: {this.state.activeItem}
                    </Header.Subheader>
                  </Header>
                  <Divider />
                  <Form>
                    <Form.Group widths='equal'>
                      <Form.Input
                        fluid
                        label='Name'
                        placeholder='Name'
                        value={this.state.name}
                        onChange={this.onName}
                      />
                      <Form.Input
                        fluid
                        label='Price'
                        placeholder='Price'
                        value={this.state.price}
                        onChange={this.onPrice}
                      />
                      <Form.Input
                        fluid
                        label='Stock'
                        placeholder='Stock'
                        value={this.state.stock}
                        onChange={this.onStock}
                      />
                    </Form.Group>

                    <Form.TextArea
                      label='Description'
                      placeholder='Please describe your product'
                      value={this.state.descript}
                      onChange={this.onDescript}
                    />
                  </Form>

                  <br />
                  <Divider />
                  <br />

                  <Grid>
                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <Header color='orange' textAlign='centered' as='h3'>
                          Upload Product Photo
                          <Header.Subheader>
                            Maximum of 6 pictures
                          </Header.Subheader>
                          <br />
                          <UploadProductPhoto onUploadPic={this.handleProductPhoto} />
                        </Header>
                      </Grid.Column>
                      <Grid.Column>
                        <Header color='orange' textAlign='centered' as='h3'>
                          Upload Description Photo
                          <Header.Subheader>
                            Maximum of 6 pictures
                          </Header.Subheader>
                          <br />
                          <UploadProductPhoto
                            onUploadPic={this.handleDescriptPhoto}
                          />
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                    
                    <Grid.Row></Grid.Row>

                    <Grid.Row columns={1}>
                      <Grid.Column>
                        <Header color='orange' textAlign='centered' as='h3'>
                          Image Preview
                        </Header>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={1}>
                      <Grid.Column>
                      {(productPhoto === '' || descriptPhoto === '') && (
                          <Image src='https://react.semantic-ui.com/images/wireframe/image.png' />
                        )}

                      {(productPhoto !== '' && descriptPhoto !== '') && (
                        s.productPhoto.map((image) => (
                          <Image
                            src={image.url}
                            size='huge'
                            alt=''
                            key={image.name}
                          />
                        ))
                      )}

                      {(productPhoto !== '' && descriptPhoto !== '') && (
                        s.descriptPhoto.map((image) => (
                          <Image
                            src={image.url}
                            size='huge'
                            alt=''
                            key={image.name}
                          />
                        ))
                      )}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Grid.Column>
              <Grid.Column width={4}>
                <Button size='large' icon labelPosition='left' color='teal' loading={s.previewLoading} onClick={this.handlePreview} >
                  <Icon name='chevron right' />
                  Go Preview
                </Button>
                {s.somethingWrong !== false && (
                  <Message warning>
                    {s.somethingWrong}
                  </Message>
                )}
                <Segment>
                  <Grid centered style={{ paddingTop: 25, paddingBottom: 25 }}>
                    {activeItem === 'newAccessory' && (
                      <Grid.Row className='accessoryCat'>
                        <Dropdown
                          button
                          floating
                          selection
                          placeholder='Accessory category'
                          options={s.accessoryCatOpt}
                          onChange={this.handleSingleSelect}
                        />
                      </Grid.Row>
                    )}

                    {activeItem !== 'newAccessory' && (
                      <Grid.Row className='clothingCat'>
                        <Dropdown
                          button
                          floating
                          selection
                          placeholder='Clothing category'
                          options={s.clothingCatOpt}
                          onChange={this.handleSingleSelect}
                        />
                      </Grid.Row>
                    )}

                    <Grid.Row className='gender'>
                      <Dropdown
                        button
                        floating
                        selection
                        placeholder='Select gender'
                        options={s.genderOpt}
                        onChange={this.handleSingleSelect}
                      />
                    </Grid.Row>

                    {activeItem !== 'newAccessory' && (
                      <>
                        <Grid.Row className='size'>
                          <Dropdown
                            button
                            floating
                            selection
                            text='Select sizes'
                          >
                            <Dropdown.Menu>
                              {s.sizeOpt.map(size => (
                                <Dropdown.Item
                                  key={size.value}
                                  id={size.id}
                                  text={size.text}
                                  value={size.value}
                                  onClick={this.handleChooseTag}
                                  tagtype='size'
                                />
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Grid.Row>
                        {s.sizes.length > 0 && (
                          <Grid.Row
                            style={{ paddingTop: 10, paddingBottom: 10 }}
                          >
                            {s.sizes.map(size => (
                              <Label key={size}>
                                {size.text}
                                <Icon
                                  name='delete'
                                  id={size.id}
                                  onClick={this.handleDeleteTag}
                                  tagtype='size'
                                />
                              </Label>
                            ))}
                          </Grid.Row>
                        )}
                      </>
                    )}

                    <Grid.Row className='color'>
                      <Dropdown button floating selection text='Select Colors'>
                        <Dropdown.Menu>
                          {s.colorOpt.map(color => (
                            <Dropdown.Item
                              key={color.value}
                              id={color.id}
                              text={color.text}
                              value={color.value}
                              onClick={this.handleChooseTag}
                              tagtype='color'
                            />
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Grid.Row>
                    {s.colors.length > 0 && (
                      <Grid.Row style={{ paddingTop: 10, paddingBottom: 10 }}>
                        {s.colors.map(color => (
                          <Label key={color}>
                            {color.text}
                            <Icon
                              name='delete'
                              id={color.id}
                              onClick={this.handleDeleteTag}
                              tagtype='color'
                            />
                          </Label>
                        ))}
                      </Grid.Row>
                    )}

                    <Grid.Row className='occasion'>
                      <Dropdown
                        button
                        floating
                        selection
                        text='Select Occasions'
                      >
                        <Dropdown.Menu>
                          <Dropdown.Menu scrolling>
                            {s.occasionOpt.map(occasion => (
                              <Dropdown.Item
                                key={occasion.value}
                                id={occasion.id}
                                text={occasion.text}
                                value={occasion.value}
                                onClick={this.handleChooseTag}
                                tagtype='occasion'
                              />
                            ))}
                          </Dropdown.Menu>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Grid.Row>
                    {s.occasions.length > 0 && (
                      <Grid.Row style={{ paddingTop: 10, paddingBottom: 10 }}>
                        {s.occasions.map(occasion => (
                          <Label key={occasion.value}>
                            {occasion.text}
                            <Icon
                              name='delete'
                              id={occasion.id}
                              onClick={this.handleDeleteTag}
                              tagtype='occasion'
                            />
                          </Label>
                        ))}
                      </Grid.Row>
                    )}

                    <Grid.Row className='style'>
                      <Dropdown button floating selection text='Select Styles'>
                        <Dropdown.Menu>
                          <Dropdown.Menu scrolling>
                            {s.styleOpt.map(style => (
                              <Dropdown.Item
                                key={style.value}
                                id={style.id}
                                text={style.text}
                                value={style.value}
                                onClick={this.handleChooseTag}
                                tagtype='style'
                              />
                            ))}
                          </Dropdown.Menu>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Grid.Row>
                    {s.styles.length > 0 && (
                      <Grid.Row style={{ paddingTop: 10, paddingBottom: 10 }}>
                        {s.styles.map(style => (
                          <Label key={style.value}>
                            {style.text}
                            <Icon
                              name='delete'
                              id={style.id}
                              onClick={this.handleDeleteTag}
                              tagtype='style'
                            />
                          </Label>
                        ))}
                      </Grid.Row>
                    )}
                  </Grid>
                </Segment>
                <Button
                  onClick={this.handleBackClick}
                  size='large'
                  color='orange'
                >
                  BACK
                </Button>
                {this.state.back === true && (
                  <div style={{ paddingTop: 10 }}>
                    <Message warning>
                      Are you sure you want to go back without adding this
                      product? The information you filled in will not be saved.
                    </Message>
                    <Button onClick={this.handleYesBack}>Yes</Button>
                    <Button onClick={this.handleNoBack}>No</Button>
                  </div>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </>
    )
  }
}

export default withRouter(NewProduct)
