import React from 'react'
import { Grid, Button, Container,Segment, Header, Icon } from 'semantic-ui-react'

import { Parallax,} from 'react-parallax';
import { withRouter } from 'react-router-dom'
// import Nav from '../nav/Nav'


class SignUpSucess extends React.Component {
  constructor(props){
    super(props)
    this.state={
  }
  this.onHandleToSign=this.onHandleToSign.bind(this)


}



onHandleToSign(event){
  const self=this
  self.props.history.push('/signin')
}

  
  render() {

    return<div>
       {/* <Nav /> */}

    
    <Parallax
    bgImage='https://i.loli.net/2020/11/25/ZHjYm1wL9runqOz.png'
    bgImageAlt=''
    strength={400}>
        
    <div>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <Container>
      <Segment>
          
      <Grid centered colums={2}>
        <Grid.Row></Grid.Row>
        <Grid.Row></Grid.Row>
        <Grid.Row>
            <Header size='huge' >
            <Icon name='smile outline'/>
            <Header.Content>You have Suceessfully Registered Now You Can Sign In</Header.Content>
            
            </Header>
        </Grid.Row>
        <Grid.Row>
            <Button onClick={this.onHandleToSign}  size='large'  content='Go Sign In' color='orange' />

        </Grid.Row>
        
        <Grid.Row></Grid.Row>
        <Grid.Row></Grid.Row>
      </Grid>
    </Segment>
      
      
    </Container>
    </div>

    <div style={{ height:"300px"} }/>
</Parallax>
    

</div>
  }

}

export default withRouter(SignUpSucess)









