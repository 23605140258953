import React from 'react'
import { Parallax } from 'react-parallax'
import {
  Segment,
  Button,
  Header,
  Form,
  Grid,
  Message
} from 'semantic-ui-react'
import { Link, withRouter } from 'react-router-dom'
import axios from 'axios'
import Foot from '../foot/Foot'
import timestamp from 'time-stamp'
import MerchantSec from '../homePage/merchantSec/MerchantSec'
const userLang = navigator.language || navigator.userLanguage


class MerchantLog extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      products: [],
      token: '',
      rememberMe: false,
      loading: true
    }
    this.onHandleUsername = this.onHandleUsername.bind(this)
    this.onHandlePassword = this.onHandlePassword.bind(this)
    this.onHandleSubmit = this.onHandleSubmit.bind(this)
    this.onHandleRememberMe = this.onHandleRememberMe.bind(this)
    this.toOpenShop = this.toOpenShop.bind(this)
  }

  toOpenShop(){
    this.props.history.push('/merchantOpenShop')
  }

  componentDidMount () {
    window.scrollTo(0, 0)
    const self = this
    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    const merchantLogin=localStorage.getItem('merchantLogin')
    if(merchantLogin==='true'){
      self.props.history.push('/merchantPage')}

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 700)
    const remember = localStorage.getItem('rememberMe')
    var identifier = localStorage.getItem('identifier')
    var password = localStorage.getItem('password')
    if (remember === 'true')
      this.setState({
        rememberMe: remember,
        username: identifier,
        password: password
      })
  }

  onHandleUsername (event) {
    this.setState({
      username: event.target.value
    })
  }

  onHandlePassword (event) {
    this.setState({
      password: event.target.value
    })
  }

  onHandleSubmit (event) {
    event.preventDefault()
    const remember = this.state.rememberMe
    const self = this

    if (remember === true) {
      const identifier = this.state.username
      const password = this.state.password
      const rememberMe = this.state.rememberMe

      axios
        .post('https://sheyou-backend.herokuapp.com/auth/local', {
          identifier: identifier,
          password: password
        })
        .then(function (res) {
          console.log(res)

          if (res.status === 200) {
            localStorage.removeItem('toLog')
            localStorage.setItem('login', 'true')
            localStorage.setItem('merchantLogin', 'true')
            localStorage.setItem('log', 'true')
            const time = timestamp.utc('YYYY-MM-DD-HH')
            localStorage.setItem('loginTime', time)
            const token = res.data.jwt
            localStorage.setItem('token', token)
            localStorage.setItem('identifier', identifier)
            localStorage.setItem('password', password)
            localStorage.setItem('rememberMe', rememberMe)
            
            // alert('You have logged in '+identifier+'! Welcome to ChicOnly')
            self.props.history.push('/loggingMerchant')
          } else {
            alert('something went wrong')
            localStorage.removeItem('toLog')
            self.setState({
              rememberMe: false,
              username: '',
              password: ''
            })
          }
        })
        .catch(error => {
          localStorage.removeItem('toLog')
          self.setState({
            rememberMe: false,
            username: '',
            password: ''
          })
          if (error.response.status === 400) {
            alert('Wrong username & password combination')
            localStorage.removeItem('identifier')
            localStorage.removeItem('password')
            localStorage.removeItem('rememberMe')
          } else {
            alert('An error occurred:', error.response.statusText)
          }
        })
    } else if (remember === 'true') {
      var identifier = localStorage.getItem('identifier')
      var password = localStorage.getItem('password')
      const identifierRetype = this.state.username
      const passwordRetype = this.state.password
      if (identifierRetype !== '' && passwordRetype !== '') {
        identifier = identifierRetype
        password = passwordRetype
      }
      axios
        .post('https://sheyou-backend.herokuapp.com/auth/local', {
          identifier: identifier,
          password: password
        })
        .then(function (res) {
          console.log(res.status)
          localStorage.removeItem('toLog')
          if (res.status === 200) {
            const time = timestamp.utc('YYYY-MM-DD-HH')
            localStorage.setItem('loginTime', time)
            localStorage.setItem('login', 'true')
            localStorage.setItem('merchantLogin', 'true')
            const token = res.data.jwt
            localStorage.setItem('token', token)
            localStorage.setItem('identifier', identifier)
            localStorage.setItem('password', password)
            localStorage.setItem('rememberMe', remember)
            self.props.history.push('/loggingMerchant')
          } else {
            alert('something went wrong')
            self.setState({
              rememberMe: false,
              username: '',
              password: ''
            })
          }
        })
        .catch(error => {
          localStorage.removeItem('toLog')
          self.setState({
            rememberMe: false,
            username: '',
            password: ''
          })
          if (error.response.status === 400) {
            alert('Wrong username & password combination')
            localStorage.removeItem('identifier')
            localStorage.removeItem('password')
            localStorage.removeItem('rememberMe')
          } else {
            alert('An error occurred:', error.response.statusText)
          }
        })
    } else {
      identifier = localStorage.getItem('identifier')
      password = localStorage.getItem('password')
      const rememberMe = localStorage.getItem('rememberMe')

      console.log(identifier, password)
      if (rememberMe !== null) {
        localStorage.removeItem('rememberMe')
      }

      if (
        (identifier === null && password === null) ||
        (identifier === '' && password === '')
      ) {
        identifier = this.state.username
        password = this.state.password
      }

      axios
        .post('https://sheyou-backend.herokuapp.com/auth/local', {
          identifier: identifier,
          password: password
        })
        .then(function (res) {
          localStorage.removeItem('toLog')
          console.log(res.status)
          if (res.status === 200) {
            localStorage.setItem('login', 'true')
            localStorage.setItem('merchantLogin', 'true')
            const token = res.data.jwt
            const time = timestamp.utc('YYYY-MM-DD-HH')
            localStorage.setItem('loginTime', time)
            localStorage.setItem('token', token)
            localStorage.setItem('identifier', identifier)
            localStorage.setItem('password', password)
            self.props.history.push('/loggingMerchant')
          } else {
            localStorage.removeItem('toLog')
            self.setState({
              rememberMe: false,
              username: '',
              password: ''
            })
            alert('something went wrong')
          }
        })
        .catch(error => {
          localStorage.removeItem('toLog')
          self.setState({
            rememberMe: false,
            username: '',
            password: ''
          })
          if (error.response.status === 400) {
            alert('Wrong username & password combination')
            localStorage.removeItem('identifier')
            localStorage.removeItem('password')
          } else {
            alert('An error occurred:', error.response.statusText)
          }
        })
    }
  }

  onHandleResetPassword (event) {
    this.props.history.push('/forgetpassword')
  }

  onHandleRememberMe (event) {
    const self = this
    const remember = self.state.rememberMe
    if (remember !== 'true') {
      self.setState({ rememberMe: true })
    } else {
      self.setState({ rememberMe: false })
    }
  }

  render () {
    console.log(this.state)
    const remember = this.state.rememberMe
    const identifier = this.state.username
    const password = this.state.password
    const self=this
    const toLog = localStorage.getItem('toLog')

    return (
      <div>
        {/* <Nav /> */}

        <Grid  >
          <Grid.Row style={{ height: 700,paddingBottom:0,marginBottom:0 ,marginTop: 0,
              marginBottom: 0}}>
          <Grid.Column width={6} className='afrogirl'></Grid.Column>
          <Grid.Column width={10} centered className='bg_color_image'>
            <br />
            <br />
            <br />
            <Grid>
              <Grid.Column width={3}></Grid.Column>
              <Grid.Column width={10}>
                <Header style={{ fontSize: 50, color: '#FFFFFF' }} inverted>
                  Welcome, Merchants!
                </Header>
              </Grid.Column>
              <Grid.Column width={3}></Grid.Column>
            </Grid>

            <br />
            <Grid columns={3}>
              <Grid.Column width={3}></Grid.Column>
              <Grid.Column width={10}>
                <Segment
                  style={{
                    paddingTop: 50,
                    paddingBottom: 50,
                    borderRadius: 10
                  }}
                >
                  {toLog === 'true' && (
                    <Message info>
                      <Message.Header>Success</Message.Header>
                      <p>Now You Can Log In</p>
                    </Message>
                  )}
                  <Grid>
                    <Grid.Row columns={4} centered>
                      {/* <Grid.Column width={2}></Grid.Column> */}
                      <Grid.Column>
                        <Header style={{ fontSize: 30 }}>Log In</Header>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <br />
                  <br />
                  <Form style={{ paddingLeft: 20, paddingRight: 20 }}>
                    {remember !== 'true' && (
                      <Form.Input
                        onChange={this.onHandleUsername}
                        icon='user'
                        iconPosition='left'
                        label='Username'
                        placeholder='Username'
                      />
                    )}

                    {remember === 'true' && (
                      <Form.Input
                        onChange={this.onHandleUsername}
                        icon='user'
                        iconPosition='left'
                        label='Username'
                        placeholder='Username'
                        value={identifier}
                      />
                    )}

                    {remember !== 'true' && (
                      <Form.Input
                        onChange={this.onHandlePassword}
                        icon='lock'
                        iconPosition='left'
                        label='Password'
                        type='password'
                      />
                    )}

                    {remember === 'true' && (
                      <Form.Input
                        onChange={this.onHandlePassword}
                        icon='lock'
                        iconPosition='left'
                        label='Password'
                        type='password'
                        value={password}
                      />
                    )}
                    <Link to='/forgetpassword'>
                      <Form.Field>
                          <h style={{color:'#384D5A'}}>
                            Forget Password
                          </h>
                      </Form.Field>
                    </Link>
                  </Form>
                  <br />
                  <br />
                  <Grid>
                    <Grid.Row columns={3}>
                      <Grid.Column width={2}></Grid.Column>
                      <Grid.Column width={6}>
                        <Button
                          onClick={this.onHandleSubmit}
                          content='Log In'
                          color='orange'
                          style={{ paddingLeft: 50, paddingRight: 50 }}
                        />
                      </Grid.Column >

                      <Grid.Column width={7}>
                        <Link to='/merchantOpenShop'>
                          <Button
                            basic
                            content='Create Account'
                            color='orange'
                          />
                        </Link>
                      </Grid.Column>
                      
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Grid.Column>
              <Grid.Column width={3}></Grid.Column>
            </Grid>
          </Grid.Column>

          </Grid.Row>
         

          <Grid.Row>
            <Parallax
              bgImage={
                'https://res.cloudinary.com/sheyou/image/upload/v1654170472/beautiful_women_posing_in_underwear_2022_02_08_02_06_16_utc_d9a0755449.jpg'
              }
              bgImageAlt='image'
              strength={-200}
            >
              <Segment style={{ backgroundColor: 'rgba(0,0,0,0.3)' }}>
              <Grid style={{ paddingTop: 80, paddingBottom: 200,marginTop:0,marginBottom:0 }}>
                <Grid.Row
                  columns={3}
                 
                >
                  <Grid.Column width={1}></Grid.Column>
                  <Grid.Column width={14}>
                    <Header
                      style={{
                        color: 'white',
                        fontSize: 100
                      }}
                    >
                      “
                    </Header>
                    <Header
                      style={{
                        position: 'relative',
                        color: 'white',
                        fontSize: 50
                      }}
                    >
                      {userLang === 'zh-CN' && '没有衣服穿'}
                      {userLang !== 'zh-CN' && 'I Have Nothing To Wear'}
                    </Header>
                  </Grid.Column>
                  <Grid.Column width={1}></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={1}></Grid.Column>
                  <Grid.Column width={8}>
                    <Header as='h3' style={{ color: 'white' }}>
                      {userLang === 'zh-CN' &&
                        '研究表明，80%的女性平均仅将衣柜里的衣服穿五次'}
                      {userLang !== 'zh-CN' &&
                        'Research shows 80% of women wear the clothing in their closets an average of 5 times.'}
                    </Header>{' '}
                  </Grid.Column>
                  <Grid.Column width={2}></Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={1}></Grid.Column>
                  <Grid.Column width={8}>
                    <Header as='h3' style={{ color: 'white' }}>
                      {userLang === 'zh-CN' &&
                        '租用可以减少购买服装的高额支出。既能满足想穿新衣服的愿望，又能减轻整理衣橱和洗衣服造成的负担。'}
                      {userLang !== 'zh-CN' &&
                        'Renting can reduce high expenses on garments. Satisfy both the want to wear something new and release the stress of closet organizing and laundry.'}
                    </Header>
                  </Grid.Column>
                  <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
              </Grid>
              </Segment>
            </Parallax>
          </Grid.Row>
          <Grid.Row style={{ height: 600, paddingTop: 0, marginTop: 0 }}>
            <Parallax
              bgImage={
                'https://res.cloudinary.com/sheyou/image/upload/v1654417910/three_warehouse_workers_doing_garment_stock_take_i_2022_03_07_23_58_38_utc_a576439648.jpg'
              }
              style={{ paddingRight: 400 }}
              bgImageAlt='merchant page image'
              strength={300}
            >
              <Segment style={{ backgroundColor: 'rgba(0,0,0,0.3)' }}>
                <Grid centered style={{ paddingTop: 100, paddingBottom: 300 }}>
                <Header
                          style={{
                            color: 'white',
                            fontSize: 50,
                            paddingBottom:100
                          }}
                        >
                          {userLang === 'zh-CN' && '商户面对用户租借'}
                          {userLang !== 'zh-CN' &&
                            'Business To Customer Renting'}
                        </Header>
                  <Grid.Row columns={3}>
                  
                    <Grid.Column width={4}></Grid.Column>
                    <Grid.Column width={8}>
                      <div>
                        
                        {userLang === 'zh-CN' && (
                          <>
                            <Header
                              as='h3'
                              style={{
                                color: 'white'
                              }}
                            >
                              舍予共享衣柜为个人品牌及商户提供平台以出租及销售他们的设计
                            </Header>
                            <Header
                              as='h3'
                              style={{
                                color: 'white'
                              }}
                            >
                              我们与专业的洗衣服务机构合作，只要每件大约20元左右的成本让商户出租更加方便
                            </Header>
                          </>
                        )}

                        {userLang !== 'zh-CN' && (
                          <>
                            <Header
                              as='h3'
                              style={{
                                color: 'white'
                              }}
                            >
                              SHEYOU Provide individual brands, designers and
                              Merchants with a platform to rent out and sell
                              their products.
                            </Header>
                            <Header
                              as='h3'
                              style={{
                                color: 'white'
                              }}
                            >
                              We also cooperates with professional laundry
                              services to make renting for merchants easy with
                              around 20 CYN a piece cost.
                            </Header>
                          </>
                        )}
                      </div>
                    </Grid.Column>
                    <Grid.Column width={4}></Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Parallax>
          </Grid.Row>

          <MerchantSec/>
        </Grid>

        <Foot />
      </div>
    )
  }
}

export default withRouter(MerchantLog)
