import {getNames, getCodes} from 'country-list'

function countrySelect(){
    const countries = getNames()
    const countryOptions = countries.map(name => ({value:'', text: name})) 
    const codes = getCodes()
    for(let i=0; i < 249; i++){
        countryOptions[i].value = codes[i]
    }
    return countryOptions
}




export {countrySelect}