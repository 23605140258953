import React from 'react'
import { Menu } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
const userLang = navigator.language || navigator.userLanguage



class AccessoryFilter extends React.Component {
    constructor(props){
    super(props)
    this.state = { 
      activeItem: 'All Accessories' ,
  }
  this.handleItemClick=this.handleItemClick.bind(this)
}


componentDidMount() {
  const self=this
  const activeItem = this.props.activeItem
  console.log(activeItem)
  if(activeItem!==undefined||activeItem!==null||activeItem!=='')
  {self.setState({activeItem:activeItem})}
}

handleItemClick(event,data){
  const name=data.name
  const link=data.link
  const self=this
  window.location.assign('https://sheyou-backend.herokuapp.com/#'+link)
    if (name === undefined || name === null) {
      self.props.history.push('/accessory')
      window.location.reload(true)
      
    }
    else {
      self.props.history.push(link)
      window.location.reload(true)
    }
}

  render() {
    const  activeItem  = this.state.activeItem
    console.log(activeItem)

    return (
      <>
        {userLang === 'zh-CN' && (
          <Menu  fluid vertical  >
            <Menu.Item header>类别</Menu.Item>

            <Menu.Item
              name='全部配饰'
              link='/accessory'
              active={activeItem === 'All Accessories'}
              onClick={this.handleItemClick}
            />

            <Menu.Item
              name='珠宝'
              link='/accessory/jewelry'
              active={activeItem === 'Jewelry'}
              onClick={this.handleItemClick}
            />

            <Menu.Item
              name='包袋'
              link='/accessory/handbags'
              active={activeItem === 'Bags'}
              onClick={this.handleItemClick}
            />

            <Menu.Item
              name='手套'
              link='/accessory/scarves'
              active={activeItem === 'Scarves'}
              onClick={this.handleItemClick}
            />

            <Menu.Item
              name='帽子'
              link='/accessory/hats'
              active={activeItem === 'Hats'}
              onClick={this.handleItemClick}
            />

            <Menu.Item
              name='墨镜'
              link='/accessory/sunglasses'
              active={activeItem === 'Sunglasses'}
              onClick={this.handleItemClick}
            />

          </Menu>
        )}

        {userLang !== 'zh-CN' && (
          <Menu  fluid vertical  >
            <Menu.Item header>Categories</Menu.Item>

            <Menu.Item
              name='All Accessories'
              link='/accessory'
              active={activeItem === 'All Accessories'}
              onClick={this.handleItemClick}
            />

            <Menu.Item
              name='Jewelry'
              link='/accessory/jewelry'
              active={activeItem === 'Jewelry'}
              onClick={this.handleItemClick}
            />

            <Menu.Item
              name='Bags'
              link='/accessory/handbags'
              active={activeItem === 'Bags'}
              onClick={this.handleItemClick}
            />

            <Menu.Item
              name='Scarves'
              link='/accessory/scarves'
              active={activeItem === 'Scarves'}
              onClick={this.handleItemClick}
            />

            <Menu.Item
              name='Hats'
              link='/accessory/hats'
              active={activeItem === 'Hats'}
              onClick={this.handleItemClick}
            />

            <Menu.Item
              name='Sunglasses'
              link='/accessory/sunglasses'
              active={activeItem === 'Sunglasses'}
              onClick={this.handleItemClick}
            />

          </Menu>
        )}
      </>
    )
  }
}
 
export default withRouter(AccessoryFilter)