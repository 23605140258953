import React from 'react'
import { Header } from 'semantic-ui-react'
const userLang = navigator.language || navigator.userLanguage

const IntroPageText = () => (
  <div>
    <Header style={{ color: 'white', fontSize: 30,paddingLeft:20,paddingRight:20 }}>
      {userLang === 'zh-CN' && '成为SHEYOU商户'}
      {userLang !== 'zh-CN' && 'Become Merchant In SHEYOU'}
    </Header>
    <Header as='h5' style={{ color: 'white' }}>
      {userLang === 'zh-CN' && '租+卖=全新商业模式'}
      {userLang !== 'zh-CN' && 'Rent+ Sell= New Business Model'}
    </Header>

    {/* <Header as='h5' style={{ color: 'white' }}>
      Renting multiple clothes and return whenever you want, or try purchasing
      them with a discount, you can have more styles with less budget
    </Header> */}
  </div>
)

export default IntroPageText
