import React from 'react'
import { Header,Segment } from 'semantic-ui-react'
import SustainButton from './SustainSecButton'
const userLang = navigator.language || navigator.userLanguage



const SustainPageText = () => (
  <div>
    {userLang === 'zh-CN' && (
      <Segment 
      circular
      // style={{backgroundColor:'rgba(255,255,255,.8)', padding:20}}
      style={{backgroundColor:'rgba(0,0,0,0.2)',  height:400, width:400}}
  
      >
  
        <Header size='large' inverted>SHEYOU的背后</Header>
        <Header size='huge' inverted>可持续时尚 & 穿衣自由</Header>

        <Header size='small' inverted>
        点击了解共享衣橱是如何运作的
        <br/>
        并探索时尚 vs. 可持续时尚的不同理念
        <br/>
        </Header>
        <br/>
        <br/>
        <SustainButton/>
        
      </Segment>
    )
    }

    {userLang !== 'zh-CN' && (
      <Segment 
      circular
      // style={{backgroundColor:'rgba(255,255,255,.8)', padding:20}}
      style={{backgroundColor:'rgba(0,0,0,0.2)',  height:400, width:400}}
  
      >
  
        <Header size='large' inverted>Behind SHEYOU</Header>
        <Header size='huge' inverted>Sustainability & Clothing Freedom</Header>
  
        <Header size='small' inverted>
        Click below to learn how clothes sharing works
        <br/>
        and ideas behind fashion vs. sustainable fashion
        <br/>
        </Header>
        <br/>
        <br/>
        <SustainButton/>
        
      </Segment>
    )}
  </div>
)

export default SustainPageText