import React from 'react'
import axios from 'axios'
import {
    Grid, Icon, Container, Header,
    Segment, Divider,
} from 'semantic-ui-react'
// import {
//     Link
//   } from "react-router-dom"; 
// import Nav from '../nav/Nav'
import { withRouter } from 'react-router-dom'
// import HandleBuyCartDelete from '../cartFunction/HandleBuyCartDelete'
import DeliveryPageShipping from './DeliveryPageShipping'
import Foot from '../foot/Foot';

class DeliveryPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            orderInfo: [],
            deliveryData: [],
            loading: true,
            returnInfo: null,
            date: '',
            time: '',
            buyProducts: [],
            userInfo: [],
            cart: [],
            firstNameSA: '',
            lastNameSA: '',
            accessoryBag: [],
            select: 'square outline',
            selectDefault: 'check square outline',
            selectID: '',


        }

        this.getDeliveryData = this.getDeliveryData.bind(this)
        this.getInfo = this.getInfo.bind(this)


    }

    componentDidMount() {
        window.scrollTo(0, 0)
        const self = this
        window.setTimeout(() => {
            if (self.state.loading === true) {
                this.setState({
                    loading: false
                })
            }

        }, 1000);
        this.getInfo()
        

    }
    getInfo() {
        const orderToDeliveryId = localStorage.getItem("orderToDeliveryId")
        const self = this

        axios('https://sheyou-backend.herokuapp.com/orders?id=' + orderToDeliveryId, {
            method: 'get'
        })
            .then(function (res) {
                if (res.status === 200) {
                    const theData = res.data[0]
                    self.setState({
                        orderInfo: theData
                    })
                    self.getDeliveryData()
                }
                else {
                    alert('Something went wrong, maybe log in again :(')
                    localStorage.setItem('login', 'false')
                }

            })
    }

    getDeliveryData() {
        const self = this
        const orderInfo = self.state.orderInfo
        const deliveryNum = orderInfo.DeliveryNum
        if (deliveryNum !== null && deliveryNum !== undefined) {
            axios('https://api.jisuapi.com/express/query?appkey=7ce376abb8f0b002&type=auto&number=' + deliveryNum, {
                method: 'post'
            })
            .then(function (res) {
                const theData = res.data.result
                console.log(res)
                self.setState({
                    deliveryData: theData,
                    loading: false
                })
            })
        }

    }



    render() {

        console.log(this.state)
        const orderInfo = this.state.orderInfo

        const deliveryData = this.state.deliveryData
        console.log(deliveryData)
        const deliveryStatus = deliveryData.deliverystatus

        return <div>

            {/* <Nav /> */}


            {this.state.loading ? (
                <Grid >
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>


                    <Grid.Row colums={2}>
                        <Grid.Column width={7}></Grid.Column>
                        <Grid.Column width={6}>
                            <Header size='huge'>
                                <Icon loading name='asterisk' size='massive' color='yellow' />
                                Loading...
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                </Grid>
            ) : (<>


                <div className='bg_image'>
                    <br />
                    <br />
                    <Container>

                        <Segment >
                            <Grid container>

                                <>
                                    {/* placedOrder */}
                                    {orderInfo.length !== 0
                                        && deliveryStatus !== 1
                                        && deliveryStatus !== 2
                                        && deliveryStatus !== 3
                                        // &&deliveryData===0
                                        // &&orderInfo.orders.status.placedOrder!==false
                                        // &&orderInfo.orders.status.deliveryToShippingUnits===false
                                        // &&orderInfo.orders.status.ordersAreBeingShipped===false
                                        // &&orderInfo.orders.status.deliveredSuccessfully===false
                                        &&
                                        <Grid.Row columns={7} style={{ marginTop: 20 }}>
                                            <Grid.Column width={1}>
                                            </Grid.Column>
                                            <Grid.Column width={2}>
                                                <Icon style={{ paddingLeft: 20 }} centered size='big' name='shopping cart' color='orange' />
                                                <Header as='h6' textAlign='left' style={{ padding: 0, marginTop: 7 }}>Placed Order</Header>
                                            </Grid.Column>
                                            <Grid.Column width={2}>
                                                <Divider />
                                            </Grid.Column>

                                            <Grid.Column width={2}>
                                                <Icon style={{ paddingLeft: 30 }} size='big' name='dolly flatbed' color='grey' />
                                                <Header as='h6' textAlign='left' style={{ padding: 0, marginTop: 7 }}>Delivery to shipping units</Header>
                                            </Grid.Column>

                                            <Grid.Column width={2}>
                                                <Divider />
                                            </Grid.Column>

                                            <Grid.Column width={2}>
                                                <Icon style={{ paddingLeft: 25 }} size='big' name='truck' color='grey' />
                                                <Header as='h6' textAlign='left' style={{ padding: 0, marginTop: 7 }}>Orders are being shipped</Header>
                                            </Grid.Column>

                                            <Grid.Column width={2}>
                                                <Divider />
                                            </Grid.Column>

                                            <Grid.Column width={2}>
                                                <Icon style={{ paddingLeft: 10 }} size='big' name='heart' color='grey' />
                                                <Header as='h6' textAlign='left' style={{ padding: 0, marginTop: 7 }}>Delivered successfully</Header>
                                            </Grid.Column>
                                        </Grid.Row>

                                    }

                                    {orderInfo.length !== 0
                                        && deliveryStatus === 1
                                        // &&orderInfo.orders.status.placedOrder!==false
                                        // &&orderInfo.orders.status.deliveryToShippingUnits!==false
                                        // &&orderInfo.orders.status.ordersAreBeingShipped===false
                                        // &&orderInfo.orders.status.deliveredSuccessfully===false
                                        &&
                                        <Grid.Row columns={7} style={{ marginTop: 20 }}>
                                            <Grid.Column width={1}>
                                            </Grid.Column>
                                            <Grid.Column width={2}>
                                                <Icon style={{ paddingLeft: 20 }} centered size='big' name='shopping cart' color='orange' />
                                                <Header as='h6' textAlign='left' style={{ padding: 0, marginTop: 7 }}>Placed Order</Header>
                                            </Grid.Column>
                                            <Grid.Column width={2}>
                                                <Divider />
                                            </Grid.Column>

                                            <Grid.Column width={2}>
                                                <Icon style={{ paddingLeft: 30 }} size='big' name='dolly flatbed' color='orange' />
                                                <Header as='h6' textAlign='left' style={{ padding: 0, marginTop: 7 }}>Delivery to shipping units</Header>
                                            </Grid.Column>

                                            <Grid.Column width={2}>
                                                <Divider />
                                            </Grid.Column>

                                            <Grid.Column width={2}>
                                                <Icon style={{ paddingLeft: 25 }} size='big' name='truck' color='grey' />
                                                <Header as='h6' textAlign='left' style={{ padding: 0, marginTop: 7 }}>Orders are being shipped</Header>
                                            </Grid.Column>

                                            <Grid.Column width={2}>
                                                <Divider />
                                            </Grid.Column>

                                            <Grid.Column width={2}>
                                                <Icon style={{ paddingLeft: 10 }} size='big' name='heart' color='grey' />
                                                <Header as='h6' textAlign='left' style={{ padding: 0, marginTop: 7 }}>Delivered successfully</Header>
                                            </Grid.Column>
                                        </Grid.Row>
                                    }

                                    {orderInfo.length !== 0
                                        && deliveryStatus === 2
                                        // &&orderInfo.orders.status.placedOrder!==false
                                        // &&orderInfo.orders.status.deliveryToShippingUnits!==false
                                        // &&orderInfo.orders.status.ordersAreBeingShipped!==false
                                        // &&orderInfo.orders.status.deliveredSuccessfully===false
                                        &&
                                        <Grid.Row columns={7} style={{ marginTop: 20 }}>
                                            <Grid.Column width={1}>
                                            </Grid.Column>
                                            <Grid.Column width={2}>
                                                <Icon style={{ paddingLeft: 20 }} centered size='big' name='shopping cart' color='orange' />
                                                <Header as='h6' textAlign='left' style={{ padding: 0, marginTop: 7 }}>Placed Order</Header>
                                            </Grid.Column>
                                            <Grid.Column width={2}>
                                                <Divider />
                                            </Grid.Column>

                                            <Grid.Column width={2}>
                                                <Icon style={{ paddingLeft: 30 }} size='big' name='dolly flatbed' color='orange' />
                                                <Header as='h6' textAlign='left' style={{ padding: 0, marginTop: 7 }}>Delivery to shipping units</Header>
                                            </Grid.Column>

                                            <Grid.Column width={2}>
                                                <Divider />
                                            </Grid.Column>

                                            <Grid.Column width={2}>
                                                <Icon style={{ paddingLeft: 25 }} size='big' name='truck' color='orange' />
                                                <Header as='h6' textAlign='left' style={{ padding: 0, marginTop: 7 }}>Orders are being shipped</Header>
                                            </Grid.Column>

                                            <Grid.Column width={2}>
                                                <Divider />
                                            </Grid.Column>

                                            <Grid.Column width={2}>
                                                <Icon style={{ paddingLeft: 10 }} size='big' name='heart' color='grey' />
                                                <Header as='h6' textAlign='left' style={{ padding: 0, marginTop: 7 }}>Delivered successfully</Header>
                                            </Grid.Column>
                                        </Grid.Row>
                                    }

                                    {orderInfo.length !== 0
                                        && deliveryStatus === 3
                                        // &&orderInfo.orders.status.placedOrder!==false
                                        // &&orderInfo.orders.status.deliveryToShippingUnits!==false
                                        // &&orderInfo.orders.status.ordersAreBeingShipped!==false
                                        // &&orderInfo.orders.status.deliveredSuccessfully!==false
                                        &&
                                        <Grid.Row columns={7} style={{ marginTop: 20 }}>
                                            <Grid.Column width={1}>
                                            </Grid.Column>
                                            <Grid.Column width={2}>
                                                <Icon style={{ paddingLeft: 20 }} centered size='big' name='shopping cart' color='orange' />
                                                <Header as='h6' textAlign='left' style={{ padding: 0, marginTop: 7 }}>Placed Order</Header>
                                            </Grid.Column>
                                            <Grid.Column width={2}>
                                                <Divider />
                                            </Grid.Column>

                                            <Grid.Column width={2}>
                                                <Icon style={{ paddingLeft: 30 }} size='big' name='dolly flatbed' color='orange' />
                                                <Header as='h6' textAlign='left' style={{ padding: 0, marginTop: 7 }}>Delivery to shipping units</Header>
                                            </Grid.Column>

                                            <Grid.Column width={2}>
                                                <Divider />
                                            </Grid.Column>

                                            <Grid.Column width={2}>
                                                <Icon style={{ paddingLeft: 25 }} size='big' name='truck' color='orange' />
                                                <Header as='h6' textAlign='left' style={{ padding: 0, marginTop: 7 }}>Orders are being shipped</Header>
                                            </Grid.Column>

                                            <Grid.Column width={2}>
                                                <Divider />
                                            </Grid.Column>

                                            <Grid.Column width={2}>
                                                <Icon style={{ paddingLeft: 10 }} size='big' name='heart' color='orange' />
                                                <Header as='h6' textAlign='left' style={{ padding: 0, marginTop: 7 }}>Delivered successfully</Header>
                                            </Grid.Column>
                                        </Grid.Row>
                                    }
                                    <Divider />


                                    {
                                        orderInfo.length !== 0 &&
                                        <DeliveryPageShipping orderInfo={orderInfo} />
                                    }
                                </>


                            </Grid>
                            <br />

                        </Segment>

                    </Container>
                    <br />
                    <br />
                </div>

            </>
            )}
            <Foot />

        </div>
    }

}

export default withRouter(DeliveryPage)


