import React from 'react'
import axios from 'axios'
import {
  Grid,
  Menu,
  Header,
  Button,
  Segment,
  Icon,
  Sidebar,
  Label,
  Divider
} from 'semantic-ui-react'
import Setting from '../profilePage/Setting'
import { Link } from 'react-router-dom'

import LoadingPhone from '../RedirectPages/LoadingPhone'
import PastOrders from '../pastOrders/PastOrdersList'
import PastPastOrders from '../pastOrders/PastPastOrderList'
import OngoingPastOrders from '../pastOrders/OngoingtPastOrderList'
import Profile from '../profilePage/ProfilePage'
import Membership from '../membership/Membership'
import NavMobilePhone from '../nav/NavMobilePhone'
import LogInPagePhonePop from './LogInPagePhonePop'

class MyPagePhone extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      products: [],
      order: [],
      someData: [],
      activeItem: 'All',
      clickValue: '',
      login: false,
      buyOrRent: '',
      justSomeProductInfo: [],
      orders: [],
      loading: true,
      signinVisible: false,
      itemsVisible: false
    }
    this.getProducts = this.getProducts.bind(this)
    this.signinVisible = this.signinVisible.bind(this)
    this.itemsVisible = this.itemsVisible.bind(this)
  }

  signinVisible () {
    const signinVisible = this.state.signinVisible
    console.log(signinVisible)

    if (signinVisible !== true) {
      this.setState({
        signinVisible: true
      })
    } else if (signinVisible === true) {
      this.setState({
        signinVisible: false
      })
    }
  }

  itemsVisible () {
    const itemsVisible = this.state.itemsVisible
    console.log(itemsVisible)

    if (itemsVisible !== true) {
      this.setState({
        itemsVisible: true
      })
    } else if (itemsVisible === true) {
      this.setState({
        itemsVisible: false
      })
    }
  }

  componentDidMount () {
    window.scrollTo(0, 0)
    const self = this

    this.getProducts()
    this.ifLog()

    console.log(this.props)
    if (this.props.activeItem === 'Membership') {
      self.setState({
        activeItem: 'Membership'
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        self.setState({
          loading: false
        })
      }
    }, 1000)
  }

  getProducts () {
    const self = this
    // const token=this.state.token
    const identifier = localStorage.getItem('identifier')
    const token = localStorage.getItem('token')

    axios('https://sheyou-backend.herokuapp.com/orders', {
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (res) {
      if (res.status === 200) {
        const d = res.data.filter(function (order) {
          if (order.orders.data.identifier === identifier) {
            return order
          } else {
            if (order.orders.info !== undefined) {
              if (order.orders.info.identifier === identifier) {
                return order
              }
            }
          }
        })
        const len = d.length
        const orders = []
        for (var i = len - 1; i >= 0; i--) {
          orders.push(d[i])
        }
        self.setState({
          orders: orders
        })

        window.setTimeout(() => {
          self.setState({
            loading: false
          })
        }, 1000)
      } else {
        alert('something went wrong :(')
        localStorage.setItem('login', 'false')
      }
    })
  }

  ifLog () {
    const log = localStorage.getItem('login')
    const self = this
    if (log === 'false') {
      self.setState({ login: false })
    } else {
      self.setState({ login: true })
    }
  }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  render () {
    const s = this.state
    const self = this
    console.log(s)
    const orders = s.orders
    const loading = s.loading
    const activeItem = this.state.activeItem
    const login = s.login

    // var ordersWithRealProductInfo = localStorage.getItem('ordersWithRealProductInfo')
    // ordersWithRealProductInfo = JSON.parse(ordersWithRealProductInfo)

    return (
      <div>
        {/* <Nav /> */}

        {loading === true && <LoadingPhone />}

        {loading === false && login !== true && (
          <>
            <Menu icon borderless style={{ paddingTop: 20 }}>
              <Grid columns='equal'>
                <Grid.Column width={1}></Grid.Column>
                <Grid.Column width={2}>
                  <Menu.Item
                    name='Setting'
                    active={activeItem === 'Setting'}
                    onClick={self.itemsVisible}
                  >
                    <Icon
                      name='setting'
                      size='large'
                      color='grey'
                      style={{ position: 'relative', top: -2 }}
                    />
                  </Menu.Item>
                </Grid.Column>

                <Grid.Column width={1}></Grid.Column>

                <Grid.Column width={6}>
                  <Menu.Item>
                    <Header
                      as='h1'
                      color='teal'
                      style={{ position: 'relative', top: -9 }}
                    >
                      SHEYOU
                    </Header>
                  </Menu.Item>
                </Grid.Column>

                <Grid.Column width={1}></Grid.Column>

                <Grid.Column width={3}>
                  <Menu.Item
                    name='Messages'
                    active={activeItem === 'Messages'}
                    onClick={self.signinVisible}
                  >
                    <Icon name='inbox' size='large' color='grey'>
                      <Label floating circular>
                        0
                      </Label>
                    </Icon>
                  </Menu.Item>
                </Grid.Column>
              </Grid>
            </Menu>

            <div className='bg_color_image_green_orange'>
              <Grid
                columns='equal'
                divided
                style={{ paddingTop: 50, paddingBottom: 50 }}
              >
                <Grid.Column width={2}></Grid.Column>
                <Grid.Column width={7}>
                  <Grid.Row></Grid.Row>
                  <Grid.Row></Grid.Row>
                  <Grid.Row
                    style={{ paddingBottom: 0, marginBottom: 0, marginTop: 30 }}
                  >
                    <Icon
                      circular
                      name='user'
                      color='grey'
                      inverted
                      onClick={self.signinVisible}
                      style={{ fontSize: '40px' }}
                    />
                  </Grid.Row>
                  <Grid.Row style={{ paddingTop: 0, marginTop: 0 }}>
                    <Header
                      as='h5'
                      onClick={self.signinVisible}
                      style={{
                        marginTop: 5,
                        paddingLeft: 10,
                        color: 'rgba(59,83,98)'
                      }}
                    >
                      <Icon.Group size='small'>
                        <Icon name='write' />
                      </Icon.Group>
                      Name
                    </Header>
                  </Grid.Row>
                  <Grid.Row></Grid.Row>
                </Grid.Column>

                <Grid.Column width={4}>
                  <Grid.Row centered>
                    <Icon.Group onClick={self.signinVisible}>
                      <Icon size='huge' name='circle' inverted />
                      <Icon
                        style={{
                          paddingLeft: 0,
                          marginLeft: 0,
                          paddingRight: 3,
                          opacity: '0.9'
                        }}
                        color='orange'
                        size='big'
                        name='shopping bag'
                      />
                    </Icon.Group>
                    <Header
                      onClick={self.signinVisible}
                      as='h5'
                      style={{
                        paddingTop: 0,
                        marginTop: 5,
                        marginBottom: 10,
                        paddingLeft: 10,
                        color: 'rgba(59,83,98)'
                      }}
                    >
                      Orders
                    </Header>
                  </Grid.Row>

                  <Grid.Row centered>
                    <Icon.Group onClick={self.signinVisible}>
                      <Icon size='huge' name='circle' inverted />
                      <Icon
                        style={{
                          paddingLeft: 0,
                          marginLeft: 0,
                          paddingRight: 3,
                          opacity: '0.9'
                        }}
                        color='orange'
                        size='big'
                        name='camera'
                      />
                    </Icon.Group>
                    <Header
                      onClick={self.signinVisible}
                      as='h5'
                      style={{
                        paddingTop: 0,
                        color: 'rgba(59,83,98)',
                        marginTop: 5,
                        paddingLeft: 15
                      }}
                    >
                      Post
                    </Header>
                  </Grid.Row>

                  <Grid.Row></Grid.Row>
                </Grid.Column>
              </Grid>
            </div>

            <div style={{ backgroundColor: '#E6E9EA', paddingTop: 10 }}>
              <Menu
                vertical
                borderless
                style={{ width: '100%', paddingTop: 5, paddingBottom: 5 }}
              >
                <Menu.Item
                  name='Profile Detail'
                  active={activeItem === 'Profile Detail'}
                  onClick={this.handleItemClick}
                  style={{ paddingBottom: 0 }}
                >
                  <Grid columns='equal '>
                    <Grid.Column width={1}></Grid.Column>
                    <Grid.Column width={2}>
                      <Icon color='teal' circular name='write' />
                    </Grid.Column>
                    <Grid.Column
                      style={{ position: 'relative', top: 6 }}
                      width={10}
                    >
                      <Header style={{ color: 'rgba(59,83,98)' }} as='h5'>
                        Profile Detail
                      </Header>
                    </Grid.Column>
                    <Grid.Column style={{ position: 'relative', top: 6 }}>
                      <Icon name='angle right' />
                    </Grid.Column>
                  </Grid>
                </Menu.Item>

                <Menu.Item
                  name='Fit Preference'
                  active={activeItem === 'Fit Preference'}
                  onClick={this.handleItemClick}
                >
                  <Grid columns='equal '>
                    <Grid.Column width={1}></Grid.Column>
                    <Grid.Column width={2}>
                      <Icon color='teal' circular name='sliders horizontal' />
                    </Grid.Column>
                    <Grid.Column
                      style={{ position: 'relative', top: 6 }}
                      width={10}
                    >
                      <Header style={{ color: 'rgba(59,83,98)' }} as='h5'>
                        Fit Preference
                      </Header>
                    </Grid.Column>
                    <Grid.Column style={{ position: 'relative', top: 6 }}>
                      <Icon name='angle right' />
                    </Grid.Column>
                  </Grid>
                </Menu.Item>
              </Menu>
            </div>

            <div style={{ backgroundColor: '#E6E9EA', paddingTop: 10 }}>
              <Menu
                vertical
                borderless
                style={{ width: '100%', paddingTop: 5, paddingBottom: 5 }}
              >
                <Menu.Item
                  name='Payment'
                  active={activeItem === 'Payment'}
                  onClick={this.handleItemClick}
                  style={{ paddingBottom: 0 }}
                >
                  <Grid columns='equal '>
                    <Grid.Column width={1}></Grid.Column>
                    <Grid.Column width={2}>
                      <Icon color='teal' circular name='credit card' />
                    </Grid.Column>
                    <Grid.Column
                      style={{ position: 'relative', top: 6 }}
                      width={10}
                    >
                      <Header style={{ color: 'rgba(59,83,98)' }} as='h5'>
                        Payment Method
                      </Header>
                    </Grid.Column>
                    <Grid.Column style={{ position: 'relative', top: 6 }}>
                      <Icon name='angle right' />
                    </Grid.Column>
                  </Grid>
                </Menu.Item>

                <Menu.Item
                  name='Address'
                  active={activeItem === 'Address'}
                  onClick={this.handleItemClick}
                  style={{ paddingBottom: 0 }}
                >
                  <Grid columns='equal '>
                    <Grid.Column width={1}></Grid.Column>
                    <Grid.Column width={2}>
                      <Icon color='teal' circular name='address book' />
                    </Grid.Column>
                    <Grid.Column
                      style={{ position: 'relative', top: 6 }}
                      width={10}
                    >
                      <Header style={{ color: 'rgba(59,83,98)' }} as='h5'>
                        My Address
                      </Header>
                    </Grid.Column>
                    <Grid.Column style={{ position: 'relative', top: 6 }}>
                      <Icon name='angle right' />
                    </Grid.Column>
                  </Grid>
                </Menu.Item>

                <Menu.Item
                  name='Get Discount'
                  active={activeItem === 'Get Discount'}
                  onClick={this.handleItemClick}
                >
                  <Grid columns='equal '>
                    <Grid.Column width={1}></Grid.Column>
                    <Grid.Column width={2}>
                      <Icon color='teal' circular name='gift' />
                    </Grid.Column>
                    <Grid.Column
                      style={{ position: 'relative', top: 6 }}
                      width={10}
                    >
                      <Header style={{ color: 'rgba(59,83,98)' }} as='h5'>
                        Get Discount
                      </Header>
                    </Grid.Column>
                    <Grid.Column style={{ position: 'relative', top: 6 }}>
                      <Icon name='angle right' />
                    </Grid.Column>
                  </Grid>
                </Menu.Item>
              </Menu>
            </div>

            <div style={{ backgroundColor: '#E6E9EA', paddingTop: 10 }}>
              <Menu
                vertical
                borderless
                style={{ width: '100%', paddingTop: 5, paddingBottom: 5 }}
              >
                <Menu.Item
                  name='Be A Merchant'
                  active={activeItem === 'Be A Merchant'}
                  onClick={this.handleItemClick}
                >
                  <Grid columns='equal '>
                    <Grid.Column width={1}></Grid.Column>
                    <Grid.Column width={2}>
                      <Icon name='flag' color='teal' circular/>
                        
                        
                    </Grid.Column>
                    <Grid.Column
                      style={{ position: 'relative', top: 6 }}
                      width={10}
                    >
                      <Header style={{ color: 'rgba(59,83,98)' }} as='h5'>
                        Be A Merchant
                      </Header>
                    </Grid.Column>
                    <Grid.Column style={{ position: 'relative', top: 6 }}>
                      <Icon name='angle right' />
                    </Grid.Column>
                  </Grid>
                </Menu.Item>
              </Menu>
            </div>

            <div style={{ backgroundColor: '#E6E9EA', paddingTop: 10 }}>
              <Menu vertical style={{ width: '100%' }}>
                <Menu.Item
                  name='About'
                  active={activeItem === 'About'}
                  onClick={this.handleItemClick}
                >
                  <Grid columns='equal '>
                    <Grid.Column width={1}></Grid.Column>
                    <Grid.Column width={12}>
                      <Header style={{ color: 'rgba(59,83,98)' }} as='h5'>
                        How This Works
                      </Header>
                    </Grid.Column>
                    <Grid.Column>
                      <Icon name='angle right' />
                    </Grid.Column>
                  </Grid>
                </Menu.Item>
                <Menu.Item
                  name='Contact Us'
                  active={activeItem === 'Contact Us'}
                  onClick={this.handleItemClick}
                >
                  <Grid columns='equal '>
                    <Grid.Column width={1}></Grid.Column>
                    <Grid.Column width={12}>
                      <Header style={{ color: 'rgba(59,83,98)' }} as='h5'>
                        Contact Us
                      </Header>
                    </Grid.Column>
                    <Grid.Column>
                      <Icon name='angle right' />
                    </Grid.Column>
                  </Grid>
                </Menu.Item>
              </Menu>

              <Grid style={{paddingBottom:55}}>
                <Grid.Row centered>
                  <Header as='h6' style={{ color: '#384D5A' }}>
                    @Sichuan Sheyu Renyu Trading Co. LTD
                  </Header>
                </Grid.Row>
              </Grid>
              
            </div>
          </>
        )}

        {loading === false && login === true && (
          <>
            <Menu icon borderless style={{ paddingTop: 20 }}>
              <Grid columns='equal'>
                <Grid.Column width={1}></Grid.Column>
                <Grid.Column width={2}>
                  <Menu.Item
                    name='Setting'
                    active={activeItem === 'Setting'}
                    onClick={self.itemsVisible}
                  >
                    <Icon
                      name='setting'
                      size='large'
                      color='grey'
                      style={{ position: 'relative', top: -2 }}
                    />
                  </Menu.Item>
                </Grid.Column>

                <Grid.Column width={1}></Grid.Column>

                <Grid.Column width={6}>
                  <Menu.Item>
                    <Header
                      as='h1'
                      color='teal'
                      style={{ position: 'relative', top: -9 }}
                    >
                      SHEYOU
                    </Header>
                  </Menu.Item>
                </Grid.Column>

                <Grid.Column width={1}></Grid.Column>

                <Grid.Column width={3}>
                  <Menu.Item
                    name='Messages'
                    active={activeItem === 'Messages'}
                    onClick={self.signinVisible}
                  >
                    <Icon name='inbox' size='large' color='grey'>
                      <Label floating circular>
                        0
                      </Label>
                    </Icon>
                  </Menu.Item>
                </Grid.Column>
              </Grid>
            </Menu>

            <div className='bg_color_image_green_orange'>
              <Grid
                columns='equal'
                divided
                style={{ paddingTop: 50, paddingBottom: 50 }}
              >
                <Grid.Column width={2}></Grid.Column>
                <Grid.Column width={7}>
                  <Grid.Row></Grid.Row>
                  <Grid.Row></Grid.Row>
                  <Grid.Row
                    style={{ paddingBottom: 0, marginBottom: 0, marginTop: 30 }}
                  >
                    <Icon
                      circular
                      name='user'
                      color='grey'
                      inverted
                      onClick={self.signinVisible}
                      style={{ fontSize: '40px' }}
                    />
                  </Grid.Row>
                  <Grid.Row style={{ paddingTop: 0, marginTop: 0 }}>
                    <Header
                      as='h5'
                      onClick={self.signinVisible}
                      style={{
                        marginTop: 5,
                        paddingLeft: 10,
                        color: 'rgba(59,83,98)'
                      }}
                    >
                      <Icon.Group size='small'>
                        <Icon name='write' />
                      </Icon.Group>
                      Name
                    </Header>
                  </Grid.Row>
                  <Grid.Row></Grid.Row>
                </Grid.Column>

                <Grid.Column width={4}>
                  <Grid.Row centered>
                    <Icon.Group onClick={self.signinVisible}>
                      <Icon size='huge' name='circle' inverted />
                      <Icon
                        style={{
                          paddingLeft: 0,
                          marginLeft: 0,
                          paddingRight: 3,
                          opacity: '0.9'
                        }}
                        color='orange'
                        size='big'
                        name='shopping bag'
                      />
                    </Icon.Group>
                    <Header
                      onClick={self.signinVisible}
                      as='h5'
                      style={{
                        paddingTop: 0,
                        marginTop: 5,
                        marginBottom: 10,
                        paddingLeft: 10,
                        color: 'rgba(59,83,98)'
                      }}
                    >
                      Orders
                    </Header>
                  </Grid.Row>

                  <Grid.Row centered>
                    <Icon.Group onClick={self.signinVisible}>
                      <Icon size='huge' name='circle' inverted />
                      <Icon
                        style={{
                          paddingLeft: 0,
                          marginLeft: 0,
                          paddingRight: 3,
                          opacity: '0.9'
                        }}
                        color='orange'
                        size='big'
                        name='camera'
                      />
                    </Icon.Group>
                    <Header
                      onClick={self.signinVisible}
                      as='h5'
                      style={{
                        paddingTop: 0,
                        color: 'rgba(59,83,98)',
                        marginTop: 5,
                        paddingLeft: 15
                      }}
                    >
                      Post
                    </Header>
                  </Grid.Row>

                  <Grid.Row></Grid.Row>
                </Grid.Column>
              </Grid>
            </div>

            <div style={{ backgroundColor: '#E6E9EA', paddingTop: 10 }}>
              <Menu
                vertical
                borderless
                style={{ width: '100%', paddingTop: 5, paddingBottom: 5 }}
              >
                <Menu.Item
                  name='Profile Detail'
                  active={activeItem === 'Profile Detail'}
                  onClick={this.handleItemClick}
                  style={{ paddingBottom: 0 }}
                >
                  <Grid columns='equal '>
                    <Grid.Column width={1}></Grid.Column>
                    <Grid.Column width={2}>
                      <Icon color='teal' circular name='write' />
                    </Grid.Column>
                    <Grid.Column
                      style={{ position: 'relative', top: 6 }}
                      width={10}
                    >
                      <Header style={{ color: 'rgba(59,83,98)' }} as='h5'>
                        Profile Detail
                      </Header>
                    </Grid.Column>
                    <Grid.Column style={{ position: 'relative', top: 6 }}>
                      <Icon name='angle right' />
                    </Grid.Column>
                  </Grid>
                </Menu.Item>

                <Menu.Item
                  name='Fit Preference'
                  active={activeItem === 'Fit Preference'}
                  onClick={this.handleItemClick}
                >
                  <Grid columns='equal '>
                    <Grid.Column width={1}></Grid.Column>
                    <Grid.Column width={2}>
                      <Icon color='teal' circular name='sliders horizontal' />
                    </Grid.Column>
                    <Grid.Column
                      style={{ position: 'relative', top: 6 }}
                      width={10}
                    >
                      <Header style={{ color: 'rgba(59,83,98)' }} as='h5'>
                        Fit Preference
                      </Header>
                    </Grid.Column>
                    <Grid.Column style={{ position: 'relative', top: 6 }}>
                      <Icon name='angle right' />
                    </Grid.Column>
                  </Grid>
                </Menu.Item>
              </Menu>
            </div>

            <div style={{ backgroundColor: '#E6E9EA', paddingTop: 10 }}>
              <Menu
                vertical
                borderless
                style={{ width: '100%', paddingTop: 5, paddingBottom: 5 }}
              >
                <Menu.Item
                  name='Payment'
                  active={activeItem === 'Payment'}
                  onClick={this.handleItemClick}
                  style={{ paddingBottom: 0 }}
                >
                  <Grid columns='equal '>
                    <Grid.Column width={1}></Grid.Column>
                    <Grid.Column width={2}>
                      <Icon color='teal' circular name='credit card' />
                    </Grid.Column>
                    <Grid.Column
                      style={{ position: 'relative', top: 6 }}
                      width={10}
                    >
                      <Header style={{ color: 'rgba(59,83,98)' }} as='h5'>
                        Payment Method
                      </Header>
                    </Grid.Column>
                    <Grid.Column style={{ position: 'relative', top: 6 }}>
                      <Icon name='angle right' />
                    </Grid.Column>
                  </Grid>
                </Menu.Item>

                <Menu.Item
                  name='Address'
                  active={activeItem === 'Address'}
                  onClick={this.handleItemClick}
                  style={{ paddingBottom: 0 }}
                >
                  <Grid columns='equal '>
                    <Grid.Column width={1}></Grid.Column>
                    <Grid.Column width={2}>
                      <Icon color='teal' circular name='address book' />
                    </Grid.Column>
                    <Grid.Column
                      style={{ position: 'relative', top: 6 }}
                      width={10}
                    >
                      <Header style={{ color: 'rgba(59,83,98)' }} as='h5'>
                        My Address
                      </Header>
                    </Grid.Column>
                    <Grid.Column style={{ position: 'relative', top: 6 }}>
                      <Icon name='angle right' />
                    </Grid.Column>
                  </Grid>
                </Menu.Item>

                <Menu.Item
                  name='Get Discount'
                  active={activeItem === 'Get Discount'}
                  onClick={this.handleItemClick}
                  style={{ paddingBottom: 0 }}
                >
                  <Grid columns='equal '>
                    <Grid.Column width={1}></Grid.Column>
                    <Grid.Column width={2}>
                      <Icon color='teal' circular name='gift' />
                    </Grid.Column>
                    <Grid.Column
                      style={{ position: 'relative', top: 6 }}
                      width={10}
                    >
                      <Header style={{ color: 'rgba(59,83,98)' }} as='h5'>
                        Get Discount
                      </Header>
                    </Grid.Column>
                    <Grid.Column style={{ position: 'relative', top: 6 }}>
                      <Icon name='angle right' />
                    </Grid.Column>
                  </Grid>
                </Menu.Item>
              </Menu>
            </div>

            <div style={{ backgroundColor: '#E6E9EA', paddingTop: 10 }}>
              <Menu vertical style={{ width: '100%' }}>
                <Menu.Item
                  name='About'
                  active={activeItem === 'About'}
                  onClick={this.handleItemClick}
                >
                  <Grid columns='equal '>
                    <Grid.Column width={1}></Grid.Column>
                    <Grid.Column width={12}>
                      <Header style={{ color: 'rgba(59,83,98)' }} as='h5'>
                        How This Works
                      </Header>
                    </Grid.Column>
                    <Grid.Column>
                      <Icon name='angle right' />
                    </Grid.Column>
                  </Grid>
                </Menu.Item>
                <Menu.Item
                  name='Contact Us'
                  active={activeItem === 'Contact Us'}
                  onClick={this.handleItemClick}
                >
                  <Grid columns='equal '>
                    <Grid.Column width={1}></Grid.Column>
                    <Grid.Column width={12}>
                      <Header style={{ color: 'rgba(59,83,98)' }} as='h5'>
                        Contact Us
                      </Header>
                    </Grid.Column>
                    <Grid.Column>
                      <Icon name='angle right' />
                    </Grid.Column>
                  </Grid>
                </Menu.Item>
              </Menu>

              <Grid>
                <Grid.Row centered>
                  <Header as='h6' style={{ color: '#384D5A' }}>
                    @Sichuan Sheyu Renyu Trading Co. LTD
                  </Header>
                </Grid.Row>
              </Grid>
            </div>
          </>
        )}

        <Sidebar
          as={Menu}
          animation='overlay'
          // onHide={this.signinVisible}
          vertical
          visible={this.state.signinVisible === true}
          direction='bottom'
        >
          <Segment style={{ width: '100%', paddingBottom: 7, paddingTop: 7 }}>
            <Grid>
              <Grid.Row columns={4}>
                <Grid.Column width={1}>
                  <Icon
                    name='close'
                    size='small'
                    onClick={self.signinVisible}
                  />
                </Grid.Column>
                <Grid.Column width={5}></Grid.Column>
                <Grid.Column width={4}>
                  <Header
                    as='h4'
                    color='teal'
                    textAlign='centered'
                    style={{
                      paddingBottom: 0,
                      marginBottom: 0,
                      fontWeight: 500
                    }}
                  >
                    Sign In
                  </Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            {/* <Header as='h6'  textAlign='centered' style={{paddingTop:0,marginTop:0,color:'#384D5A'}}>
                             Next Generation Fashion Platform 
                         </Header> */}
          </Segment>
          <LogInPagePhonePop />
        </Sidebar>

        <Sidebar
          as={Menu}
          animation='overlay'
          // onHide={this.signinVisible}
          vertical
          visible={this.state.itemsVisible === true}
          direction='right'
        >
          <div style={{ backgroundColor: '#E6E9EA' }}>
            <Menu
              vertical
              style={{
                width: '100%',
                marginBottom: 20,
                paddingBottom: 16,
                paddingTop: 16
              }}
            >
              <Menu.Item
                name='Account Details'
                active={activeItem === 'Account Details'}
                onClick={this.handleItemClick}
              >
                <Grid columns='equal '>
                  <Grid.Column width={1}></Grid.Column>
                  <Grid.Column width={12}>
                    <Header>Account Details</Header>
                  </Grid.Column>
                  <Grid.Column>
                    <Icon name='angle right' style={{ position: 'relative' }} />
                  </Grid.Column>
                </Grid>
              </Menu.Item>
              <Divider />
              <Menu.Item
                name='Fit&Preference'
                active={activeItem === 'Fit&Preference'}
                onClick={this.handleItemClick}
              >
                <Grid columns='equal '>
                  <Grid.Column width={1}></Grid.Column>
                  <Grid.Column width={12}>
                    <Header>Fit&Preference</Header>
                  </Grid.Column>
                  <Grid.Column>
                    <Icon
                      name='angle right'
                      style={{ position: 'relative', top: 3 }}
                    />
                  </Grid.Column>
                </Grid>
              </Menu.Item>
            </Menu>

            <Menu
              vertical
              style={{
                width: '100%',
                marginBottom: 20,
                paddingBottom: 16,
                paddingTop: 16
              }}
            >
              <Menu.Item
                name='Change Password'
                active={activeItem === 'Change Password'}
                onClick={this.handleItemClick}
              >
                <Grid columns='equal '>
                  <Grid.Column width={1}></Grid.Column>
                  <Grid.Column width={12}>
                    <Header>Change Password</Header>
                  </Grid.Column>
                  <Grid.Column>
                    <Icon
                      name='edit'
                      style={{ position: 'relative', top: 3 }}
                    />
                  </Grid.Column>
                </Grid>
              </Menu.Item>

              <Menu.Item
                name='Log Out'
                active={activeItem === 'Log Out'}
                onClick={this.handleItemClick}
              >
                <Grid columns='equal '>
                  <Grid.Column width={1}></Grid.Column>
                  <Grid.Column width={12}>
                    <Header>Log Out</Header>
                  </Grid.Column>
                  <Grid.Column>
                    <Icon
                      name='sign-out'
                      style={{ position: 'relative', top: 3 }}
                    />
                  </Grid.Column>
                </Grid>
              </Menu.Item>
            </Menu>

            <Menu
              vertical
              style={{
                width: '100%',
                marginBottom: 20,
                paddingBottom: 16,
                paddingTop: 16
              }}
            >
              <Menu.Item
                name='Billing Information'
                active={activeItem === 'Billing Information'}
                onClick={this.handleItemClick}
              >
                <Grid columns='equal '>
                  <Grid.Column width={1}></Grid.Column>
                  <Grid.Column width={12}>
                    <Header>Billing Information</Header>
                  </Grid.Column>
                  <Grid.Column>
                    <Icon
                      name='angle right'
                      style={{ position: 'relative', top: 3 }}
                    />
                  </Grid.Column>
                </Grid>
              </Menu.Item>

              <Divider />

              <Menu.Item
                name='Shipping Address'
                active={activeItem === 'Shipping Address'}
                onClick={this.handleItemClick}
              >
                <Grid columns='equal '>
                  <Grid.Column width={1}></Grid.Column>
                  <Grid.Column width={12}>
                    <Header>Shipping Address</Header>
                  </Grid.Column>
                  <Grid.Column>
                    <Icon
                      name='angle right'
                      style={{ position: 'relative', top: 3 }}
                    />
                  </Grid.Column>
                </Grid>
              </Menu.Item>
            </Menu>
          </div>
        </Sidebar>

        <NavMobilePhone />
      </div>
    )
  }
}

export default MyPagePhone

// {ordersWithRealProductInfo.map(function(order){
//     return(<Grid.Row columns={4}>
//                 <Grid.Column width={1}></Grid.Column>

//                 <Grid.Column width={8}>
//                 <Header as='h5' color='grey' textAlign='center'>Time of the Order: {order.created_at}</Header>
//                 </Grid.Column>

//                 {order.orders.info.rentOrBuy==="buy"&&
//                 <Grid.Column width={6}>
//                     <Header as='h5' color='grey' textAlign='center'>Buying Order</Header>
//                 </Grid.Column>
//                 }

//                 {order.orders.info.rentOrBuy==="rent"&&
//                 <Grid.Column width={6}>
//                     <Header as='h5' color='grey' textAlign='center'>Renting Order</Header>
//                 </Grid.Column>
//                 }

//                 <Grid.Column width={16}>

//                 {order.orders.data.map(function(theOrder){
//                     return(
//                         <Segment>
//                                 <Grid>
//                                 <Grid.Row columns={3}>
//                                             <Grid.Column width={1}>

//                                             </Grid.Column >
//                                             <Grid.Column width={4}>
//                                                 <Image size='small' src={"https://sheyou-backend.herokuapp.com/"+theOrder.picture[0].formats.small.url}/>
//                                             </Grid.Column >
//                                             <Grid.Column width={10}>
//                                                 <Grid>
//                                                 <Grid.Row>
//                                                 <Header> {theOrder.name}</Header>
//                                                 </Grid.Row>
//                                                 <Grid.Row Column={2}>
//                                                     Date:
//                                                 </Grid.Row>

//                                                 <Grid.Column width={8}>
//                                                     Price:
//                                                     {' '+theOrder.price+' RMB'}
//                                                     </Grid.Column>
//                                                     <Grid.Column width={8}>
//                                                         <Button basic color='teal'>Purchase Again</Button>
//                                                     </Grid.Column>
//                                                 <Grid.Row>

//                                                 </Grid.Row>
//                                                 </Grid>
//                                             </Grid.Column>
//                                     </Grid.Row>

//                             </Grid>

//                             </Segment>

//                     )
//                 })}

//                 </Grid.Column>

//                 <Grid.Column>

//                 </Grid.Column>
//             </Grid.Row>

//         )
// })}
