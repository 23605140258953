import React from 'react'
import axios from 'axios'
import { Grid, Header, Icon, Label, Pagination, Segment} from 'semantic-ui-react'
import MainFilter from './SaleMainFilter'
import SizeFilter from './SaleSizeFilter'
import { withRouter } from 'react-router-dom'
import SaleCardTemplete from './SaleCardTemplete'
import Foot from '../foot/Foot'
import { Parallax } from 'react-parallax';
import round from '@stdlib/math-base-special-round'
const userLang = navigator.language || navigator.userLanguage



class SalePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      products: [],
      loading: true,
      activePage: 1,
      pageNum: 0

      // productId
    }
    // this.onHandleClick = this.onHandleClick.bind(this)
    this.handlePaginationChange=this.handlePaginationChange.bind(this)

  }
  componentDidMount() {

    window.scrollTo(0, 0)

    const self = this
    const p = this.props
    const is = p.is
    const activeItem = p.activeItem
    
    self.setState({
      activeItem: activeItem,
      is: is
    })

    var page = p.location.search.substring(3)
    console.log(page==='')
    if(page===null||page===undefined||page===''){
      page=1
    }
    
    console.log(page)
    if(page!==undefined&&page!==null){
      self.setState({
        activePage: page
      })
      this.getProducts()
    }
    
    // window.onload = (event) => {
    //   self.setState({
    //     loading: false
    //   })
    // };

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }

    }, 700);
  }

  handlePaginationChange(e, {activePage}){
    this.setState({activePage})
    const thisPath = this.props.history.location.pathname
    this.props.history.push(thisPath+'?p='+activePage)
    window.location.reload(true)
  }


  getProducts() {
    const self = this
    const p = this.props
    const is = p.is
    var activeItem = p.activeItem
    // console.log(activeItem)

    if(activeItem!=='All'){
      activeItem.toLowerCase()
      axios.get('https://sheyou-backend.herokuapp.com/second-hand-products')
      .then(function(res){
        if(res.status === 200){
          const data = res.data
          const products =[]
          if(is === 'categories'){
            data.forEach(function(item){
              item.categories.forEach(function(category){
                if(category.name === activeItem){
                  products.push(item)
                }
              })
            })
          }
          else{
            data.forEach(function(item){
              item.sizes.forEach(function(size){
                if(size.name === activeItem){
                  products.push(item)
                }
              })
            })
          }
          console.log(products)

          const itemNum = products.length
          const activePage = self.state.activePage
          var dataShow=[]
          for(var i = activePage * 9 - 9; i < activePage * 9; i++){
            if(i<itemNum){
              dataShow.push(products[i])
            }
          }

          var roundPageNum = 0
          var pageNum = 0
          roundPageNum = round(itemNum / 9 )
          pageNum = itemNum / 9.0
          if (pageNum - roundPageNum > 0){
            pageNum = roundPageNum + 1
          }

          self.setState({
            products: dataShow,
            loading: false,
            itemNum: itemNum,
            pageNum: pageNum
          })
        }
        else{
          alert('something went wrong :(')
          localStorage.setItem('login', false)
        }
      })
    }
    else{
      axios.get('https://sheyou-backend.herokuapp.com/second-hand-products')
      .then(function (res) {
        console.log(res)
        if (res.status === 200) {
          const data = res.data
          // const products = data
          const itemNum = data.length
          const activePage = self.state.activePage

          var dataShow = []
          for (var i = activePage*9 - 9; i < activePage*9; i++){
            if(i<itemNum){
              dataShow.push(data[i])
            }
          }

          var roundPageNum = 0
          var pageNum = 0
          roundPageNum = round(itemNum / 9)
          pageNum = itemNum / 8.0

          if(pageNum - roundPageNum > 0){
              pageNum = roundPageNum + 1
          }

          // console.log(data)
          self.setState({
            products: dataShow,
            loading: false,
            itemNum: itemNum,
            pageNum: pageNum
          })
          // self.setState({
          //   products: products
          // })
        }
        else {
          alert('something went wrong :(')
          localStorage.setItem('login', false)
        }

      })
    }
  }

  // onHandleClick(event, data) {
  //   const self = this
  //   const productId = data.id
  //   event.preventDefault()
  //   localStorage.setItem('saleproductId', productId)
  //   self.props.history.push('/saleproduct')
  // }

  render() {
    const s = this.state
    console.log(s)
    const loading = s.loading
    // const n={position:'absolute',top:1000}
    const self=this
    return <div>
      {/* <Nav /> */}


      {loading ? (
        <Grid >
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>


          <Grid.Row colums={2}>
            <Grid.Column width={7}></Grid.Column>
            <Grid.Column width={6}>
              <Header size='huge'>
                <Icon loading name='asterisk' size='massive' color='yellow' />
                Loading...
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
        </Grid>
      ) : (
        <Grid stackable style={{marginTop:10}}>
          <Grid.Column floated='left' width={4} style={{ paddingLeft: 80 }} >

              {userLang === 'zh-CN' && (
                <Label tag size='big'>
                  出售
                </Label>
              )}
              {userLang !== 'zh-CN' && (
                <Label tag size='big'>
                  Sale
                </Label>
              )}

              {/* <Segment> */}
              <MainFilter activeItem={this.state.activeItem}/>
              {/* </Segment> */}
              {/* <Segment> */}
              <SizeFilter activeItem={this.state.activeItem}/>
              {/* </Segment> */}

          </Grid.Column>

          {s.products.length === 0 &&

          <Grid.Column width={12} style={{ paddingRight: 50 }}>

            <Parallax
              bgImage='https://i.loli.net/2020/11/25/ZHjYm1wL9runqOz.png'
              bgImageAlt=''
              strength={200}>

              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />

              <div>
                <Segment>
                  <Grid>
                    <Grid.Row centered>
                      <Header>
                      <Icon loading name='asterisk' size='massive' color='yellow' />

                        Loading ...
                        </Header>
                    </Grid.Row>

                    {/* <Grid.Row centered>
                      <Button size='huge' color='orange' onClick={this.handleItemClick}> Browse All</Button>
                    </Grid.Row> */}
                  </Grid>
                </Segment>
              </div>

              <div style={{ height: "270px" }} />
            </Parallax>

          </Grid.Column>

          }

          <Grid.Column width={12}>

            <SaleCardTemplete products={s.products} />
            <Grid>
              <Grid.Row centered>
                <Pagination
                  
                  onPageChange={this.handlePaginationChange}
                  activePage={self.state.activePage}
                  pointing
                  secondary
                  ellipsisItem={false}
                  totalPages={this.state.pageNum}
                />
              </Grid.Row>
            </Grid>
            {/* {s.products.map(function(product){
                  return <Card onClick={self.onHandleClick} id={product.id}>
                      <Image src={'https://sheyou-backend.herokuapp.com/'+product.picture[0].url}/>
                    <Card.Content>
                      
                    <Card.Header>
                      {product.name}
                    </Card.Header>
                    
                  <Card.Meta>{product.description}</Card.Meta>
              
                    <Card.Description>
                      {product.price+'RMB/ Free for Member'}
                    </Card.Description>
                  </Card.Content>
                  </Card>
                    })} */}

          </Grid.Column>
        </Grid>
      )}
      <Foot />

    </div>


  }

}

export default withRouter(SalePage)

