import React from 'react'
import {
  Grid,
  Menu,
  Header,
  Button,
  Segment,
  Icon,
  Image
} from 'semantic-ui-react'
import { Parallax } from 'react-parallax'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'


import Foot from '../../foot/Foot'
import axios from 'axios'

import MerchantHome from './MerchantHome'
import MerchantStore from './MerchantStore'
import MerchantProduct from './MerchantProduct'
import NewProduct from './NewProduct'

class MerchantPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      login: false,
      userInfo: [],
      userID: '',
      merchantVerified: false,
      merchantInfo: {},


      loading: true
    }
    this.handleItemClick = this.handleItemClick.bind(this)
    // this.handleGoVerify = this.handleGoVerify.bind(this)
  }

  componentDidMount () {
    window.scrollTo(0, 0)
    const self = this
    const p = this.props
    const activeItem = p.activeItem

    self.setState({activeItem: activeItem})

    const merchantLogin=localStorage.getItem('merchantLogin')
    if(merchantLogin!=='true'){
      self.props.history.push('/merchantsLog')
    }

    this.ifLog()

    console.log(p)
    if (activeItem === 'Membership') {
      self.setState({
        activeItem: 'Membership'
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 700)
  }

 
  ifLog () {
    const log = localStorage.getItem('login')
    const self = this
    if (log === 'false') {
      self.setState({ login: false })
    } else {
      self.setState({ login: true })
      this.getUserInfo()
    }
  }

  getUserInfo(){
    const self = this
    const token = localStorage.getItem('token')
    self.setState({loading: true})
    axios('https://sheyou-backend.herokuapp.com/users/me', {
        method: 'get',
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(function(res){
        console.log(res)
        const userInfo = [res.data]
        console.log(userInfo)
        if(userInfo === null){
          self.setState({
            userInfo: [],
            userID: userInfo[0].id,
            loading: false
          })
        }else if(userInfo.length > 0){
          self.setState({
            userInfo: userInfo,
            userID: userInfo[0].id,
            loading: false
          })
        }

        // const merchantInfo = userInfo[0].merchantInfo
        // console.log(merchantInfo)
        // if((merchantInfo !== null) && (merchantInfo.merchantVerified === true)){
        //     self.setState({merchantVerified: true})
        // }else{
        //   self.setState({merchantVerified: false})
        // }
      })
  }

  // handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  handleItemClick(event, data) {
    var name = data.name
    const link = data.to
    // const self = this
    window.location.assign('https://sheyoufashion.com/#'+link)
    if (name === undefined || name === null) {
      this.props.history.push('/merchantpage')
      window.location.reload(true)
    }
    else {
      this.props.history.push(link)
      window.location.reload(true)
    }
  }

  // handleGoVerify(event, data){
  //   const self = this
  //   alert('please go verify your store to upload products')
  //   self.props.history.push("/merchantVerify/address")
  // }


  render () {
    const s = this.state
    const loading = s.loading
    const activeItem = this.state.activeItem
    const login = s.login
    console.log(s.merchantVerified)

    return (
      <div>
        {/* <Nav /> */}

        {loading === true && (
          <Grid>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>

            <Grid.Row colums={2}>
              <Grid.Column width={7}></Grid.Column>
              <Grid.Column width={6}>
                <Header size='huge'>
                  <Icon loading name='asterisk' size='massive' color='yellow' />
                  Loading...
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid>
        )}

        {loading === false && login === false && (
          <Parallax
            bgImage='https://i.loli.net/2020/11/25/ZHjYm1wL9runqOz.png'
            bgImageAlt=''
            strength={200}
          >
            <div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <Segment>
                <Grid>
                  <Grid.Row centered>
                    <Header> You Should login first</Header>
                  </Grid.Row>

                  <Grid.Row centered>
                    <Link to='/merchantsLog'>
                      <Button size='huge' color='orange'>
                        {' '}
                        Login
                      </Button>
                    </Link>
                  </Grid.Row>
                </Grid>
              </Segment>
            </div>

            <div style={{ height: '200px' }} />
          </Parallax>
        )}

        {loading === false && login !== false&&login!=='false' && (
          <Grid columns={2} style={{paddingTop:10}} >
            <Grid.Column width={3} style={{ paddingLeft: 50 }}>
              <Menu secondary vertical>
                <Menu.Item>
                  <Image
                    size='mini'
                    style={{ position: 'relative', left: 15, paddingBottom: 5 }}
                    src='https://res.cloudinary.com/sheyou/image/upload/v1647579043/_265d4956e0.png?140123.80000007153'
                  />
                  <p style={{ color: '#767676' }}>Merchants</p>
                </Menu.Item>
                <Menu.Item
                  name='Home'
                  to = '/merchantpage'
                  active={activeItem === 'Home'}
                  onClick={this.handleItemClick}
                >
                  <Grid>
                    <Grid.Row>
                      
                      <Grid.Column width={5}>Home</Grid.Column>
                     
                    </Grid.Row>
                  </Grid>
                </Menu.Item>

                <Menu.Item
                  name='Store'
                  to='/merchantpage/store'
                  active={activeItem === 'Store'}
                  onClick={this.handleItemClick}
                >
                    <Grid.Column>Store</Grid.Column>
                  
                </Menu.Item>

                  <Menu.Item
                    name='Product'
                    to='/merchantpage/product'
                    active={activeItem === 'Product'}
                    onClick={this.handleItemClick}
                  >
                      <Grid.Column>Product</Grid.Column>
                  
                  </Menu.Item>
                
                {/* {s.verify !== true && (
                  <Menu.Item
                    name='Product'
                    link='/merchantpage/product'
                    active={activeItem === 'Product'}
                    onClick={this.handleGoVerify}
                  >
                      <Grid.Column>Product</Grid.Column>
                  
                  </Menu.Item>
                )}
                 */}
              </Menu>

              
              
            </Grid.Column>

        
            {activeItem === 'Home' && (
              <Grid.Column width={13} style={{ paddingRight: 40 }}>
               
                  <MerchantHome/>
                
              </Grid.Column>
            )}

            {activeItem === 'Store' && (
              <Grid.Column width={13} style={{paddingRight: 40}}>

                <MerchantStore/>

              </Grid.Column>
            )}

            {activeItem === 'Product' && (
              <Grid.Column width={13} style={{paddingRight: 40}}>
                <MerchantProduct/>
              </Grid.Column>
            )}

            {}

            {activeItem === 'newClothing' && (
              <Grid.Column width={13} style={{paddingRight: 40}}>
                <NewProduct activeItem = 'newClothing' />
              </Grid.Column>
            )}

             {activeItem === 'newAccessory' && (
              <Grid.Column width={13} style={{paddingRight: 40}}>
                <NewProduct activeItem = 'newAccessory' />
              </Grid.Column>
            )}

             {activeItem === 'newSale' && (
              <Grid.Column width={13} style={{paddingRight: 40}}>
                <NewProduct activeItem = 'newSale' />
              </Grid.Column>
            )}

          </Grid>
        )}
        
        <Foot />
      </div>
    )
  }
}

export default withRouter(MerchantPage)
