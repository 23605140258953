import React from 'react'
import {
  Grid,
  Icon,
  Segment,
  Container,
  Header,
  Button
} from 'semantic-ui-react'
import Foot from '../foot/Foot'

import { withRouter } from 'react-router-dom'



class Alipay extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      activeItem: true
    }
  }

  componentDidMount () {
    window.scrollTo(0, 0)
    const self = this



    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 7000)
  }

  render () {
    console.log()

    const loading = this.state.loading

    return (
      <div>
        {/* <Nav /> */}

        {loading ? (
          <Grid>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>

            <Grid.Row colums={2}>
              <Grid.Column width={7}></Grid.Column>
              <Grid.Column width={6}>
                <Header size='huge'>
                  <Icon loading name='asterisk' size='massive' color='yellow' />
                  Loading...
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid>
        ) : (
          <>
            <div className='bg_image'>
              <br />
              <br />

              <Container>
                <Segment>
                  <Grid container>
                    <Button name='pay'>
                      Pay
                    </Button>
                  </Grid>
                </Segment>
              </Container>

              <br />
              <br />
              <Foot />
            </div>
          </>
        )}
      </div>
    )
  }
}

export default withRouter(Alipay)
