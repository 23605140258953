import React from 'react'
import { Icon } from 'semantic-ui-react';




 
class ToPrevious extends React.Component{
    constructor (props) {
        super(props)
        this.state = {
       
        }
    }
    
      componentDidMount () {
       
      }

      toPrevios(){
        window.history.go(-1)
      }
    
    render(){
        const self=this
        return          <Icon name='chevron left' style={{paddingTop:20,paddingLeft:20}} size="large" inverted onClick={self.toPrevios}/>

}
    
} 

export default ToPrevious