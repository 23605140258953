import React from 'react'

import {
  Grid,
  Image,
  Header,
  Icon,
  Segment,
  Button
} from 'semantic-ui-react'

// import { Parallax,} from 'react-parallax';
// import Nav from '../nav/Nav'
import { withRouter } from 'react-router-dom'

class MerchantHome extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true
    }
  }

  componentDidMount () {
    const self = this
    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 700)
  }

  render () {
    // const self = this

    return (
      <>
        {this.state.loading ? (
          <Grid>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>

            <Grid.Row colums={2}>
              <Grid.Column width={7}></Grid.Column>
              <Grid.Column width={6}>
                <Header size='huge'>
                  <Icon loading name='asterisk' size='massive' color='yellow' />
                  Loading...
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid>
        ) : (
          <>
            <Grid stackable>
              <Grid.Row columns={2}>
                <Grid.Column width={9}>
                  <Segment >
                      <br/>
                    <Grid style={{paddingLeft:20,paddingRight:10}}>
                      <Grid.Row>
                        <Header>Store Data</Header>
                      </Grid.Row>

                      <Grid.Row columns='equal' style={{height:92}}>
                        <Grid.Column>
                          
                            <Grid style={{height:80,backgroundColor:'rgb(249,250,251)', marginRight:5,paddingLeft:5}}>
                              <Grid.Row style={{paddingTop:0,paddingBottom:0,marginBottom:0,marginTop:0}} centered>
                                <p
                                  style={{
                                    color: '#767676',
                                  }}
                                >
                                  Total Income
                                </p>
                              </Grid.Row>
                              <Grid.Row style={{paddingTop:0,paddingBottom:0,marginBottom:0,marginTop:0}} centered>
                                <Header as='h4'>99.9</Header>
                              </Grid.Row>
                              <Grid.Row style={{paddingTop:0,paddingBottom:0,marginBottom:0,marginTop:0}} centered>
                                <p
                                  style={{
                                    color: '#767676',
                                    position: 'relative',
                                    top: -3
                                  }}
                                >
                                  Yesterday 0
                                </p>
                              </Grid.Row>
                            </Grid>

                        </Grid.Column>

                        <Grid.Column>
                          
                            <Grid style={{height:80,backgroundColor:'rgb(249,250,251)', marginRight:5,paddingLeft:5}}>
                              <Grid.Row style={{paddingTop:0,paddingBottom:0,marginBottom:0,marginTop:0}} centered>
                                <p
                                  style={{
                                    color: '#767676',
                                  }}
                                >
                                  Visitors
                                </p>
                              </Grid.Row>
                              <Grid.Row style={{paddingTop:0,paddingBottom:0,marginBottom:0,marginTop:0}} centered>
                                <Header as='h4'>99</Header>
                              </Grid.Row>
                              <Grid.Row style={{paddingTop:0,paddingBottom:0,marginBottom:0,marginTop:0}} centered>
                                <p
                                  style={{
                                    color: '#767676',
                                    position: 'relative',
                                    top: -3
                                  }}
                                >
                                  Yesterday 0
                                </p>
                              </Grid.Row>
                            </Grid>

                        </Grid.Column>
                        <Grid.Column>
                          
                          <Grid style={{height:80, backgroundColor:'rgb(249,250,251)', marginRight:5}}>
                            <Grid.Row style={{paddingTop:0,paddingBottom:0,marginBottom:0,marginTop:0}} centered>
                              <p
                                style={{
                                  color: '#767676',
                                }}
                              >
                                Orders
                              </p>
                            </Grid.Row>
                            <Grid.Row style={{paddingTop:0,paddingBottom:0,marginBottom:0,marginTop:0}} centered>
                              <Header as='h4'>23</Header>
                            </Grid.Row>
                            <Grid.Row style={{paddingTop:0,paddingBottom:0,marginBottom:0,marginTop:0}} centered>
                              <p
                                style={{
                                  color: '#767676',
                                  position: 'relative',
                                  top: -3
                                }}
                              >
                                Yesterday 0
                              </p>
                            </Grid.Row>
                          </Grid>

                      </Grid.Column>
                      <Grid.Column>
                          
                            <Grid style={{backgroundColor:'rgb(249,250,251)',height:80, marginRight:5,paddingLeft:5}}>
                              <Grid.Row style={{paddingTop:0,paddingBottom:0,marginBottom:0,marginTop:0}} centered>
                                <p
                                  style={{
                                    color: '#767676',
                                  }}
                                >
                                Conversion Rate
                                </p>
                              </Grid.Row>
                              <Grid.Row style={{paddingTop:0,paddingBottom:0,marginBottom:0,marginTop:0}} centered>
                                <Header as='h4'>45%</Header>
                              </Grid.Row>
                              <Grid.Row style={{paddingTop:0,paddingBottom:0,marginBottom:0,marginTop:0}} centered>
                                <p
                                  style={{
                                    color: '#767676',
                                    position: 'relative',
                                    top: -3
                                  }}
                                >
                                  Yesterday 0%
                                </p>
                              </Grid.Row>
                            </Grid>

                        </Grid.Column>                      
                      </Grid.Row>
                  
                      <Grid.Row columns='equal' style={{height:92}}>
                        <Grid.Column>
                          
                            <Grid style={{height:80, backgroundColor:'rgb(249,250,251)',marginRight:5,paddingLeft:5}}>
                              <Grid.Row style={{paddingTop:0,paddingBottom:0,marginBottom:0,marginTop:0}} centered>
                                <p
                                  style={{
                                    color: '#767676',
                                  }}
                                >
                                  Views
                                </p>
                              </Grid.Row>
                              <Grid.Row style={{paddingTop:0,paddingBottom:0,marginBottom:0,marginTop:0}} centered>
                                <Header as='h4'>233</Header>
                              </Grid.Row>
                              <Grid.Row style={{paddingTop:0,paddingBottom:0,marginBottom:0,marginTop:0}} centered>
                                <p
                                  style={{
                                    color: '#767676',
                                    position: 'relative',
                                    top: -3
                                  }}
                                >
                                  Yesterday
                                </p>
                              </Grid.Row>
                            </Grid>

                        </Grid.Column>

                        <Grid.Column>
                          
                            <Grid style={{backgroundColor:'rgb(249,250,251)',height:80, marginRight:5,paddingLeft:5}}>
                              <Grid.Row style={{paddingTop:0,paddingBottom:0,marginBottom:0,marginTop:0}} centered>
                                <p
                                  style={{
                                    color: '#767676',
                                  }}
                                >
                                  Income Per Order
                                </p>
                              </Grid.Row>
                              <Grid.Row style={{paddingTop:0,paddingBottom:0,marginBottom:0,marginTop:0}} centered>
                                <Header as='h4'>78</Header>
                              </Grid.Row>
                              <Grid.Row style={{paddingTop:0,paddingBottom:0,marginBottom:0,marginTop:0}} centered>
                                <p
                                  style={{
                                    color: '#767676',
                                    position: 'relative',
                                    top: -3
                                  }}
                                >
                                  Yesterday 0
                                </p>
                              </Grid.Row>
                            </Grid>

                        </Grid.Column>
                        <Grid.Column>
                          
                          <Grid style={{height:80, backgroundColor:'rgb(249,250,251)', marginRight:5}}>
                            <Grid.Row style={{paddingTop:0,paddingBottom:0,marginBottom:0,marginTop:0}} centered>
                              <p
                                style={{
                                  color: '#767676',
                                }}
                              >
                                Buyers
                              </p>
                            </Grid.Row>
                            <Grid.Row style={{paddingTop:0,paddingBottom:0,marginBottom:0,marginTop:0}} centered>
                              <Header as='h4'>30</Header>
                            </Grid.Row>
                            <Grid.Row style={{paddingTop:0,paddingBottom:0,marginBottom:0,marginTop:0}} centered>
                              <p
                                style={{
                                  color: '#767676',
                                  position: 'relative',
                                  top: -3
                                }}
                              >
                                Yesterday 0
                              </p>
                            </Grid.Row>
                          </Grid>

                      </Grid.Column>
                      <Grid.Column>
                          
                            <Grid style={{backgroundColor:'rgb(249,250,251)',height:80, marginRight:5,paddingLeft:5}}>
                              <Grid.Row style={{paddingTop:0,paddingBottom:0,marginBottom:0,marginTop:0}} centered>
                                <p
                                  style={{
                                    color: '#767676',
                                  }}
                                >
                                  Products
                                </p>
                              </Grid.Row>
                              <Grid.Row style={{paddingTop:0,paddingBottom:0,marginBottom:0,marginTop:0}} centered>
                                <Header as='h4'>111</Header>
                              </Grid.Row>
                              <Grid.Row style={{paddingTop:0,paddingBottom:0,marginBottom:0,marginTop:0}} centered>
                                <p
                                  style={{
                                    color: '#767676',
                                    position: 'relative',
                                    top: -3
                                  }}
                                >
                                  Yesterday 0
                                </p>
                              </Grid.Row>
                            </Grid>

                        </Grid.Column>                      
                      </Grid.Row>

                     

                      
                    </Grid>
                    <br/>
                  </Segment>
                </Grid.Column>
                <Grid.Column width={7}>
                  <Segment>
                    <br />

                    <Grid >
                      <Grid.Row >
                        <Grid.Column width={1}></Grid.Column>
                        <Grid.Column width={4}>
                          <Image src='https://res.cloudinary.com/sheyou/image/upload/v1649165084/_b7d786a54e.png?21902375.5' />
                        </Grid.Column>
                        <Grid.Column width={1}></Grid.Column>
                        <Grid.Column width={9}>
                          <Grid>
                            <Grid.Row
                              style={{ paddingBottom: 0, marginBottom: 5 }}
                            >
                           
                                <Header style={{ color: '#767676' }}>
                                 sheyoufashion
                                  <Icon name='write' color='grey' style={{fontSize:15,paddingLeft:10,position:'relative',top:-4}}></Icon>
                                </Header>
                             
                              
                            </Grid.Row>
                            <Grid.Row style={{ paddingTop: 10, marginTop: 5 }}>
                              
                                <Button basic color='teal' size='mini' style={{paddingLeft:20,paddingRight:20}}>
                                  Edit Your Shop
                                </Button>
                            
                            </Grid.Row>
                          </Grid>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row style={{ paddingLeft: 10, paddingRight: 10 }}>
                        <Grid.Column width={3}>
                          <Header as='h4'>Account</Header>
                        </Grid.Column>
                        <Grid.Column width={3}>
                          <p
                            style={{
                              color: '#767676',
                              position: 'relative',
                              top: -3
                            }}
                          >
                            Susanna
                          </p>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row style={{ paddingLeft: 10, paddingRight: 10 }}>
                        <Grid.Column width={4}>
                          <Header as='h4'>Detail Info</Header>
                        </Grid.Column>
                        <Grid.Column width={3}>
                          <p
                            style={{
                              color: '#767676',
                              position: 'relative',
                              top: -3,
                              textDecoration: 'underline'
                            }}
                          >
                            Fill Out
                          </p>
                        </Grid.Column>
                        <Grid.Column
                          width={1}
                          style={{
                            position: 'relative',
                            left: -3
                          }}
                        >
                          <Icon
                            name='chevron right'
                            style={{
                              color: '#767676',
                              position: 'relative',
                              top: -3,
                              right: 5
                            }}
                            color='grey'
                            size='mini'
                          ></Icon>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row
                        columns={10}
                        style={{ paddingLeft: 10, paddingRight: 10 }}
                      >
                        <Grid.Column width={3}>
                          <Header as='h4'>Rating</Header>
                        </Grid.Column>
                        <Grid.Column width={3}>
                          <p
                            style={{
                              color: '#767676',
                              position: 'relative',
                              top: -3
                            }}
                          >
                            Description
                          </p>
                        </Grid.Column>
                        <Grid.Column width={1}>
                          <Header as='h4'>0.0</Header>
                        </Grid.Column>
                        <Grid.Column width={1}></Grid.Column>

                        <Grid.Column width={2}>
                          <p
                            style={{
                              color: '#767676',
                              position: 'relative',
                              top: -3
                            }}
                          >
                            Service
                          </p>
                        </Grid.Column>
                        <Grid.Column width={1}>
                          <Header as='h4'>0.0</Header>
                        </Grid.Column>
                        <Grid.Column width={1}></Grid.Column>

                        <Grid.Column width={2}>
                          <p
                            style={{
                              color: '#767676',
                              position: 'relative',
                              top: -3
                            }}
                          >
                            Logistics
                          </p>
                        </Grid.Column>
                        <Grid.Column
                          width={1}
                          style={{ position: 'relative', left: 5 }}
                        >
                          <Header as='h4'>0.0</Header>
                        </Grid.Column>
                        <Grid.Column width={1}></Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <br />
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <Segment >
                      <br/>
                    <Grid style={{paddingLeft:20,paddingRight:10}}>
                      <Grid.Row>
                        <Header>Verify Your Store to Upload Products</Header>
                      </Grid.Row>

                      <Grid.Row columns='equal' style={{height:92}}>
                        <Grid.Column>
                          
                            <Grid style={{height:80, marginRight:5,paddingLeft:5}}>
                              <Grid.Row style={{paddingTop:0,paddingBottom:0,marginBottom:0,marginTop:0}} centered>
                                <Icon circular size="big" name='address book' color='teal'></Icon>
                              </Grid.Row>
                              <Grid.Row style={{paddingTop:0,paddingBottom:0,marginBottom:0,marginTop:0}} centered>
                                <Header as='h4'>Contact Address</Header>
                              </Grid.Row>
                             
                            </Grid>
         

                        </Grid.Column>
                        <Grid.Column>
                          
                            <Grid style={{height:80, marginRight:5,paddingLeft:5}}>
                              <Grid.Row style={{paddingTop:0,paddingBottom:0,marginBottom:0,marginTop:0}} centered>
                                <Icon circular size="big" name='mobile alternate' color='teal'></Icon>
                              </Grid.Row>
                              <Grid.Row style={{paddingTop:0,paddingBottom:0,marginBottom:0,marginTop:0}} centered>
                                <Header as='h4'>Mobile Phone</Header>
                              </Grid.Row>
                             
                            </Grid>
         

                        </Grid.Column>
                        <Grid.Column>
                          
                            <Grid style={{height:80, marginRight:5,paddingLeft:5}}>
                              <Grid.Row style={{paddingTop:0,paddingBottom:0,marginBottom:0,marginTop:0}} centered>
                                <Icon  circular size="big"name='credit card' color='teal'></Icon>
                              </Grid.Row>
                              <Grid.Row style={{paddingTop:0,paddingBottom:0,marginBottom:0,marginTop:0}} centered>
                                <Header as='h4'>Credit Card</Header>
                              </Grid.Row>
                             
                            </Grid>
         

                        </Grid.Column>
                        <Grid.Column>
                          
                            <Grid style={{height:80, marginRight:5,paddingLeft:5}}>
                              <Grid.Row style={{paddingTop:0,paddingBottom:0,marginBottom:0,marginTop:0}} centered>
                                <Icon  circular size="big"name='id card' color='teal'></Icon>
                              </Grid.Row>
                              <Grid.Row style={{paddingTop:0,paddingBottom:0,marginBottom:0,marginTop:0}} centered>
                                <Header as='h4'>Identity Detail</Header>
                              </Grid.Row>
                             
                            </Grid>
         

                        </Grid.Column>

                                          
                      </Grid.Row>
                  
                     

                     

                      
                    </Grid>
                    <br/>
                  </Segment>
          </>
        )}
      </>
    )
  }
}

export default withRouter(MerchantHome)
