import React from 'react'
import {
  Grid,
  Icon,
  Image,
  Segment,
  Container,
  Button,
  Header,
  Radio
} from 'semantic-ui-react'
import axios from 'axios'
import Foot from '../foot/Foot'
import { withRouter } from 'react-router-dom'
import timestamp from 'time-stamp'

class Payment extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      paid: false,
      value: 0,
      token: '',
      identifier: '',
      password: '',
      membership: '',
      isAMember: '',
      loading: true,
      AliPayIcon: '',
      wechatPayIcon: '',
      expireDate: ''
    }
    this.onHandleSubmit = this.onHandleSubmit.bind(this)
  }

  componentDidMount () {
    const identifier = localStorage.getItem('identifier')
    const password = localStorage.getItem('password')
    const token = localStorage.getItem('token')
    const self = this

    const plan = localStorage.getItem('plan')
    const year = timestamp.utc('YYYY')
    const month = timestamp.utc('MM')
    const day = timestamp.utc('DD')
    var newDate = new Date(year, month, day)
    newDate.setMonth(newDate.getMonth())

    self.setState({
      identifier: identifier,
      password: password,
      plan: plan,
      expireDate: newDate
    })

    self.setState({
      wechatPayIcon:
        'https://res.cloudinary.com/sheyou/image/upload/v1641199060/wechatpay_4a5593c3f3.png?37981.400000002235',

      AliPayIcon:
        'https://res.cloudinary.com/sheyou/image/upload/v1641199061/alipay_9a312bd4b6.png?55584.400000002235'
    })

    axios('https://sheyou-backend.herokuapp.com/users/me', {
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (res) {
      const userInfo = [res.data]
      if (userInfo === null) {
        self.setState({
          userInfo: [],
          userID: userInfo[0].id
        })
      } else if (userInfo.length > 0) {
        console.log(userInfo)
        self.setState({
          userInfo: userInfo,
          userID: userInfo[0].id,
          membership: userInfo[0].membership,
          loading: false
        })
      }
    })
    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 1000)
  }

  handleChange = (e, { value }) => this.setState({ value })

  onHandleSubmit (event) {
    var userInfo = localStorage.getItem('userInfo')
    userInfo = JSON.parse(userInfo)
    const id = userInfo[0].id
    const plan = this.state.plan
    const year = timestamp.utc('YYYY')
    const month = timestamp.utc('MM')
    const day = timestamp.utc('DD')
    const payment = this.state.value
    var paymentInfo = {
      userID: id,
      plan: plan,
      payment: payment,
      time: {
        year: year,
        month: month,
        day: day
      }
    }
    paymentInfo = JSON.stringify(paymentInfo)
    localStorage.setItem('paymentInfo', paymentInfo)

    // if (payment === 'Alipay') {
    //   self.props.history.push('/alipay')
    // } else if (payment === 'WechatPay') {
    //   self.props.history.push('/wechatpay')
    // }

    /////////////////////////////////////////////////////
    // var newDate = new Date(year, month, day)
    // newDate.setMonth(newDate.getMonth());
    // console.log(newDate)

    // var membership = {}
    // if (plan === 'plan3') {
    //     membership = {
    //         isAMember: true,
    //         endDate: newDate,
    //         boxMaxNum: 2,
    //         plan: 3,
    //         clothingItemMaxNum: 6,
    //         accessoryMaxNum: 3
    //     }
    // } else if (plan === 'plan2') {
    //     membership = {
    //         isAMember: true,
    //         endDate: newDate,
    //         boxMaxNum: 4,
    //         plan: 2,
    //         clothingItemMaxNum: 3,
    //         accessoryMaxNum: 0
    //     }
    // } else if (plan === 'plan1') {
    //     membership = {
    //         isAMember: true,
    //         endDate: newDate,
    //         boxMaxNum: 2,
    //         plan: 1,
    //         clothingItemMaxNum: 3,
    //         accessoryMaxNum: 0
    //     }
    // }

    // axios('https://sheyou-backend.herokuapp.com/users/' + id, {
    //     method: 'put',
    //     data: {
    //         membership: membership
    //     },
    //     headers: {
    //         'Authorization': 'Bearer ' + token,
    //     }
    // }).then(function (res) {
    //     if (res.status === 200) {
    //         console.log(res)
    //         self.props.history.push("/mypage/membership")
    //     } else {
    //         alert('something went wrong :' + res)
    //     }
    // })
    //     .catch(function (err) {
    //         alert('something went wrong: ' + err)
    //     })
  }

  handleChange = (e, { value }) => this.setState({ value })

  render () {
    const membership = this.state.membership
    var expireDate = this.state.expireDate.toString()
    console.log()

    const loading = this.state.loading

    return (
      <div>
        {/* <Nav /> */}

        {loading ? (
          <Grid>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>

            <Grid.Row colums={2}>
              <Grid.Column width={7}></Grid.Column>
              <Grid.Column width={6}>
                <Header size='huge'>
                  <Icon loading name='asterisk' size='massive' color='yellow' />
                  Loading...
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid>
        ) : (
          <>
            <div className='bg_image'>
              <br />
              <br />

              <Container>
                <Segment>
                  <Grid container>
                    {membership.isAMember !== true && (
                      <>
                        <Grid.Row centered>
                          <Segment
                            style={{
                              paddingLeft: 200,
                              paddingRight: 200,
                              marginTop: 30
                            }}
                          >
                            <Grid.Row columns={5} divided>
                              <Grid.Column width={2}></Grid.Column>
                              <Grid.Column
                                width={7}
                                style={{ paddingBottom: 0, marginBottom: 10 }}
                              >
                                <Header
                                  as='h4'
                                  style={{ paddingBottom: 0, marginBottom: 0 }}
                                >
                                  Expire Date:
                                </Header>
                                <p>
                                  {expireDate.substring(0, 11) +
                                    ', ' +
                                    expireDate.substring(11, 15)}
                                </p>
                              </Grid.Column>
                              <Grid.Column width={4}>
                                <Header
                                  as='h4'
                                  style={{ paddingBottom: 0, marginBottom: 0 }}
                                >
                                  {'Plan: '}
                                </Header>
                                <p>
                                  {this.state.plan === 'plan1' &&
                                    '3 clthing / shipment'}
                                  {this.state.plan === 'plan2' &&
                                    '3 clthing / shipment'}
                                  {this.state.plan === 'plan3' &&
                                    '6 clthing + 4 accessory / shipment'}
                                  {this.state.plan === 'plan4' &&
                                    '6 clthing + 4 accessory / shipment'}
                                </p>
                              </Grid.Column>
                              <Grid.Column>
                                {this.state.plan === 'plan1' &&
                                  '2 shipment / month'}
                                {this.state.plan === 'plan2' &&
                                  '4 shipment / month'}
                                {this.state.plan === 'plan3' &&
                                  '2 shipment / month'}
                                {this.state.plan === 'plan4' &&
                                  '4 shipment / month'}
                              </Grid.Column>
                            </Grid.Row>
                          </Segment>
                        </Grid.Row>

                        {this.state.plan === 'plan1' && (
                          <>
                            <Grid.Row centered>
                              <p>You should pay 299 yuan </p>
                            </Grid.Row>
                            {/* <Grid.Row centered>
                                                    <p> If you have finished your payment，please click NEXT</p>
                                                </Grid.Row> */}

                            <Grid.Row columns={4}>
                              <Grid.Column width={1}></Grid.Column>
                              <Grid.Column width={7}>
                                <Grid centered>
                                  <Grid.Row>
                                    <Image
                                      size='tiny'
                                      centered
                                      src={this.state.AliPayIcon}
                                    />
                                  </Grid.Row>
                                  <Grid.Row>
                                    {/* <Popup
                                                                    content={<Image src={this.state.AliPay} />}
                                                                    on='checked'
                                                                    pinned
                                                                    trigger={<Checkbox label='Alipay'
                                                                        radio
                                                                        name='Alipay'
                                                                        value='Alipay'
                                                                        checked={this.state.value === 'Alipay'}
                                                                        onChange={this.handleChange} />}
                                                                /> */}
                                    <Radio
                                      label='Alipay'
                                      name='Alipay'
                                      value='Alipay'
                                      checked={this.state.value === 'Alipay'}
                                      onChange={this.handleChange}
                                    />
                                  </Grid.Row>
                                </Grid>
                              </Grid.Column>
                              <Grid.Column width={7}>
                                <Grid centered>
                                  <Grid.Row>
                                    <Image
                                      size='tiny'
                                      centered
                                      src={this.state.wechatPayIcon}
                                    />
                                  </Grid.Row>
                                  <Grid.Row>

                                    <Radio
                                      label='WechatPay'
                                      name='WechatPay'
                                      value='WechatPay'
                                      checked={this.state.value === 'WechatPay'}
                                      onChange={this.handleChange}
                                    />
                                  </Grid.Row>
                                </Grid>
                              </Grid.Column>

                              <Grid.Column width={1}></Grid.Column>
                            </Grid.Row>
                            <Grid.Row></Grid.Row>

                            <Grid.Row centered>
                              <Button
                                color='orange'
                                style={{ paddingLeft: 40, paddingRight: 40 }}
                                onClick={this.onHandleSubmit}
                              >
                                Next
                              </Button>
                            </Grid.Row>
                          </>
                        )}
                        {this.state.plan === 'plan2' && (
                          <>
                            <Grid.Row centered>
                              <p>You should pay 499 yuan </p>
                            </Grid.Row>
                            {/* <Grid.Row centered>
                              <p>
                                {' '}
                                If you have finished your payment，please click
                                NEXT
                              </p>
                            </Grid.Row> */}

                            <Grid.Row columns={4}>
                              <Grid.Column width={1}></Grid.Column>
                              <Grid.Column width={7}>
                                <Grid centered>
                                  <Grid.Row>
                                    <Image
                                      size='tiny'
                                      centered
                                      src={this.state.AliPayIcon}
                                    />
                                  </Grid.Row>
                                  <Grid.Row>
                                    <Radio
                                      label='Alipay'
                                      name='Alipay'
                                      value='Alipay'
                                      checked={this.state.value === 'Alipay'}
                                      onChange={this.handleChange}
                                    />
                                  </Grid.Row>
                                </Grid>
                              </Grid.Column>
                              <Grid.Column width={7}>
                                <Grid centered>
                                  <Grid.Row>
                                    <Image
                                      size='tiny'
                                      centered
                                      src={this.state.wechatPayIcon}
                                    />
                                  </Grid.Row>
                                  <Grid.Row>
                                    <Radio
                                      label='WechatPay'
                                      name='WechatPay'
                                      value='WechatPay'
                                      checked={this.state.value === 'WechatPay'}
                                      onChange={this.handleChange}
                                    />
                                  </Grid.Row>
                                </Grid>
                              </Grid.Column>

                              <Grid.Column width={1}></Grid.Column>
                            </Grid.Row>
                            <Grid.Row></Grid.Row>

                            <Grid.Row centered>
                              <Button
                                color='orange'
                                style={{ paddingLeft: 40, paddingRight: 40 }}
                                onClick={this.onHandleSubmit}
                              >
                                Next
                              </Button>
                            </Grid.Row>
                          </>
                        )}

                        {this.state.plan === 'plan3' && (
                          <>
                            <Grid.Row centered>
                              <p>You should pay 699 yuan </p>
                            </Grid.Row>
                            <Grid.Row centered>
                              <p>
                                {' '}
                                If you have finished your payment，please click
                                NEXT
                              </p>
                            </Grid.Row>

                            <Grid.Row columns={4}>
                              <Grid.Column width={1}></Grid.Column>
                              <Grid.Column width={7}>
                                <Grid centered>
                                  <Grid.Row>
                                    <Image
                                      size='tiny'
                                      centered
                                      src={'alipay.png'}
                                    />
                                  </Grid.Row>
                                  <Grid.Row>
                                    <Radio
                                      label='Alipay'
                                      name='Alipay'
                                      value='Alipay'
                                      checked={this.state.value === 'Alipay'}
                                      onChange={this.handleChange}
                                    />
                                  </Grid.Row>
                                </Grid>
                              </Grid.Column>
                              <Grid.Column width={7}>
                                <Grid centered>
                                  <Grid.Row>
                                    <Image
                                      size='tiny'
                                      centered
                                      src={'wechatpay.png'}
                                    />
                                  </Grid.Row>
                                  <Grid.Row>
                                    <Radio
                                      label='WechatPay'
                                      name='WechatPay'
                                      value='WechatPay'
                                      checked={this.state.value === 'WechatPay'}
                                      onChange={this.handleChange}
                                    />
                                  </Grid.Row>
                                </Grid>
                              </Grid.Column>

                              <Grid.Column width={1}></Grid.Column>
                            </Grid.Row>
                            <Grid.Row></Grid.Row>

                            <Grid.Row centered>
                              <Button
                                color='orange'
                                style={{ paddingLeft: 40, paddingRight: 40 }}
                                onClick={this.onHandleSubmit}
                              >
                                Next
                              </Button>
                            </Grid.Row>
                          </>
                        )}
                      </>
                    )}

                    {membership.isAMember === true && (
                      <>
                        <Grid.Row></Grid.Row>
                        <Grid.Row
                          centered
                          style={{ marginTop: 50, marginBottom: 50 }}
                        >
                          <Header>Hello, You are already a member</Header>
                        </Grid.Row>

                        <Grid.Row></Grid.Row>

                        <Grid.Row centered>
                          <Button
                            color='orange'
                            style={{ paddingLeft: 40, paddingRight: 40 }}
                            onClick={this.onHandleOkay}
                          >
                            Okay
                          </Button>
                        </Grid.Row>
                      </>
                    )}
                  </Grid>
                </Segment>
              </Container>

              <br />
              <br />
              <Foot />
            </div>
          </>
        )}
      </div>
    )
  }
}

export default withRouter(Payment)
