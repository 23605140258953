import React from 'react'
import {
  Grid,
  Form,
  Button,
  Segment,
  Message,
  Header,
  Icon
} from 'semantic-ui-react'

// import IntlTelInput from 'react-intl-tel-input-ueat'

import { Parallax } from 'react-parallax'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
// import Nav from '../nav/Nav'
import Foot from '../foot/Foot'
import newOTP from 'otp-generators'
import emailjs from 'emailjs-com'
import { passwordStrength } from 'check-password-strength'
var jwt = require('jsonwebtoken')
const userLang = navigator.language || navigator.userLanguage

class SignupPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      username: '',
      email: '',
      password: '',
      passStrength: '',
      passWeak: false,
      userinfo: '',
      sentEmail: '',
      loading: false,
      activeItem: 'Phone',
      somethingWrong: false
    }
    this.onHandleUsername = this.onHandleUsername.bind(this)
    this.onHandleEmail = this.onHandleEmail.bind(this)
    this.onHandlePassword = this.onHandlePassword.bind(this)
    this.onHandleSubmit = this.onHandleSubmit.bind(this)
    this.onHandlePhoneNumber = this.onHandlePhoneNumber.bind(this)
    this.onHandlePhoneVerification = this.onHandlePhoneVerification.bind(this)
  }

  componentDidMount () {
    window.scrollTo(0, 0)
  }

  onHandlePhoneNumber (event) {
    this.setState({
      phoneNumber: event.target.value
    })
  }

  onHandlePhoneVerification (event) {
    this.setState({
      phoneVerification: event.target.value
    })
  }

  onHandleUsername (event) {
    this.setState({
      username: event.target.value
    })

    console.log(this.state)
  }

  onHandleEmail (event) {
    this.setState({
      email: event.target.value
    })

    console.log(this.state)
  }

  onHandlePassword (event) {
    const password = event.target.value
    this.setState({
      password: password,
      passStrength: passwordStrength(password).value
    })
    console.log(this.state.passStrength)
  }

  onHandleSubmit (event) {
    event.preventDefault()
    const self = this
    const passStrength = self.state.passStrength
    const identifier = this.state.username
    const email = email
    console.log(passStrength)
    if (passStrength === 'Weak' || passStrength === 'Too weak') {
      this.setState({
        passWeak: true
      })
    } else {
      this.setState({
        passWeak: false,
        loading: true
      })
      axios
        .post('https://sheyou-backend.herokuapp.com/auth/local/register', {
          username: this.state.username,
          email: this.state.email,
          password: this.state.password,
          confirmed: false
        })
        .then(function (response) {
          console.log(response)
          self.setState({
            loading: false,
            sentEmail: true
          })
          const id = response.data.user.id
          localStorage.setItem('id', id)

          const verificationCode = newOTP.generate(6, {
            alphabets: false,
            upperCase: false,
            specialChar: false
          })

          const token=jwt.sign(
            {
              data: verificationCode
            },
            'sheyoufashion',
            { expiresIn: '5min' }
          )

          emailjs
            .send(
              'service_orld5qb',
              'template_c1h9qsr',
              {
                email: JSON.stringify(email),
                reply_to: '1181456762@qq.com',
                name: JSON.stringify(identifier),
                verificationCode: verificationCode
              },
              'user_4rMSnDYBigUutDlR5RSib'
            )
            .then(
              function (response) {
                console.log('SUCCESS!', response.status, response.text)
                self.setState({
                  loading: false,
                  sentEmail: true
                })

                localStorage.setItem('identifier', identifier)
                localStorage.setItem('verificationCode', token)
                self.props.history.push('/emailConfirmation')
              },
              function (error) {
                self.setState({
                  loading: false,
                  sentEmail: false
                })
                self.setState({ somethingWrong: 'FAILED...' + error.text })
              }
            )
        })
        .catch(error => {
          const identifier = self.state.username
          const email = self.state.email
          const password = self.state.password
       

          console.log(error)
          localStorage.setItem('email', email)

          console.log('An error occurred:', error.response)
        })
    }
  }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  render () {
    const sentEmail = this.state.sentEmail
    const passWeak = this.state.passWeak
    const loading = this.state.loading
    return (
      <div>
        {/* < Nav /> */}
        <Parallax
          bgImage='https://i.loli.net/2020/11/25/ZHjYm1wL9runqOz.png'
          bgImageAlt=''
          strength={400}
        >
          <div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

            {userLang === 'zh-CN' && (
              <Grid centered>
                <Grid.Column width={8}>
                  <Segment>
                    {this.state.somethingWrong !== false && (
                      <Message>
                        <Message.Header>出现了错误</Message.Header>
                        <p>{this.state.somethingWrong}</p>
                      </Message>
                    )}
                    <Form stackable onSubmit={this.onHandleSubmit}>
                      <Form.Input
                        onChange={this.onHandleUsername}
                        label='用户名'
                        placeholder='Username'
                      />
                      <Form.Input
                        onChange={this.onHandleEmail}
                        label='邮箱地址'
                        placeholder='Email'
                      />
                      <Form.Input
                        onChange={this.onHandlePassword}
                        label='密码'
                        placeholder='Password'
                        type='password'
                      />
                    </Form>

                    <br />
                    {passWeak === true && (
                      <Grid.Row>
                        <Message
                          warning
                          header='密码过弱'
                          content='请加强您的密码组合，您的密码必须大于8位，并包含大小写，数字字母组合，以及特殊符号。'
                        />
                      </Grid.Row>
                    )}
                    {sentEmail === true && (
                      <Grid.Row>
                        <Message
                          success
                          header='邮件已发送'
                          content='确认邮件已成功发送，请查看您的邮箱,五分钟后过期'
                        />
                      </Grid.Row>
                    )}
                    {sentEmail === false && (
                      <Grid.Row>
                        <Message
                          error
                          header='注册失败'
                          content='抱歉，出了点错误'
                        />
                      </Grid.Row>
                    )}
                    <br />

                    {loading === true && (
                      <Header size='big'>
                        <Icon
                          loading
                          name='asterisk'
                          size='huge'
                          color='yellow'
                        />
                        Loading...
                      </Header>
                    )}

                    <Grid columns={4} centered>
                      <Grid.Column width={4}>
                        <Button
                          onClick={this.onHandleSubmit}
                          type='submit'
                          fluid
                          size='large'
                          floated='right'
                          content='注册'
                          color='orange'
                        />
                      </Grid.Column>
                    </Grid>
                  </Segment>
                </Grid.Column>
              </Grid>
            )}

            {userLang !== 'zh-CN' && (
              <Grid centered>
                <Grid.Column width={8}>
                  <Segment>
                    {this.state.somethingWrong !== false && (
                      <Message>
                        <Message.Header>Something Wrong</Message.Header>
                        <p>{this.state.somethingWrong}</p>
                      </Message>
                    )}
                    <Form stackable onSubmit={this.onHandleSubmit}>
                      <Form.Input
                        onChange={this.onHandleUsername}
                        label='Username'
                        placeholder='Username'
                      />
                      <Form.Input
                        onChange={this.onHandleEmail}
                        label='Email'
                        placeholder='Email'
                      />
                      <Form.Input
                        onChange={this.onHandlePassword}
                        label='Password'
                        placeholder='Password'
                        type='password'
                      />
                    </Form>

                    <br />
                    {passWeak === true && (
                      <Grid.Row>
                        <Message
                          warning
                          header='Weak Password'
                          content='Please create a stronger password combination. Your password must be longer than 8 digits, including upper lowercase letters, numbers, and special characters.'
                        />
                      </Grid.Row>
                    )}
                    {sentEmail === true && (
                      <Grid.Row>
                        <Message
                          success
                          header='Email Sent'
                          content='We Have Successfully Sent Confirmation, Please Check In Your Email, Expire in 5 Minutes'
                        />
                      </Grid.Row>
                    )}
                    {sentEmail === false && (
                      <Grid.Row>
                        <Message
                          error
                          header='Register Failure'
                          content='Sorry, Something Went Wrong'
                        />
                      </Grid.Row>
                    )}
                    <br />

                    {loading === true && (
                      <Header size='big'>
                        <Icon
                          loading
                          name='asterisk'
                          size='huge'
                          color='yellow'
                        />
                        Loading...
                      </Header>
                    )}

                    <Grid columns={4} centered>
                      <Grid.Column width={4}>
                        <Button
                          onClick={this.onHandleSubmit}
                          type='submit'
                          fluid
                          size='large'
                          floated='right'
                          content='Sign UP'
                          color='orange'
                        />
                      </Grid.Column>
                    </Grid>
                  </Segment>
                </Grid.Column>
              </Grid>
            )}
          </div>

          <div style={{ height: '300px' }} />
        </Parallax>

        <Foot />
      </div>
    )
  }
}

export default withRouter(SignupPage)
