import React from 'react'
import SustainPageText from './SustainSecText'
import SusParallax from './SustainSecParallax'




class SustainSec extends React.Component{
    render(){
      return<div> 
 
          <SusParallax strength={150} image="https://res.cloudinary.com/sheyou/image/upload/v1654170486/five_positive_multiethnic_women_posing_standing_to_2021_09_02_04_35_31_utc_644aab45f3.jpg" content1={<SustainPageText/>}/>

          {/* <SusParallax Parall strength={100} image="https://i.loli.net/2021/04/15/4SF1JxHKnoqpbie.jpg"/> */}
         
          



      </div>
    }
  }
  
  export default SustainSec;
