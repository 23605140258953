import React from 'react'
import { Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
const userLang = navigator.language || navigator.userLanguage


const HomePageButton = () => (
  <div>
    <Link to='/about'>
    
      <Button size="huge" circular color='teal' style={{ paddingLeft:60, paddingRight:60}}>
      {userLang === 'zh-CN' && ('立刻加入')}
      {userLang !== 'zh-CN' && ('Join Now')}
      </Button>
 
 
    
    </Link>
  </div>
)

export default HomePageButton

//    <Button size="huge" circular  style={{backgroundColor:'white', paddingLeft:60, paddingRight:60}}>
