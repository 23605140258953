import React from 'react'
// import axios from 'axios'
import Parallax from './HomeSecParallax'

class HomeSec extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      pic:''

    }

  }


  componentDidMount(){

  }

    render(){
      return<div> 
       
         <Parallax  name="HomepAGE" image={"https://res.cloudinary.com/sheyou/image/upload/v1654168716/positive_diverse_women_enjoying_time_on_sandy_coas_2022_01_17_19_08_29_utc_0ba4f60e0c.jpg"}/>
         {/* https://i.loli.net/2021/04/14/ldmjepDLfksIuCT.jpg */}
      </div>
    }
  }
  
  export default HomeSec;