import React from 'react'
import { Button, } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
const userLang = navigator.language || navigator.userLanguage

const SustainButton = () => (
   
  <div>
    <Link to='/about'>
    {userLang === 'zh-CN' && (
      <Button color='teal' circular size='huge'>更多</Button>
    )}
    {userLang !== 'zh-CN' && (
      <Button color='teal' circular size='huge'>Learn More</Button>
    )}
    </Link>
  </div>
  
)

export default SustainButton