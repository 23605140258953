import React from 'react'
import { Grid,Form, Button, Container,Segment, Header, Icon } from 'semantic-ui-react'

import { Parallax,} from 'react-parallax';
import axios from 'axios'
import { withRouter } from 'react-router-dom'
// import Nav from '../nav/Nav'


class ForgetPasswordPhone extends React.Component {
  constructor(props){
    super(props)
    this.state={
      newpassword:'',
      confirm:'',
      email:'',
      sentEmail:''
  }
  // this.onHandleNewPassword=this.onHandleNewPassword.bind(this)
  // this.onHandleConfirm=this.onHandleConfirm.bind(this)
  this.onHandleEmail=this.onHandleEmail.bind(this)
  this.onHandleSubmit=this.onHandleSubmit.bind(this)


}


onHandleEmail(event){
  
    this.setState({
      email:event.target.value
    })
  
  }

onHandleSubmit(){
  const self=this
  const email=self.state.email
  axios.post('https://sheyou-backend.herokuapp.com/auth/forgot-password', {
    email:email,
    })
    .then(response=>{
      console.log('Your user received an email',response)
      // self.props.history.push('/resetpassword')
      if(response.status===200)
      {
        self.setState({
        sentEmail:true
        })
      }
      else{
        self.setState({
          sentEmail:false
        })
      }
    })
    .catch(error=>{
      console.log('An error occurred:', error.response)
      self.setState({
        sentEmail:false
      })
    })
}

// onHandleDropdown(event,data){
//   this.setState({
//     value:data.value
//   })
// }
  
  render() {
    const sentEmail=this.state.sentEmail

    return<div>
       {/* <Nav /> */}

    
    <Parallax
    bgImage='https://res.cloudinary.com/sheyou/image/upload/v1645963256/IMG_2342_06609c160e.jpg?167986.89999997616'
    bgImageAlt=''
    strength={400}>
        
    
    <div>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    
    <Container>

      {sentEmail===''&&
        <Segment>
       <Form stackable >
         <Form.Input onChange={this.onHandleEmail}
           label='Email'
           placeholder='Email'
         />
         {/* <Form.Input onChange={this.onHandleNewPassword}
           label='New Password'
           placeholder='New Password'
         />
         <Form.Input onChange={this.onHandleConfirm}
           label='Confirm'
           placeholder='Confirm'
         />
         */}
       </Form>

       <br/>
       <br/>
       

       <Grid  columns={3} centered>
         
           <Grid.Column width={10}>
           <Button onClick={this.onHandleSubmit} type='submit' fluid size='large' floated='right' content='Next' color='orange' />
           </Grid.Column>

       </Grid>
        </Segment>
      }

      {sentEmail===true&&
      <Segment>
          
      <Grid centered colums={2}>
        <Grid.Row></Grid.Row>
        <Grid.Row></Grid.Row>
        <Grid.Row>
        <Header size='huge' color='orange'>
          <Icon name='smile outline'/>
          <Header.Content>Rest Link Has Sent To Your Email</Header.Content>
          
        </Header>
        </Grid.Row>
        
        <Grid.Row></Grid.Row>
        <Grid.Row></Grid.Row>
      </Grid>
    </Segment>
      
      }

  {sentEmail===false&&
        <Segment>
          
          <Grid centered colums={2}>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row>
            <Header size='huge' color='orange'>
              <Icon name='exclamation triangle'/>
              <Header.Content>Something Went Wrong, Please Try Again</Header.Content>
              
            </Header>
            </Grid.Row>
            
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid>
        </Segment>
      }
     
    </Container>
    </div>

    <div style={{ height:"300px"} }/>
</Parallax>
    

</div>
  }

}

export default withRouter(ForgetPasswordPhone)









