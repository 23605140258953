import React from 'react'
import axios from 'axios'
import { 
    Grid, 
    Header, 
    Icon, 
    Label, 
    Pagination, 
    Segment,
    Menu,
    Sidebar,
    Image
} from 'semantic-ui-react'
import SaleCardPhone from './SaleCardPhone'
import { withRouter } from 'react-router-dom'
import { Parallax } from 'react-parallax';
import round from '@stdlib/math-base-special-round'
import { Link } from 'react-router-dom'
import NavMobilePhone from '../nav/NavMobilePhone'


import LogInPagePhonePop from '../mypage/LogInPagePhonePop'


class SalePagePhone extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      products: [],
      loading: true,
      activePage: 1,
      pageNum: 0,
      categoriesSelected: 'Select',
      categoriesVisible: '',
      sizesVisible: '',
      sizesSelected: 'Select',
      activeItemPath: 'Sale',
      activeItem: 'All',
      cart: [],
      bag: [],
      accessoryCart: [],
      accessoryBag: [],
      signinVisible: ''

      // productId
    }
    // this.onHandleClick = this.onHandleClick.bind(this)
    this.handlePaginationChange = this.handlePaginationChange.bind(this)
    this.categoriesVisible = this.categoriesVisible.bind(this)
    this.sizesVisible = this.sizesVisible.bind(this)
    this.signinVisible = this.signinVisible.bind(this)
    this.handleItemClickCart = this.handleItemClickCart.bind(this)
    this.handleItemClick = this.handleItemClick.bind(this)
  }
  componentDidMount() {

    window.scrollTo(0, 0)

    const self = this
    const p = this.props
    const is = p.is
    const activeItem = p.activeItem
    
    self.setState({
      activeItem: activeItem,
      is: is
    })

    var page = p.location.search.substring(3)
    console.log(page==='')
    if(page===null||page===undefined||page===''){
      page=1
    }
    
    self.setState({
        activeItem: activeItem,
        is: is
      })

    console.log(page)
    if(page!==undefined&&page!==null){
      self.setState({
        activePage: page
      })
      this.getProducts()
    }
    
    // window.onload = (event) => {
    //   self.setState({
    //     loading: false
    //   })
    // };

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }

    }, 700);
  }

  handlePaginationChange(e, {activePage}){
    this.setState({activePage})
    const thisPath = this.props.history.location.pathname
    this.props.history.push(thisPath+'?p='+activePage)
    window.location.reload(true)
  }


  getProducts() {
    const self = this
    const p = this.props
    const is = p.is
    var activeItem = p.activeItem
    // console.log(activeItem)

    if(activeItem!=='All'){
      activeItem.toLowerCase()
      axios.get('https://sheyou-backend.herokuapp.com/second-hand-products')
      .then(function(res){
        if(res.status === 200){
          const data = res.data
          const products =[]
          if(is === 'categories'){
            data.forEach(function(item){
              item.categories.forEach(function(category){
                if(category.name === activeItem){
                  products.push(item)
                }
              })
            })
          }
          else{
            data.forEach(function(item){
              item.sizes.forEach(function(size){
                if(size.name === activeItem){
                  products.push(item)
                }
              })
            })
          }
          console.log(products)

          const itemNum = products.length
          const activePage = self.state.activePage
          var dataShow=[]
          for(var i = activePage * 8 - 8; i < activePage * 8; i++){
            if(i<itemNum){
              dataShow.push(products[i])
            }
          }
          
          var roundPageNum = 0
          var pageNum = 0
          roundPageNum = round(itemNum / 9)
          pageNum = itemNum / 8.0

          if(pageNum - roundPageNum > 0){
              pageNum = roundPageNum + 1
          }

          self.setState({
            products: dataShow,
            loading: false,
            itemNum: itemNum,
            pageNum: pageNum
          })
        }
        else{
          alert('something went wrong :(')
          localStorage.setItem('login', false)
        }
      })
    }
    else{
      axios.get('https://sheyou-backend.herokuapp.com/second-hand-products')
      .then(function (res) {
        console.log(res)
        if (res.status === 200) {
          const data = res.data
          // const products = data
          const itemNum = data.length
          const activePage = self.state.activePage

          var dataShow = []
          for (var i = activePage*8 - 8; i < activePage*8; i++){
            if(i<itemNum){
              dataShow.push(data[i])
            }
          }

          var roundPageNum = 0
          var pageNum = 0
          roundPageNum = (itemNum / 8)
          pageNum = itemNum / 8.0
          if(roundPageNum - pageNum < 0){
              pageNum = roundPageNum + 1
          }
          // console.log(data)
          self.setState({
            products: dataShow,
            loading: false,
            itemNum: itemNum,
            pageNum: pageNum
          })
          // self.setState({
          //   products: products
          // })
        }
        else {
          alert('something went wrong :(')
          localStorage.setItem('login', false)
        }

      })
    }
  }

  // onHandleClick(event, data) {
  //   const self = this
  //   const productId = data.id
  //   event.preventDefault()
  //   localStorage.setItem('saleproductId', productId)
  //   self.props.history.push('/saleproduct')
  // }

  categoriesVisible(){
      const categoriesVisible = this.state.categoriesVisible
      const self = this
      console.log(categoriesVisible)
      if (categoriesVisible !== true){
          self.setState({
              categoriesVisible: true
          })
      }else if(categoriesVisible === true) {
        self.setState({
            categoriesVisible: false
        })
      }
  }

  sizesVisible(){
      const sizesVisible = this.state.sizesVisible
      const self = this
      if (sizesVisible !== true){
          self.setState({
              sizesVisible: true
          })
      }else if(sizesVisible === true){
          self.setState({
              sizesVisible: false
          })
      }
  }

  signinVisible () {
    const signinVisible = this.state.signinVisible
    console.log(signinVisible)

    if (signinVisible !== true) {
      this.setState({
        signinVisible: true
      })
    }else if (signinVisible === true) {
      this.setState({
        signinVisible: false
      })
    }
  }

  handleItemClickCart (event, data) {
    // var name = data.name
    // const link = data.link
    const self = this
    const log = localStorage.getItem('login')
    if (log !== 'true') {
      self.signinVisible()
    } else {
      self.props.history.push('/cart')
    }
  }

  handleItemClick (event, data) {
    // var name = data.name
    const link = data.link
    const self = this
    window.location.assign('https://www.sheyoufashion.com/#' + link)
    if (link === undefined || link === null) {
      self.props.history.push('/sale')
      window.location.reload(true)
    } else {
      self.props.history.push(link)
      window.location.reload(true)
    }
  }

  render() {
    const s = this.state
    const self = this
    const loading = s.loading
    // const n={position:'absolute',top:1000}
    console.log(s.activeItem)

    const cartNum = s.cart.length + s.accessoryCart.length
    // const bagNum = s.bag.length + s.accessoryBag.length

    return <div>
      {/* <Nav /> */}


      {loading ? (
        <Grid >
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>


          <Grid.Row colums={2}>
            <Grid.Column width={7}></Grid.Column>
            <Grid.Column width={6}>
              <Header size='huge'>
                <Icon loading name='asterisk' size='massive' color='yellow' />
                Loading...
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
        </Grid>
      ) : (
        <Grid>
            <Grid.Row
              centered
              style={{ paddingBottom: 0, marginBottom: 0, marginTop: 10 }}
            >
              <Menu borderless secondary>
                <Menu.Item
                  as={Link}
                  link='/clothing'
                  name='Clothing'
                  active={s.activeItemPath === 'Clothing'}
                  onClick={this.handleItemClick}
                />

                <Menu.Item
                  as={Link}
                  link='/accessory'
                  name='Accessory'
                  active={s.activeItemPath === 'Accessory'}
                  onClick={this.handleItemClick}
                />

                <Menu.Item
                  as={Link}
                  link='/sale'
                  name='Sale'
                  active={s.activeItemPath === 'Sale'}
                  onClick={this.handleItemClick}
                />
                <Menu.Item
                  name='Cart'
                  linkto='/cart'
                  active={s.activeItemPath === 'Cart'}
                  onClick={this.handleItemClickCart}
                >
                  <Icon name='shopping cart' color='teal'>
                    <Label floating circular>
                      {cartNum}
                    </Label>
                  </Icon>

                  {/* heart */}
                </Menu.Item>
              </Menu>
            </Grid.Row>
            <Grid.Row
              columns={3}
              style={{
                paddingBottom: 0,
                marginBottom: 0,
                paddingTop: 0,
                marginTop: 5
              }}
            >
              <Grid.Column width={1}></Grid.Column>
              <Grid.Column width={14}>
                <Segment style={{ height: 70 }}>
                  <Grid divided style={{ height: 70 }}>
                    <Grid.Row columns={2}>
                      <Grid.Column width={2}></Grid.Column>
                      <Grid.Column width={6}>
                        <Grid style={{ height: 70 }}>
                          <Grid.Row
                            style={{ paddingBottom: 0, marginBottom: 0 }}
                          >
                            <Header as='h6' style={{ paddingLeft: 10 }}>
                              Categories
                            </Header>
                          </Grid.Row>
                          <Grid.Row>
                            {/* <Select placeholder='Select your country' options={countryOptions} /> */}
                            {
                                this.state.activeItem==='All'&&<Header
                                as='h6'
                                color='orange'
                                style={{ paddingLeft: 10 }}
                                onClick={self.categoriesVisible}
                              >
                                <Icon corner name='list' />
                                
                                Select
                              </Header>
                              } 
                              {
                                this.state.activeItem!=='All'&&<Header
                                as='h6'
                                color='orange'
                                style={{ paddingLeft: 10 }}
                                onClick={self.categoriesVisible}
                              >
                                <Icon corner name='list' />
                                
                                {this.state.activeItem}
                              </Header>
                              } 



                            <Sidebar
                              as={Menu}
                              animation='overlay'
                              // onHide={this.categoriesVisible}
                              vertical
                              visible={this.state.categoriesVisible === true}
                              width='thin'
                              direction='bottom'
                            >
                              <Menu.Item
                                onClick={this.handleItemClick}
                                link='/sale'
                              >
                                All
                              </Menu.Item>
                              <Menu.Item
                                onClick={this.handleItemClick}
                                link='/sale/feminine'
                              >
                                Feminine
                              </Menu.Item>
                              <Menu.Item
                                onClick={this.handleItemClick}
                                link='/sale/masculine'
                              >
                                Masculine
                              </Menu.Item>
                            </Sidebar>

                            {/* <Button.Group style={{marginLeft:10}} basic size='mini' color='orange'>
                            <Button >
                              <Icon name='list' />
                              Select
                            </Button>
                            <Dropdown
                              className='button icon'
                              floating
                              options={optionsCategories}
                              trigger={<></>}
                            />
                          </Button.Group> */}
                          </Grid.Row>
                        </Grid>
                      </Grid.Column>

                      <Grid.Column width={6}>
                        <Grid style={{ height: 70 }}>
                          <Grid.Row
                            style={{ paddingBottom: 0, marginBottom: 0 }}
                          >
                            <Header as='h6' style={{ paddingLeft: 10 }}>
                              Sizes
                            </Header>
                          </Grid.Row>
                          <Grid.Row>
                            <Header
                              style={{ paddingLeft: 10 }}
                              as='h6'
                              color='orange'
                              onClick={self.sizesVisible}
                            >
                              <Image
                                style={{
                                  height: 18,
                                  width: 18,
                                  position: 'relative',
                                  top: -5
                                }}
                                src='https://res.cloudinary.com/sheyou/image/upload/v1645878606/4_4417bd5612.png?441339.6999999881'
                                size='mini'
                              />
                              <p
                                style={{
                                  position: 'relative',
                                  top: -20,
                                  left: 27
                                }}
                              >
                                Select
                              </p>
                            </Header>

                            <Sidebar
                              as={Menu}
                              animation='overlay'
                              // onHide={this.sizesVisible}
                              vertical
                              visible={this.state.sizesVisible === true}
                              width='thin'
                              direction='bottom'
                            >
                              <Menu.Item
                                onClick={this.handleItemClick}
                                link='/sale/average'
                              >
                                Average
                              </Menu.Item>
                              <Menu.Item
                                onClick={this.handleItemClick}
                                link='/sale/m'
                              >
                                M
                              </Menu.Item>
                              <Menu.Item
                                onClick={this.handleItemClick}
                                link='/sale/s'
                              >
                                S
                              </Menu.Item>
                              <Menu.Item
                                onClick={this.handleItemClick}
                                link='/sale/l'
                              >
                                L
                              </Menu.Item>
                              <Menu.Item
                                onClick={this.handleItemClick}
                                link='/sale/xxsxs'
                              >
                                XXS XS
                              </Menu.Item>
                              <Menu.Item
                                onClick={this.handleItemClick}
                                link='/sale/xxlxl'
                              >
                                XXL XL
                              </Menu.Item>
                            </Sidebar>

                            {/* <Button.Group  basic size='mini' color='orange' style={{marginLeft:10}}>
                            <Button style={{height:30, paddingRight:35}}>
                              <Image
                                style={{
                                  height: 15,
                                  width: 15,
                                  position:'relative',
                                  top:-1,
                                  left:-3
                                }}
                                src='https://res.cloudinary.com/sheyou/image/upload/v1645878606/4_4417bd5612.png?441339.6999999881'
                               
                              />
                              <p style={{position:'relative',top:-16,left:17}}>
                              Select
                              </p>
                            </Button>
                            <Dropdown
                              className='button icon'
                              floating
                              options={optionsSizes}
                              trigger={<></>}
                            />
                          </Button.Group> */}
                          </Grid.Row>
                        </Grid>
                      </Grid.Column>

                      <Grid.Column width={1}></Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Grid.Column>
              <Grid.Column width={1}></Grid.Column>
            </Grid.Row>

            <Grid.Row>
              {s.products.length === 0 && (
                <Grid.Column width={16} style={{ paddingRight: 50 }}>
                  <Parallax
                    bgImage='https://i.loli.net/2020/11/25/ZHjYm1wL9runqOz.png'
                    bgImageAlt=''
                    strength={200}
                  >
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />

                    <div>
                      <Segment>
                        <Grid>
                          <Grid.Row centered>
                            <Header>
                              <Icon
                                loading
                                name='asterisk'
                                size='massive'
                                color='yellow'
                              />
                              Loading ...
                            </Header>
                          </Grid.Row>

                          {/* <Grid.Row centered>
                      <Button size='huge' color='orange' onClick={this.handleItemClick}> Browse All</Button>
                    </Grid.Row> */}
                        </Grid>
                      </Segment>
                    </div>

                    <div style={{ height: '270px' }} />
                  </Parallax>
                </Grid.Column>
              )}
              <Grid.Column width={16}>
                <SaleCardPhone products={s.products} />
                <Grid>
                  <Grid.Row centered>
                    <Pagination
                      onPageChange={this.handlePaginationChange}
                      activePage={self.state.activePage}
                      pointing
                      secondary
                      ellipsisItem={false}
                      totalPages={this.state.pageNum}
                    />
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>

            <Sidebar
              as={Menu}
              animation='overlay'
              // onHide={this.signinVisible}
              vertical
              visible={this.state.signinVisible === true}
             
              direction='bottom'
            >
                <Segment
                  style={{ width: '100%', paddingBottom: 7, paddingTop: 7 }}
                >
                  <Grid>
                    <Grid.Row columns={4}>
                      <Grid.Column width={1}>
                        <Icon
                          name='close'
                          size='small'
                          onClick={self.signinVisible}
                        />
                      </Grid.Column>
                      <Grid.Column width={5}></Grid.Column>
                      <Grid.Column width={4}>
                        <Header
                          as='h4'
                          color='teal'
                          textAlign='centered'
                          style={{
                            paddingBottom: 0,
                            marginBottom: 0,
                            fontWeight: 500
                          }}
                        >
                          Sign In
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  {/* <Header as='h6'  textAlign='centered' style={{paddingTop:0,marginTop:0,color:'#384D5A'}}>
                             Next Generation Fashion Platform 
                         </Header> */}
                </Segment>
                <LogInPagePhonePop />
            
            </Sidebar>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid>
      )}

     <NavMobilePhone />
    </div>

  }

}

export default withRouter(SalePagePhone)

