import React from 'react'
import { Parallax } from 'react-parallax'
import {
  Segment,
  Button,
  Divider,
  Form,
  Grid,
  Checkbox,
  Message
} from 'semantic-ui-react'
import { Link, withRouter } from 'react-router-dom'
import axios from 'axios'
// import Nav from '../nav/Nav'

import Foot from '../foot/Foot'
import timestamp from 'time-stamp'
const userLang = navigator.language || navigator.userLanguage

class LoginPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      products: [],
      token: '',
      rememberMe: false,
      loading: true,
      buttonLoading: false,
      loggedIn: false,
      somethingWrong: false
    }
    this.onHandleUsername = this.onHandleUsername.bind(this)
    this.onHandlePassword = this.onHandlePassword.bind(this)
    this.onHandleSubmit = this.onHandleSubmit.bind(this)
    this.onHandleRememberMe = this.onHandleRememberMe.bind(this)
  }

  componentDidMount () {
    window.scrollTo(0, 0)
    const self = this
    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 700)
    const remember = localStorage.getItem('rememberMe')
    var identifier = localStorage.getItem('identifier')
    var password = localStorage.getItem('password')
    if (remember === 'true')
      this.setState({
        rememberMe: remember,
        username: identifier,
        password: password
      })
  }

  onHandleUsername (event) {
    this.setState({
      username: event.target.value
    })
  }

  onHandlePassword (event) {
    this.setState({
      password: event.target.value
    })
  }

  onHandleSubmit (event) {
    event.preventDefault()
    const remember = this.state.rememberMe
    const self = this
    self.setState({
      buttonLoading: true
    })

    if (remember === true) {
      const identifier = this.state.username
      const password = this.state.password
      const rememberMe = this.state.rememberMe

      axios
        .post('https://sheyou-backend.herokuapp.com/auth/local', {
          identifier: identifier,
          password: password
        })
        .then(function (res) {
          console.log(res)

          if (res.status === 200) {
            self.setState({
              buttonLoading: false,
              loggedIn: true
            })
            localStorage.removeItem('toLog')
            localStorage.setItem('login', 'true')
            localStorage.setItem('log', 'true')
            const time = timestamp.utc('YYYY-MM-DD-HH')
            localStorage.setItem('loginTime', time)
            const token = res.data.jwt
            localStorage.setItem('token', token)
            localStorage.setItem('identifier', identifier)
            localStorage.setItem('password', password)
            localStorage.setItem('rememberMe', rememberMe)
            // alert('You have logged in '+identifier+'! Welcome to ChicOnly')
            self.props.history.push('/logging')
          } else {
            // alert('something went wrong')
            localStorage.removeItem('toLog')
            self.setState({
              rememberMe: false,
              username: '',
              password: ''
            })
          }
        })
        .catch(error => {
          localStorage.removeItem('toLog')
          self.setState({
            buttonLoading: false,
            rememberMe: false,
            username: '',
            password: ''
          })
          if (error.response.status === 400) {
            self.setState({
              buttonLoading: false,
              somethingWrong: 'Wrong username & password combination'
            })
            // alert("Wrong username & password combination")
            localStorage.removeItem('identifier')
            localStorage.removeItem('password')
            localStorage.removeItem('rememberMe')
          } else {
            self.setState({
              buttonLoading: false,
              somethingWrong: 'An error occurred:' + error.response.statusText
            })
            // alert('An error occurred:', error.response.statusText)
          }
        })
    } else if (remember === 'true') {
      var identifier = localStorage.getItem('identifier')
      var password = localStorage.getItem('password')
      const identifierRetype = this.state.username
      const passwordRetype = this.state.password
      if (identifierRetype !== '' && passwordRetype !== '') {
        identifier = identifierRetype
        password = passwordRetype
      }
      axios
        .post('https://sheyou-backend.herokuapp.com/auth/local', {
          identifier: identifier,
          password: password
        })
        .then(function (res) {
          console.log(res.status)
          localStorage.removeItem('toLog')
          if (res.status === 200) {
            self.setState({
              loggedIn: true,
              buttonLoading: false,
            })
            const time = timestamp.utc('YYYY-MM-DD-HH')
            localStorage.setItem('loginTime', time)
            localStorage.setItem('login', 'true')
            const token = res.data.jwt
            localStorage.setItem('token', token)
            localStorage.setItem('identifier', identifier)
            localStorage.setItem('password', password)
            localStorage.setItem('rememberMe', remember)
            self.props.history.push('/logging')
          } else {
            self.setState({
              buttonLoading: false,
              rememberMe: false,
              username: '',
              password: ''
            })
          }
        })
        .catch(error => {
          localStorage.removeItem('toLog')
          self.setState({
            buttonLoading: false,
            rememberMe: false,
            username: '',
            password: ''
          })
          if (error.response.status === 400) {
            self.setState({
              somethingWrong: 'Wrong username & password combination'
            })
            localStorage.removeItem('identifier')
            localStorage.removeItem('password')
            localStorage.removeItem('rememberMe')
          } else {
            self.setState({
              somethingWrong: 'An error occurred:' + error.response.statusText
            })
          }
        })
    } else {
      identifier = localStorage.getItem('identifier')
      password = localStorage.getItem('password')
      const rememberMe = localStorage.getItem('rememberMe')

      console.log(identifier, password)
      if (rememberMe !== null) {
        localStorage.removeItem('rememberMe')
      }

      if (
        (identifier === null && password === null) ||
        (identifier === '' && password === '')
      ) {
        identifier = this.state.username
        password = this.state.password
      }

      axios
        .post('https://sheyou-backend.herokuapp.com/auth/local', {
          identifier: identifier,
          password: password
        })
        .then(function (res) {
          localStorage.removeItem('toLog')
          console.log(res.status)
          if (res.status === 200) {
            self.setState({
              loggedIn: true,
              buttonLoading: false,
            })
            localStorage.setItem('login', 'true')
            const token = res.data.jwt
            const time = timestamp.utc('YYYY-MM-DD-HH')
            localStorage.setItem('loginTime', time)
            localStorage.setItem('token', token)
            localStorage.setItem('identifier', identifier)
            localStorage.setItem('password', password)
            self.props.history.push('/logging')
          } else {
            localStorage.removeItem('toLog')
            self.setState({
              buttonLoading: false,
              rememberMe: false,
              username: '',
              password: ''
            })
          }
        })
        .catch(error => {
          localStorage.removeItem('toLog')
          self.setState({
            rememberMe: false,
            username: '',
            password: '',
            buttonLoading: false,
          })
          if (error.response.status === 400) {
            self.setState({
              somethingWrong: 'Wrong username & password combination'
            })
            localStorage.removeItem('identifier')
            localStorage.removeItem('password')
          } else {
            self.setState({
              somethingWrong: 'An error occurred:' + error.response.statusText
            })
          }
        })
    }
  }

  onHandleResetPassword (event) {
    this.props.history.push('/forgetpassword')
  }

  onHandleRememberMe (event) {
    const self = this
    const remember = self.state.rememberMe
    if (remember !== 'true') {
      self.setState({ rememberMe: true })
    } else {
      self.setState({ rememberMe: false })
    }
  }

  render () {
    console.log(this.state)
    const remember = this.state.rememberMe
    const identifier = this.state.username
    const password = this.state.password
    // const toLog = localStorage.getItem('toLog')

    return (
      <div>
        {/* <Nav /> */}
        <Parallax
          bgImage='https://i.loli.net/2020/11/25/ZHjYm1wL9runqOz.png'
          bgImageAlt=''
          strength={400}
        >
          <div>
            <Segment placeholder>
              {userLang === 'zh-CN' && (
                <Grid columns={2} centered>
                  <Grid.Column>
                    {this.state.loggedIn === true && (
                      <Message positive>
                        <Message.Header>成功</Message.Header>
                        <p>您已成功登陆</p>
                      </Message>
                    )}
                    {this.state.somethingWrong !== false && (
                      <Message negative>
                        <Message.Header>登陆失败</Message.Header>
                        <p>{this.state.somethingWrong==='Wrong username & password combination'&&"用户名和密码错误或者不匹配"}</p>
                        <p>{this.state.somethingWrong!=='Wrong username & password combination'&&"出现未知问题:("}</p>

                      </Message>
                    )}

                    <Form>
                      {remember !== 'true' && (
                        <Form.Input
                          onChange={this.onHandleUsername}
                          icon='user'
                          iconPosition='left'
                          label='用户名'
                          placeholder='Username'
                        />
                      )}

                      {remember === 'true' && (
                        <Form.Input
                          onChange={this.onHandleUsername}
                          icon='user'
                          iconPosition='left'
                          label='用户名'
                          placeholder='Username'
                          value={identifier}
                        />
                      )}

                      {remember !== 'true' && (
                        <Form.Input
                          onChange={this.onHandlePassword}
                          icon='lock'
                          iconPosition='left'
                          label='密码'
                          type='password'
                        />
                      )}

                      {remember === 'true' && (
                        <Form.Input
                          onChange={this.onHandlePassword}
                          icon='lock'
                          iconPosition='left'
                          label='密码'
                          type='password'
                          value={password}
                        />
                      )}

                      <Form.Field>
                        {remember === 'true' && (
                          <Checkbox
                            defaultChecked
                            color='orange'
                            label='记住我'
                            onClick={this.onHandleRememberMe}
                          />
                        )}
                        {remember !== 'true' && (
                          <Checkbox
                            color='orange'
                            label='记住我'
                            onClick={this.onHandleRememberMe}
                          />
                        )}
                      </Form.Field>
                    </Form>
                    <br />
                    <br />
                    <Grid>
                      <Grid.Row columns={2}>
                        <Grid.Column>
                          <Button
                            loading={this.state.buttonLoading}
                            onClick={this.onHandleSubmit}
                            content='登录'
                            color='orange'
                          />
                        </Grid.Column>

                        <Grid.Column>
                          <Link to='/forgetpassword'>
                            <Button basic content='忘记密码' color='orange' />
                          </Link>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>

                  <Grid.Column verticalAlign='middle'>
                    <Link to='/signup'>
                      <Button
                        className='ml-auto'
                        content='注册'
                        icon='signup'
                        size='big'
                        color='orange'
                      />
                    </Link>
                  </Grid.Column>
                </Grid>
              )}

              {userLang !== 'zh-CN' && (
                <Grid columns={2} centered>
                  <Grid.Column>
                    {this.state.loggedIn === true && (
                      <Message positive>
                        <Message.Header>Success</Message.Header>
                        <p>Now You Are Logged In</p>
                      </Message>
                    )}
                    {this.state.somethingWrong !== false && (
                      <Message negative>
                        <Message.Header>Log In Failure</Message.Header>
                        <p>{this.state.somethingWrong}</p>
                      </Message>
                    )}

                    <Form>
                      {remember !== 'true' && (
                        <Form.Input
                          onChange={this.onHandleUsername}
                          icon='user'
                          iconPosition='left'
                          label='Username'
                          placeholder='Username'
                        />
                      )}

                      {remember === 'true' && (
                        <Form.Input
                          onChange={this.onHandleUsername}
                          icon='user'
                          iconPosition='left'
                          label='Username'
                          placeholder='Username'
                          value={identifier}
                        />
                      )}

                      {remember !== 'true' && (
                        <Form.Input
                          onChange={this.onHandlePassword}
                          icon='lock'
                          iconPosition='left'
                          label='Password'
                          type='password'
                        />
                      )}

                      {remember === 'true' && (
                        <Form.Input
                          onChange={this.onHandlePassword}
                          icon='lock'
                          iconPosition='left'
                          label='Password'
                          type='password'
                          value={password}
                        />
                      )}

                      <Form.Field>
                        {remember === 'true' && (
                          <Checkbox
                            defaultChecked
                            color='orange'
                            label='Remember Me'
                            onClick={this.onHandleRememberMe}
                          />
                        )}
                        {remember !== 'true' && (
                          <Checkbox
                            color='orange'
                            label='Remember Me'
                            onClick={this.onHandleRememberMe}
                          />
                        )}
                      </Form.Field>
                    </Form>
                    <br />
                    <br />
                    <Grid>
                      <Grid.Row columns={2}>
                        <Grid.Column>
                          <Button
                            loading={this.state.buttonLoading}
                            onClick={this.onHandleSubmit}
                            content='Log In'
                            color='orange'
                          />
                        </Grid.Column>

                        <Grid.Column>
                          <Link to='/forgetpassword'>
                            <Button
                              basic
                              content='Forget Password'
                              color='orange'
                            />
                          </Link>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>

                  <Grid.Column verticalAlign='middle'>
                    <Link to='/signup'>
                      <Button
                        className='ml-auto'
                        content='Sign up'
                        icon='signup'
                        size='big'
                        color='orange'
                      />
                    </Link>
                  </Grid.Column>
                </Grid>
              )}

              <Divider vertical>Or</Divider>
            </Segment>
          </div>
          <div style={{ height: '500px' }} />
        </Parallax>

        <Foot />
      </div>
    )
  }
}

export default withRouter(LoginPage)
