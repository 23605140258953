import React from 'react'
import { Card, Image, Label, Placeholder, Dimmer } from 'semantic-ui-react'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
const userLang = navigator.language || navigator.userLanguage



class HeartTemplete extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userInfo: [],
      userID: [],
      heartList: [],
      membership: [],
      color: '',
      identifier: '',
      token: '',
      log: '',
      loading: true,
      accessoryHeartList: []

    }
    this.onHandleClick = this.onHandleClick.bind(this)
    this.ifHearted = this.ifHearted.bind(this)
    this.heartTag = this.heartTag.bind(this)
    this.getUserInfo = this.getUserInfo.bind(this)

  }


  componentDidMount() {

    const self = this
    const log = localStorage.getItem('login')
    self.setState({ log: log })
    if (log === 'true') {

      self.getUserInfo()
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 7000);
  }

  getUserInfo() {
    const self = this
    const identifier = localStorage.getItem('identifier')
    const token = localStorage.getItem('token')


    self.setState({
      identifier: identifier,
      token: token
    })

    axios('https://sheyou-backend.herokuapp.com/users/me', {
      method: 'get',
      headers: {
        'Authorization': 'Bearer ' + token,
      }
    })
      .then(function (res) {
        if (res.status === 200) {
          const userInfo = [res.data]
          if (userInfo[0].heart === null) {
            userInfo[0].heart = []
          }
          if (userInfo[0].accessoryHeart === null) {
            userInfo[0].accessoryHeart = []
          }
          self.setState({
            userInfo: userInfo,
            userID: userInfo[0].id,
            heartList: userInfo[0].heart,
            membership: userInfo[0].membership,
            accessoryHeartList: userInfo[0].accessoryHeart,
            loading: false
          })


        }
        else {
          alert('Something went wrong, maybe log in again :(')
          localStorage.setItem('login', 'false')
        }


      })

  }

  ifHearted(productt, productType, categories) {

    const self = this
    const productId = productt.id
    var heartList = self.state.heartList
    var accessoryHeartList = self.state.accessoryHeartList
    var heart = []
    // console.log(accessoryHeartList)

    if (heartList === null) {
      heartList = []
    }
    if (accessoryHeartList === null) {
      accessoryHeartList = []
    }

    if (categories === 'Accessaries') {
      heart = accessoryHeartList.filter(function (heart) {
        return heart.id === productId
      })
    } else {
      heart = heartList.filter(function (heart) {

        if (productType === 'sale') {
          return heart.type === 'sale' && heart.id === productId

        } else {
          return heart.type !== 'sale' && heart.id === productId
        }

      })
    }

    if (heart.length !== 0) {
      return 'yellow'
    } else { return '' }

  }


  onHandleClick(event, data) {
    const self = this
    const productId = event.target.id
    const productType = event.target.name
    const category = event.target.title
    console.log(productType, category)
    event.preventDefault()
    if (productType === 'sale') {
      localStorage.setItem('saleproductId', productId)
      self.props.history.push('/saleproduct')
    } else if (category === 'Accessaries') {
      localStorage.setItem('AcessoryId', productId)
      self.props.history.push('/accessoryItem')
    }
    else {
      localStorage.setItem('productId', productId)
      self.props.history.push('/product')
    }

  }

  heartTag(hearted, productId, productType) {
    const self = this
    var heartList = self.state.heartList
    if (heartList === null) {
      heartList = []
    }
    var heartData = []
    const token = self.state.token
    const userID = self.state.userID

    if (hearted === 'yellow') {
      const heartLen = heartList.length
      for (var i = 0; i < heartLen; i++) {
        if (productType === 'sale') {
          if (heartList[i].id !== productId || heartList[i].type !== 'sale') { heartData.push(heartList[i]) }
        } else {
          if (heartList[i].id !== productId || heartList[i].type === 'sale') { heartData.push(heartList[i]) }
        }

      }

      console.log(heartData)
      axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
        method: 'put',
        data: {
          heart: heartData
        },
        headers: {
          'Authorization': 'Bearer ' + token,
        }
      }).then(function (res) {
        console.log(res)
        if (res.status === 200) {
          self.getUserInfo()

        }
        else {
          alert('something went wrong, please log in again :(')
          localStorage.setItem('login', false)
          // self.setState({
          //   addHeartSuccess:'somethingWrong'
          // })                          
        }

      })
    }
    if (hearted === '') {
      heartData = heartList
      heartData.push({ id: productId })
      axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
        method: 'put',
        data: {
          heart: heartData
        },
        headers: {
          'Authorization': 'Bearer ' + token,
        }
      }).then(function (res) {
        console.log(res)
        if (res.status === 200) {
          // self.componentDidMount()
          self.getUserInfo()

        }
        else {
          alert('something went wrong, please log in again :(')
          localStorage.setItem('login', false)
          // self.setState({
          //   addHeartSuccess:'somethingWrong'
          // })                          
        }

      })
    }
  }

  accessoryHeartTag(hearted, productId, productType) {
    const self = this
    var heartList = self.state.heartList
    if (heartList === null) {
      heartList = []
    }
    var heartData = []
    const token = self.state.token
    const userID = self.state.userID

    if (hearted === 'yellow') {
      const heartLen = heartList.length
      for (var i = 0; i < heartLen; i++) {
        if (heartList[i].id !== productId) { heartData.push(heartList[i]) }


      }

      console.log(heartData)
      axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
        method: 'put',
        data: {
          accessoryHeart: heartData
        },
        headers: {
          'Authorization': 'Bearer ' + token,
        }
      }).then(function (res) {
        console.log(res)
        if (res.status === 200) {
          self.getUserInfo()

        }
        else {
          alert('something went wrong, please log in again :(')
          localStorage.setItem('login', false)
          // self.setState({
          //   addHeartSuccess:'somethingWrong'
          // })                          
        }

      })
    }
    if (hearted === '') {
      heartData = heartList
      heartData.push({
        id: productId
      })
      axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
        method: 'put',
        data: {
          accessoryHeart: heartData
        },
        headers: {
          'Authorization': 'Bearer ' + token,
        }
      }).then(function (res) {
        console.log(res)
        if (res.status === 200) {
          // self.componentDidMount()
          self.getUserInfo()

        }
        else {
          alert('something went wrong, please log in again :(')
          localStorage.setItem('login', false)
          // self.setState({
          //   addHeartSuccess:'somethingWrong'
          // })                          
        }

      })
    }
  }

  render() {
    const p = this.props
    const self = this
    const products = p.products
    // console.log(products)
    const loading = self.state.loading
    const log = self.state.log
    // const color=self.state.color

    // const heartList = self.state.heartList


    return (
      <Card.Group centered >

        {products.map(function (product) {
          const heart = self.ifHearted(product, product.type, product.categories[0].name)
          return <Card id={product.id} >
            <Dimmer active={product.stock === '0'} inverted />
            {loading ? (
              <>
                <Placeholder>
                  <Placeholder.Image square />

                </Placeholder>

                <Card.Content>
                  <Placeholder>
                    <Placeholder.Paragraph>
                      <Placeholder.Line length='very short' />
                      <Placeholder.Line length='medium' />
                      <Placeholder.Line length='short' />
                    </Placeholder.Paragraph>

                  </Placeholder>
                </Card.Content>
              </>

            ) : (<>
              {log === 'true' && product.categories[0].name !== 'Accessaries' &&
                <Label
                  as='a'
                  color={heart}
                  corner='right'
                  icon='heart'
                  onClick={() => self.heartTag(heart, product.id, product.type)}
                />}
              {log === 'true' && product.categories[0].name === 'Accessaries' &&
                <Label
                  as='a'
                  color={heart}
                  corner='right'
                  icon='heart'
                  onClick={() => self.accessoryHeartTag(heart, product.id, product.type)}
                />}
              {product.type === 'sale' &&
                <Image
                  id={product.id}
                  onClick={self.onHandleClick}
                  title={product.categories[0].name}
                  name={product.type}
                  style={{
                    height: '290px',
                    width: '230px',
                    marginLeft: '25px'

                  }}
                  src={product.picture[0].formats.small.url}
                />}
              {
                product.type !== 'sale' &&
                <Image
                  style={{ height: 300 }}
                  id={product.id}
                  title={product.categories[0].name}
                  name={product.type}
                  onClick={self.onHandleClick}
                  src={product.picture[0].formats.small.url}
                />
              }


              <Card.Content
                id={product.id}
                title={[product.type, product.categories[0].name]}
                onClick={self.onHandleClick}>


                <Card.Header id={product.id}>
                  {product.name}
                </Card.Header>

                {
                  product.description!==null&&
                  <Card.Meta id={product.id}>{product.description.substring(0, 20) + "..."}</Card.Meta>
                }
                



                <Card.Description id={product.id}>
                  {userLang === 'zh-CN' && (product.price + 'RMB/ 会员免费')}
                  {userLang !== 'zh-CN' && (product.price + 'RMB/ Free for Member')}
                </Card.Description>
              </Card.Content> </>)}
          </Card>

        })}
      </Card.Group>
    )
  }
}

export default withRouter(HeartTemplete)