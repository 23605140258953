import React from 'react'
import {
  Grid,
  Segment,
} from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import Foot from '../foot/Foot'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import CheckoutForm from './CheckOutForm'

const userLang = navigator.language || navigator.userLanguage

var stripe=  require('stripe')('pk_live_51KqFsaAJHCMvx7lQ0LuHIkKoVgH0MUNIXlRRoj51W7iwjnXlsIXXPUSE7CbqmLlH8KQk8FBhzCpY5swVbWk17bRD005rZKTBuo')

var stripePromise = loadStripe(
  'pk_live_51KqFsaAJHCMvx7lQ0LuHIkKoVgH0MUNIXlRRoj51W7iwjnXlsIXXPUSE7CbqmLlH8KQk8FBhzCpY5swVbWk17bRD005rZKTBuo'
)


class StripePay extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true
    }

  }

  componentDidMount () {
    window.scrollTo(0, 0)
    const self = this

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 4000)
  }

  handleSubmit (event,data) {
    const self=this
    console.log(self,event,data)

    var elements=this
   
    // var elements = stripe.elements({
    //   clientSecret:
    //     'pi_3KqynmAJHCMvx7lQ3j4X8huj_secret_Ng6v64Ru6w5tjE7gSnkM2I3eA'
    // })

    // Customize which fields are collected by the Payment Element
    var paymentElement = elements.create('payment', {
      fields: {
        billingDetails: {
          name: 'SHEYOU',
          email: 'susanna.xinshu@gmail.com',
          phone: '17308079166',
          address: '国际花园'
        }
      }
    })

    stripe
      .confirmPayment({
        paymentElement,
        confirmParams: {
          return_url: 'https://www.sheyoufashion.com/result/success',
          payment_method_data: {
            billing_details: {
              name: 'Susanna',
              email: '1181456762@qq.com'
            }
          }
        }
      })
      .then(function (result) {
        if (result.error) {
          console.log(result)
          self.props.history.push('/result/fail')
        }
      })
  }
 

  render () {
    const loading = this.state.loading
    const self=this
    const options = {
      // passing the client secret obtained from the server
      clientSecret:
        'pi_3KtaVAAJHCMvx7lQ1TOcKQW6_secret_JcRuaPpYE9vLrcPmPBZHtTTSv'
    }

    return <div> 
    
        <div className='bg_color_image'>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />

          <Grid>
            <Grid.Row>
              <Grid.Column width={2}></Grid.Column>
              <Grid.Column width={12}>
                {userLang==='zh-CN'&&
                <Segment>
                <Elements stripe={stripePromise} options={options} locale='zh'>
                 
                  <CheckoutForm/>
                </Elements>
              </Segment>
                }
                {userLang!=='zh-CN'&&
                <Segment>
                <Elements stripe={stripePromise} options={options}>
                 
                  <CheckoutForm/>
                </Elements>
              </Segment>
                }
                
              </Grid.Column>
              <Grid.Column width={2}></Grid.Column>
            </Grid.Row>
          </Grid>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
        <Foot />
    
  </div>
}
}

export default withRouter(StripePay)
