import React from 'react'
import { withRouter } from 'react-router-dom'
import DeviceEmulator from 'react-device-emulator';
import 'react-device-emulator/lib/styles/style.css';


class Nav extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    componentDidMount() {


    }


    // handleItemClick = (e, { name }) => this.setState({ activeItem: name })

    render() {




        return <div>
      <DeviceEmulator type="tab" withDeviceSwitch withRotator>
        <h1>Welcome to React Device Emulator</h1>
      </DeviceEmulator>
      <DeviceEmulator type="mobile" withDeviceSwitch withRotator url="www.worksfair.com"/>
      <DeviceEmulator type="tab" withoutChrome url="www.worksfair.com"/>
  
        </div>
}
}

export default withRouter(Nav)