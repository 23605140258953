import React from 'react'
import {
  Grid,
  Header,
  Icon
} from 'semantic-ui-react'

import { withRouter } from 'react-router-dom'
const userLang = navigator.language || navigator.userLanguage

class LoginFirst extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }
  componentDidMount () {
   
  }

  render () {
    return (
        <Grid>
       
        <Grid.Row style={{position:'absolute', top: '50%'}} centered>
         
            <Header size='huge'>
              <Icon loading name='asterisk' size='huge' color='yellow' />
              Loading...
            </Header>
         
        </Grid.Row>
      
      </Grid>
    )
  }
}

export default withRouter(LoginFirst)
