import React from 'react'
import {
  Grid,
  Header,
  Button,
  Form,
  Icon,
  Divider,
  Message
} from 'semantic-ui-react'

import axios from 'axios'

import newOtp from 'otp-generators'

import { withRouter } from 'react-router-dom'
import { countrySelect } from './CountrySelect'

class MobilePhone extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      log: '',
      identifier: '',
      token: '',
      userInfo: [],
      userID: '',
      merchantInfo: {},

      country: '',
      phoneNum: '',
      smsCode: '',
      sentCode: '',
      sentCodeSuccess: '',
      verified: '',

      saved: false,
      nextShow: false,
      loading: true,
      somethingWrong: false,
      saveLoading: false
    }
    this.getUserInfo = this.getUserInfo.bind(this)

    this.onCountry = this.onCountry.bind(this)
    this.onPhoneNum = this.onPhoneNum.bind(this)
    this.onSendSMS = this.onSendSMS.bind(this)
    this.onSMSCode = this.onSMSCode.bind(this)
    this.onVerifyCode = this.onVerifyCode.bind(this)

    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleNextClick = this.handleNextClick.bind(this)
    this.handleBackClick = this.handleBackClick.bind(this)
    this.handleBackToStart = this.handleBackToStart.bind(this)
  }

  componentDidMount () {
    const self = this
    const log = localStorage.getItem('login')
    self.setState({ log: log })
    if (log === 'true') {
      self.getUserInfo()
    }
    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 700)
  }

  getUserInfo () {
    const self = this
    const identifier = localStorage.getItem('identifier')
    const token = localStorage.getItem('token')

    self.setState({
      identifier: identifier,
      token: token
    })

    axios('https://sheyou-backend.herokuapp.com/users/me', {
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(function (res) {
        console.log(res)
        const userInfo = [res.data]
        if (userInfo === null) {
          self.setState({
            userInfo: [],
            userID: userInfo[0].id
          })
        } else if (userInfo.length > 0) {
          self.setState({
            userInfo: userInfo,
            userID: userInfo[0].id
          })
        }

        const merchantInfo = userInfo[0].merchantInfo
        console.log(merchantInfo)
        if (merchantInfo !== null) {
          self.setState({ merchantInfo: merchantInfo })
        }

        if (
          merchantInfo.mobilephone !== null &&
          merchantInfo.mobilephone !== undefined
        ) {
          self.setState({
            country: merchantInfo.mobilephone.country,
            phoneNum: merchantInfo.mobilephone.phonenum,
            saved: true,
            nextShow: true,
            verified: true
          })
        }

        console.log(userInfo)
      })

      .catch(function (err) {
        self.props.history.push('/loginfirst')
      })
  }

  onCountry (event, data) {
    this.setState({
      country: data.value,
      saved: false
    })
    if (this.state.merchantInfo.mobilephone !== undefined) {
      if (this.state.merchantInfo.mobilephone.country === data.value) {
        this.setState({
          verified: true
        })
      } else {
        this.setState({
          verified: ''
        })
      }
    }
  }

  onPhoneNum (event) {
    this.setState({
      phoneNum: event.target.value,
      saved: false
    })
    if (this.state.merchantInfo.mobilephone !== undefined) {
      if (this.state.merchantInfo.mobilephone.phonenum === event.target.value) {
        this.setState({
          verified: true
        })
      } else {
        this.setState({
          verified: ''
        })
      }
    }
  }

  onSendSMS () {
    const self = this
    const phoneNum = self.state.phoneNum
    const sentCode = newOtp.generate(4, {
      alphabets: false,
      upperCase: false,
      specialChar: false
    })

    this.setState({
      sentCode: sentCode
    })
    const token = this.state.token
    axios({
      method: 'post',
      url: 'https://sheyou-backend.herokuapp.com/sms/send',
      data: {
        verificationCode: sentCode,
        PhoneNumberSet: [phoneNum]
      },
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
      .then(function (res) {
        console.log(res)
        self.setState({ sentCodeSuccess: true })
      })
      .catch(function (err) {
        console.log(err)
        self.setState({
          sentCodeSuccess: false,
          somethingWrong:
            'SMS code not sent, please make sure you have entered a valid phone number. ' +
            err
        })
      })
  }

  onSMSCode (event) {
    this.setState({
      smsCode: event.target.value
    })
  }

  onVerifyCode () {
    const smsCode = this.state.smsCode
    const sentCode = this.state.sentCode
    console.log(smsCode, sentCode)
    if (smsCode === sentCode) {
      this.setState({
        verified: true,
        nextShow: true
      })
    } else if (smsCode !== sentCode) {
      this.setState({
        verified: false,
        nextShow: false
      })
    }
  }

  handleSaveClick () {
    const self = this
    const s = self.state
    const userID = this.state.userID
    const token = localStorage.getItem('token')


    if (s.verified === true) {
      const mobilePhone = {
        mobilephone: {
          country: s.country,
          phonenum: s.phoneNum
        }
      }

      self.setState({saveLoading: true})

      const merchantInfo = Object.assign(s.merchantInfo, mobilePhone)
      console.log(merchantInfo)

      axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
        method: 'put',
        data: {merchantInfo},
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
        .then(function (response) {
          console.log(response)
          self.setState({
            saved: true,
            somethingWrong: false,
            saveLoading: false
          })
        })

        .catch(function (err) {
          self.setState({
            somethingWrong: 'something is wrong' + err,
            saveLoading: false
          })
        })
    } else {
      self.setState({
        somethingWrong:
          'You have not verified your mobile phone number, please verify before you save the information.'
      })
    }
  }

  handleBackClick () {
    const self = this
    const link = '/merchantVerify/card'
    window.location.assign('https://sheyoufashion.com/#' + link)
    self.props.history.push(link)
    window.location.reload(true)
  }

  handleNextClick () {
    const self = this
    const s = self.state
    const link = '/merchantVerify/id'
    const mobilephone = s.merchantInfo.mobilephone

    if (mobilephone !== undefined) {
      if (
        s.verified === true &&
        mobilephone.country === s.country &&
        mobilephone.phonenum === s.phoneNum
      ) {
        self.setState({
          saved: true
        })
      }
    }

    if (s.saved === true && s.verified === true) {
      window.location.assign('https://sheyoufashion.com/#' + link)
      self.props.history.push(link)
      window.location.reload(true)
    } else if (s.saved !== true) {
      self.setState({
        somethingWrong:
          'You have not saved your information, please click the save button before you proceed.'
      })
    } else if (s.verified !== true) {
      self.setState({
        somethingWrong:
          'You have not verified your new mobile phone number, please verify it before you proceed.'
      })
    }
  }

  handleBackToStart () {
    const self = this
    const link = '/merchantVerify/address'
    window.location.assign('https://sheyoufashion.com/#' + link)
    self.props.history.push(link)
    window.location.reload(true)
  }

  render () {
    const self = this
    const s = self.state
    const verified = s.verified
    const sentCodeSuccess = s.sentCodeSuccess
    const countries = countrySelect()

    return (
      <>
        {s.loading ? (
          <Grid>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>

            <Grid.Row colums={2}>
              <Grid.Column width={7}></Grid.Column>
              <Grid.Column width={6}>
                <Header size='huge'>
                  <Icon loading name='asterisk' size='massive' color='yellow' />
                  Loading...
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid>
        ) : (
          <>
            {(s.merchantInfo === {} ||
              s.merchantInfo.creditcard === undefined ||
              s.merchantInfo.address === undefined) && (
              <Grid centered>
                <Grid.Row>
                  <Message negative>
                    <Message.Header>
                      Required Merchant Verification Information Empty
                    </Message.Header>
                    <p>
                      Please go back and fill in your merchant address or verify
                      your credit card first.
                    </p>
                  </Message>
                </Grid.Row>

                <Grid.Row columns={1}>
                  <Grid.Column>
                    <Button
                      onClick={this.handleBackToStart}
                      color='orange'
                      fluid
                    >
                      BACK TO START
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}

            {s.merchantInfo !== {} &&
              s.merchantInfo.creditcard !== undefined &&
              s.merchantInfo.address !== undefined && (
                <Grid>
                  <Grid.Row columns={1}>
                    <Grid.Column width={16}>
                      <Header>Verify Your Phone Number</Header>
                      <Divider />
                      <Grid>
                        <Grid.Column width={10}>
                          <Form>
                            <Form.Group>
                              <Form.Field
                                style={{ marginLeft: 151, marginRight: 0 }}
                              >
                                <Form.Select
                                  fluid
                                  label='Country'
                                  placeholder='Select Country'
                                  options={countries}
                                  search
                                  searchInput={{}}
                                  style={{ width: 211 }}
                                  onChange={this.onCountry}
                                  value={this.state.country}
                                />
                              </Form.Field>
                              <Form.Field
                                style={{ marginLeft: 17, marginRight: 0 }}
                              >
                                <Form.Input
                                  fluid
                                  label='Phone Number'
                                  placeholder='Enter Phone Number'
                                  style={{ width: 300 }}
                                  value={this.state.phoneNum}
                                  onChange={this.onPhoneNum}
                                />
                              </Form.Field>
                            </Form.Group>
                            {verified !== false && (
                              <Form.Field style={{ marginLeft: 378 }}>
                                <Form.Input
                                  fluid
                                  label='SMS Code'
                                  placeholder='Enter SMS Code'
                                  style={{ width: 202 }}
                                  value={this.state.smsCode}
                                  onChange={this.onSMSCode}
                                />
                              </Form.Field>
                            )}
                            {verified === false && (
                              <Form.Field style={{ marginLeft: 378 }}>
                                <Form.Input
                                  error='Failed: Your SMS code is incorrect.'
                                  fluid
                                  label='SMS Code'
                                  placeholder='Enter SMS Code'
                                  style={{ width: 202 }}
                                  value={this.state.smsCode}
                                  onChange={this.onSMSCode}
                                />
                              </Form.Field>
                            )}
                          </Form>
                        </Grid.Column>

                        <Grid.Column width={6}>
                          <Grid>
                            <Grid.Row style={{ paddingTop: 39 }}>
                              <Button
                                fluid
                                style={{ marginLeft: 30 }}
                                onClick={this.onSendSMS}
                              >
                                Send SMS Code
                              </Button>
                            </Grid.Row>
                          </Grid>
                          <Grid>
                            <Grid.Row style={{ paddingTop: 26 }}>
                              <Button
                                style={{ marginLeft: -68 }}
                                onClick={this.onVerifyCode}
                              >
                                Verify
                              </Button>
                            </Grid.Row>
                          </Grid>
                        </Grid.Column>

                        {sentCodeSuccess === true && (
                          <Grid.Row centered>
                            <Message
                              positive
                              header='Success'
                              content='You one-time code has been sent.'
                            />
                          </Grid.Row>
                        )}
                        {verified === true && (
                          <Grid.Row centered>
                            <Message
                              success
                              header='Verified'
                              content='Your phone number has been successfully verified.'
                            />
                          </Grid.Row>
                        )}

                        <Grid.Row centered>
                          {this.state.somethingWrong !== false && (
                            <Message warning>
                              <Message.Header>
                                Somthing Went Wrong
                              </Message.Header>
                              <p>{this.state.somethingWrong}</p>
                            </Message>
                          )}
                          {((this.state.saved === true) && (this.state.saveLoading === false)) && (
                          <Message positive>
                            <Message.Header>Saved</Message.Header>
                            <p>
                              You have saved your mobile phone, please
                              confirm and then click NEXT
                            </p>
                          </Message>
                          )}
                        </Grid.Row>

                        
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row columns={3}>
                    <Grid.Column>
                      <Button
                        onClick={this.handleBackClick}
                        color='orange'
                        fluid
                      >
                        BACK
                      </Button>
                    </Grid.Column>
                    <Grid.Column>
                      <Button loading={this.state.saveLoading} onClick={this.handleSaveClick} fluid>
                        SAVE
                      </Button>
                    </Grid.Column>
                    <Grid.Column>
                      <Button
                        onClick={this.handleNextClick}
                        disabled={!this.state.nextShow}
                        color='orange'
                        fluid
                      >
                        NEXT
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}
          </>
        )}
      </>
    )
  }
}

export default withRouter(MobilePhone)
