import React from 'react'
import {
  Image,
  Menu,
  Sidebar,
  Icon,
  Header,
  Grid,
  Segment
} from 'semantic-ui-react'
// import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import timestamp from 'time-stamp'
import LogInPagePhonePop from '../mypage/LogInPagePhonePop'

class NavMobilePhone extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      activeItem: 'Home',
      login: 'false',
      open: false,
      identifier: '',
      cart: [],
      bag: [],
      accessoryCart: [],
      accessoryBag: [],
      // isAMember: false,
      windowWidth: 0,
      signinVisible: false

      // show:false
    }
    this.onHandleSignOut = this.onHandleSignOut.bind(this)
    this.handleItemClick = this.handleItemClick.bind(this)
    this.show = this.show.bind(this)
    this.signinVisible = this.signinVisible.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleItemClickNeedLog = this.handleItemClickNeedLog.bind(this)
  }

  componentDidMount () {
    // const self=this
    console.log(window.location.hash)
    var pathname = window.location.hash
    const self=this

    if (pathname === '#/') {
      self.setState({
        activeItem: 'Home'
      })
    }
    if (pathname === '#/clothing' || pathname === '#/accessory') {
      self.setState({
        activeItem: 'Clothing'
      })
    }
    if (pathname === '#/share') {
      self.setState({
        activeItem: 'Share'
      })
    }
    if (pathname === '#/heart') {
      self.setState({
        activeItem: 'Heart'
      })
    }
    if (pathname === '#/user') {
      self.setState({
        activeItem: 'Mine'
      })
    }
    window.addEventListener(
      'hashchange',
      function (event) {
        console.log(pathname)
        pathname = window.location.hash

        if (pathname === '#/') {
          self.setState({
            activeItem: 'Home'
          })
        }
        if (pathname === '#/clothing' || pathname === '#/accessory') {
          self.setState({
            activeItem: 'Clothing'
          })
        }
        if (pathname === '#/share') {
          self.setState({
            activeItem: 'Share'
          })
        }
        if (pathname === '#/heart') {
          self.setState({
            activeItem: 'Heart'
          })
        }
        if (pathname === '#/user') {
          self.setState({
            activeItem: 'Mine'
          })
        }
      },
      true
    )

    this.getUserInfo()
  }

  // handleItemClickMember () {
  //   const isAMember = this.state.isAMember
  //   if (isAMember === true) {
  //     this.props.history.push('/mypage/membership')
  //   } else {
  //     this.props.history.push('/plans')
  //   }
  // }

  getUserInfo () {
    const self = this
    const log = localStorage.getItem('login')
    const identifier = localStorage.getItem('identifier')

    const token = localStorage.getItem('token')

    const now = timestamp.utc('YYYY-MM-DD')
    // console.log(now)

    // const activeItem=localStorage.getItem('menu')
    // if(activeItem!==undefined||activeItem!==null)
    // {self.setState({activeItem:activeItem})}

    self.setState({
      login: log,
      identifier: identifier
    })

    if (log === 'true') {
      axios('https://sheyou-backend.herokuapp.com/users/me', {
        method: 'get',
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(function (res) {
        if (res.status === 200) {
          var userInfo = [res.data]

          if (userInfo[0].cart === null) {
            userInfo[0].cart = []
          }
          if (userInfo[0].accessoryCart === null) {
            userInfo[0].accessoryCart = []
          }
          if (userInfo[0].accessoryBag === null) {
            userInfo[0].accessoryBag = []
          }
          if (userInfo[0].bag === null) {
            userInfo[0].bag = []
          }
          self.setState({
            userInfo: userInfo,
            userID: userInfo[0].id,
            cart: userInfo[0].cart,
            bag: userInfo[0].bag,
            accessoryCart: userInfo[0].accessoryCart,
            accessoryBag: userInfo[0].accessoryBag,
            membership: userInfo[0].membership,
            // isAMember: userInfo[0].membership.isAMember
          })
          userInfo = JSON.stringify(userInfo)
          localStorage.setItem('userInfo', userInfo)
        } else {
          alert('Something went wrong, maybe log in again :(')
          localStorage.setItem('login', 'false')
        }
      })
    }
  }

  handleItemClick (target, data) {
    // const name=data.name
    const link = data.linkto
    console.log(target, data)
    this.setState({
      activeItem: data.name
    })
    if (link === '/clothing') {
      localStorage.setItem('filter', 'All')
    }
    if (link === '/accessory') {
      localStorage.setItem('accessoryFilter', 'All Accessories')
    }
    if (link === '/sale') {
      localStorage.setItem('filter', 'All')
    }
    if (link === '/heart') {
      localStorage.setItem('filter', 'All')
    }
    const self = this
    // if (name===undefined||name===null)
    // {
    //   localStorage.setItem('menu',"Home")
    //   self.setState({activeItem:name})
    // }
    // else
    // {
    //   localStorage.setItem('menu',name)
    //   self.setState({activeItem:name})
    // }
    self.props.history.push(link)
    window.setTimeout(() => {
      if (self.state.loading === true) {
        window.location.reload(true)
      }
    }, 200)
  }

  handleItemClickNeedLog (target, data) {
    // const name=data.name
    const link = data.linkto
    const self = this
    const log = localStorage.getItem('login')
    console.log(target, data)
    this.setState({
      activeItem: data.name
    })

    if (log !== 'true') {
      self.signinVisible()
    } else {
      self.props.history.push(link)
    }
  }

  onHandleSignOut (event) {
    event.preventDefault()
    const self = this
    self.setState({ login: false })
    const rememberMe = localStorage.getItem('rememberMe')
    if (rememberMe === 'true') {
      localStorage.setItem('login', 'false')
    } else {
      localStorage.setItem('login', 'false')
      localStorage.setItem('identifier', '')
      localStorage.setItem('password', '')
    }

    // alert('you have been signed out')
    self.props.history.push('/')
  }

  show () {
    const self = this
    self.setState({ open: true })
  }

  handleCancel () {
    const self = this
    self.setState({ open: false })
  }

  signinVisible () {
    const signinVisible = this.state.signinVisible

    if (signinVisible === false) {
      this.setState({
        signinVisible: true
      })
    }
    if (signinVisible === true) {
      this.setState({
        signinVisible: false
      })
    }
  }

  // handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  render () {
    const activeItem = this.state.activeItem

    const self = this
    const s = self.state

    return (
      <>
        <Menu fixed={'bottom'} borderless icon='labeled' size='mini' widths={4}>
          <Menu.Item
            linkto='/'
            name='Home'
            // active={activeItem === 'Home'}
            onClick={this.handleItemClick}
          >
            {activeItem === 'Home' && (
              <Icon name='home' color='teal' size='tiny' />
            )}
            {activeItem !== 'Home' && (
              <Icon name='home' color='grey' size='tiny' />
            )}

            {activeItem === 'Home' && (
              <Header
                size='tiny'
                color='teal'
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  marginTop: 0,
                  marginBottom: 0
                }}
              >
                Home
              </Header>
            )}
            {activeItem !== 'Home' && (
              <Header
                size='tiny'
                color='grey'
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  marginTop: 0,
                  marginBottom: 0
                }}
              >
                Home
              </Header>
            )}
          </Menu.Item>

          <Menu.Item
            linkto='/clothing'
            name='Clothing'
            // active={activeItem === 'Clothing'}
            onClick={this.handleItemClick}
          >
            {activeItem === 'Clothing' && (
              // <Icon name='shopping bag' color='grey' size='tiny'/>
              <Image
                style={{
                  position: 'relative',
                  bottom: 4,
                  height: 24,
                  width: 24,
                  marginBottom: 1,
                  marginTop: 1
                }}
                src='https://res.cloudinary.com/sheyou/image/upload/v1645859101/t_shirt_23ad28188a.png?31417776.400000006'
                size='mini'
              />
            )}
            {activeItem !== 'Clothing' && (
              // <Icon name='shopping bag' color='grey' size='tiny'/>
              <Image
                style={{
                  position: 'relative',
                  bottom: 4,
                  height: 24,
                  width: 24,
                  marginBottom: 1,
                  marginTop: 1
                }}
                src='https://res.cloudinary.com/sheyou/image/upload/v1645859101/t_shirt_3_d9ab40189a.png?31395586.900000006'
                size='mini'
              />
            )}

            {activeItem === 'Clothing' && (
              <Header
                size='tiny'
                color='teal'
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  marginTop: 0,
                  marginBottom: 0
                }}
              >
                Clothing
              </Header>
            )}
            {activeItem !== 'Clothing' && (
              <Header
                size='tiny'
                color='grey'
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  marginTop: 0,
                  marginBottom: 0
                }}
              >
                Clothing
              </Header>
            )}
          </Menu.Item>

          <Menu.Item
            linkto='/heart'
            name='Heart'
            // active={activeItem === 'Heart'}
            onClick={this.handleItemClick}
          >
            {activeItem === 'Heart' && (
              <Icon name='heart' color='teal' size='tiny' />
            )}
            {activeItem !== 'Heart' && (
              <Icon name='heart' color='grey' size='tiny' />
            )}

            {activeItem === 'Heart' && (
              <Header
                size='tiny'
                color='teal'
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  marginTop: 0,
                  marginBottom: 0
                }}
              >
                Heart
              </Header>
            )}
            {activeItem !== 'Heart' && (
              <Header
                size='tiny'
                color='grey'
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  marginTop: 0,
                  marginBottom: 0
                }}
              >
                Heart
              </Header>
            )}
          </Menu.Item>

          <Menu.Item
            linkto='/user'
            name='Mine'
            // active={activeItem === 'User'}
            onClick={this.handleItemClick}
          >
            {activeItem === 'Mine' && (
              <Icon name='user' color='teal' size='tiny' />
            )}
            {activeItem !== 'Mine' && (
              <Icon name='user' color='grey' size='tiny' />
            )}

            {activeItem === 'Mine' && (
              <Header
                size='tiny'
                color='teal'
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  marginTop: 0,
                  marginBottom: 0
                }}
              >
                Mine
              </Header>
            )}
            {activeItem !== 'Mine' && (
              <Header
                size='tiny'
                color='grey'
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  marginTop: 0,
                  marginBottom: 0
                }}
              >
                Mine
              </Header>
            )}
          </Menu.Item>

          {/* <Grid.Column>
              <Menu.Item
                linkto='/share'
                name='Share'
                // active={activeItem === 'Share'}
                onClick={this.handleItemClickNeedLog}
              >
                {activeItem === 'Share' && (
                  <Icon name='bullseye' color='teal' size='tiny' />
                )}
                {activeItem !== 'Share' && (
                  <Icon name='bullseye' color='grey' size='tiny' />
                )}

                {activeItem === 'Share' && (
                  <Header
                    size='tiny'
                    color='teal'
                    style={{
                      paddingTop: 0,
                      paddingBottom: 0,
                      marginTop: 0,
                      marginBottom: 0
                    }}
                  >
                    Share
                  </Header>
                )}
                {activeItem !== 'Share' && (
                  <Header
                    size='tiny'
                    color='grey'
                    style={{
                      paddingTop: 0,
                      paddingBottom: 0,
                      marginTop: 0,
                      marginBottom: 0
                    }}
                  >
                    Share
                  </Header>
                )}
              </Menu.Item>
            </Grid.Column> */}
        </Menu>

        <Sidebar
          as={Menu}
          animation='overlay'
          // onHide={this.signinVisible}
          vertical
          visible={this.state.signinVisible === true}
          direction='bottom'
        >
          <Segment style={{ width: '100%', paddingBottom: 7, paddingTop: 7 }}>
            <Grid>
              <Grid.Row columns={4}>
                <Grid.Column width={1}>
                  <Icon
                    name='close'
                    size='small'
                    onClick={self.signinVisible}
                  />
                </Grid.Column>
                <Grid.Column width={5}></Grid.Column>
                <Grid.Column width={4}>
                  <Header
                    as='h4'
                    color='teal'
                    textAlign='centered'
                    style={{
                      paddingBottom: 0,
                      marginBottom: 0,
                      fontWeight: 500
                    }}
                  >
                    Sign In
                  </Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            {/* <Header as='h6'  textAlign='centered' style={{paddingTop:0,marginTop:0,color:'#384D5A'}}>
                             Next Generation Fashion Platform 
                         </Header> */}
          </Segment>
          <LogInPagePhonePop />
        </Sidebar>
      </>
    )
  }
}

export default withRouter(NavMobilePhone)
