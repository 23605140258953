import React from 'react'
import { Menu } from 'semantic-ui-react'
// import {
//   Link
// } from "react-router-dom";
import { withRouter } from 'react-router-dom'
const userLang = navigator.language || navigator.userLanguage



class HeartSizeFilter extends React.Component {
  constructor(props){
  super(props)
  this.state = {}
this.handleItemClick=this.handleItemClick.bind(this)
}


componentDidMount() {
const self=this
const activeItem = this.props.activeItem
if(activeItem!==undefined||activeItem!==null)
{self.setState({activeItem:activeItem})}
}

handleItemClick(event,data){
const name=data.name
const link=data.link
// const self=this
// window.location.assign('http://localhost:3000/#'+link)

// this.setState({
//   activeItem:name
// })

if (name===undefined||name===null)
{
  this.props.history.push('/heart')
  window.location.reload(true)
}
else
{
  this.props.history.push(link)
  window.location.reload(true)
}
// self.props.history.push(link)

}
  render() {
    const { activeItem } = this.state

    return (
      <Menu  fluid vertical >
          {userLang === 'zh-CN' && (
            <>
            <Menu.Item header>尺码</Menu.Item>
            <Menu.Item
              link='/heart/average'
              name='均码'
              active={activeItem === 'Average'}
              onClick={this.handleItemClick}
            />
            </>
          )}
          {userLang !== 'zh-CN' && (
            <>
            <Menu.Item header>Sizes</Menu.Item>
            <Menu.Item
              link='/heart/average'
              name='Average'
              active={activeItem === 'Average'}
              onClick={this.handleItemClick}
            />
            </>
          )}
          <Menu.Item
            link='/heart/m'
            name='M'
            active={activeItem === 'M'}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            link='/heart/s'
            name='S'
            active={activeItem === 'S'}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            link='/heart/l'
            name='L'
            active={activeItem === 'L'}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            link='/heart/xxsxs'
            name='XXS/XS'
            active={activeItem === 'XXS/XS'}
            onClick={this.handleItemClick}
          />

          <Menu.Item
            link='/heart/xxlxl'
            name='XXL/XL'
            active={activeItem === 'XXL/XL'}
            onClick={this.handleItemClick}
          />
</Menu>
     
    )
  }
}
 
export default withRouter(HeartSizeFilter)