import React from 'react'
import PastOrderItemRenting from './PastOrderItemRenting'
import { Grid, Header, Label, Button, Confirm } from 'semantic-ui-react'
import PastOrderITem from './PastOrderItem'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import countdown from 'future-count-down'



class PastOrder extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            delivered: false,
            days: '',
            hours: '',
            minutes: '',
            seconds: '',
            open1: false
        }
        this.onHandleClickDelivery = this.onHandleClickDelivery.bind(this)
        this.return = this.return.bind(this)
        this.onHandleDelivered = this.onHandleDelivered.bind(this)
        this.pay = this.pay.bind(this)
        this.cancel = this.cancel.bind(this)
        this.onHandleClickDeliveryReturn=this.onHandleClickDeliveryReturn.bind(this)

    }

    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })
    open1 = () => this.setState({ open1: true })
    close1 = () => this.setState({ open1: false })

    onHandleDelivered(data, event) {
        const self = this
        const orderid = event.orderid
        const token = localStorage.getItem('token')
        this.setState({
            loading: true
        })
        console.log(data, event)
        axios('https://sheyou-backend.herokuapp.com/orders/' + orderid, {
            method: 'put',
            data: {
                delivered: true
            },
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }).catch(error => console.log(error))
            .then(function (res) {
                console.log(res)
                if (res.status !== 200) {
                    alert('something went wrong')
                } else {
                    self.setState({
                        loading: false,
                        delivered: true,
                        open: false
                    })
                }
            })
    }

    onHandleClickDeliveryReturn(event, data){
        event.preventDefault()
        const self = this
        console.log(self)
        const orderid = data.orderid
        localStorage.setItem('orderToDeliveryId', orderid)
        self.props.history.push('/deliveryreturn')
    }

    onHandleClickDelivery(event, data) {
        event.preventDefault()
        const self = this
        const orderid = data.orderid
        localStorage.setItem('orderToDeliveryId', orderid)
        self.props.history.push('/delivery')
    }
    pay(event, data) {
        const self = this
        const orderid = data.orderid
        event.preventDefault()
        const p = this.props
        const d = p.data.orders
        if (d.info === null || d.info === undefined) {
            localStorage.setItem('orderId', orderid)
            self.props.history.push('/checkout?orderid='+orderid)
        } else {
            localStorage.setItem('orderId', orderid)
            self.props.history.push('/confirm?orderid='+orderid)
        }



    }

    return(event, data) {
        const self = this
        const orderid = data.orderid
        event.preventDefault()
        localStorage.setItem('returnorderid', orderid)
        self.props.history.push('/return')
    }

    componentDidMount() {
        const p = this.props
        const data = p.data
        const paid = data.paid

        const self = this



        if (paid !== true) {
            const timeOfTheOrder = data.created_at
            const orderid = p.orderid

            var days = parseInt(timeOfTheOrder.substring(8, 10))
            days = days + 2
            // var hour = timeOfTheOrder.substring(11, 13)
            var years = timeOfTheOrder.substring(0, 4)
            var months = timeOfTheOrder.substring(5, 7)
            months = months - 1
            self.setState({
                loading: true
            })
            console.log(days, months, years)
            countdown({
                endDate: new Date(years, months, days),
                interval: 1000,
                run: function ({ days, hours, minutes, seconds }) {
                    self.setState({
                        days: days,
                        hours: hours,
                        minutes: minutes,
                        seconds: seconds,
                    })
                },
                finish() {
                    const token = localStorage.getItem('token')

                    axios('https://sheyou-backend.herokuapp.com/orders/' + orderid, {
                        method: 'put',
                        data: {
                            canceled: true
                        },
                        headers: {
                            'Authorization': 'Bearer ' + token,
                        }
                    }).catch(error => console.log(error))
                        .then(function (res) {
                            console.log(res)

                            if (res.status !== 200) {
                                alert('something went wrong')
                            } else {
                                self.setState({
                                    loading: false
                                })

                                window.location.reload(true)
                            }
                        })
                }
            })
        }



    }

    cancel() {
        const p = this.props
        const orderid = p.orderid
        const self = this
        const token = localStorage.getItem('token')


        self.setState({
            loading: true
        })
        axios('https://sheyou-backend.herokuapp.com/orders/' + orderid, {
            method: 'put',
            data: {
                canceled: true
            },
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }).catch(error => console.log(error))
            .then(function (res) {
                console.log(res)
                if (res.status !== 200) {
                    alert('something went wrong')
                } else {
                    self.setState({
                        loading: false
                    })

                    window.location.reload(true)
                }
            })
    }


    render() {
        const p = this.props
        const data = p.data
        const timeOfTheOrder = data.created_at
        const info = data.orders
        // const address = info.info
        // const rentOrBuy = address.rentOrBuy
        const products = info.data
        const rentOrBuy = products.rentOrBuy
        const returned = data.returned
        const orderid = data.id
        const delivered = data.delivered
        const returnInfo = p.returnInfo


        const clothing = products.clothing.filter(function (each) {
            return each.type !== 'sale'
        })
        const saleClothing = products.clothing.filter(function (each) {
            return each.type === 'sale'
        })
        const accessory = products.accessory
        const paid = data.paid




        return <>
            <Grid.Row columns={4}>
                <Grid.Column width={1}></Grid.Column>

                {paid === true && rentOrBuy === "buy" &&
                    <Grid.Column style={{ paddingTop: 12 }} width={7}>
                        {timeOfTheOrder.substring(5, 7) === '01' &&
                            <Header as='h5' color='grey' textAlign='center'>
                                {'Bought At :  January ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0, 4)}
                            </Header>
                        }
                        {timeOfTheOrder.substring(5, 7) === '02' &&
                            <Header as='h5' color='grey' textAlign='center'>
                                {'Bought At :  February ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0, 4)}
                            </Header>
                        }
                        {timeOfTheOrder.substring(5, 7) === '03' &&
                            <Header as='h5' color='grey' textAlign='center'>
                                {'Bought At :  March ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0, 4)}
                            </Header>
                        }
                        {timeOfTheOrder.substring(5, 7) === '04' &&
                            <Header as='h5' color='grey' textAlign='center'>
                                {'Bought At :  April ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0, 4)}
                            </Header>
                        }
                        {timeOfTheOrder.substring(5, 7) === '05' &&
                            <Header as='h5' color='grey' textAlign='center'>
                                {'Bought At :  May ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0,4)}
                            </Header>
                        }
                        {timeOfTheOrder.substring(5, 7) === '06' &&
                            <Header as='h5' color='grey' textAlign='center'>
                                {'Bought At :  June ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0,4)}
                            </Header>
                        }
                        {timeOfTheOrder.substring(5, 7) === '07' &&
                            <Header as='h5' color='grey' textAlign='center'>
                                {'Bought At :  July ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0,4)}
                            </Header>
                        }
                        {timeOfTheOrder.substring(5, 7) === '08' &&
                            <Header as='h5' color='grey' textAlign='center'>
                                {'Bought At :  August ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0, 4)}
                            </Header>
                        }
                        {timeOfTheOrder.substring(5, 7) === '09' &&
                            <Header color='orange' as='h6' textAlign='center'>
                                {'Bought At :  September ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0, 4)}
                            </Header>
                        }
                        {timeOfTheOrder.substring(5, 7) === '10' &&
                            <Header as='h5' color='grey' textAlign='center'>
                                {'Bought At :  October ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0, 4)}
                            </Header>
                        }
                        {timeOfTheOrder.substring(5, 7) === '11' &&
                            <Header as='h5' color='grey' textAlign='center'>
                                {'Bought At :  November ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0, 4)}
                            </Header>
                        }
                        {timeOfTheOrder.substring(5, 7) === '12' &&
                            <Header as='h5' color='grey' textAlign='center'>
                                {'Bought At :  December ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0, 4)}
                            </Header>
                        }


                    </Grid.Column>
                }

                {paid === true && rentOrBuy !== "buy" &&

                    <Grid.Column style={{ paddingTop: 12 }} width={7}>
                        {timeOfTheOrder.substring(5, 7) === '01' &&
                            <Header as='h5' color='grey' textAlign='center'>
                                {'Rented At  : January ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0, 4)}
                            </Header>
                        }
                        {timeOfTheOrder.substring(5, 7) === '02' &&
                            <Header as='h5' color='grey' textAlign='center'>
                                {'Rented At  : February ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0, 4)}
                            </Header>
                        }
                        {timeOfTheOrder.substring(5, 7) === '03' &&
                            <Header as='h5' color='grey' textAlign='center'>
                                {'Rented At  : March ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0, 4)}
                            </Header>
                        }
                        {timeOfTheOrder.substring(5, 7) === '04' &&
                            <Header as='h5' color='grey' textAlign='center'>
                                {'Rented At  : April ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0, 4)}
                            </Header>
                        }
                        {timeOfTheOrder.substring(5, 7) === '05' &&
                            <Header as='h5' color='grey' textAlign='center'>
                                {'Rented At  : May ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0,4)}
                            </Header>
                        }
                        {timeOfTheOrder.substring(5, 7) === '06' &&
                            <Header as='h5' color='grey' textAlign='center'>
                                {'Rented At  : June ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0,4)}
                            </Header>
                        }
                        {timeOfTheOrder.substring(5, 7) === '07' &&
                            <Header as='h5' color='grey' textAlign='center'>
                                {'Rented At  : July ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0,4)}
                            </Header>
                        }
                        {timeOfTheOrder.substring(5, 7) === '08' &&
                            <Header as='h5' color='grey' textAlign='center'>
                                {'Rented At  : August ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0, 4)}
                            </Header>
                        }
                        {timeOfTheOrder.substring(5, 7) === '09' &&
                            <Header color='orange' as='h6' textAlign='center'>
                                {'Rented At  : September ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0, 4)}
                            </Header>
                        }
                        {timeOfTheOrder.substring(5, 7) === '10' &&
                            <Header as='h5' color='grey' textAlign='center'>
                                {'Rented At  : October ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0, 4)}
                            </Header>
                        }
                        {timeOfTheOrder.substring(5, 7) === '11' &&
                            <Header as='h5' color='grey' textAlign='center'>
                                {'Rented At  : November ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0, 4)}
                            </Header>
                        }
                        {timeOfTheOrder.substring(5, 7) === '12' &&
                            <Header as='h5' color='grey' textAlign='center'>
                                {'Rented At  : December ' + timeOfTheOrder.substring(8, 10) + ", " + timeOfTheOrder.substring(0, 4)}
                            </Header>
                        }


                    </Grid.Column>
                }

                {paid !== true && <Grid.Column style={{ paddingTop: 12 }} width={9}>
                    <Header as='h5' color='grey' textAlign='center'>

                        {this.state.days + " days " + this.state.hours + " hours "
                            + this.state.minutes + " minutes " + this.state.seconds + " seconds,"
                            + " before closing the order"}
                    </Header>
                </Grid.Column>}

                {paid !== true && <>
                    {this.state.delivered !== true &&
                        <Grid.Column tablet={3} computer={2}>
                            <Button.Group>
                                <Button color='yellow'
                                    orderid={orderid} style={{ paddingLeft: 35, paddingRight: 35 }}
                                    onClick={this.pay}>
                                    Pay
                                </Button>
                                <Button.Or />
                                <Button
                                    onClick={this.open1}>Cancel</Button>
                            </Button.Group>

                        </Grid.Column>
                    }
                    <Confirm
                        orderid={orderid}
                        open={this.state.open1}
                        onCancel={this.close1}
                        onConfirm={this.cancel}

                    />
                </>
                }


                {paid === true && delivered !== true && <>
                    {this.state.delivered !== true &&
                        <Grid.Column tablet={4} computer={3}>
                            <Button color='orange'
                                onClick={this.open}>
                                Received
                            </Button>
                        </Grid.Column>
                    }

                    <Confirm
                        orderid={orderid}
                        open={this.state.open}
                        onCancel={this.close}
                        onConfirm={this.onHandleDelivered}

                    />
                </>
                }

                {(returnInfo === undefined || returnInfo === null) && paid === true && rentOrBuy === 'rent' && delivered === true && (returned === false || returned === null) &&
                    <Grid.Column tablet={4} computer={3}>
                        <Button color='teal' orderid={orderid} onClick={this.return}>Return Now</Button>
                    </Grid.Column>
                }



                {paid === true && (returnInfo === undefined || returnInfo === null) && <Grid.Column width={2}>
                    <Button content='Status' icon='truck'
                        labelPosition='right'
                        orderid={orderid} onClick={this.onHandleClickDelivery} />
                </Grid.Column>}

                {paid === true && (returnInfo !== undefined && returnInfo !== null) && <Grid.Column width={2}>
                    <Button content='Status' icon='truck'
                        labelPosition='right'
                        orderid={orderid} onClick={this.onHandleClickDeliveryReturn} />
                </Grid.Column>}

                {(returnInfo !== undefined && returnInfo !== null) && paid === true && rentOrBuy === 'rent' && delivered === true && (returned === false || returned === null) &&
                    <Grid.Column tablet={4} computer={3}>
                        <Label as='a' color='yellow' tag>
                            Waiting For Pick Up ...
                        </Label>
                    </Grid.Column>
                }


                {rentOrBuy === "buy" &&

                    <Grid.Column width={16}>
                        {clothing.map(function (it) {
                            const id = it.id
                            return <PastOrderITem id={id} orderid={orderid} delivered={delivered} is={'clothing'} />

                        })}

                        {saleClothing.map(function (it) {
                            const id = it.id
                            return <PastOrderITem id={id} orderid={orderid} delivered={delivered} is={'saleClothing'} />

                        })}
                        {accessory.map(function (it) {
                            const id = it.id
                            return <PastOrderITem id={id} orderid={orderid} delivered={delivered} is={'accessory'} />

                        })}
                    </Grid.Column>
                }

                {rentOrBuy !== "buy" && returned !== true &&

                    <Grid.Column width={16}>
                        {clothing.map(function (it) {
                            const id = it.id
                            return <PastOrderItemRenting id={id} orderid={orderid} returned={returned} delivered={delivered} is={'clothing'} />
                        })}

                        {saleClothing.map(function (it) {
                            const id = it.id
                            return <PastOrderItemRenting id={id} orderid={orderid} returned={returned} delivered={delivered} is={'saleClothing'} />
                        })}
                        {accessory.map(function (it) {
                            const id = it.id
                            return <PastOrderItemRenting id={id} orderid={orderid} returned={returned} delivered={delivered} is={'accessory'} />
                        })}
                    </Grid.Column>
                }

            </Grid.Row>
        </>


    }
}


export default withRouter(PastOrder)