import React from 'react'
// import { Parallax, } from 'react-parallax';
import {
  Segment,
  Button,
  Divider,
  Form,
  Grid,
  Checkbox,
  Message,
  Header,
  Icon
} from 'semantic-ui-react'
import { Link, withRouter } from 'react-router-dom'
import axios from 'axios'
import timestamp from 'time-stamp'
import ToPrevious from './ToPrevious'

class LoginPagePhone extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      products: [],
      token: '',
      somethingWrong: false,
      rememberMe: false,
      loading: true
    }
    this.onHandleUsername = this.onHandleUsername.bind(this)
    this.onHandlePassword = this.onHandlePassword.bind(this)
    this.onHandleSubmit = this.onHandleSubmit.bind(this)
    this.onHandleRememberMe = this.onHandleRememberMe.bind(this)
  }

  componentDidMount () {
    window.scrollTo(0, 0)
    const self = this

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 700)

    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    const remember = localStorage.getItem('rememberMe')
    var identifier = localStorage.getItem('identifier')
    var password = localStorage.getItem('password')
    if (remember === 'true')
      this.setState({
        rememberMe: remember,
        username: identifier,
        password: password
      })
  }

  onHandleUsername (event) {
    this.setState({
      username: event.target.value
    })
  }

  onHandlePassword (event) {
    this.setState({
      password: event.target.value
    })
  }

  onHandleSubmit (event) {
    event.preventDefault()
    const remember = this.state.rememberMe
    const self = this

    if (remember === true) {
      const identifier = this.state.username
      const password = this.state.password
      const rememberMe = this.state.rememberMe

      axios
        .post('https://sheyou-backend.herokuapp.com/auth/local', {
          identifier: identifier,
          password: password
        })
        .then(function (res) {
          console.log(res)

          if (res.status === 200) {
            localStorage.removeItem('toLog')
            localStorage.setItem('login', 'true')
            const time = timestamp.utc('YYYY-MM-DD-HH')
            localStorage.setItem('loginTime', time)
            const token = res.data.jwt
            localStorage.setItem('token', token)
            localStorage.setItem('identifier', identifier)
            localStorage.setItem('password', password)
            localStorage.setItem('rememberMe', rememberMe)
            // alert('You have logged in '+identifier+'! Welcome to ChicOnly')
            self.props.history.push('/logging')
          } else {
            alert('something went wrong')
            localStorage.removeItem('toLog')
            self.setState({
              rememberMe: false,
              username: '',
              password: ''
            })
          }
        })
        .catch(error => {
          localStorage.removeItem('toLog')
          self.setState({
            rememberMe: false,
            username: '',
            password: ''
          })
          if (error.response.status === 400) {
            // alert('Wrong username & password combination')
            self.setState({
              somethingWrong: 'Wrong username & password combination'
            })
            localStorage.removeItem('identifier')
            localStorage.removeItem('password')
            localStorage.removeItem('rememberMe')
          } else {
            // alert('An error occurred:', error.response.statusText)
            const errorMessage = error.response.statusText
            self.setState({
              somethingWrong: 'An error occurred: ' + errorMessage
            })
          }
        })
    } else if (remember === 'true') {
      var identifier = localStorage.getItem('identifier')
      var password = localStorage.getItem('password')
      const identifierRetype = this.state.username
      const passwordRetype = this.state.password
      if (identifierRetype !== '' && passwordRetype !== '') {
        identifier = identifierRetype
        password = passwordRetype
      }
      axios
        .post('https://sheyou-backend.herokuapp.com/auth/local', {
          identifier: identifier,
          password: password
        })
        .then(function (res) {
          console.log(res.status)
          localStorage.removeItem('toLog')
          if (res.status === 200) {
            const time = timestamp.utc('YYYY-MM-DD-HH')
            localStorage.setItem('loginTime', time)
            localStorage.setItem('login', 'true')
            const token = res.data.jwt
            localStorage.setItem('token', token)
            localStorage.setItem('identifier', identifier)
            localStorage.setItem('password', password)
            localStorage.setItem('rememberMe', remember)
            self.props.history.push('/logging')
          } else {
            // alert('something went wrong')
            self.setState({
              rememberMe: false,
              username: '',
              password: '',
              somethingWrong: 'something went wrong'
            })
          }
        })
        .catch(error => {
          localStorage.removeItem('toLog')
          self.setState({
            rememberMe: false,
            username: '',
            password: ''
          })
          if (error.response.status === 400) {
            // alert('Wrong username & password combination')
            self.setState({
              somethingWrong: 'Wrong username & password combination'
            })
            localStorage.removeItem('identifier')
            localStorage.removeItem('password')
            localStorage.removeItem('rememberMe')
          } else {
            const errorMessage = error.response.statusText
            // alert('An error occurred:', error.response.statusText)
            self.setState({
              somethingWrong: 'An error occurred: ' + errorMessage
            })
          }
        })
    } else {
      identifier = localStorage.getItem('identifier')
      password = localStorage.getItem('password')
      const rememberMe = localStorage.getItem('rememberMe')

      console.log(identifier, password)
      if (rememberMe !== null) {
        localStorage.removeItem('rememberMe')
      }

      if (
        (identifier === null && password === null) ||
        (identifier === '' && password === '')
      ) {
        identifier = this.state.username
        password = this.state.password
      }

      axios
        .post('https://sheyou-backend.herokuapp.com/auth/local', {
          identifier: identifier,
          password: password
        })
        .then(function (res) {
          localStorage.removeItem('toLog')
          console.log(res.status)
          if (res.status === 200) {
            localStorage.setItem('login', 'true')
            const token = res.data.jwt
            const time = timestamp.utc('YYYY-MM-DD-HH')
            localStorage.setItem('loginTime', time)
            localStorage.setItem('token', token)
            localStorage.setItem('identifier', identifier)
            localStorage.setItem('password', password)
            self.props.history.push('/logging')
          } else {
            localStorage.removeItem('toLog')
            self.setState({
              rememberMe: false,
              username: '',
              password: '',
              somethingWrong: 'something went wrong'
            })
            // alert('something went wrong')
          }
        })
        .catch(error => {
          localStorage.removeItem('toLog')
          self.setState({
            rememberMe: false,
            username: '',
            password: ''
          })
          if (error.response.status === 400) {
            // alert('Wrong username & password combination')
            self.setState({
              somethingWrong: 'Wrong username & password combination'
            })
            localStorage.removeItem('identifier')
            localStorage.removeItem('password')
          } else {
            const errorMessage = error.response.statusText
            self.setState({
              somethingWrong: 'An error occurred: ' + errorMessage
            })
            // alert('An error occurred:', error.response.statusText)
          }
        })
    }
  }

  onHandleResetPassword (event) {
    this.props.history.push('/forgetpassword')
  }

  onHandleRememberMe (event) {
    const self = this
    const remember = self.state.rememberMe
    if (remember !== 'true') {
      self.setState({ rememberMe: true })
    } else {
      self.setState({ rememberMe: false })
    }
  }

  render () {
    console.log(this.state)
    const remember = this.state.rememberMe
    const identifier = this.state.username
    const password = this.state.password
    const toLog = localStorage.getItem('toLog')

    return (
      <div
        className='bg_color_image'
        style={{ height: '110vh', width: '100%' }}
      >
        <ToPrevious/>
        <Grid style={{position: 'relative', top: '10%'}}>
          <Grid.Row columns={3} centered>
            <Grid.Column width={5}>
            <Header style={{ fontSize: 30, color: 'white' }}>Log In</Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Segment
          placeholder
          style={{position: 'relative', top: '15%',  marginLeft: 10, marginRight: 10 }}
        >
          
          <Grid centered>
            <Grid.Row>
              {toLog === 'true' && (
                <Message positive>
                  <Message.Header>Success</Message.Header>
                  <p>Now You Can Log In</p>
                </Message>
              )}
              {this.state.somethingWrong !== false && (
                <Message negative>
                  <Message.Header>Log In Failure</Message.Header>
                  <p>{this.state.somethingWrong}</p>
                </Message>
              )}

              <Form>
                {remember !== 'true' && (
                  <Form.Input
                    onChange={this.onHandleUsername}
                    icon='user'
                    iconPosition='left'
                    label='Username'
                    placeholder='Username'
                  />
                )}

                {remember === 'true' && (
                  <Form.Input
                    onChange={this.onHandleUsername}
                    icon='user'
                    iconPosition='left'
                    label='Username'
                    placeholder='Username'
                    value={identifier}
                  />
                )}

                {remember !== 'true' && (
                  <Form.Input
                    onChange={this.onHandlePassword}
                    icon='lock'
                    iconPosition='left'
                    label='Password'
                    type='password'
                  />
                )}

                {remember === 'true' && (
                  <Form.Input
                    onChange={this.onHandlePassword}
                    icon='lock'
                    iconPosition='left'
                    label='Password'
                    type='password'
                    value={password}
                  />
                )}

                <Form.Field>
                  {remember === 'true' && (
                    <Checkbox
                      defaultChecked
                      color='orange'
                      label='Remember Me'
                      onClick={this.onHandleRememberMe}
                    />
                  )}
                  {remember !== 'true' && (
                    <Checkbox
                      color='orange'
                      label='Remember Me'
                      onClick={this.onHandleRememberMe}
                    />
                  )}
                </Form.Field>
              </Form>
              <br />
              <br />
            </Grid.Row>

            <Grid.Row columns={2}>
              <Grid.Column>
                <Button
                  onClick={this.onHandleSubmit}
                  content='Log In'
                  color='orange'
                  style={{paddingLeft:50,paddingRight:50}}
                />
              </Grid.Column>

              <Grid.Column>
                <Link to='/forgetpassword'>
                  <Button basic content='Forget Password' color='orange' />
                </Link>
              </Grid.Column>
            </Grid.Row>

            <Divider horizontal>Or</Divider>

            <Grid.Row verticalAlign='middle'>
              <Link to='/signup'>
                <Button
                  className='ml-auto'
                  content='Sign up'
                  icon='signup'
                  color='teal'
                />
              </Link>
            </Grid.Row>
          </Grid>
        </Segment>
      </div>
    )
  }
}

export default withRouter(LoginPagePhone)
