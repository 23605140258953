import React from 'react'
import { Header } from 'semantic-ui-react'
const userLang = navigator.language || navigator.userLanguage

const IntroTextPhone = () => (
  <div>
    <Header as='h5' style={{ color: 'white' }}>
      {userLang === 'zh-CN' && '什么是共享衣橱？'}
      {userLang !== 'zh-CN' && 'What is Clothes Sharing?'}
    </Header>
    <Header style={{ color: 'white', fontSize: 30 ,paddingLeft:20,paddingRight:20}}>
      {userLang === 'zh-CN' && 'SheYou为您带来一种全新的穿搭体验'}
      {userLang !== 'zh-CN' && 'It is a new way of dressing yourself.'}
    </Header>

    {/* <Header as='h5' style={{ color: 'white' }}>
      Renting multiple clothes and return whenever you want, or try purchasing
      them with a discount, you can have more styles with less budget
    </Header> */}
  </div>
)

export default IntroTextPhone
