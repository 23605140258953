import React from 'react'
import axios from 'axios'

import {
    Grid,
    Header,
    Icon,
    Segment,
    Button,
    Message,
    Dropdown,
    Divider
} from 'semantic-ui-react'

import { withRouter, Link } from 'react-router-dom'
import CardTemplate from './MerchantCardTemplate'

class MerchantProduct extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            identifier: '',
            token: '',
            userID: '',
            userInfo: [],
            products: [],
            productLoading: true,
            filterCat: 'all',
            filterSize: 'average',
            loading: true,
            dropitdown:false,
            merchantVerified: ''
        }
        this.handleAddProduct = this.handleAddProduct.bind(this)
        this.handleGoVerify = this.handleGoVerify.bind(this)
        this.getUserInfo = this.getUserInfo.bind(this)
        this.dropitdown = this.dropitdown.bind(this)

    }

    componentDidMount(){
        const self = this
        const p = self.props
        // const verified = p.verify
        const log = localStorage.getItem('login')
        self.setState({ 
            // verified: verified,
            log: log 
        })
        if(log==='true'){
            self.getUserInfo()
          }
    
        window.onload = event => {
            self.setState({
                loading: false
            })
        }

        window.setTimeout(() => {
            if(self.state.loading === true) {
                this.setState({
                    loading: false
                })
            }
        }, 700)

    }

    getUserInfo() {
        const self = this
        const identifier = localStorage.getItem('identifier')
        const token = localStorage.getItem('token')
    
        self.setState({
          identifier: identifier,
          token: token,
          loading: true,
          productLoading: true
        })
        
        axios('https://sheyou-backend.herokuapp.com/users/me', {
          method: 'get',
          headers: {
            'Authorization': 'Bearer ' + token,
          }
        })
          .then(function (res) {
            if (res.status === 200) {
              const userInfo = [res.data]
              console.log(userInfo)
              self.setState({
                userInfo: userInfo,
                userID: userInfo[0].id,
              })

              const merchantInfo = userInfo[0].merchantInfo
              if((merchantInfo !== null) && (merchantInfo.merchantVerified === true)){
                self.setState({
                    merchantVerified: true,
                    loading: false
                })
                }else{
                self.setState({
                    merchantVerified: false,
                    loading: false
                })
                }
            }
          })
          .catch(function(err){
            //   console.log(err)
            self.props.history.push('/loginfirst')
        })

        axios('https://sheyou-backend.herokuapp.com/products', {
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(function(res){
            var products = res.data.filter(function(product){
                if(product.users_permissions_user !== null){
                    return(product.users_permissions_user.id === self.state.userID)
                }
                else{
                    return false
                }
            })

            console.log(products)

            self.setState({
                products: products,
                productLoading: false
            })
        })
        .catch(function(err){
            // console.log(err)
              self.props.history.push('/loginfirst')
      })
    
      }

    handleAddProduct(event, data) {
        var value = data.value
        const self = this
        const link = '/merchantpage/product/'+value
        window.location.assign('https://www.sheyoufashion.com/#'+link)
        self.props.history.push(link)
        window.location.reload(true)
      }

      dropitdown(){
        this.setState({
            dropitdown:true
        })
      }

    handleGoVerify(){
        const self = this
        const userID = self.state.userID
        self.props.history.push('/merchantVerify/address')

    }

    render(){
        const s = this.state
        // const loading = s.loading
        // const self = this
        const verified = this.state.merchantVerified
        console.log(verified)
        const catFilterOpt = [
            {
                key: 'all',
                text: 'All',
                value: 'all'
            },
            {
                key: 'clothing',
                text: 'Clothing',
                value: 'clothing'
            },
            {
                key: 'sale',
                text: 'Sale',
                value: 'sale'
            },
            {
                key: 'accessory',
                text: 'Accessory',
                value: 'accessory'
            },
            {
                key: 'feminine',
                text: 'Feminine',
                value: 'feminine'
            },
            {
                key: 'masculine',
                text: 'Masculine',
                value: 'masculine'
            }
        ]

        const sizeFilterOpt = [
            {
                key: 'average',
                text: 'Average',
                value: 'average'
            },
            {
                key: 'm',
                text: 'M',
                value: 'm'
            },
            {
                key: 's',
                text: 'S',
                value: 's'
            },
            {
                key: 'l',
                text: 'L',
                value: 'l'
            },
            {
                key: 'xsxxs',
                text: 'XS XXS',
                value: 'xsxxs'
            },
            {
                key: 'xlxxl',
                text: 'XL XXL',
                value: 'xlxxl'
            }
        ]
        
        return(
            <>
            {this.state.loading ? (
                <Grid>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>

                    <Grid.Row colums={2}>
                    <Grid.Column width={7}></Grid.Column>
                    <Grid.Column width={6}>
                        <Header size='huge'>
                        <Icon loading name='asterisk' size='massive' color='yellow' />
                        Loading...
                        </Header>
                    </Grid.Column>
                    </Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                </Grid>
            ) : (
                <div>
                    {verified === true && (
                        <Grid centered>
                            <Grid.Row>
                                <Grid.Column width='12'>
                                <Segment>
                                    <Header textAlign='left'>
                                        My Products
                                        <Header.Subheader> {s.products.length} entries found</Header.Subheader>
                                    </Header>
                                    <Divider/>
                                    <Grid.Column floated='right' width={6}>
                                        <Button.Group color='teal' floated='right'>
                                            <Button size='large' >Upload New</Button>
                                            <Dropdown 
                                                floating
                                                className='button icon'
                                                trigger={s.dropitdown}
                                            >
                                                <Dropdown.Menu>
                                                    <Dropdown.Header content='Main Categories' />
                                                    <Dropdown.Item icon='add' value='newClothing' text='Clothing' onClick={this.handleAddProduct}/>
                                                    <Dropdown.Item icon='add' value='newAccessory' text='Accessory' onClick={this.handleAddProduct}/>
                                                    <Dropdown.Item icon='add' value='newSale' text='For Sale' onClick={this.handleAddProduct}/>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Button.Group>
                                    </Grid.Column>
                                    <Grid.Column floated='left' width={10}> 
                                        {/* <Button content='filter' icon='filter'/> */}
                                        <Grid.Row>
                                            <Header as='h5'>
                                                <Icon name='filter' />
                                                <Header.Content>
                                                Filter by category:  {' '}
                                                <Dropdown
                                                    inline
                                                    options={catFilterOpt}
                                                    defaultValue={this.state.filterCat}
                                                />
                                                </Header.Content>
                                            </Header>
                                        </Grid.Row>
                                        <br />
                                        <Grid.Row>
                                            <Header as='h5'>
                                                <Icon name='filter'/>
                                                <Header.Content>
                                                Filter by size:  {' '}
                                                <Dropdown
                                                    inline
                                                    options={sizeFilterOpt}
                                                    defaultValue={this.state.filterSize}
                                                />
                                                </Header.Content>
                                            </Header>
                                        </Grid.Row>
                                    </Grid.Column>
                                </Segment>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                {s.productLoading === true && (
                                    <Header size='huge'>
                                    <Icon loading name='asterisk' size='massive' color='yellow' />
                                    Loading...
                                    </Header>
                                )}
                                {s.productLoading !== true && (
                                    <>
                                    {s.products.length > 0 && (
                                        <Grid.Column>
                                            <CardTemplate products={s.products} />
                                        </Grid.Column>
                                    )}
                                    {s.products.length === 0 && (
                                        <Grid.Column>
                                            <br />
                                            <Header textAlign='center' >
                                            You have not uploaded any products.
                                            </Header>
                                            <br />
                                        </Grid.Column>
                                    )}
                                    </>
                                )}
                            </Grid.Row>
                        </Grid>
                    )}

                    {verified === false && (
                        <Segment>
                        <br/>
                        <Grid centered style={{paddingLeft:10,paddingRight:10, marginTop: 7}}>
                            <Grid.Row>
                                <Message
                                    size='large'
                                    warning
                                    header = 'You have not verified your merchant account.'
                                    content = 'Please go verify your merchant account before you can add or view any products.'
                                />
                            </Grid.Row>
                            <Grid.Row>
                                <Button size='large' color='orange' onClick={this.handleGoVerify}>Go Verify</Button>
                            </Grid.Row>
                        </Grid>
                        <br/>
                        <br/>
                    </Segment>
                    )}

                    {verified === '' && (
                        <Grid>
                            <Grid.Row></Grid.Row>
                            <Grid.Row></Grid.Row>
                            <Grid.Row></Grid.Row>
                            <Grid.Row></Grid.Row>
                            <Grid.Row colums={2}>
                            <Grid.Column width={7}></Grid.Column>
                            <Grid.Column width={6}>
                                <Header size='huge'>
                                <Icon loading name='asterisk' size='massive' color='yellow' />
                                Loading...
                                </Header>
                            </Grid.Column>
                            </Grid.Row>
                            <Grid.Row></Grid.Row>
                            <Grid.Row></Grid.Row>
                            <Grid.Row></Grid.Row>
                            <Grid.Row></Grid.Row>
                         </Grid>
                    )}
                </div>
            )}
            </>
        )
    }


}

export default withRouter(MerchantProduct)