import React from 'react'
import {
    Container, Grid, Header,
    Segment, Label, Icon, Button, Divider, GridColumn
} from 'semantic-ui-react';
// import { Link } from 'react-router-dom';
// import ReactPlayer from 'react-player'
import Foot from '../foot/Foot'
import "../../App.css"
import { withRouter } from 'react-router-dom'
import "slick-carousel/slick/slick.css";


class Plans extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            activeItem: 'What is Clothes Shaing',
            activeIndex: -1,
            loading: true,
            color: '',
            color1: '',
            color2: ''

        }
        this.changeColor = this.changeColor.bind(this)
        this.changeColor1 = this.changeColor1.bind(this)
        this.changeColor2 = this.changeColor2.bind(this)
        this.plan1 = this.plan1.bind(this)
        this.plan2 = this.plan2.bind(this)
        this.plan3 = this.plan3.bind(this)
    }
    changeColor() {
        if (this.state.color === 'teal') {
            this.setState({
                color: ''
            })
        } else {
            this.setState({
                color: 'teal'
            })
        }
    }
    changeColor1() {
        if (this.state.color1 === 'teal') {
            this.setState({
                color1: ''
            })
        } else {
            this.setState({
                color1: 'teal'
            })
        }
    }
    changeColor2() {
        if (this.state.color2 === 'teal') {
            this.setState({
                color2: ''
            })
        } else {
            this.setState({
                color2: 'teal'
            })
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)

        const self = this

        window.onload = (event) => {
            self.setState({
                loading: false
            })
        };

        window.setTimeout(() => {
            if (self.state.loading === true) {
                this.setState({
                    loading: false
                })
            }

        }, 500)
    }


    plan1(){
        localStorage.setItem('plan','plan1')
        this.props.history.push('/pay')
    }


    plan2(){
        localStorage.setItem('plan','plan2')
        this.props.history.push('/pay')
    }


    plan3(){
        localStorage.setItem('plan','plan3')
        this.props.history.push('/pay')
    }


    handleItemClick = (e, { name }) => this.setState({ activeItem: name })

    render() {
        const loading = this.state.loading


        return <div>
            {/* <Nav /> */}

            {loading ? (
                <Grid >
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>


                    <Grid.Row colums={2}>
                        <Grid.Column width={7}></Grid.Column>
                        <Grid.Column width={6}>
                            <Header size='huge'>
                                <Icon loading name='asterisk' size='massive' color='yellow' />
                                Loading...
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                </Grid>
            ) : (
                <>
                    <div className='bg_image'>
                        <br />
                        <br />
                        <Container style={{ marginTop: 10, marginBottom:10 }}>

                                <Segment raised>

                                    <Grid centered stackable>

                                        <Grid.Row >
                                        </Grid.Row>

                                        <Grid.Row centered style={{ fontSize: 30 }} >
                                            <Header  >Pick A Plan</Header>

                                        </Grid.Row>

                                        <Grid.Row centered columns={3}>

                                            <Grid.Column width={5}>
                                                <Segment placeholder color={this.state.color}
                                                    onMouseOver={this.changeColor} onMouseOut={this.changeColor} >
                                                    <Label as='a' color='teal' ribbon='left' size='large'>
                                                        <strike>  {'330 '}  </strike>
                                                        {' 299 ¥ / month'}
                                                    </Label>
                                                    <br />

                                                    <Divider />
                                                    <Grid.Column with={16}>
                                                        <Header as='h5' textAlign='center'>
                                                            About
                                                        </Header>

                                                    </Grid.Column>

                                                    <Grid.Column with={2}>

                                                        <Header textAlign='center' as='h1'
                                                        >
                                                            6
                                                        </Header>
                                                    </Grid.Column>
                                                    <Grid.Column width={14}>
                                                        <Header as='h5' textAlign='center'>
                                                            items per month
                                                        </Header>
                                                    </Grid.Column>

                                                    <br />
                                                    <Grid.Column with={14}
                                                        style={{ paddingLeft: 15, paddingRight: 15 }}>
                                                        <Header textAlign='center'>
                                                            <Header.Subheader as='h5'>
                                                                less budget more fun
                                                            </Header.Subheader>
                                                        </Header>

                                                    </Grid.Column>
                                                    <Divider />
                                                    <br />
                                                    <GridColumn width={16}>
                                                        <Header as='h5' >
                                                            <Icon name='checkmark' size='tiny' />
                                                            <Header.Content>
                                                                2 shipments per month
                                                            </Header.Content>
                                                        </Header>
                                                    </GridColumn>
                                                    <GridColumn width={16}>
                                                        <Header as='h5' >
                                                            <Icon name='checkmark' size='tiny' />
                                                            <Header.Content>
                                                                3 items per shipment
                                                            </Header.Content>
                                                        </Header>
                                                    </GridColumn>
                                                    <GridColumn width={16}>
                                                        <Header as='h5'>
                                                            <Icon name='checkmark' size='tiny' />
                                                            <Header.Content>
                                                                Add items easily
                                                            </Header.Content>
                                                        </Header>
                                                    </GridColumn>
                                                    <GridColumn width={16}>
                                                        <Header as='h5' >
                                                            <Icon name='checkmark' size='tiny' />
                                                            <Header.Content>
                                                                full closet access
                                                            </Header.Content>
                                                        </Header>
                                                    </GridColumn>
                                                    <br />
                                                    <br />
                                                    <GridColumn width={16}>
                                                        <Button secondary onClick={this.plan1} style={{ paddingLeft: 50, paddingRight: 50 }}>
                                                            Try Now
                                                        </Button>
                                                    </GridColumn>
                                                    <br />

                                                </Segment>
                                            </Grid.Column>

                                            <Grid.Column width={5}>
                                                <Segment placeholder color={this.state.color1}
                                                    onMouseOver={this.changeColor1} onMouseOut={this.changeColor1} >


                                                    <Label as='a' color='teal' ribbon='left' size='large'>
                                                        <strike>  {'660 '}  </strike>
                                                        {' 499 ¥ / month'}
                                                    </Label>
                                                    <br />

                                                    <Divider />
                                                    <Grid.Column with={16}>
                                                        <Header as='h5' textAlign='center'>
                                                            About
                                                        </Header>

                                                    </Grid.Column>

                                                    <Grid.Column with={2}>

                                                        <Header textAlign='center' as='h1'
                                                        >
                                                            12
                                                        </Header>
                                                    </Grid.Column>
                                                    <Grid.Column width={14}>
                                                        <Header as='h5' textAlign='center'>
                                                            weekly newness
                                                        </Header>
                                                    </Grid.Column>

                                                    <br />
                                                    <Grid.Column with={14}
                                                        style={{ paddingLeft: 15, paddingRight: 15 }}>
                                                        <Header textAlign='center'>
                                                            <Header.Subheader as='h5'>
                                                                More shipments
                                                            </Header.Subheader>
                                                        </Header>

                                                    </Grid.Column>
                                                    <Divider />
                                                    <br />
                                                    <GridColumn width={16}>
                                                        <Header as='h5' textAlign='center'>
                                                            <Icon name='checkmark' size='tiny' />
                                                            <Header.Content>
                                                                4 shipments per month
                                                            </Header.Content>
                                                        </Header>
                                                    </GridColumn>
                                                    <GridColumn width={16}>
                                                        <Header as='h5' >
                                                            <Icon name='checkmark' size='tiny' />
                                                            <Header.Content>
                                                                3 items per shipment
                                                            </Header.Content>
                                                        </Header>
                                                    </GridColumn>
                                                    <GridColumn width={16}>
                                                        <Header as='h5'>
                                                            <Icon name='checkmark' size='tiny' />
                                                            <Header.Content>
                                                                Add items easily
                                                            </Header.Content>
                                                        </Header>
                                                    </GridColumn>
                                                    <GridColumn width={16}>
                                                        <Header as='h5' >
                                                            <Icon name='checkmark' size='tiny' />
                                                            <Header.Content>
                                                                full closet access
                                                            </Header.Content>
                                                        </Header>
                                                    </GridColumn>
                                                    <br />
                                                    <br />
                                                    <GridColumn width={16}>
                                                        <Button secondary onClick={this.plan2} style={{ paddingLeft: 50, paddingRight: 50 }}>
                                                            Try Now
                                                        </Button>
                                                    </GridColumn>
                                                    <br />

                                                </Segment>
                                            </Grid.Column>

                                            <Grid.Column width={5}>

                                                <Segment placeholder color={this.state.color2}
                                                    onMouseOver={this.changeColor2} onMouseOut={this.changeColor2}>


                                                    <Label as='a' color='teal' ribbon='left' size='large'>
                                                        <strike>  {'1100 '}  </strike>
                                                        {' 699 ¥ / month'}
                                                    </Label>
                                                    <br />

                                                    <Divider />
                                                    <Grid.Column with={16}>
                                                        <Header as='h5' textAlign='center'>
                                                            About
                                                        </Header>

                                                    </Grid.Column>

                                                    <Grid.Column with={2}>

                                                        <Header textAlign='center' as='h1'
                                                        >
                                                            20
                                                        </Header>
                                                    </Grid.Column>
                                                    <Grid.Column width={14}>
                                                        <Header as='h5' textAlign='center'>
                                                            items per month
                                                        </Header>
                                                    </Grid.Column>

                                                    <br />
                                                    <Grid.Column with={14}
                                                        style={{ paddingLeft: 15, paddingRight: 15 }}>
                                                        <Header textAlign='center'>
                                                            <Header.Subheader as='h5'>
                                                                big packages big surprise
                                                            </Header.Subheader>
                                                        </Header>
                                                    </Grid.Column>
                                                    <Divider />
                                                    <br />
                                                    <GridColumn width={16}>
                                                        <Header as='h5' textAlign='center'>
                                                            <Icon name='checkmark' size='tiny' />
                                                            <Header.Content>
                                                                2 shipments per month
                                                            </Header.Content>
                                                        </Header>
                                                    </GridColumn>
                                                    <GridColumn width={16}>
                                                        <Header as='h5' textAlign='center'>
                                                            <Icon name='checkmark' size='tiny' />
                                                            <Header.Content>
                                                                6 items per shipment
                                                            </Header.Content>
                                                        </Header>
                                                    </GridColumn>
                                                    <GridColumn width={16}>
                                                        <Header as='h5' textAlign='center'>
                                                            <Icon name='checkmark' size='tiny' />
                                                            <Header.Content>
                                                                6 clothing + 3 accessory
                                                            </Header.Content>
                                                        </Header>
                                                    </GridColumn>
                                                    <GridColumn width={16}>

                                                        <Header as='h5' textAlign='center'>
                                                            <Icon name='checkmark' size='tiny' />
                                                            <Header.Content>
                                                                full closet access
                                                            </Header.Content>
                                                        </Header>
                                                    </GridColumn>
                                                    <br />
                                                    <br />
                                                    <GridColumn width={16}>
                                                        <Button secondary onClick={this.plan3} style={{ paddingLeft: 50, paddingRight: 50 }}>
                                                            Try Now
                                                        </Button>
                                                    </GridColumn>
                                                    <br />

                                                </Segment>

                                            </Grid.Column>



                                        </Grid.Row>

                                        <Grid.Row>
                                        </Grid.Row>

                                        <Grid.Row>
                                        </Grid.Row>
                                    </Grid>
                                </Segment>

                        </Container>
                        <br />
                        <br />
                    </div>

                </>
            )
            }
            <Foot />
        </div >

    }

}

export default withRouter(Plans)

