import React from 'react'
import axios from 'axios'
import {
  Grid,
  Image,
  Icon,
  Container,
  Button,
  Header,
  Radio,
  Select,
  Segment,
  Menu,
  Label,
  Modal,
  Popup,
  Divider
} from 'semantic-ui-react'

import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import { withRouter } from 'react-router-dom'
import ProductReview from '../productReview/ProductReview'
// import { useContext } from 'react';
// import { forEach } from 'json-server-auth';
import NavMobilePhone from '../nav/NavMobilePhone'
const userLang = navigator.language || navigator.userLanguage

class ProductPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      products: [],
      availability: [],
      stock: 0,
      images: [],
      details: [],
      activeItem: 'Product Information',
      colors: [],
      sizes: [],
      selectNum: 1,
      cartItemId: 0,
      colorSelected: '',
      sizeSelected: '',
      cartItem: [],
      value: '',
      exceedNum: false,
      userInfo: [],
      userID: '',
      identifier: '',
      token: '',
      addCartSuccess: false,
      heartChange: 'heart outline',
      addHeartSuccess: false,
      addMemberBagSuccess: false,
      cart: [],
      heart: [],
      membership: {},
      inCart: false,
      CartMaxed: false,
      Hearted: false,
      bagRepeat: false,
      cartRepeat: false,
      numHitLowLimit: false,

      addExtraClothing: false,
      open: false,
      open2: false,
      open3: false,
      log: '',
      loading: true
    }

    this.handleAddBuyCart = this.handleAddBuyCart.bind(this)
    this.handleAddMemberBag = this.handleAddMemberBag.bind(this)
    this.handleAddHeartList = this.handleAddHeartList.bind(this)
    this.handleSelectMore = this.handleSelectMore.bind(this)
    this.handleSelectLess = this.handleSelectLess.bind(this)
    this.selectColor = this.selectColor.bind(this)
    this.selectSize = this.selectSize.bind(this)
    this.addExtraClothing = this.addExtraClothing.bind(this)
    this.heartChange = this.heartChange.bind(this)
    this.lowLimitAlertClose = this.lowLimitAlertClose.bind(this)
    this.exceedNumAlertClose = this.exceedNumAlertClose.bind(this)
    this.getProducts = this.getProducts.bind(this)
    this.getAvailability = this.getAvailability.bind(this)
  }

  componentDidMount () {
    window.scrollTo(0, 0)
    this.getProducts()
    this.getAvailability()

    const identifier = localStorage.getItem('identifier')
    const token = localStorage.getItem('token')

    const self = this
    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 700)

    self.setState({
      identifier: identifier,
      token: token
    })

    const log = localStorage.getItem('login')
    self.setState({ log: log })
    if (log === 'true') {
      axios('https://sheyou-backend.herokuapp.com/users/me', {
        method: 'get',
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(function (res) {
        if (res.status === 200) {
          const userInfo = [res.data]
          if (userInfo[0].cart === null) {
            userInfo[0].cart = []
          }
          if (userInfo[0].heart === null) {
            userInfo[0].heart = []
          }
          if (userInfo[0].bag === null) {
            userInfo[0].bag = []
          }
          self.setState({
            userInfo: userInfo,
            userID: userInfo[0].id,
            cart: userInfo[0].cart,
            heart: userInfo[0].heart.filter(function (each) {
              return each.type !== 'sale'
            }),
            bag: userInfo[0].bag,
            membership: userInfo[0].membership,
            extraClothing: userInfo[0].extraClothing,
            extraAccessories: userInfo[0].extraAccessories
          })
          self.heartChange()
        } else {
          alert('something went wrong :(')
          localStorage.setItem('login', false)
        }
      })
    }
  }

  heartChange () {
    const self = this
    const id = this.state.cartItemId
    const heart = self.state.heart
    const log = self.state.log

    if (log === 'true') {
      if (heart !== []) {
        for (var i = 0; i < heart.length; i++) {
          if (heart[i].id === id) {
            self.setState({ heartChange: 'heart' })
          }
        }
      }
    } else {
      alert('please log in first')
      self.props.history.push('/signin')
    }
  }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  getProducts () {
    const self = this

    const productId = this.props.history.location.search.substring(4)
    console.log(productId)

    axios
      .get('https://sheyou-backend.herokuapp.com/products?id=' + productId)
      .then(function (res) {
        if (res.status === 200) {
          const data = res.data[0]
          self.setState({
            products: data,
            pictures: data.picture.map(function (pic) {
              return pic.url
            }),
            details: data.detail.map(function (pic) {
              return pic.url
            }),
            images: data.picture.map(function (pic) {
              return { original: pic.url, thumbnail: pic.formats.thumbnail.url }
            }),
            categories: data.categories,
            ocassions: data.ocassions,
            stock: data.stock,
            cartItemId: data.id,
            colors: data.colors.map(function (color) {
              return color.name
            }),
            sizes: data.sizes.map(function (size) {
              return size.name
            })
          })
        } else {
          alert('something went wrong :(')
          localStorage.setItem('login', false)
        }
      })
  }

  getAvailability () {
    const self = this
    const productId = localStorage.getItem('productId')
    axios
      .get('https://sheyou-backend.herokuapp.com/products?id=' + productId)
      .then(function (res) {
        if (res.status === 200) {
          const data = res.data[0]
          if (data.stock > 0) {
            self.setState({
              availability: 'In Stock'
            })
          } else {
            self.setState({
              availability: 'Out of Stock'
            })
          }
        } else {
          alert('something went wrong :(')
          localStorage.setItem('login', false)
        }
      })
  }

  handleAddBuyCart () {
    //   const log=localStorage.getItem('login')
    //   console.log(log)
    const id = this.state.cartItemId
    const selectNum = this.state.selectNum
    const selectColor = this.state.colorSelected
    const selectSize = this.state.sizeSelected
    const self = this
    const s = self.state

    // const membership=self.state.membership

    const userID = self.state.userID
    const token = self.state.token

    const log = self.state.log

    if (log === 'true') {
      if (selectColor === '') {
        self.setState({ colorSelected: false })
      }
      if (selectSize === '') {
        self.setState({ sizeSelected: false })
      }
      if (
        selectColor !== '' &&
        selectSize !== '' &&
        selectColor !== false &&
        selectSize !== false
      ) {
        const cartItem = {
          id: id,
          number: selectNum,
          colors: selectColor,
          sizes: selectSize
        }

        // const cartData1 = JSON.stringify([cartItem])
        // const cartData2=localStorage.getItem('buyItem')

        const cartItemOrigin = self.state.cart

        const stock = s.stock

        if (stock >= selectNum) {
          if (cartItemOrigin === null || cartItemOrigin === '') {
            axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
              method: 'put',
              data: {
                cart: [cartItem]
              },
              headers: {
                Authorization: 'Bearer ' + token
              }
            }).then(function (res) {
              console.log(res)
              if (res.status === 200) {
                self.setState({
                  addCartSuccess: true
                })
                self.props.history.push('/cart')
                // alert('add successfully, now you can check in your cart :)')
              } else {
                self.setState({
                  addCartSuccess: 'somethingWrong'
                })
                alert('you are being logged out')
                localStorage.setItem('login', false)
              }
            })

            // localStorage.setItem('buyItem',cartData1)
          } else {
            const cartLength = cartItemOrigin.length
            var ifRepeat = false
            var cartData = []
            for (var i = 0; i < cartLength; i++) {
              if (
                cartItemOrigin[i].id === id &&
                cartItemOrigin[i].type !== 'sale'
              ) {
                ifRepeat = true
              }
            }

            if (ifRepeat === true) {
              self.setState({ cartRepeat: true })
            }

            // alert('yehhh')
            // if(ifRepeat===true){
            //   alert("it's already in the cart")

            // const stock=this.state.stock
            // var newCartItem=[]

            // for(i=0;i<cartLength;i++){
            //     if(cartItemOrigin[i].id===id&&cartItemOrigin[i].number+selectNum<=stock)
            //     {newCartItem=cartItemOrigin[i]
            //      newCartItem.number=cartItemOrigin[i].number+selectNum
            //      cartData.push(newCartItem)
            //      alert('add successfully, now you can check in your cart')}

            //      if(cartItemOrigin[i].id===id&& cartItemOrigin[i].number+selectNum>stock)
            //      {alert ('sorry, we do not have enough in stock')}

            //      if(cartItemOrigin[i].id!==id)
            //      {cartData.push(cartItemOrigin[i])}

            //     }

            if (ifRepeat === false) {
              cartData = cartItemOrigin
              cartData.push(cartItem)

              axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
                method: 'put',
                data: {
                  cart: cartData
                },
                headers: {
                  Authorization: 'Bearer ' + token
                }
              }).then(function (res) {
                console.log(res)
                if (res.status === 200) {
                  self.setState({
                    addCartSuccess: true
                  })
                  self.props.history.push('/cart')
                  // alert ('add successfullw you can check in your cart')
                } else {
                  // alert('sorry, something went wrong')
                  self.setState({
                    addCartSuccess: 'somethingWrong'
                  })
                  alert('you are being logged out')
                  localStorage.setItem('login', false)
                }
              })
            }
          }
        } else {
          self.setState({
            addCartSuccess: 'stockNotEnough'
          })
        }

        // cartData=JSON.stringify(cartData)
        // localStorage.setItem('buyItem',cartData)
      }
    } else {
      alert('please log in first')
      self.props.history.push('/signin')
    }
  }

  handleAddHeartList () {
    //   const log=localStorage.getItem('login')
    //   console.log(log)
    const id = this.state.cartItemId
    const self = this
    const s = self.state
    const log = s.log

    const userID = self.state.userID
    const token = self.state.token

    const heartChange = s.heartChange

    const cartItem = {
      id: id
    }

    var heartOrigin = s.heart

    if (log === 'true') {
      if (
        heartOrigin === null ||
        heartOrigin === '' ||
        heartOrigin === undefined
      ) {
        heartOrigin = []
      }

      var heartData = []
      // const cartData1 = JSON.stringify([cartItem])
      // const cartData2=localStorage.getItem('rentItem')

      if (heartChange === 'heart outline') {
        heartData = heartOrigin
        heartData.push(cartItem)

        self.setState({ heart: heartData })

        axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
          method: 'put',
          data: {
            heart: heartData
          },
          headers: {
            Authorization: 'Bearer ' + token
          }
        }).then(function (res) {
          console.log(res)
          if (res.status === 200) {
            self.setState({
              addHeartSuccess: true,
              heartChange: 'heart'
            })
          } else {
            self.setState({
              addHeartSuccess: 'somethingWrong'
            })
          }
        })
      }
      // cartData=JSON.stringify(cartData)
      // localStorage.setItem('rentItem',cartData)

      if (heartChange === 'heart') {
        const heartLen = heartOrigin.length
        for (var i = 0; i < heartLen; i++) {
          if (heartOrigin[i].id !== id) {
            heartData.push(heartOrigin[i])
          }
        }

        self.setState({ heart: heartData })

        axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
          method: 'put',
          data: {
            heart: heartData
          },
          headers: {
            Authorization: 'Bearer ' + token
          }
        }).then(function (res) {
          console.log(res)
          if (res.status === 200) {
            self.setState({
              heartChange: 'heart outline',
              addHeartSuccess: 'canceled'
            })
          } else {
            self.setState({
              addHeartSuccess: 'somethingWrong'
            })
          }
        })
      }
      // cartData=JSON.stringify(cartData)
      // localStorage.setItem('rentItem',cartData)
    } else {
      alert('please log in first')
      self.props.history.push('/signin')
    }
  }

  handleAddMemberBag () {
    //   const log=localStorage.getItem('login')
    //   console.log(log)
    const id = this.state.cartItemId
    const selectNum = this.state.selectNum
    const selectColor = this.state.colorSelected
    const selectSize = this.state.sizeSelected
    const self = this
    const log = self.state.log
    var cartClothingItemMaxNum = 0
    var membership = self.state.membership

    if (membership !== null) {
      cartClothingItemMaxNum = membership.clothingItemMaxNum
      if (cartClothingItemMaxNum === null || cartClothingItemMaxNum === '') {
        cartClothingItemMaxNum = 3
      }
    } else {
      cartClothingItemMaxNum = 3
      membership = { clothingItemMaxNum: 3 }
    }

    // const cartAccessoryMaxNum=membership.cartAccessoryMaxNum

    const userID = self.state.userID
    const token = self.state.token

    if (log === 'true') {
      if (selectColor === '') {
        self.setState({ colorSelected: false })
      }
      if (selectSize === '') {
        self.setState({ sizeSelected: false })
      }
      if (
        selectColor !== '' &&
        selectSize !== '' &&
        selectColor !== false &&
        selectSize !== false
      ) {
        const cartItem = {
          id: id,
          number: selectNum,
          colors: selectColor,
          sizes: selectSize
        }

        // const cartData1 = JSON.stringify([cartItem])
        // const cartData2=localStorage.getItem('buyItem')

        const cartItemOrigin = self.state.bag

        if (
          cartItemOrigin === null ||
          cartItemOrigin === '' ||
          cartItemOrigin === undefined
        ) {
          axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
            method: 'put',
            data: {
              bag: [cartItem]
            },
            headers: {
              Authorization: 'Bearer ' + token
            }
          }).then(function (res) {
            console.log(res)
            if (res.status === 200) {
              self.setState({
                addMemberBagSuccess: true
              })
              self.props.history.push('/cart')
              // alert('add successfully, now you can check in your cart :)')
            } else {
              // alert('sorry, something went wrong :(')
              self.setState({
                addMemberBagSuccess: 'somethingWrong'
              })
            }
          })

          // localStorage.setItem('buyItem',cartData1)
        } else {
          const cartLength = cartItemOrigin.length
          if (cartLength < cartClothingItemMaxNum) {
            var ifRepeat = false
            var cartData = []
            for (var i = 0; i < cartLength; i++) {
              if (cartItemOrigin[i].id === id) {
                ifRepeat = true
              }
            }
            // alert('yehhh')
            if (ifRepeat === true) {
              // alert("it's already in the cart
              self.setState({ bagRepeat: true })
              // const stock=this.state.stock
              // var newCartItem=[]

              // for(i=0;i<cartLength;i++){
              //     if(cartItemOrigin[i].id===id&&cartItemOrigin[i].number+selectNum<=stock)
              //     {newCartItem=cartItemOrigin[i]
              //      newCartItem.number=cartItemOrigin[i].number+selectNum
              //      cartData.push(newCartItem)
              //      alert('add successfully, now you can check in your cart')}

              //      if(cartItemOrigin[i].id===id&& cartItemOrigin[i].number+selectNum>stock)
              //      {alert ('sorry, we do not have enough in stock')}

              //      if(cartItemOrigin[i].id!==id)
              //      {cartData.push(cartItemOrigin[i])}

              //     }
            }

            if (ifRepeat === false) {
              cartData = cartItemOrigin
              cartData.push(cartItem)

              axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
                method: 'put',
                data: {
                  bag: cartData
                },
                headers: {
                  Authorization: 'Bearer ' + token
                }
              }).then(function (res) {
                console.log(res)
                if (res.status === 200) {
                  self.setState({
                    addMemberBagSuccess: true
                  })
                  self.props.history.push('/cart')
                  // alert ('add successfully, now you can check in your cart')
                } else {
                  // alert('sorry, something went wrong')
                  self.setState({
                    addMemberBagSuccess: 'somethingWrong'
                  })
                }
              })
            }
          } else {
            // alert("you don't have enough place in your cart :(, you can delete some clothing or pay for extra place")
            const cartLength = cartItemOrigin.length
            ifRepeat = false
            for (i = 0; i < cartLength; i++) {
              if (cartItemOrigin[i].id === id) {
                ifRepeat = true
              }
            }
            // alert('yehhh')
            if (ifRepeat === true) {
              self.setState({ bagRepeat: true })
            } else {
              self.setState({
                addExtraClothing: true,
                open: true
              })
            }
          }

          // cartData=JSON.stringify(cartData)
          // localStorage.setItem('buyItem',cartData)
        }
      }
    } else {
      alert('please log in first')
      self.props.history.push('/signin')
    }
  }

  handleSelectMore () {
    var self = this
    var selectNum = this.state.selectNum
    var stock = this.state.stock
    stock = JSON.parse(stock)

    if (selectNum >= stock) {
      self.setState({ exceedNum: true })
    }

    if (selectNum < stock) {
      self.setState({ selectNum: selectNum + 1 })
    }
  }

  handleSelectLess () {
    var self = this
    var selectNum = this.state.selectNum
    // var stock=this.state.stock
    // stock=JSON.parse(stock)
    if (selectNum >= 1) {
      self.setState({ numHitLowLimit: true })
    }
    if (selectNum > 1) {
      self.setState({ selectNum: selectNum - 1 })
    }
  }

  //   selectColor({value}){
  //     //   const color=data.value
  //     const self=this
  //       self.setState({colorSelected:{value}})
  //   }
  selectColor (data, event) {
    const self = this
    self.setState({ colorSelected: event.value })
  }
  selectSize (data, event) {
    const self = this
    self.setState({ sizeSelected: event.value })
  }

  open = () => this.setState({ open: true })
  close = () => this.setState({ open: false })

  open2 = () => this.setState({ open2: true })
  close2 = () => this.setState({ open2: false })

  open3 = () => this.setState({ open3: true })
  close3 = () => this.setState({ open3: false })

  closeAll = () => this.setState({ open: false, open2: false })

  addExtraClothing () {
    const s = this.state
    const self = this
    const userID = s.userID
    const token = s.token

    const id = this.state.cartItemId
    const selectNum = this.state.selectNum
    const selectColor = this.state.colorSelected
    const selectSize = this.state.sizeSelected

    const cart = s.bag
    console.log(cart)

    const cartItem = {
      id: id,
      number: selectNum,
      colors: selectColor,
      sizes: selectSize
    }

    console.log(cartItem)

    cart.push(cartItem)

    if (s.userID !== '') {
      axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
        method: 'put',
        data: {
          bag: cart
        },
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(function (res) {
        console.log(res)
        if (res.status === 200) {
          self.setState({
            open2: true
          })
          self.props.history.push('/cart')
        } else {
          self.setState({
            open3: true
          })
        }
      })
    }
  }

  lowLimitAlertClose () {
    const self = this
    self.setState({
      numHitLowLimit: false
    })
  }

  exceedNumAlertClose () {
    const self = this
    self.setState({
      exceedNum: false
    })
  }

  render () {
    const self = this
    const s = this.state
    const product = s.products
    // const log=s.log
    // const pictures=s.pictures
    // const availability=s.availability
    // const categories=s.categories
    // const ocassions=s.ocassions

    const sizeOptions = [
      s.sizes.map(function (size) {
        return { key: size, value: size, text: size }
      })
    ]

    const colors = s.colors

    const images = s.images
    const details = s.details
    const activeItem = this.state.activeItem
    const selectNum = this.state.selectNum

    const productId = localStorage.getItem('productId')
    const selectColor = this.selectColor

    const style = {
      borderRadius: 12,
      opacity: 0.9,
      padding: '1.5em',
      color: 'orange'
    }

    const addCartSuccess = s.addCartSuccess
    const addHeartSuccess = s.addHeartSuccess
    const addMemberBagSuccess = s.addMemberBagSuccess
    console.log(addCartSuccess)

    return (
      <div>
        {/* <Nav /> */}

        {this.state.loading ? (
          <Grid>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>

            <Grid.Row colums={2}>
              <Grid.Column width={7}></Grid.Column>
              <Grid.Column width={6}>
                <Header size='huge'>
                  <Icon loading name='asterisk' size='massive' color='yellow' />
                  Loading...
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid>
        ) : (
          <>
            <Container fluid style={{ paddingTop: 10 }}>
              <Grid stackable>
                <Grid.Row columns={4}>
                  <Grid.Column width={1}></Grid.Column>
                  <Grid.Column width={6}>
                    <Grid>
                      <Grid.Row>
                        <ImageGallery items={images} />
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>

                  <Grid.Column width={1}></Grid.Column>

                  <Grid.Column width={8}>
                    <Grid divided='vertically'>
                      <Grid.Column width={16}></Grid.Column>
                      {/* <Grid.Row>
                                      
                                  </Grid.Row> */}

                      <Grid.Row centered>
                        <Grid.Column width={9}>
                          <p textAlign='center'>{product.name}</p>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row centered>
                        <Grid.Column width={10}>
                          <Grid.Row>
                            {userLang !== 'zh-CN' && (
                              <Header>{'$' + product.price} Sale Price</Header>
                            )}
                            {userLang === 'zh-CN' && (
                              <Header>{'$' + product.price} 出售价</Header>
                            )}
                          </Grid.Row>
                          {product.rentPrice !== null && (
                            <Grid.Row>
                              {userLang !== 'zh-CN' && (
                                <Header>
                                  {'$' + product.rentPrice} Rent Price
                                </Header>
                              )}
                              {userLang === 'zh-CN' && (
                                <Header>
                                  {'$' + product.rentPrice} 租借价
                                </Header>
                              )}
                            </Grid.Row>
                          )}
                        </Grid.Column>

                        <Grid.Column width={2}>
                          <Popup
                            trigger={
                              <Icon
                                name={s.heartChange}
                                size='large'
                                color='orange'
                                onClick={this.handleAddHeartList}
                              ></Icon>
                            }
                            content={'Do you like me :)'}
                            style={style}
                          />
                          {addHeartSuccess === 'somethingWrong' && (
                            <Label basic color='red' pointing='left'>
                              出错了 :(
                            </Label>
                          )}
                          {addHeartSuccess === true && (
                            <Label basic color='green' pointing='left'>
                              已加入您的收藏 :)
                            </Label>
                          )}
                          {addHeartSuccess === 'canceled' && (
                            <Label basic color='yellow' pointing='left'>
                              您已取消收藏
                            </Label>
                          )}
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row centered>
                        <Grid.Column width={5}>
                          {userLang === 'zh-CN'&&<Header as='h5'>选择颜色:</Header>}
                          {userLang !== 'zh-CN'&&<Header as='h5'>Color:</Header>}
                          
                        </Grid.Column>
                        <Grid.Column width={8}>
                          {colors.map(function (color) {
                            return (
                              <Radio
                                label={color}
                                value={color}
                                onChange={selectColor}
                              />
                            )
                          })}
                          {/* <Radio label='pink' defaultChecked /> */}
                          {s.colorSelected === false &&userLang === 'zh-CN' &&  (
                            <Label basic color='red' pointing='left'>
                              请选择颜色
                            </Label>
                          )}
                          {s.colorSelected === false &&userLang !== 'zh-CN' &&  (
                            <Label basic color='red' pointing='left'>
                              Choose Color
                            </Label>
                          )}
                        </Grid.Column>
                        <Grid.Column width={5}>
                          <Header as='h5'>选择尺码:</Header>
                        </Grid.Column>
                        <Grid.Column width={10}>
                          <Select
                            placeholder='选择尺码'
                            options={sizeOptions[0]}
                            onChange={this.selectSize}
                          />
                          {s.sizeSelected === false && (
                            <Label basic color='red' pointing='left'>
                              请选择尺码
                            </Label>
                          )}
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row centered>
                        {s.numHitLowLimit === true && (
                          <Grid.Column width={6}>
                            <Label basic color='red' pointing='right'>
                              您的商品数已不能再减少
                              <Icon
                                name='delete'
                                onClick={this.lowLimitAlertClose}
                              />
                            </Label>
                          </Grid.Column>
                        )}

                        <Grid.Column width={2}>
                          <Icon name='minus' onClick={this.handleSelectLess} />
                        </Grid.Column>
                        <Grid.Column width={2}>{selectNum}</Grid.Column>
                        <Grid.Column width={2}>
                          <Icon name='plus' onClick={this.handleSelectMore} />
                        </Grid.Column>

                        {s.exceedNum === true && (
                          <Grid.Column width={7}>
                            <Label basic color='red' pointing='left'>
                              库存不足，请停止添加
                              <Icon
                                name='delete'
                                onClick={this.exceedNumAlertClose}
                              />
                            </Label>
                          </Grid.Column>
                        )}
                      </Grid.Row>

                      <Grid.Row centered columns={3}>
                        <Grid.Column width={7} >
                          {userLang === 'zh-CN' && (
                            <Button
                              style={{ paddingLeft: 30, paddingRight: 30 }}
                              icon
                              color='orange'
                              size='big'
                              basic
                              onClick={this.handleAddMemberBag}
                            >
                              <Icon name='shopping bag' />
                              租借
                            </Button>
                          )}

                          {userLang !== 'zh-CN' && (
                            <Button
                              style={{ paddingLeft: 15, paddingRight: 15 }}
                              icon
                              color='orange'
                              size='big'
                              basic
                              onClick={this.handleAddMemberBag}
                            >
                              <Icon name='shopping bag' />
                              Add to Rent
                            </Button>
                          )}
                          {addMemberBagSuccess === true &&
                            userLang === 'zh-CN' && (
                              <Label basic color='green' pointing='left'>
                                已加入您的租借单 :)
                              </Label>
                            )}
                          {addMemberBagSuccess === true &&
                            userLang !== 'zh-CN' && (
                              <Label basic color='green' pointing='left'>
                                Added to Rent :)
                              </Label>
                            )}
                          {addMemberBagSuccess === 'somethingWrong' &&
                            userLang === 'zh-CN' && (
                              <Label basic color='red' pointing='left'>
                                出错了 :(
                              </Label>
                            )}
                          {addMemberBagSuccess === 'somethingWrong' &&
                            userLang !== 'zh-CN' && (
                              <Label basic color='red' pointing='left'>
                                Something Went Wrong :(
                              </Label>
                            )}
                          {s.bagRepeat === true && userLang === 'zh-CN' && (
                            <Label basic color='yellow' pointing='left'>
                              您已添加了此商品
                            </Label>
                          )}
                          {s.bagRepeat === true && userLang !== 'zh-CN' && (
                            <Label basic color='yellow' pointing='left'>
                              You Already Added This Product
                            </Label>
                          )}
                        </Grid.Column>

                        <Modal
                          onClose={self.close}
                          onOpen={self.open}
                          open={s.open}
                          dimmer={'inverted'}
                        >
                          <Modal.Content image>
                            {product.length !== 0 && (
                              <Image
                                size='medium'
                                src={product.picture[0].url}
                                wrapped
                              />
                            )}
                            <Modal.Description>
                              <br />
                              <br />
                              <br />
                              <br />
                              <br />
                              {userLang === 'zh-CN' && (
                                <>
                                  <p>您的会员计划仅包含3件服装</p>
                                  <Header>
                                    您是否愿意额外支付30元添加此服装？
                                  </Header>
                                </>
                              )}
                              {userLang !== 'zh-CN' && (
                                <>
                                  <p>Your Plan Only Cover 3 Items</p>
                                  <Header>Do you want to pay extra 30？</Header>
                                </>
                              )}
                            </Modal.Description>
                          </Modal.Content>

                          <Modal.Actions>
                            {userLang === 'zh-CN' && (
                              <Button color='black' onClick={self.close}>
                                取消
                              </Button>
                            )}
                            {userLang !== 'zh-CN' && (
                              <Button color='black' onClick={self.close}>
                                Cancel
                              </Button>
                            )}

                            {userLang === 'zh-CN' && (
                              <Button
                                content='确认'
                                labelPosition='right'
                                icon='arrow alternate circle right'
                                onClick={self.addExtraClothing}
                                positive
                              />
                            )}

                            {userLang !== 'zh-CN' && (
                              <Button
                                content='Confirm'
                                labelPosition='right'
                                icon='arrow alternate circle right'
                                onClick={self.addExtraClothing}
                                positive
                              />
                            )}
                          </Modal.Actions>

                          <Modal
                            onClose={self.close2}
                            open={s.open2}
                            size='small'
                          >
                            <Modal.Content image>
                              <div className='image'>
                                <Icon
                                  name='check square'
                                  size='massive'
                                  color='green'
                                />
                              </div>
                              <Modal.Description>
                                <br />
                                {userLang === 'zh-CN' && (
                                  <Header>添加成功</Header>
                                )}

                                {userLang !== 'zh-CN' && (
                                  <Header>Added Successfully</Header>
                                )}
                              </Modal.Description>
                            </Modal.Content>

                            <Modal.Actions>
                              {userLang === 'zh-CN' && (
                                <Button
                                  content='确认'
                                  onClick={self.closeAll}
                                />
                              )}

                              {userLang !== 'zh-CN' && (
                                <Button
                                  content='Confirm'
                                  onClick={self.closeAll}
                                />
                              )}
                            </Modal.Actions>
                          </Modal>

                          <Modal
                            onClose={self.close3}
                            open={s.open3}
                            size='small'
                          >
                            <Modal.Content image>
                              <div className='image'>
                                <Icon name='close' size='massive' color='red' />
                              </div>
                              <Modal.Description>
                                <br />
                                {userLang === 'zh-CN' && (
                                  <Header>抱歉，出错了</Header>
                                )}
                                {userLang !== 'zh-CN' && (
                                  <Header>Sorry. something went wrong</Header>
                                )}
                              </Modal.Description>
                            </Modal.Content>

                            <Modal.Actions>
                              {userLang === 'zh-CN' && (
                                <Button content='确认' onClick={self.close3} />
                              )}

                              {userLang !== 'zh-CN' && (
                                <Button
                                  content='Confirm'
                                  onClick={self.close3}
                                />
                              )}
                            </Modal.Actions>
                          </Modal>
                        </Modal>

                        <Grid.Column width={7}>
                          {userLang === 'zh-CN' && (
                            <Button
                              style={{ paddingLeft: 30, paddingRight: 30 }}
                              icon
                              color='orange'
                              size='big'
                              onClick={this.handleAddBuyCart}
                            >
                              <Icon name='cart' /> 购买
                            </Button>
                          )}

                          {userLang !== 'zh-CN' && (
                            <Button
                              style={{ paddingLeft: 15, paddingRight: 15 }}
                              icon
                              color='orange'
                              size='big'
                              onClick={this.handleAddBuyCart}
                            >
                              <Icon name='cart' /> Add To Buy
                            </Button>
                          )}

                          {addCartSuccess === true &&userLang === 'zh-CN'&& (
                            <Label basic color='green' pointing='left'>
                              已加入您的购物车 :)
                            </Label>
                          )}
                          {addCartSuccess === true &&userLang !== 'zh-CN'&& (
                            <Label basic color='green' pointing='left'>
                              Added
                            </Label>
                          )}
                          {addCartSuccess === 'somethingWrong'&&userLang === 'zh-CN' && (
                            <Label basic color='red' pointing='left'>
                              出错了 :(
                            </Label>
                          )}
                          {addCartSuccess === 'somethingWrong'&&userLang !== 'zh-CN' && (
                            <Label basic color='red' pointing='left'>
                              Something Went Wrong
                            </Label>
                          )}
                          {s.cartRepeat === true&&userLang === 'zh-CN' && (
                            <Label basic color='yellow' pointing='left'>
                              您已添加了此商品
                            </Label>
                          )}
                          {s.cartRepeat === true&&userLang !== 'zh-CN' && (
                            <Label basic color='yellow' pointing='left'>
                              Product Added
                            </Label>
                          )}
                          {addCartSuccess === 'stockNotEnough'&&userLang === 'zh-CN' && (
                            <Label basic color='red' pointing='left'>
                              库存不足:(
                            </Label>
                          )}
                          {addCartSuccess === 'stockNotEnough'&&userLang !== 'zh-CN' && (
                            <Label basic color='red' pointing='left'>
                              Storage Not Enough
                            </Label>
                          )}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row></Grid.Row>
              </Grid>
            </Container>

            <br />
            <Container>
              {userLang === 'zh-CN' && (
                <Menu attached='top' tabular color='teal'>
                  <Menu.Item
                    name='商品详情'
                    active={activeItem === 'Product Information'}
                    onClick={this.handleItemClick}
                  />
                  <Menu.Item
                    name='评价'
                    active={activeItem === 'Reviews'}
                    onClick={this.handleItemClick}
                  />
                </Menu>
              )}

              {userLang !== 'zh-CN' && (
                <Menu attached='top' tabular color='teal'>
                  <Menu.Item
                    name='Product Information'
                    active={activeItem === 'Product Information'}
                    onClick={this.handleItemClick}
                  />
                  <Menu.Item
                    name='Reviews'
                    active={activeItem === 'Reviews'}
                    onClick={this.handleItemClick}
                  />
                </Menu>
              )}

              {s.activeItem === 'Product Information' && (
                <Segment attached='bottom'>
                  <Grid.Row>
                    <Grid.Column width={16}>
                      <p>{product.description}</p>
                    </Grid.Column>
                  </Grid.Row>
                  <Divider/>
                  {details.map(function (detail) {
                    return <Image fluid src={detail} />
                  })}
                </Segment>
              )}

              {s.activeItem === 'Reviews' && (
                <ProductReview productId={productId} is={'clothing'} />
              )}
            </Container>
          </>
        )}

        <NavMobilePhone />
      </div>
    )
  }
}

export default withRouter(ProductPage)
