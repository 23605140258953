import React from 'react'
import IntroPageButton from './IntroSecButton'
import { Parallax } from 'react-parallax'

// import Slick from './IntroSecSlick'
import { Grid, Header } from 'semantic-ui-react'

const userLang = navigator.language || navigator.userLanguage

class IntroSec extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      grey: '',
      white: '',
      blackdress: ''
    }
  }

  componentDidMount () {
 
  }

  render () {
    return (
      <div>
        <Parallax
          bgImage={
            'https://res.cloudinary.com/sheyou/image/upload/v1653724876/glamorous_african_woman_in_bright_clothes_posing_o_2022_02_03_10_40_25_utc_54cada45a8.jpg'
          }
          bgImageAlt='home page image'
          strength={200}
        >
       
            <Grid centered>
              <Grid.Row columns={3} style={{ paddingTop: 140 }}>
                <Grid.Column width={3}></Grid.Column>
                <Grid.Column width={10}>
                  {userLang === 'zh-CN' && (
                    <>
                      <Header as='h3' style={{ color: 'white' }}>
                        什么是共享衣橱？
                      </Header>
                      <Header style={{ color: 'white', fontSize: 50 }}>
                        SheYou为您带来一种全新的穿搭体验
                      </Header>

                      <Header as='h3' style={{ color: 'white' }}>
                        租借多件时装并随时归还 或是尝试以低价购入，在这里
                        以更低的价格拥有更多变的衣橱
                      </Header>
                    </>
                  )}

                  {userLang !== 'zh-CN' && (
                    <>
                      <Header as='h3' style={{ color: 'white' }}>
                        What is Clothes Sharing?
                      </Header>
                      <Header style={{ color: 'white', fontSize: 50 }}>
                        It is a new way of dressing yourself.
                      </Header>

                      <Header as='h3' style={{ color: 'white' }}>
                        Renting multiple clothes and return whenever you want,
                        or try purchasing them with a discount, you can have
                        more styles with less budget
                      </Header>
                    </>
                  )}
                </Grid.Column>
                <Grid.Column width={3}></Grid.Column>
              </Grid.Row>
              <Grid.Row style={{ marginTop: 60 }}>
                <IntroPageButton />
              </Grid.Row>
            </Grid>
      

          <div style={{ height: '200px' }} />
        </Parallax>
      </div>
    )
  }
}

export default IntroSec

// <Grid stackable
// style={{ backgroundColor: '#F5F5F5', paddingBottom: 50 }}
// >

// <Grid.Row columns={3} style={{ paddingBottom: 40 }}>
//   <Grid.Column width={1}></Grid.Column>

//   <Grid.Column width={6} >

//     <Grid centered >
//       <Grid.Row style={{ marginTop: 140 }}>
//         <IntroPageText />
//       </Grid.Row>
//       <Grid.Row style={{ marginTop: 60 }}>
//         <IntroPageButton />
//       </Grid.Row>

//     </Grid>

//   </Grid.Column >

//   <Grid.Column width={9} >
//     <Grid columns={2}>
//       <Grid.Column width={8}>
//         <br />
//         <br />
//         <br />
//         <Image style={{ height: 450, marginLeft: 50 }} src={"https://s2.loli.net/2021/12/27/lDZSCpeVbNYGXx5.png"} />
//         {/* https://sheyou-backend.herokuapp.com/ + 'blackdress.png' */}
//       </Grid.Column>
//       <Grid.Column width={8}>
//         <br />
//         <br />
//         <Grid>
//           <Grid.Row>

//             <Image style={{ height: 250 }} src={"https://s2.loli.net/2021/12/27/pe8mdMqkbwzXAYi.png"} />

//           </Grid.Row>
//           <Grid.Row>
//             <Image style={{ height: 250 }} src={"https://s2.loli.net/2021/12/27/oKFX2s3JArag1NP.png"} />

//           </Grid.Row>
//         </Grid>
//       </Grid.Column>
//     </Grid>

//   </Grid.Column>

// </Grid.Row>
// <Grid.Row columns={1}>
//   <Grid.Column width={16} >
//     <Segment style={{ marginLeft: 120, marginRight: 120, paddingBottom: 30 }}>
//       <Slick style={{ backgroundColor: 'white' }} />
//     </Segment>
//   </Grid.Column>
// </Grid.Row>

// </Grid>
