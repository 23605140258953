import React from 'react'
import PastPastOrders from './PastPastOrders'

class PastPastOrderList extends React.Component {



    render() {
        const p = this.props
        const orders = p.orders

        const activeItem=p.activeItem

        return (orders.map(function(it) {
            if(it.canceled!==true){
                if(it.orders.data.rentOrBuy==="rent"&&it.returned===true)
                return <PastPastOrders data={it} activeItem={activeItem} orderid={it.id} delivered={it.delivered}/>
                if(it.orders.data.rentOrBuy==="buy"&&it.delivered===true)
                return <PastPastOrders data={it} activeItem={activeItem} orderid={it.id} delivered={it.delivered}/>
                
            }
            
        }))
        
    }
}


PastPastOrderList.defaultProps = {
    orders: []
}


export default PastPastOrderList