import React from 'react'
import { Menu } from 'semantic-ui-react'

import { withRouter } from 'react-router-dom'
const userLang = navigator.language || navigator.userLanguage



class SizeFilter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
    this.handleItemClick = this.handleItemClick.bind(this)
  }


  componentDidMount() {
    const self = this
    const activeItem = this.props.activeItem
    if (activeItem !== undefined || activeItem !== null) { self.setState({ activeItem: activeItem }) }
  }

  handleItemClick(event, data) {
    const name = data.name
    const link = data.link
    // const self = this
    window.location.assign('https://www.sheyoufashion.com/#'+link)

    if (name === undefined || name === null) {
      this.props.history.push('/clothing')
      window.location.reload(true)
    }
    else {
      this.props.history.push(link)
      window.location.reload(true)
    }
  }

  render() {
    const { activeItem } = this.state

    return (
      <>
        {userLang === 'zh-CN' && (
          <Menu fluid vertical >
            <Menu.Item header>尺码</Menu.Item>
            <Menu.Item
              link='/clothing/average'
              name='均码'
              active={activeItem === 'Average'}
              onClick={this.handleItemClick}
            /><Menu.Item
              link='/clothing/m'
              name='M'
              active={activeItem === 'M'}
              onClick={this.handleItemClick}
            />
            <Menu.Item
              link='/clothing/s'
              name='S'
              active={activeItem === 'S'}
              onClick={this.handleItemClick}
            />
            <Menu.Item
              link='/clothing/l'
              name='L'
              active={activeItem === 'L'}
              onClick={this.handleItemClick}
            />
            <Menu.Item
              link='/clothing/xxsxs'
              name='XXS/XS'
              active={activeItem === 'XXS/XS'}
              onClick={this.handleItemClick}
            />
    
            <Menu.Item
              link='/clothing/xxlxl'
              name='XXL/XL'
              active={activeItem === 'XXL/XL'}
              onClick={this.handleItemClick}
            />
          </Menu>
        )}
        {userLang !== 'zh-CN' && (
          <Menu fluid vertical >

            <Menu.Item header>Sizes</Menu.Item>
            <Menu.Item
              link='/clothing/average'
              name='Average'
              active={activeItem === 'Average'}
              onClick={this.handleItemClick}
            /><Menu.Item
              link='/clothing/m'
              name='M'
              active={activeItem === 'M'}
              onClick={this.handleItemClick}
            />
            <Menu.Item
              link='/clothing/s'
              name='S'
              active={activeItem === 'S'}
              onClick={this.handleItemClick}
            />
            <Menu.Item
              link='/clothing/l'
              name='L'
              active={activeItem === 'L'}
              onClick={this.handleItemClick}
            />
            <Menu.Item
              link='/clothing/xxsxs'
              name='XXS/XS'
              active={activeItem === 'XXS/XS'}
              onClick={this.handleItemClick}
            />
    
            <Menu.Item
              link='/clothing/xxlxl'
              name='XXL/XL'
              active={activeItem === 'XXL/XL'}
              onClick={this.handleItemClick}
            />
          </Menu>
        )}
      </>

    )
  }
}

export default withRouter(SizeFilter)