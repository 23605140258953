import React from 'react'
import { Card, Image, Placeholder, Label} from 'semantic-ui-react'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
const userLang = navigator.language || navigator.userLanguage



class CardTemplete extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userInfo: [],
      userID: [],
      heartList: [],
      membership: [],
      color: '',
      identifier: '',
      token: '',
      log: '',
      loading: true

    }
    this.onHandleClick = this.onHandleClick.bind(this)
    this.ifHearted = this.ifHearted.bind(this)
    this.heartTag = this.heartTag.bind(this)
    this.getUserInfo = this.getUserInfo.bind(this)

  }


  componentDidMount() {

    const self = this
    const log = localStorage.getItem('login')
    self.setState({ log: log })
    if (log === 'true') {

      self.getUserInfo()
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 5000);
  }

  getUserInfo() {
    const self = this
    const identifier = localStorage.getItem('identifier')
    const token = localStorage.getItem('token')


    self.setState({
      identifier: identifier,
      token: token
    })

    axios('https://sheyou-backend.herokuapp.com/users/me', {
      method: 'get',
      headers: {
        'Authorization': 'Bearer ' + token,
      }
    })
      .then(function (res) {
        if (res.status === 200) {
          const userInfo = [res.data]
          if (userInfo[0].heart === null) {
            userInfo[0].heart = []
          }
          self.setState({
            userInfo: userInfo,
            userID: userInfo[0].id,
            heartList: userInfo[0].heart,
            membership: userInfo[0].membership,
            loading: false
          })


        }
        else {
          alert('Something went wrong, maybe log in again :(')
          localStorage.setItem('login', 'false')
        }


      })

  }

  ifHearted(productt) {

    const self = this
    const productId = productt.id
    var heartList = self.state.heartList

    if (heartList === null) {
      heartList = []
    }

    const heart = heartList.filter(function (heart) {

      return heart.type!=='sale'&&heart.id === productId

    })

    if (heart.length !== 0) {
      return 'yellow'
    } else { return '' }

  }


  onHandleClick(event, data) {
    const self = this
    // const productId = data.id
    const productId = event.target.id
    event.preventDefault()
    // localStorage.setItem('productId', productId)
    self.props.history.push('/product?id='+productId)

  }

  heartTag(hearted, productId) {
    const self = this
    var heartList = self.state.heartList
    if (heartList === null) {
      heartList = []
    }
    var heartData = []
    const token = self.state.token
    const userID = self.state.userID

    if (hearted === 'yellow') {
      const heartLen = heartList.length
      for (var i = 0; i < heartLen; i++) {
        if (heartList[i].id !== productId||heartList[i].type==='sale')
         { heartData.push(heartList[i]) }
      }

      console.log(heartData)
      axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
        method: 'put',
        data: {
          heart: heartData
        },
        headers: {
          'Authorization': 'Bearer ' + token,
        }
      }).then(function (res) {
        console.log(res)
        if (res.status === 200) {
          self.getUserInfo()

        }
        else {
          alert('something went wrong, please log in again :(')
          localStorage.setItem('login', false)
          // self.setState({
          //   addHeartSuccess:'somethingWrong'
          // })                          
        }

      })
    }
    if (hearted === '') {
      heartData = heartList
      heartData.push({ id: productId })
      axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
        method: 'put',
        data: {
          heart: heartData
        },
        headers: {
          'Authorization': 'Bearer ' + token,
        }
      }).then(function (res) {
        console.log(res)
        if (res.status === 200) {
          // self.componentDidMount()
          self.getUserInfo()

        }
        else {
          alert('something went wrong, please log in again :(')
          localStorage.setItem('login', false)
          // self.setState({
          //   addHeartSuccess:'somethingWrong'
          // })                          
        }

      })
    }
  }



  render() {
    const p = this.props
    const self = this
    const products = p.products
    const loading = self.state.loading
    const log = self.state.log
    // const color=self.state.color
    // const heartList = self.state.heartList


    return (
      <Card.Group centered >
                                                  
        {products.map(function (product) {
          const heart = self.ifHearted(product)
          return <>{product.stock!=='0'&&<Card id={product.id} >
            {loading ? (
              <>
                <Placeholder>
                  <Placeholder.Image square />

                </Placeholder>

                <Card.Content>
                  <Placeholder>
                    <Placeholder.Paragraph>
                      <Placeholder.Line length='very short' />
                      <Placeholder.Line length='medium' />
                      <Placeholder.Line length='short' />
                    </Placeholder.Paragraph>

                  </Placeholder>
                </Card.Content>
              </>

            ) : (<>
              {log === 'true' &&
                <Label

                  as='a'
                  color={heart}
                  corner='right'
                  icon='heart'
                  onClick={() => self.heartTag(heart, product.id)}


                />}
              <Image
                id={product.id}
                onClick={self.onHandleClick}
                style={{height:300}}
                // label={{
                //   as: 'a',
                //   color: heart,
                //   corner: 'right',
                //   icon: 'heart',

                //   }
                // }
                src={product.picture[0].url}
              />

              <Card.Content id={product.id} onClick={self.onHandleClick}>


                <Card.Header id={product.id}>
                  {product.name}
                </Card.Header>

              {
                product.description!==null&&
                <Card.Meta id={product.id}>{product.description.substring(0,20)+"..."}</Card.Meta>
              }




                {userLang === 'zh-CN' && (
                  <Card.Description id={product.id}>
                    {product.price + 'RMB/ 会员免费'}
                  </Card.Description>
                )}

                {userLang !== 'zh-CN' && (
                  <Card.Description id={product.id}>
                    {product.price + 'RMB/ Free for Member'}
                  </Card.Description>
                )}

              </Card.Content> </>)}
          </Card>
            }</>

        })}
      </Card.Group>
    )
  }
}

export default withRouter(CardTemplete)