import React from 'react'
import axios from 'axios'
import { Grid, Header, Icon, Label, Segment,  Pagination, Menu, Sidebar} from 'semantic-ui-react'
// import MainFilter from './AccessoryFilter'
import { withRouter } from 'react-router-dom'
// import Nav from '../nav/Nav'
import AccessoryCardPhone from './AccessoryCardPhone'
// import Foot from '../foot/Foot'
import { Parallax } from 'react-parallax';
import round from '@stdlib/math-base-special-round'
import {Link} from 'react-router-dom'

// import timestamp from 'time-stamp'
import NavMobilePhone from '../nav/NavMobilePhone'
import LogInPagePhonePop from '../mypage/LogInPagePhonePop'

class AccessoryPagePhone extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      products: [],
      loading: true,
      activePage: 1,
      pageNum: 0,
      categoriesSelected: 'Select',
      categoriesVisible: '',
      activeItemPath: 'Accessory', //这个地方是关于top menu的灰色选择显示
      activeItem: 'All Accessories',
      cart: [],
      bag: [],
      accessoryCart: [],
      accessoryBag: [],
      signinVisible: ''
      // productId
    }
    this.handlePaginationChange = this.handlePaginationChange.bind(this)
    this.categoriesVisible = this.categoriesVisible.bind(this)
    this.signinVisible = this.signinVisible.bind(this)
    this.handleItemClickCart = this.handleItemClickCart.bind(this)
    this.handleItemClick = this.handleItemClick.bind(this)
  }
  componentDidMount() {
    const self = this
    const p = this.props
    var page = p.location.search.substring(3)
    console.log(page === '')
    if (page === null || page === undefined || page === '') {
      page = 1
    }
    const activeItem = p.activeItem

    self.setState({
      activeItem: activeItem,
    })
    if (page !== undefined && page !== null) {
      self.setState({
        activePage: page
      })
      this.getProducts()
    }

    window.scrollTo(0, 0)

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }

    }, 2000);
    // this.getProducts()

  }
  handlePaginationChange(e, { activePage }) {
    this.setState({ activePage })
    const thisPath = this.props.history.location.pathname
    this.props.history.push(thisPath + '?p=' + activePage)
    window.location.reload(true)
  }

  getProducts() {
    const self = this

    const p = this.props
    var activeItem = p.activeItem
    self.setState({
      activeItem:activeItem
    })

    if (activeItem === undefined) {
      activeItem = "All Accessories"
    }

    if (activeItem !== 'All Accessories') {
      axios.get('https://sheyou-backend.herokuapp.com/accessory-categories?name=' + activeItem)
        .then(function (res) {
          if (res.status === 200) {
            console.log(res)
            const data = res.data[0].accessories
            const itemNum = data.length
            var activePage = self.state.activePage
            activePage = parseInt(activePage)
            // self.setState({
            //   products: data,
            //   loading: false
            // })

            var dataShow = []
            for (var i = activePage * 8 - 8; i < activePage * 8; i++) {
              if (i < itemNum) {
                dataShow.push(data[i])
              }
            }

            var roundPageNum = 0
            var pageNum = 0
            roundPageNum = round(itemNum / 8)
            pageNum = itemNum / 8.0

            if (pageNum - roundPageNum > 0) {
              pageNum = roundPageNum + 1
            }

            console.log(dataShow)
            self.setState({
              products: dataShow,
              pageNum: pageNum,
              loading: false,
              itemNum: itemNum
            })
          }
          else {
            alert('something went wrong :(')
            localStorage.setItem('login', false)
          }

        })
    } else {
      axios.get('https://sheyou-backend.herokuapp.com/accessaries')
        .then(function (res) {
          if (res.status === 200) {
            console.log(res)
            const data = res.data

            const itemNum = data.length
            var activePage = self.state.activePage
            activePage = parseInt(activePage)


            var dataShow = []
            for (var i = activePage * 8 - 8; i < activePage * 8; i++) {
              if (i < itemNum) {
                dataShow.push(data[i])
              }
            }

            var roundPageNum = 0
            var pageNum = 0
            roundPageNum = round(itemNum / 8)
            pageNum = itemNum / 8.0

            if (pageNum - roundPageNum > 0) {
              pageNum = roundPageNum + 1
            }

            self.setState({
              products: dataShow,
              pageNum: pageNum,
              loading: false,
              itemNum: itemNum
            })
          }
          else {
            alert('something went wrong :(')
            localStorage.setItem('login', false)
          }

        })
    }

  }

  categoriesVisible(){
    const categoriesVisible = this.state.categoriesVisible
    const self=this
    console.log(categoriesVisible)
    if(categoriesVisible !== true){
        self.setState({
            categoriesVisible: true
        })
    }else if(categoriesVisible === true){
        self.setState({
            categoriesVisible: false
        })
    }
  }

  signinVisible(){
      const signinVisible = this.state.signinVisible
      console.log(signinVisible)

      if(signinVisible !== true){
          this.setState({
              signinVisible: true
          })
      }else if (signinVisible === true){
        this.setState({
          signinVisible: false
        })
      }
  }

  handleItemClickCart(event, data){
    // var name = data.name
    // const link = data.link
    const self = this
    const log = localStorage.getItem('login')
    if(log !== 'true'){
      self.signinVisible()
    } else{
      self.props.history.push('/cart')
    }
  }

  handleItemClick(event, data){
    // var name = data.name
    const link = data.link
    const self = this
    window.location.assign('https://www.sheyoufashion.com/#' + link)
    if(link === undefined || link === null){
      self.props.history.push('/accessory')
      window.location.reload(true)
    }else{
      self.props.history.push(link)
      window.location.reload(true)
    }
  }
  
  // onHandleClick(event,data){
  //   const self=this
  //   const productId= data.id
  //   event.preventDefault()
  //   localStorage.setItem('productId',productId)
  //   self.props.history.push('/product')

  // }

  render() {
    const s = this.state
    const self = this
    const loading = s.loading
    // const n = {position: 'absolute', top: 1000}
    console.log(s.activeItem)

    const cartNum = s.cart.length + s.accessoryCart.length
    // const bagNum = s.bag.length + s.accessoryBag.length

    return <div>
      {/* <Nav /> */}


      {loading ? (
        <Grid >
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>


          <Grid.Row colums={2}>
            <Grid.Column width={7}></Grid.Column>
            <Grid.Column width={6}>
              <Header size='huge'>
                <Icon loading name='asterisk' size='massive' color='yellow' />
                Loading...
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
        </Grid>
      ) : (
        <Grid>
          <Grid.Row
            centered
            style={{paddingBottom: 0, marginBottom: 0, marginTop: 10}}
          >
            <Menu borderless secondary>
              <Menu.Item
                as={Link}
                link='/clothing'
                name = 'Clothing'
                active={s.activeItemPath === 'Clothing'}
                onClick={this.handleItemClick}
              />
              <Menu.Item
                as={Link}
                link='/accessory'
                name='Accessory'
                active={s.activeItemPath === 'Accessory'}
                onClick={this.handleItemClick}
              />
              <Menu.Item
                as={Link}
                link='/sale'
                name='Sale'
                active={s.activeItemPath === 'Sale'}
                onClick={this.handleItemClick}
              />
              <Menu.Item
                name='Cart'
                linkto='/cart'
                active={s.activeItemPath === 'Cart'}
                onClick={this.handleItemClickCart}
              >
                <Icon name='shopping cart' color='teal'>
                  <Label floating circular>
                    {cartNum}
                  </Label>
                </Icon>

              </Menu.Item>
            </Menu>
          </Grid.Row>
          <Grid.Row
            centered
            columns={3}
            style={{
              paddingBottom: 0,
              marginBottom: 0,
              paddingTop: 0,
              marginTop: 5
            }}
          >
            <Grid.Column width={1}></Grid.Column>
            <Grid.Column width={14}>
              <Segment style={{height: 70}}>
                <Grid divided style={{ height: 70 }}>
                  <Grid.Row colomns = {2}>
                      <Grid.Column width = {5}></Grid.Column>
                      <Grid.Column width = {6}>
                        <Grid style = {{height: 70}}>
                          <Grid.Row
                            style = {{paddingBottom: 0, marginBottom: 0}}
                          >
                            <Header as = 'h6' style = {{paddingLeft: 10}}>
                              Categories
                            </Header>
                          </Grid.Row>
                          <Grid.Row>
                            {
                              this.state.activeItem === 'All Accessories'&&<Header
                                as = 'h6'
                                color = 'orange'
                                style = {{ paddingLeft: 10}}
                                onClick = {self.categoriesVisible}
                              >
                               <Icon corner name ='list' /> 
                               
                               Select
                              </Header>
                            }
                            {
                              this.state.activeItem !== 'All Accessories'&&<Header
                                as = 'h6'
                                color = 'orange'
                                style = {{paddingLeft: 10}}
                                onClick = {self.categoriesVisible}
                              >
                                <Icon corner name = 'list' />

                                {this.state.activeItem}
                              </Header>
                            }

                            <Sidebar
                              as = {Menu}
                              animation = 'overlay'
                              vertical
                              visible = {this.state.categoriesVisible === true}
                              width = 'thin'
                              direction = 'bottom'
                            >
                              <Menu.Item
                                onClick = {this.handleItemClick}
                                link = '/accessory'
                              >
                                All Accessories
                              </Menu.Item>
                              <Menu.Item
                                onClick = {this.handleItemClick}
                                link = '/accessory/jewelry'
                              >
                                Jewelry
                              </Menu.Item>
                              <Menu.Item
                                onClick = {this.handleItemClick}
                                link = '/accessory/handbags'
                              >
                                Bags
                              </Menu.Item>
                              <Menu.Item
                                onClick = {this.handleItemClick}
                                link = '/accessory/scarves'
                              >
                                Scarves
                              </Menu.Item>
                              <Menu.Item
                                onClick = {this.handleItemClick}
                                link = '/accessory/hats'
                              >
                                Hats
                              </Menu.Item>
                              <Menu.Item
                                onClick = {this.handleItemClick}
                                link = '/accessory/sunglasses'
                              >
                                Sunglasses
                              </Menu.Item>
                            </Sidebar>

                          </Grid.Row>
                        </Grid>
                      </Grid.Column>
                      <Grid.Column width={1}></Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Grid.Column>
            <Grid.Column width = {1}></Grid.Column>
          </Grid.Row>

          <Grid.Row>
            {s.products.length === 0 && (
              <Grid.Column wdith = {16} style = {{paddingRight: 50}}>
                <Parallax
                  bgImage = 'https://i.loli.net/2020/11/25/ZHjYm1wL9runqOz.png'
                  bgImageAlt = ''
                  strength = {200}
                >
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />

                  <div>
                    <Segment>
                      <Grid>
                        <Grid.Row centered>
                          <Header>
                            <Icon
                              loading
                              name = 'asterisk'
                              size = 'massive'
                              color = 'yellow'
                            />
                            Loading ...
                          </Header>
                        </Grid.Row>
                      </Grid>
                    </Segment>
                  </div>

                  <div style = {{ height: '270px'}}/>
                </Parallax>
              </Grid.Column>
            )} 
            {/* this part will load when product infor is not fetched*/}
            <Grid.Column width ={16}>
              <AccessoryCardPhone products = {s.products}/>
              <Grid>
                <Grid.Row centered>
                  <Pagination
                    onPageChange = {this.handlePaginationChange}
                    activePage = {self.state.activePage}
                    pointing
                    secondary
                    ellipsisItem = {false}
                    totalPages = {this.state.pageNum}
                  />
                </Grid.Row>
              </Grid>

            </Grid.Column>
          </Grid.Row>

          <Sidebar
            as = {Menu}
            animation = 'overlay'
            vertical
            visible = {this.state.signinVisible === true}

            direction = 'bottom'
          >
            <Segment
              style = {{width: '100%', paddingBottom: 7, paddingTop: 7}}
            >
              <Grid>
                <Grid.Row columns = {4}>
                  <Grid.Column width = {1}>
                    <Icon
                      name = 'close'
                      size = 'small'
                      onClick = {self.signinVisible}
                    />
                  </Grid.Column>
                  <Grid.Column width = {5}></Grid.Column>
                  <Grid.Column width = {4}>
                    <Header
                      as = 'h4'
                      color = 'teal'
                      textAlign = 'centered'
                      style = {{
                        paddingBottom: 0,
                        marginBottom: 0,
                        fontWeight: 500
                      }}
                    >
                      Sign In
                    </Header>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
            <LogInPagePhonePop />

          </Sidebar>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>

        </Grid>
      )}

      <NavMobilePhone/>
    </div>


  }

}

export default withRouter(AccessoryPagePhone)

