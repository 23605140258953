import React from 'react'
import AboutPage from '../component/aboutPage/AboutPagePhone'
import { HashRouter as Switch, Route } from 'react-router-dom'
import SignupPage from '../component/RedirectPages/SignupPagePhone'
// import MemberBox from '../component/shoppingpages/MemberBox'
import ProductPage from '../component/clothingPage/ProductPagePhone'
import CheckOut from '../component/shoppingpages/CheckOutPage'
import WhatIsClothesSharing from '../component/aboutPage/WhatIsClothesSharing'
// import Payment from '../component/shoppingpages/Payment'
import Confirm from '../component/shoppingpages/Confirm'
import RentingConfirm from '../component/shoppingpages/RentingConfirm'
import CheckOutRentingPage from '../component/shoppingpages/CheckOutRentingPage'
import Comment from '../component/pastOrders/Comment'
import Delivery from '../component/delivery/DeliveryPage'
import ForgetPassword from '../component/RedirectPages/ForgetPasswordPhone'
import ResetPassword from '../component/RedirectPages/ResetPasswordPhone'
import CartPage from '../component/shoppingpages/CartPage'
import SaleProduct from '../component/SalePage/SaleProduct'
import AccessoryItem from '../component/accessoryPage/AccessoryItem'
import SignUpSuccess from '../component/RedirectPages/SignUpSuccessPhone'
import ChangeEmail from '../component/changeEmail/ChangeEmail'
import VerifyEmail from '../component/changeEmail/VerifyEmail'
import ConfirmRent from '../component/shoppingpages/ConfirmRent'
import KeepPurchase from '../component/RedirectPages/KeepPurchase'
import Return from '../component/returnPage/ReturnRenting'
import Plans from '../component/plans/Plans'
import Pay from '../component/plans/Pay'
import DeliveryPageReturn from '../component/delivery/DeliveryPageReturn'
import EmailConfirmation from '../component/RedirectPages/EmailConfirmationPhone'
import Alipay from '../component/payment/Alipay'
import WechatPay from '../component/payment/WechatPay'
import Paypal from '../component/payment/Paypal'
import PhoneEmulator from '../component/PhoneEmulator'
import HomePagePhone from '../component/homePage/HomePagePhone/HomePagePhone.js'
import ClothingPagePhone from '../component/clothingPage/ClothingPagePhone'
import AccessoryPagePhone from '../component/accessoryPage/AccessoryPagePhone'
import SalePagePhone from '../component/SalePage/SalePagePhone'
import LogInPagePhone from '../component/RedirectPages/LogInPagePhone'
import HeartPagePhone from '../component/heartPage/HeartPagePhone'
import MyPagePhone from '../component/mypage/MyPagePhone'
import LoggingPagePhone from '../component/nav/LoggingPagePhone'
import MerchantOpenShop from '../component/merchants/MerchantOpenShopPhone'
import MerchantPage from '../component/merchants/merchantPage/MerchantPagePhone'
import MerchantsLog from '../component/merchants/MerchantsLogPhone'

class PcPadVersion extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true
    }
  }
  componentDidMount () {
  }
  render () {
    return (
      <Switch>
        <Route exact path='/merchantpage'>
          <MerchantPage activeItem='Home' />
        </Route>

        <Route exact path='/merchantsLog'>
          <MerchantsLog />
        </Route>
        
        <Route exact path='/merchantOpenShop'>
          <MerchantOpenShop />
        </Route>

        <Route exact path='/phoneemulator'>
          <PhoneEmulator />
        </Route>

        <Route exact path='/logging'>
          <LoggingPagePhone />
        </Route>

        <Route exact path='/alipay'>
          <Alipay />
        </Route>
        <Route exact path='/paypal'>
          <Paypal />
        </Route>
        <Route exact path='/wechatpay'>
          <WechatPay />
        </Route>

        <Route exact path='/pay'>
          <Pay />
        </Route>
        <Route exact path='/deliveryreturn'>
          <DeliveryPageReturn />
        </Route>
        <Route exact path='/mypage/membership'>
          <MyPagePhone activeItem='Membership' />
        </Route>
        <Route exact path='/return'>
          <Return />
        </Route>
        <Route exact path='/plans'>
          <Plans />
        </Route>
        <Route exact path='/keeppurchase'>
          <KeepPurchase />
        </Route>
        <Route exact path='/confirmrent'>
          <ConfirmRent />
        </Route>
        <Route exact path='/changeEmail'>
          <ChangeEmail />
        </Route>
        <Route exact path='/verifyEmail'>
          <VerifyEmail />
        </Route>

        <Route exact path='/accessoryItem'>
          <AccessoryItem />
        </Route>

        <Route exact path='/accessory'>
          <AccessoryPagePhone activeItem='All Accessories' />
        </Route>
        <Route exact path='/accessory/jewelry'>
          <AccessoryPagePhone reload={true} activeItem='Jewelry' />
        </Route>
        <Route exact path='/accessory/handbags'>
          <AccessoryPagePhone reload={true} activeItem='Bags' />
        </Route>
        <Route exact path='/accessory/scarves'>
          <AccessoryPagePhone reload={true} activeItem='Scarves' />
        </Route>
        <Route exact path='/accessory/hats'>
          <AccessoryPagePhone reload={true} activeItem='Hats' />
        </Route>
        <Route exact path='/accessory/sunglasses'>
          <AccessoryPagePhone reload={true} activeItem='Sunglasses' />
        </Route>

        <Route exact path='/auth/email-confirmation'>
          <SignUpSuccess />
        </Route>

        <Route exact path='/heart'>
          <HeartPagePhone activeItem='All' is='categories' />
        </Route>

        <Route exact path='/heart/feminine'>
          <HeartPagePhone reload={true} activeItem='Feminine' is='categories' />
        </Route>
        <Route exact path='/heart/masculine'>
          <HeartPagePhone
            reload={true}
            activeItem='Masculine'
            is='categories'
          />
        </Route>
        <Route exact path='/heart/accessaries'>
          <HeartPagePhone
            reload={true}
            activeItem='Accessaries'
            is='categories'
          />
        </Route>
        <Route exact path='/heart/average'>
          <HeartPagePhone reload={true} activeItem='Average' is='sizes' />
        </Route>
        <Route exact path='/heart/m'>
          <HeartPagePhone reload={true} activeItem='M' is='sizes' />
        </Route>
        <Route exact path='/heart/s'>
          <HeartPagePhone reload={true} activeItem='S' is='sizes' />
        </Route>
        <Route exact path='/heart/l'>
          <HeartPagePhone reload={true} activeItem='L' is='sizes' />
        </Route>
        <Route exact path='/heart/xxsxs'>
          <HeartPagePhone reload={true} activeItem='XXS/XS' is='sizes' />
        </Route>
        <Route exact path='/heart/xxlxl'>
          <HeartPagePhone reload={true} activeItem='XXL/XL' is='sizes' />
        </Route>

        <Route exact path='/saleproduct'>
          <SaleProduct />
        </Route>
        <Route exact path='/sale/feminine'>
          <SalePagePhone reload={true} activeItem='Feminine' is='categories' />
        </Route>
        <Route exact path='/sale/masculine'>
          <SalePagePhone reload={true} activeItem='Masculine' is='categories' />
        </Route>
        <Route exact path='/sale/accessaries'>
          <SalePagePhone
            reload={true}
            activeItem='Accessaries'
            is='categories'
          />
        </Route>
        <Route exact path='/sale/average'>
          <SalePagePhone reload={true} activeItem='Average' is='sizes' />
        </Route>
        <Route exact path='/sale/m'>
          <SalePagePhone reload={true} activeItem='M' is='sizes' />
        </Route>
        <Route exact path='/sale/s'>
          <SalePagePhone reload={true} activeItem='S' is='sizes' />
        </Route>
        <Route exact path='/sale/l'>
          <SalePagePhone reload={true} activeItem='L' is='sizes' />
        </Route>
        <Route exact path='/sale/xxsxs'>
          <SalePagePhone reload={true} activeItem='XXS/XS' is='sizes' />
        </Route>
        <Route exact path='/sale/xxlxl'>
          <SalePagePhone reload={true} activeItem='XXL/XL' is='sizes' />
        </Route>

        {/* <Route exact path="/mypage">
  <MyPage />
</Route> */}

        <Route exact path='/user'>
          <MyPagePhone />
        </Route>

        <Route exact path='/'>
          <HomePagePhone />
        </Route>
        <Route exact path='/comment'>
          <Comment />
        </Route>
        <Route exact path='/delivery'>
          <Delivery />
        </Route>

        <Route exact path='/cart'>
          <CartPage />
        </Route>
        <Route exact path='/resetpassword'>
          <ResetPassword />
        </Route>
        <Route exact path='/forgetpassword'>
          <ForgetPassword />
        </Route>
        <Route exact path='/sale'>
          <SalePagePhone activeItem='All' is='categories' />
        </Route>

        <Route exact path='/rentingconfirm'>
          <RentingConfirm />
        </Route>
        <Route exact path='/rentingcheckout'>
          <CheckOutRentingPage />
        </Route>
        <Route exact path='/checkout'>
          <CheckOut />
        </Route>
        {/* <Route exact path='/heart'>
      <Heart activeItem='All' is='categories' />
    </Route> */}
        {/* <Route exact path='/payment'>
      <Payment />
    </Route> */}
        <Route exact path='/confirm'>
          <Confirm />
        </Route>
        <Route exact path='/emailConfirmation'>
          <EmailConfirmation />
        </Route>

        {/* <Route exact path='/memberbox'>
      <MemberBox />
    </Route> */}

        <Route exact path='/product'>
          <ProductPage />
        </Route>
        <Route exact path='/signin'>
          <LogInPagePhone />
        </Route>
        <Route exact path='/signup'>
          <SignupPage />
        </Route>
        <Route exact path='/clothing'>
          <ClothingPagePhone activeItem='All' is='categories' />
        </Route>
        <Route exact path='/about'>
          <AboutPage />
        </Route>
        <Route exact path='/whatisclothessharing'>
          <WhatIsClothesSharing />
        </Route>

        <Route exact path='/clothing/xxsxs'>
          <ClothingPagePhone reload={true} activeItem='XXS/XS' is='sizes' />
        </Route>

        <Route exact path='/clothing/average'>
          <ClothingPagePhone reload={true} activeItem='Average' is='sizes' />
        </Route>

        <Route exact path='/clothing/xxlxl'>
          <ClothingPagePhone reload={true} activeItem='XXL/XL' is='sizes' />
        </Route>

        <Route exact path='/clothing/m'>
          <ClothingPagePhone reload={true} activeItem='M' is='sizes' />
        </Route>

        <Route exact path='/clothing/l'>
          <ClothingPagePhone reload={true} activeItem='L' is='sizes' />
        </Route>

        <Route exact path='/clothing/s'>
          <ClothingPagePhone reload={true} activeItem='S' is='sizes' />
        </Route>

        <Route exact path='/clothing/masculine'>
          <ClothingPagePhone
            reload={true}
            activeItem='Masculine'
            is='categories'
          />
        </Route>

        <Route exact path='/clothing/feminine'>
          <ClothingPagePhone
            reload={true}
            activeItem='Feminine'
            is='categories'
          />
        </Route>
      </Switch>
    )
  }
}

export default PcPadVersion
