import React from 'react'
import {
  Form,
  Grid,
  Header,
  Segment,
  Icon,
  Container
} from 'semantic-ui-react'
// import { Link } from 'react-router-dom';
// import ReactPlayer from 'react-player'
import Foot from '../foot/Foot'
import '../../App.css'
import { withRouter } from 'react-router-dom'
const userLang = navigator.language || navigator.userLanguage


class ContactPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true
    }
  }

  componentDidMount () {
    window.scrollTo(0, 0)

    const self = this

    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 500)
  }

  render () {
    const loading = this.state.loading

    return (
      <div>
        {/* <Nav /> */}

        {loading ? (
          <Grid>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>

            <Grid.Row colums={2}>
              <Grid.Column width={7}></Grid.Column>
              <Grid.Column width={6}>
                <Header size='huge'>
                  <Icon loading name='asterisk' size='massive' color='yellow' />
                  Loading...
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid>
        ) : (
        <>
          {userLang === 'zh-CN' && (
            <div className='bg_image'>
              <br />
              <br />
              <br />
              <br />
              <Segment raised>
                <Grid>
                  <Grid.Row></Grid.Row>
                  <Grid.Row centered style={{ fontSize: 30 }}>
                    <Header>联系我们 </Header>
                  </Grid.Row>
                </Grid>
                <Grid columns={4} divided>
                  <Grid.Column width={3}></Grid.Column>

                  <Grid.Column width={6}>
                    <Icon
                      name='phone'
                      color='teal'
                      size='big'
                      style={{ position: 'relative', top: 20 }}
                    >
                      <Header
                        as='h4'
                        style={{ position: 'relative', left: -6, top: 5 }}
                        color='teal'
                      >
                        手机
                      </Header>
                    </Icon>
                    <Grid style={{ position: 'relative', left: 100, top: -10 }}>
                      <Grid.Row>
                        <Header as='h5' color='grey'>
                          +86 17308079166
                        </Header>
                      </Grid.Row>
                      <Grid.Row>
                        <Header as='h5' color='grey'>
                          +86 18780168713
                        </Header>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>

                  <Grid.Column width={6}>
                    <Icon
                      name='mail'
                      color='teal'
                      size='big'
                      style={{ position: 'relative', top: 20 }}
                    >
                      <Header
                        as='h4'
                        style={{ position: 'relative', left: -3, top: 5 }}
                        color='teal'
                      >
                        邮箱
                      </Header>
                    </Icon>
                    <Grid style={{ position: 'relative', left: 100, top: -10 }}>
                      <Grid.Row>
                        <Header as='h5' color='grey'>
                          1181456762@qq.com
                        </Header>
                      </Grid.Row>
                      <Grid.Row>
                        <Header as='h5' color='grey'>
                          2658494661@qq.com
                        </Header>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid>

                <br />
                <br />
                <br />

                <Container>
                  <Form>
                      <Form.Input
                        fluid
                        label='姓名'
                        placeholder='姓名'
                        width={3}
                      />
                    <Form.Group widths='equal'>
                      <Form.Input
                        fluid
                        label='手机号码'
                        placeholder='手机号码'
                      />
                      <Form.Input fluid label='邮箱' placeholder='邮箱' />
                    </Form.Group>
                    <Form.TextArea
                      label='写下您的留言'
                      placeholder='写下您的留言'
                    />

                    <br />
                    <Grid>
                      <Grid.Row centered>
                        <Form.Button
                          color='orange'
                          centered
                          style={{ paddingLeft: 30, paddingRight: 30 }}
                        >
                          发送您的留言
                        </Form.Button>
                      </Grid.Row>
                    </Grid>
                  </Form>
                </Container>

                <br />
                <br />
                <br />
                <br />
              </Segment>

              <br />
              <br />
              <br />
              <br />
            </div>
          )}
          {userLang !== 'zh-CN' && (
            <div className='bg_image'>
              <br />
              <br />
              <br />
              <br />
              <Segment raised>
                <Grid>
                  <Grid.Row></Grid.Row>
                  <Grid.Row centered style={{ fontSize: 30 }}>
                    <Header>Contact Us </Header>
                  </Grid.Row>
                </Grid>
                <Grid columns={4} divided>
                  <Grid.Column width={3}></Grid.Column>

                  <Grid.Column width={6}>
                    <Icon
                      name='phone'
                      color='teal'
                      size='big'
                      style={{ position: 'relative', top: 20 }}
                    >
                      <Header
                        as='h4'
                        style={{ position: 'relative', left: -6, top: 5 }}
                        color='teal'
                      >
                        PHONE
                      </Header>
                    </Icon>
                    <Grid style={{ position: 'relative', left: 100, top: -10 }}>
                      <Grid.Row>
                        <Header as='h5' color='grey'>
                          +86 17308079166
                        </Header>
                      </Grid.Row>
                      <Grid.Row>
                        <Header as='h5' color='grey'>
                          +86 18780168713
                        </Header>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>

                  <Grid.Column width={6}>
                    <Icon
                      name='mail'
                      color='teal'
                      size='big'
                      style={{ position: 'relative', top: 20 }}
                    >
                      <Header
                        as='h4'
                        style={{ position: 'relative', left: -3, top: 5 }}
                        color='teal'
                      >
                        EMAIL
                      </Header>
                    </Icon>
                    <Grid style={{ position: 'relative', left: 100, top: -10 }}>
                      <Grid.Row>
                        <Header as='h5' color='grey'>
                          1181456762@qq.com
                        </Header>
                      </Grid.Row>
                      <Grid.Row>
                        <Header as='h5' color='grey'>
                          2658494661@qq.com
                        </Header>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid>

                <br />
                <br />
                <br />

                <Container>
                  <Form>
                    <Form.Group widths='equal'>
                      <Form.Input
                        fluid
                        label='First name'
                        placeholder='First name'
                      />
                      <Form.Input
                        fluid
                        label='Last name'
                        placeholder='Last name'
                      />
                    </Form.Group>
                    <Form.Group widths='equal'>
                      <Form.Input
                        fluid
                        label='Phone Number'
                        placeholder='Phone Number'
                      />
                      <Form.Input fluid label='Email' placeholder='Email' />
                    </Form.Group>
                    <Form.TextArea
                      label='Write Your Message'
                      placeholder='Write Your Message'
                    />

                    <br />
                    <Grid>
                      <Grid.Row centered>
                        <Form.Button
                          color='orange'
                          centered
                          style={{ paddingLeft: 30, paddingRight: 30 }}
                        >
                          Send Your Message
                        </Form.Button>
                      </Grid.Row>
                    </Grid>
                  </Form>
                </Container>

                <br />
                <br />
                <br />
                <br />
              </Segment>

              <br />
              <br />
              <br />
              <br />
            </div>
          )}
        </>
        )}
        <Foot />
      </div>
    )
  }
}

export default withRouter(ContactPage)
