import React from 'react'
import { Menu } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
const userLang = navigator.language || navigator.userLanguage



class MainFilter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeItem: 'All',
    }
    this.handleItemClick = this.handleItemClick.bind(this)
  }


  componentDidMount() {
    const self = this
    const activeItem = this.props.activeItem
    if (activeItem !== undefined || activeItem !== null) { self.setState({ activeItem: activeItem }) }
  }

  handleItemClick(event, data) {
    var name = data.name
    const link = data.link
    const self = this
    window.location.assign('https://www.sheyoufashion.com/#'+link)
    if (name === undefined || name === null) {
      self.props.history.push('/clothing')
      window.location.reload(true)
      
    }
    else {
      self.props.history.push(link)
      window.location.reload(true)
    }
  }

  render() {
    const activeItem = this.state.activeItem

    return (
      <>
      {userLang === 'zh-CN' && (
        <Menu fluid vertical  >
          <Menu.Item header>类别</Menu.Item>

          <Menu.Item
            name='全部'
            link='/clothing'
            active={activeItem === 'All'}
            onClick={this.handleItemClick}
          />

          <Menu.Item
            name='女性化'
            link='/clothing/feminine'
            active={activeItem === 'Feminine'}
            onClick={this.handleItemClick}
          />

          <Menu.Item
            name='男性化'
            link='/clothing/masculine'
            active={activeItem === 'Masculine'}
            onClick={this.handleItemClick}
          />

        </Menu>
      )}

      {userLang !== 'zh-CN' && (
        <Menu fluid vertical  >
          <Menu.Item header>Categories</Menu.Item>

          <Menu.Item
            name='All'
            link='/clothing'
            active={activeItem === 'All'}
            onClick={this.handleItemClick}
          />

          <Menu.Item
            name='Feminine'
            link='/clothing/feminine'
            active={activeItem === 'Feminine'}
            onClick={this.handleItemClick}
          />

          <Menu.Item
            name='Masculine'
            link='/clothing/masculine'
            active={activeItem === 'Masculine'}
            onClick={this.handleItemClick}
          />

        </Menu>
      )

      }
      </>

    )
  }
}

export default withRouter(MainFilter)