import React from 'react'
import axios from 'axios'

import { Grid, Divider,Button, Header, Icon} from 'semantic-ui-react'
// import { Parallax, } from 'react-parallax';
// import Nav from '../nav/Nav'
import {
    Link
} from "react-router-dom";
import { withRouter } from 'react-router-dom'
import dateCountDown from 'date-countdown'

class Membership extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            membership: '',
            daysLeft: 0, 
            endDate:''


        }
        this.getUserInfo = this.getUserInfo.bind(this)
        this.countDown = this.countDown.bind(this)

    }

    componentDidMount() {
        const self = this
        window.onload = (event) => {
            self.setState({
                loading: false
            })
        };

        window.setTimeout(() => {
            if (self.state.loading === true) {
                this.setState({
                    loading: false
                })
            }

        }, 700);
        this.getUserInfo()


    }

    getUserInfo() {
        const self = this
        // const token=this.state.token
        const identifier = localStorage.getItem('identifier')
        const token = localStorage.getItem('token')

        self.setState({
            identifier: identifier,
            token: token
        })

        axios('https://sheyou-backend.herokuapp.com/users/me', {
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then(function (res) {
                if (res.status === 200) {
                    const userInfo = [res.data]
                    if (userInfo === null) {
                        self.setState({
                            userInfo: [],
                            userID: userInfo[0].id,

                        })
                    }
                    console.log(userInfo)
                    self.setState({
                        membership: userInfo[0].membership,
                        loading: false,
                        endDate:userInfo[0].membership.endDate
                    })
                    self.countDown()
                }
                else {
                    console.log('something went wrong: ' + res)
                }

            }).catch(function (err) {
                console.log('something went wrong: ' + err)
            })
    }

    countDown() {
        const endDate = this.state.endDate
        const self=this
        var userInfo = localStorage.getItem('userInfo')
        userInfo = JSON.parse(userInfo)
        const token = localStorage.getItem('token')
        const id = userInfo[0].id
        const membership= {
            isAMember: false,
            clothingItemMaxNum: 3,
            accessoryMaxNum: 0
          }
        if (endDate !== undefined && endDate !== null) {
            const year = parseInt(endDate.substring(0, 4))
            const month = parseInt(endDate.substring(5, 7))
            const day = parseInt(endDate.substring(8, 10))
            const daysLeft = dateCountDown(year, month, day)
          
            if (daysLeft <= 0) {
                axios('https://sheyou-backend.herokuapp.com/users/' + id, {
                    method: 'put',
                    data: {
                        membership: membership
                    },
                    headers: {
                        'Authorization': 'Bearer ' + token,
                    }
                }).then(function (res) {
                    if (res.status === 200) {
                        console.log(res)
                        self.props.history.push("/mypage/membership")
                    } else {
                        alert('something went wrong :' + res)
                    }
                })
                    .catch(function (err) {
                        alert('something went wrong: ' + err)
                    })
            }
            this.setState({
                daysLeft: daysLeft
            })
        }



    }


    render() {

        const self = this
        const s = self.state
        console.log(s.daysLeft === 0)
        // const s.membership.endDate = s.membership.endDate
        // const isMember = s.membership.isMember


        return <>{this.state.loading ? (
            <Grid >
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>


                <Grid.Row colums={2}>
                    <Grid.Column width={7}></Grid.Column>
                    <Grid.Column width={6}>
                        <Header size='huge'>
                            <Icon loading name='asterisk' size='massive' color='yellow' />
                            Loading...
                        </Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
            </Grid>
        ) : (<Grid container>

            <Grid.Row>
            </Grid.Row>

            <Grid.Row>
                <Header>Membership</Header>
            </Grid.Row>

            <Divider />

            {
                s.daysLeft > 0 &&
                <Grid.Row columns={5} divided>
                    <Grid.Column width={2}>
                    </Grid.Column>
                    <Grid.Column width={7}>
                        <Header as='h4'>{'End Date:  '} </Header>
                        {s.membership.endDate.substring(5, 7) === '01' &&
                            <Header color='orange' as='h4'>
                                {'January ' + s.membership.endDate.substring(8, 10) + ", " + s.membership.endDate.substring(0, 4)}
                            </Header>
                        }
                        {s.membership.endDate.substring(5, 7) === '02' &&
                            <Header color='orange' as='h4'>
                                {'February ' + s.membership.endDate.substring(8, 10) + ", " + s.membership.endDate.substring(0, 4)}
                            </Header>
                        }
                        {s.membership.endDate.substring(5, 7) === '03' &&
                            <Header color='orange' as='h4'>
                                {'March ' + s.membership.endDate.substring(8, 10) + ", " + s.membership.endDate.substring(0, 4)}
                            </Header>
                        }
                        {s.membership.endDate.substring(5, 7) === '04' &&
                            <Header color='orange' as='h4'>
                                {'April ' + s.membership.endDate.substring(8, 10) + ", " + s.membership.endDate.substring(0, 4)}
                            </Header>
                        }
                        {s.membership.endDate.substring(5, 7) === '05' &&
                            <Header color='orange' as='h4'>
                                {'May ' + s.membership.endDate.substring(8, 10) + ", " + s.membership.endDate.substring}(0,4)
                            </Header>
                        }
                        {s.membership.endDate.substring(5, 7) === '06' &&
                            <Header color='orange' as='h4'>
                                {'June ' + s.membership.endDate.substring(8, 10) + ", " + s.membership.endDate.substring}(0,4)
                            </Header>
                        }
                        {s.membership.endDate.substring(5, 7) === '07' &&
                            <Header color='orange' as='h4'>
                                {'July ' + s.membership.endDate.substring(8, 10) + ", " + s.membership.endDate.substring}(0,4)
                            </Header>
                        }
                        {s.membership.endDate.substring(5, 7) === '08' &&
                            <Header color='orange' as='h4'>
                                {'August ' + s.membership.endDate.substring(8, 10) + ", " + s.membership.endDate.substring(0, 4)}
                            </Header>
                        }
                        {s.membership.endDate.substring(5, 7) === '09' &&
                            <Header color='orange' as='h4'>
                                {'September ' + s.membership.endDate.substring(8, 10) + ", " + s.membership.endDate.substring(0, 4)}
                            </Header>
                        }
                        {s.membership.endDate.substring(5, 7) === '10' &&
                            <Header color='orange' as='h4'>
                                {'October ' + s.membership.endDate.substring(8, 10) + ", " + s.membership.endDate.substring(0, 4)}
                            </Header>
                        }
                        {s.membership.endDate.substring(5, 7) === '11' &&
                            <Header color='orange' as='h4'>
                                {'November ' + s.membership.endDate.substring(8, 10) + ", " + s.membership.endDate.substring(0, 4)}
                            </Header>
                        }
                        {s.membership.endDate.substring(5, 7) === '12' &&
                            <Header color='orange' as='h4'>
                                {'December ' + s.membership.endDate.substring(8, 10) + ", " + s.membership.endDate.substring(0, 4)}
                            </Header>
                        }


                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Header as='h4' >Days Left: </Header>
                        <Header color='orange' as='h4'>
                            {s.daysLeft}
                        </Header>
                    </Grid.Column>
                </Grid.Row>
            }

            {
                (s.daysLeft === 0 || s.daysLeft < 0) &&s.membership.isAMember === true&&
                <>
                    <Grid.Row centered>
                        <Header color='orange'>
                            Sorry, You are not a member anymore
                        </Header>
                    </Grid.Row>
                    <Grid.Row centered>
                        <Header color='orange'>
                            Do you want to renew your membership?
                        </Header>
                    </Grid.Row>
                    <Grid.Row centered>
                        <Link to='/plans'>
                            <Button size='large' style={{ paddingLeft: 50, paddingRight: 50 }}> Yes</Button>
                        </Link>
                    </Grid.Row>
                </>
            }



            {
                s.daysLeft > 0 &&s.membership.isAMember === true && <>
                    <Grid.Row>
                        <Header> Your Plan</Header>
                    </Grid.Row>

                    <Divider />

                    <Grid.Row columns={5} divided>
                        <Grid.Column width={2}>
                        </Grid.Column>
                        <Grid.Column width={7}>
                            <Header as='h4'>
                                {s.membership.plan === 1 &&
                                    '6 Items / month'}
                                {s.membership.plan === 2 &&
                                    '12 Items / month'}
                                {s.membership.plan === 3 &&
                                    '20 Items / month'}
                                {s.membership.plan === 4 &&
                                    '40 Items / month'}

                            </Header>
                            <Header color='orange' as='h4'>
                                {s.membership.plan === 1 &&
                                    '3 clthing / shipment'
                                }
                                {s.membership.plan === 2 &&
                                    '3 clthing / shipment'
                                }
                                {s.membership.plan === 3 &&
                                    '6 clthing + 4 accessory / shipment'
                                }
                                {s.membership.plan === 4 &&
                                    '6 clthing + 4 accessory / shipment'
                                }
                            </Header>

                        </Grid.Column>
                        <Grid.Column width={5}>
                            <Header as='h4' >Boxes: </Header>
                            <Header color='orange' as='h4'>
                                {s.membership.plan === 1 &&
                                    '2 shipment / month'
                                }
                                {s.membership.plan === 2 &&
                                    '4 shipment / month'
                                }
                                {s.membership.plan === 3 &&
                                    '2 shipment / month'
                                }
                                {s.membership.plan === 4 &&
                                    '4 shipment / month'
                                }
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                </>
            }

            {s.membership.isAMember !== true && <>
                <Grid.Row centered>
                    <Header color='orange'>
                        Sorry, You are not a member
                    </Header>
                </Grid.Row>
                <Grid.Row centered>
                    <Header color='orange'>
                        Do you want to be a member?
                    </Header>
                </Grid.Row>
                <Grid.Row centered>
                    <Link to='/plans'>
                        <Button size='large' style={{ paddingLeft: 50, paddingRight: 50 }}> Yes</Button>
                    </Link>
                </Grid.Row>
            </>

            }

            <Grid.Row></Grid.Row>

        </Grid>
        )}</>
    }

}

export default withRouter(Membership)


