import React from 'react'
import { Header } from 'semantic-ui-react'
const userLang = navigator.language || navigator.userLanguage

const IntroPageText = () => (
  <div>
    <Header style={{ color: 'white', fontSize: 30,paddingLeft:40,paddingRight:40 }}>
      {userLang === 'zh-CN' && 'SHEYOU的背后'}
      {userLang !== 'zh-CN' && 'Behind SHEYOU'}
    </Header>
    <Header as='h5' style={{ color: 'white' }}>
      {userLang === 'zh-CN' && '可持续时尚 & 穿衣自由'}
      {userLang !== 'zh-CN' && 'Sustainability & Clothing Freedom'}
    </Header>

    {/* <Header as='h5' style={{ color: 'white' }}>
      Renting multiple clothes and return whenever you want, or try purchasing
      them with a discount, you can have more styles with less budget
    </Header> */}
  </div>
)

export default IntroPageText
