import React from 'react'
import axios from 'axios'
import {
  Grid,
  Image,
  Icon,
  Container,
  Button,
  Header,
  Segment,
  Popup,
  Dimmer,
  Menu,
  Label
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { Parallax } from 'react-parallax'
import { withRouter } from 'react-router-dom'
// import HandleBuyCartDelete from '../cartFunction/HandleBuyCartDelete'

class CartPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      products: '',
      productsRental: '',
      buyProducts: '',
      buyEmpty: true,
      login: false,
      quantity: 0,
      colors: '',
      buyCartInfo: '',
      productPrice: 0,
      accessoryPrice: 0,
      activeItem: 'Buy',

      userInfo: [],
      userID: '',
      cart: [],
      membership: [],
      identifier: '',
      token: '',
      accessory: [],

      open: false,
      open1: false,

      loading: true,
      active: false
    }

    // this.handleSelectMore=this.handleSelectMore.bind(this)
    // this.handleSelectLess=this.handleSelectLess.bind(this)
    this.onHandleDelete = this.onHandleDelete.bind(this)
    this.toClothingPage = this.toClothingPage.bind(this)
    this.handleSelectLess = this.handleSelectLess.bind(this)
    this.handleSelectMore = this.handleSelectMore.bind(this)
    this.getAccessories = this.getAccessories.bind(this)
    this.close = this.close.bind(this)
    this.close1 = this.close1.bind(this)
    this.onHandleCheckOut = this.onHandleCheckOut.bind(this)
  }

  componentDidMount () {
    const identifier = localStorage.getItem('identifier')
    const token = localStorage.getItem('token')
    const log = localStorage.getItem('login')
    const self = this
    const loading = self.state.loading

    window.setTimeout(() => {
      if (loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 2000)

    if (log === 'false') {
      self.setState({ login: false })
    } else {
      self.setState({ login: true })

      axios('https://sheyou-backend.herokuapp.com/users/me', {
        method: 'get',
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(function (res) {
        console.log(res)
        if (res.status === 200) {
          var userInfo = res.data
          if (userInfo.cart === null) {
            userInfo.cart = []
          }
          if (userInfo.accessoryCart === null) {
            userInfo.accessoryCart = []
          }
          if (userInfo.bag === null) {
            userInfo.bag = []
          }
          if (userInfo.accessoryBag === null) {
            userInfo.accessoryBag = []
          }

          self.setState({
            userInfo: userInfo,
            userID: userInfo.id,
            cart: userInfo.cart,
            accessory: userInfo.accessoryCart,
            bag:userInfo.bag,
            accessoryBag:userInfo.accessoryBag,
            membership: userInfo.membership
          })

          console.log(userInfo.cart,userInfo.accessoryCart)
            
          self.getProductInBuyCart()
          self.getAccessories()
        } else {
          alert('Something went wrong, maybe log in again :(')
          localStorage.setItem('login', 'false')
        }
      })
    }

    self.setState({
      identifier: identifier,
      token: token
    })
  }

  getProductInBuyCart () {
    const self = this
    var price = 0
    // const cartIt=localStorage.getItem('buyItem')

    // const parsedCart = JSON.parse(cartIt)

    // if(cartIt===[]||cartIt==null){
    //     self.setState({buyEmpty:true})
    // }else{self.setState({buyEmpty:false})
    const userID = self.state.userID
    const token = self.state.token

    const cartItems = self.state.cart

    self.setState({ buyProducts: '' })

    if (cartItems.length === undefined) {
      alert('something went wrong :(')
      axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
        method: 'put',
        data: {
          cart: []
        },
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(function (res) {
        console.log(res)
        if (res.status === 200) {
          alert('your cart has been cleared sorry')
        } else {
          alert('something went wrong again')
        }
        self.setState({
          loading: false
        })
      })
    } else {
      cartItems.forEach(function (cart) {
        if (cart.type !== 'sale') {
          axios
            .get('https://sheyou-backend.herokuapp.com/products?id=' + cart.id)
            .then(function (res) {
              if (res.status === 200) {
                const productSelected = res.data[0]

                if (self.state.buyProducts === '') {
                  var product = productSelected

                  self.setState({ buyCartInfo: cart })

                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes
                  const itemPrice = product.price * product.quantity
                  price = price + itemPrice
                  console.log(price)
                  const stringProduct = [product]
                  self.setState({
                    buyProducts: stringProduct,
                    productPrice: price
                  })
                } else if (self.state.buyProducts !== '') {
                  product = productSelected

                  // parsedCart.filter(function(cartP){
                  // if(cartP.id===cart.id){
                  self.setState({ buyCartInfo: cart })
                  // }})
                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes
                  const itemPrice = product.price * product.quantity
                  price = price + itemPrice

                  const stringProduct = product
                  product = self.state.buyProducts

                  product.push(stringProduct)
                  self.setState({
                    buyProducts: product,
                    productPrice: price
                  })
                }
              } else {
                alert('something went wrong')
                localStorage.setItem('login', 'false')
              }

              self.setState({
                loading: false
              })
            })
        } else if (cart.type === 'sale') {
          axios
            .get(
              'https://sheyou-backend.herokuapp.com/second-hand-products?id=' +
                cart.id
            )
            .then(function (res) {
              if (res.status === 200) {
                const productSelected = res.data[0]

                if (self.state.buyProducts === '') {
                  var product = productSelected

                  self.setState({ buyCartInfo: cart })

                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes
                  product.type = 'sale'

                  const itemPrice = product.price * product.quantity
                  price = price + itemPrice

                  const stringProduct = [product]
                  self.setState({
                    buyProducts: stringProduct,
                    productPrice: price
                  })
                } else if (self.state.buyProducts !== '') {
                  product = productSelected

                  // parsedCart.filter(function(cartP){
                  // if(cartP.id===cart.id){
                  self.setState({ buyCartInfo: cart })
                  // }})
                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes
                  product.type = 'sale'
                  const itemPrice = product.price * product.quantity
                  price = price + itemPrice

                  const stringProduct = product
                  product = self.state.buyProducts

                  product.push(stringProduct)
                  self.setState({
                    buyProducts: product,
                    productPrice: price
                  })
                }
              } else {
                alert('something went wrong')
                localStorage.setItem('login', 'false')
              }
            })
        }
      })
    }
  }

  getAccessories () {
    const self = this
    const userID = self.state.userID
    const token = self.state.token
    var price = 0

    const cartItems = self.state.accessory

    self.setState({ buyProducts: '' })

    if (cartItems.length === undefined) {
      alert('something went wrong :(')
      axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
        method: 'put',
        data: {
          accessoryCart: []
        },
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(function (res) {
        console.log(res)
        if (res.status === 200) {
          alert('your accessories has been cleared sorry')
        } else {
          alert('something went wrong again')
        }
        self.setState({
          loading: false
        })
      })
    } else {
      cartItems.forEach(function (cart) {
        if (cart.type !== 'sale') {
          axios
            .get(
              'https://sheyou-backend.herokuapp.com/accessaries?id=' + cart.id
            )
            .then(function (res) {
              if (res.status === 200) {
                const productSelected = res.data[0]

                if (self.state.buyProducts === '') {
                  var product = productSelected

                  self.setState({ buyCartInfo: cart })

                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes
                  const itemPrice = product.price * product.quantity
                  price = price + itemPrice

                  const stringProduct = [product]
                  self.setState({
                    buyProducts: stringProduct,
                    accessoryPrice: price
                  })
                } else if (self.state.buyProducts !== '') {
                  product = productSelected

                  // parsedCart.filter(function(cartP){
                  // if(cartP.id===cart.id){
                  self.setState({ buyCartInfo: cart })
                  // }})
                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes
                  const itemPrice = product.price * product.quantity
                  price = price + itemPrice
                  console.log(price)

                  const stringProduct = product
                  product = self.state.buyProducts

                  product.push(stringProduct)
                  self.setState({
                    buyProducts: product,
                    accessoryPrice: price
                  })
                }
              } else {
                alert('something went wrong')
                localStorage.setItem('login', 'false')
              }

              self.setState({
                loading: false
              })
            })
        } else if (cart.type === 'sale') {
          axios
            .get(
              'https://sheyou-backend.herokuapp.com/second-hand-products?id=' +
                cart.id
            )
            .then(function (res) {
              if (res.status === 200) {
                const productSelected = res.data[0]

                if (self.state.buyProducts === '') {
                  var product = productSelected

                  self.setState({ buyCartInfo: cart })

                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes
                  const itemPrice = product.price * product.quantity
                  price = price + itemPrice

                  const stringProduct = [product]
                  self.setState({
                    buyProducts: stringProduct,
                    accessoryPrice: price
                  })
                } else if (self.state.buyProducts !== '') {
                  product = productSelected

                  // parsedCart.filter(function(cartP){
                  // if(cartP.id===cart.id){
                  self.setState({ buyCartInfo: cart })
                  // }})
                  product.quantity = self.state.buyCartInfo.number
                  product.colors[0] = self.state.buyCartInfo.colors
                  product.sizes[0] = self.state.buyCartInfo.sizes
                  const itemPrice = product.price * product.quantity
                  price = price + itemPrice

                  const stringProduct = product
                  product = self.state.buyProducts

                  product.push(stringProduct)
                  self.setState({
                    buyProducts: product,
                    accessoryPrice: price
                  })
                }
              } else {
                alert('something went wrong')
                localStorage.setItem('login', 'false')
              }
            })
        }
      })
    }
  }

  getProductInRentCart() {
    const self = this
    // const cartIt=localStorage.getItem('buyItem')

    // const parsedCart = JSON.parse(cartIt)

    // if(cartIt===[]||cartIt==null){
    //     self.setState({buyEmpty:true})
    // }else{self.setState({buyEmpty:false})
    const userID = self.state.userID
    const token = self.state.token

    const cartItems = self.state.cart

    self.setState({
      buyProducts: []
    })

    if (cartItems === null) {
      axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
        method: 'put',
        data: {
          cart: []
        },
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(function (res) {
        console.log(res)
        if (res.status === 200) {
          console.log(res.status)
        } else {
          alert('something went wrong again')
          localStorage.setItem('login', 'false')
        }

        self.setState({
          loading: false
        })
      })
    }

    if (cartItems.length === undefined) {
      alert('something went wrong :(')
      axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
        method: 'put',
        data: {
          cart: []
        },
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(function (res) {
        console.log(res)
        if (res.status === 200) {
          alert('your bag has been cleared sorry')
        } else {
          alert('something went wrong again')
          localStorage.setItem('login', 'false')
        }

        self.setState({
          loading: false
        })
      })
    } else {
      cartItems.forEach(function (cart) {
        axios
          .get('https://sheyou-backend.herokuapp.com/products?id=' + cart.id)
          .then(function (res) {
            const productSelected = res.data[0]

            if (self.state.buyProducts === []) {
              var product = productSelected

              // parsedCart.filter(function(cartP){
              // if(cartP.id===cart.id){
              self.setState({ buyCartInfo: cart })
              // }})

              product.quantity = self.state.buyCartInfo.number
              product.colors[0] = self.state.buyCartInfo.colors
              product.sizes[0] = self.state.buyCartInfo.sizes

              const stringProduct = [product]
              self.setState({ buyProducts: stringProduct })
            } else if (self.state.buyProducts !== []) {
              product = productSelected

              // parsedCart.filter(function(cartP){
              // if(cartP.id===cart.id){
              self.setState({ buyCartInfo: cart })
              // }})
              product.quantity = self.state.buyCartInfo.number
              product.colors[0] = self.state.buyCartInfo.colors
              product.sizes[0] = self.state.buyCartInfo.sizes

              const stringProduct = product
              product = self.state.buyProducts

              product.push(stringProduct)
              self.setState({ buyProducts: product })
            }

            self.setState({
              loading: false
            })
          })
      })
    }
  }
  getProductInAccessoryRentCart () {
    const self = this
    const userID = self.state.userID
    const token = self.state.token

    const accessoryItems = self.state.accessories

    self.setState({
      accessoryProducts: []
    })

    if (accessoryItems === null) {
      axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
        method: 'put',
        data: {
          accessoryBag: []
        },
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(function (res) {
        console.log(res)
        if (res.status === 200) {
          console.log(res.status)
        } else {
          localStorage.setItem('login', 'false')
        }

        self.setState({
          loading: false
        })
      })
    }
    if (accessoryItems.length === undefined) {
      alert('something went wrong :(')
      axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
        method: 'put',
        data: {
          accessoryBag: []
        },
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(function (res) {
        console.log(res)
        if (res.status === 200) {
          alert('your bag has been cleared sorry')
        } else {
          alert('something went wrong again')
          localStorage.setItem('login', 'false')
        }

        self.setState({
          loading: false
        })
      })
    } else {
      accessoryItems.forEach(function (accessory) {
        axios
          .get(
            'https://sheyou-backend.herokuapp.com/accessaries?id=' +
              accessory.id
          )
          .then(function (res) {
            console.log(res)

            const productSelected = res.data[0]

            if (self.state.accessoryProducts === []) {
              var product = productSelected

              // parsedCart.filter(function(cartP){
              // if(cartP.id===cart.id){
              self.setState({ accessoryCartInfo: accessory })
              // }})

              product.quantity = self.state.accessoryCartInfo.number
              product.colors[0] = self.state.accessoryCartInfo.colors
              product.sizes[0] = self.state.accessoryCartInfo.sizes

              const stringProduct = [product]
              self.setState({ accessoryProducts: stringProduct })
            } else if (self.state.accessoryProducts !== []) {
              product = productSelected

              // parsedCart.filter(function(cartP){
              // if(cartP.id===cart.id){
              self.setState({ accessoryCartInfo: accessory })
              // }})
              product.quantity = self.state.accessoryCartInfo.number
              product.colors[0] = self.state.accessoryCartInfo.colors
              product.sizes[0] = self.state.accessoryCartInfo.sizes

              const stringProduct = product
              product = self.state.accessoryProducts

              product.push(stringProduct)
              self.setState({ accessoryProducts: product })
            }

            self.setState({
              loading: false
            })
          })
      })
    }
  }

  handleSelectLess (event, data) {
    const self = this
    const userID = self.state.userID
    const token = self.state.token
    self.setState({
      loading: true
    })
    const thisId = data.id
    event.preventDefault()
    const type = data.type
    const category = data.category

    const cartItems = self.state.cart.filter(function (item) {
      return item.type !== 'sale'
    })
    const saleCartItem = self.state.cart.filter(function (item) {
      return item.type === 'sale'
    })
    const accessoryCartItem = self.state.accessory
    const newCart = []
    const newAccessoryCart = []
    var cartNum = -1

    if (type === 'sale') {
      saleCartItem.forEach(function (cartItem) {
        if (cartItem.id === thisId) {
          cartNum = cartItem.number
        }
        if (cartNum <= 1) {
          self.setState({
            open: [data.id, type, category],
            loading: false
          })
        } else {
          if (cartItem.id !== thisId) {
            newCart.push(cartItem)
          }

          if (cartItem.id === thisId) {
            cartItem.number = cartItem.number - 1
            newCart.push(cartItem)
          }

          cartItems.forEach(function (cartItem) {
            newCart.push(cartItem)
          })
          accessoryCartItem.forEach(function (cartItem) {
            newAccessoryCart.push(cartItem)
          })

          axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
            method: 'put',
            data: {
              cart: newCart,
              accessoryCart: newAccessoryCart
            },
            headers: {
              Authorization: 'Bearer ' + token
            }
          }).then(function (res) {
            console.log(res)
            if (res.status === 200) {
              self.setState({
                addCartSuccess: true,
                accessory: newAccessoryCart,
                cart: newCart,
                open: false,
                open1: false
              })
              self.getProductInBuyCart()
              self.getAccessories()
              //   alert('add successfully, now you can check in your cart :)')
            } else {
              alert('sorry, something went wrong :(')
            }
          })
        }
      })
    }

    if (type !== 'sale' && category !== 'Accessaries') {
      cartItems.forEach(function (cartItem) {
        if (cartItem.id === thisId) {
          cartNum = cartItem.number
        }
        if (cartNum <= 1) {
          self.setState({
            open: [data.id, type, category],
            loading: false
          })
        } else {
          if (cartItem.id !== thisId) {
            newCart.push(cartItem)
          }

          if (cartItem.id === thisId) {
            cartItem.number = cartItem.number - 1
            newCart.push(cartItem)
          }
          saleCartItem.forEach(function (cartItem) {
            newCart.push(cartItem)
          })
          accessoryCartItem.forEach(function (cartItem) {
            newAccessoryCart.push(cartItem)
          })
          axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
            method: 'put',
            data: {
              cart: newCart,
              accessoryCart: newAccessoryCart
            },
            headers: {
              Authorization: 'Bearer ' + token
            }
          }).then(function (res) {
            console.log(res)
            if (res.status === 200) {
              self.setState({
                addCartSuccess: true,
                accessory: newAccessoryCart,
                cart: newCart,
                open: false,
                open1: false
              })
              self.getProductInBuyCart()
              self.getAccessories()
              //   alert('add successfully, now you can check in your cart :)')
            } else {
              alert('sorry, something went wrong :(')
            }
          })
        }
      })
    }

    if (category === 'Accessaries' && type === undefined) {
      accessoryCartItem.forEach(function (cartItem) {
        if (cartItem.id === thisId) {
          cartNum = cartItem.number
        }
        if (cartNum <= 1) {
          self.setState({
            open: [data.id, type, category],
            loading: false
          })
        } else {
          if (cartItem.id !== thisId) {
            newAccessoryCart.push(cartItem)
          }

          if (cartItem.id === thisId) {
            cartItem.number = cartItem.number - 1
            newAccessoryCart.push(cartItem)
          }
          cartItems.forEach(function (cartItem) {
            newCart.push(cartItem)
          })
          saleCartItem.forEach(function (cartItem) {
            newCart.push(cartItem)
          })

          axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
            method: 'put',
            data: {
              cart: newCart,
              accessoryCart: newAccessoryCart
            },
            headers: {
              Authorization: 'Bearer ' + token
            }
          }).then(function (res) {
            console.log(res)
            if (res.status === 200) {
              self.setState({
                addCartSuccess: true,
                cart: newCart,
                accessory: newAccessoryCart,
                open: false,
                open1: false
              })
              self.getAccessories()
              self.getProductInBuyCart()

              //   alert('add successfully, now you can check in your cart :)')
            } else {
              alert('sorry, something went wrong :(')
            }
          })
        }
      })
    }
  }

  handleSelectMore (event, data) {
    const self = this
    self.setState({
      loading: true
    })
    const userID = self.state.userID
    const token = self.state.token

    const thisId = data.id
    event.preventDefault()
    const type = data.type
    const category = data.category

    const cartItems = self.state.cart.filter(function (item) {
      return item.type !== 'sale'
    })
    const saleCartItem = self.state.cart.filter(function (item) {
      return item.type === 'sale'
    })
    const accessoryCartItem = self.state.accessory
    const newCart = []
    const newAccessoryCart = []

    var stock = -1
    var cartNum = -1

    if (type === 'sale') {
      saleCartItem.forEach(function (cartItem) {
        if (cartItem.id === thisId) {
          cartNum = cartItem.number
        }
      })
      axios
        .get(
          'https://sheyou-backend.herokuapp.com/second-hand-products?id=' +
            thisId
        )
        .then(function (res) {
          if (res.status === 200) {
            const productSelected = res.data[0]
            stock = productSelected.stock
            console.log(stock)

            if (cartNum >= stock) {
              self.setState({
                open1: [data.id, type, category],
                loading: false
              })
            } else {
              saleCartItem.forEach(function (cartItem) {
                if (cartItem.id !== thisId) {
                  newCart.push(cartItem)
                }

                if (cartItem.id === thisId) {
                  cartItem.number = cartItem.number + 1
                  newCart.push(cartItem)
                }
              })
              cartItems.forEach(function (cartItem) {
                newCart.push(cartItem)
              })
              accessoryCartItem.forEach(function (cartItem) {
                newAccessoryCart.push(cartItem)
              })

              axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
                method: 'put',
                data: {
                  cart: newCart,
                  accessoryCart: newAccessoryCart
                },
                headers: {
                  Authorization: 'Bearer ' + token
                }
              }).then(function (res) {
                console.log(res)
                if (res.status === 200) {
                  self.setState({
                    addCartSuccess: true,
                    accessory: newAccessoryCart,
                    cart: newCart,
                    open: false,
                    open1: false,
                    loading: false
                  })
                  self.getProductInBuyCart()
                  self.getAccessories()
                  //   alert('add successfully, now you can check in your cart :)')
                } else {
                  alert('sorry, something went wrong :(')
                }
              })
            }
          } else {
            alert('something went wrong again')
          }
        })
    }

    if (type !== 'sale' && category !== 'Accessaries') {
      cartItems.forEach(function (cartItem) {
        if (cartItem.id === thisId) {
          cartNum = cartItem.number
        }
      })

      axios
        .get('https://sheyou-backend.herokuapp.com/products?id=' + thisId)
        .then(function (res) {
          if (res.status === 200) {
            const productSelected = res.data[0]
            stock = productSelected.stock
            console.log(stock)

            if (cartNum >= stock) {
              self.setState({
                open1: [data.id, type, category],
                loading: false
              })
            } else {
              cartItems.forEach(function (cartItem) {
                if (cartItem.id !== thisId) {
                  newCart.push(cartItem)
                }

                if (cartItem.id === thisId) {
                  cartItem.number = cartItem.number + 1
                  newCart.push(cartItem)
                }
              })
              saleCartItem.forEach(function (cartItem) {
                newCart.push(cartItem)
              })
              accessoryCartItem.forEach(function (cartItem) {
                newAccessoryCart.push(cartItem)
              })
              axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
                method: 'put',
                data: {
                  cart: newCart,
                  accessoryCart: newAccessoryCart
                },
                headers: {
                  Authorization: 'Bearer ' + token
                }
              }).then(function (res) {
                console.log(res)
                if (res.status === 200) {
                  self.setState({
                    addCartSuccess: true,
                    accessory: newAccessoryCart,
                    cart: newCart,
                    open: false,
                    open1: false,
                    loading: false
                  })
                  self.getProductInBuyCart()
                  self.getAccessories()
                  //   alert('add successfully, now you can check in your cart :)')
                } else {
                  alert('sorry, something went wrong :(')
                }
              })
            }
          } else {
            alert('something went wrong again')
          }
        })
    }

    if (category === 'Accessaries' && type === undefined) {
      accessoryCartItem.forEach(function (cartItem) {
        if (cartItem.id === thisId) {
          cartNum = cartItem.number
        }
      })
      axios
        .get('https://sheyou-backend.herokuapp.com/accessaries?id=' + thisId)
        .then(function (res) {
          if (res.status === 200) {
            const productSelected = res.data[0]
            stock = productSelected.stock
            console.log(stock)

            if (cartNum >= stock) {
              self.setState({
                open1: [data.id, type, category],
                loading: false
              })
            } else {
              accessoryCartItem.forEach(function (cartItem) {
                if (cartItem.id !== thisId) {
                  newAccessoryCart.push(cartItem)
                }

                if (cartItem.id === thisId) {
                  cartItem.number = cartItem.number + 1
                  newAccessoryCart.push(cartItem)
                }
              })
              cartItems.forEach(function (cartItem) {
                newCart.push(cartItem)
              })
              saleCartItem.forEach(function (cartItem) {
                newCart.push(cartItem)
              })
              axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
                method: 'put',
                data: {
                  cart: newCart,
                  accessoryCart: newAccessoryCart
                },
                headers: {
                  Authorization: 'Bearer ' + token
                }
              }).then(function (res) {
                console.log(res)
                if (res.status === 200) {
                  self.setState({
                    addCartSuccess: true,
                    accessory: newAccessoryCart,
                    cart: newCart,
                    open: false,
                    open1: false,
                    loading: false
                  })
                  self.getAccessories()
                  self.getProductInBuyCart()
                  //   alert('add successfully, now you can check in your cart :)')
                } else {
                  alert('sorry, something went wrong :(')
                }
              })
            }
          } else {
            alert('something went wrong again')
          }
        })
    }
  }

  onHandleDelete (event, data) {
    const self = this
    self.setState({
      loading: true
    })
    const thisId = data.id
    const type = data.type
    const category = data.category
    event.preventDefault()
    // var thisId=event.target.id
    // thisId=JSON.parse(thisId)
    const cartItems = self.state.cart.filter(function (item) {
      return item.type !== 'sale'
    })
    const saleCartItem = self.state.cart.filter(function (item) {
      return item.type === 'sale'
    })
    const accessoryCartItem = self.state.accessory

    const newCart = []
    const newAccessoryCart = []

    if (type === 'sale') {
      saleCartItem.forEach(function (cartItem) {
        if (cartItem.id !== thisId) {
          newCart.push(cartItem)
        }
      })
      cartItems.forEach(function (cartItem) {
        newCart.push(cartItem)
      })
      accessoryCartItem.forEach(function (cartItem) {
        newAccessoryCart.push(cartItem)
      })
    }

    if (type !== 'sale' && category !== 'Accessaries') {
      cartItems.forEach(function (cartItem) {
        if (cartItem.id !== thisId) {
          newCart.push(cartItem)
        }
      })
      saleCartItem.forEach(function (cartItem) {
        newCart.push(cartItem)
      })
      accessoryCartItem.forEach(function (cartItem) {
        newAccessoryCart.push(cartItem)
      })
    }

    if (category === 'Accessaries' && type === undefined) {
      accessoryCartItem.forEach(function (cartItem) {
        if (cartItem.id !== thisId) {
          newAccessoryCart.push(cartItem)
        }
      })
      cartItems.forEach(function (cartItem) {
        newCart.push(cartItem)
      })
      saleCartItem.forEach(function (cartItem) {
        newCart.push(cartItem)
      })
    }

    const userID = self.state.userID
    const token = self.state.token
    axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
      method: 'put',
      data: {
        cart: newCart,
        accessoryCart: newAccessoryCart
      },
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (res) {
      console.log(res)
      if (res.status === 200) {
        self.setState({
          addCartSuccess: true,
          cart: newCart,
          accessory: newAccessoryCart,
          open: false,
          open1: false,
          loading: false
        })
        self.getProductInBuyCart()
        self.getAccessories()
        //   alert('add successfully, now you can check in your cart :)')
      } else {
        alert('sorry, something went wrong :(')
      }
    })
  }

  toClothingPage () {
    const self = this
    self.props.history.push('/clothing')
  }

  close () {
    const self = this
    self.setState({
      open: false
    })
  }

  close1 () {
    const self = this
    self.setState({
      open1: false
    })
  }

  onHandleCheckOut () {
    const self = this
    const cart = this.state.cart
    const accessories = this.state.accessory
    const price = this.state.accessoryPrice + this.state.productPrice
    const identifier = localStorage.getItem('identifier')

    var theCart=cart.map(function(each){
      if(each.type!=='sale')
      {return each.id}
    })

    var theSale=cart.map(function(each){
      if(each.type==='sale')
      {return each.id}
    })

    var theAccessories=accessories.map(function(each){
      return each.id
    })

    const order = {
      data: {
        clothing: cart,
        accessory: accessories,
        rentOrBuy: 'buy',
        price: price,
        identifier: identifier
      }
    }
    const token = localStorage.getItem('token')
    const userID = this.state.userID


    axios('https://sheyou-backend.herokuapp.com/users/' + userID, {
        method: 'put',
        data: {
          cart:[],
          accessoryCart:[]
        },
        headers: {
          'Authorization': 'Bearer ' + token,
        }
      }).then(function (res) {
        if (res.status === 200) {
          axios('https://sheyou-backend.herokuapp.com/orders', {
            method: 'post',
            data: {
              orders: order,
              users_permissions_user:userID,
              products:theCart,
              accessories:theAccessories,
              second_hand_products:theSale
      
            },
            headers: {
              Authorization: 'Bearer ' + token
            }
          })
            .then(function (res) {
              if (res.status === 200) {
                localStorage.setItem('orderId', res.data.id)
              }
            self.props.history.push('/checkout?orderid='+res.data.id)
            })
        } else {
          alert('something went wrong' + res)
        }

      }).catch(function (e) {
        alert('something went wrong' + e)
      })


    }
  

  handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  render () {
    const s = this.state
    const self = this
    const login = s.login
    var fee = 0
    var products = s.buyProducts

    const style = {
      borderRadius: 12,
      opacity: 0.9,
      padding: '1.5em',
      color: 'orange'
    }

    const open = s.open
    const open1 = s.open1
    var loading = s.loading
    const price = s.productPrice + s.accessoryPrice
    console.log(price)

    return (
      <div>
        {/* <Nav /> */}

        {loading === true && (
          <Grid>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>

            <Grid.Row colums={2}>
              <Grid.Column width={7}></Grid.Column>
              <Grid.Column width={6}>
                <Header size='huge'>
                  <Icon loading name='asterisk' size='massive' color='yellow' />
                  Loading...
                </Header>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}

        {loading === false && login === true&& products.length > 0  && (
          <Grid stackable style={{paddingTop:10}}>
            <Grid.Row>
              <Grid.Column>
                <Container>
                  <br />

                  <Grid divided='vertically' style={{paddingBottom:0,marginBottom:0}}>
                    <Grid.Row columns={2} style={{paddingTop:0,marginTop:0}}>
                      <Grid.Column width={16}>
                        <Header as='h2'>
                          <Icon name='shopping cart' />

                          <Header.Content>Shopping Cart</Header.Content>
                        </Header>
                      </Grid.Column>
                      <Grid.Column width={16}>
                        <Menu attached='top' tabular>
                          <Menu.Item
                            name='Buy'
                            active={s.activeItem === 'Buy'}
                            onClick={this.handleItemClick}
                          >
                            Buy
                            <Label color='teal' basic>
                              1
                            </Label>
                          </Menu.Item>

                          <Menu.Item
                            name='Rent'
                            active={s.activeItem === 'Rent'}
                            onClick={this.handleItemClick}
                          >
                            Rent
                            <Label color='teal' basic>
                              2
                            </Label>
                          </Menu.Item>
                        </Menu>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <Segment attached='bottom' >
                    <Grid divided='vertically' style={{paddingTop:0,marginTop:0}}>
                      <Grid.Row columns={4} divided style={{paddingTop:0,marginTop:0}}>
                        <Grid.Column width={8}>PRODUCT</Grid.Column>
                        <Grid.Column width={4}>QTY</Grid.Column>
                        <Grid.Column width={2}>PRICE</Grid.Column>
                      </Grid.Row>

                      {s.activeItem==='Buy'&&products.length > 0&&
                      products.map(function (product) {
                        return (
                          <Grid.Row columns={9}>
                            <Dimmer active={product.stock === '0'} inverted>
                              <Icon
                                id={product.id}
                                size='large'
                                type={product.type}
                                category={product.categories[0].name}
                                onClick={self.onHandleDelete}
                                circular
                                color='grey'
                                name='close'
                              />
                            </Dimmer>

                            <Grid.Column width={1}>
                              {product.stock !== '0' && (
                                <Icon
                                  id={product.id}
                                  type={product.type}
                                  category={product.categories[0].name}
                                  onClick={self.onHandleDelete}
                                  circular
                                  color='orange'
                                  name='close'
                                />
                              )}
                            </Grid.Column>
                            <Grid.Column width={3}>
                              <Image
                                size='medium'
                                src={product.picture[0].url}
                              />
                            </Grid.Column>

                            <Grid.Column width={4}>
                              <Grid>
                                <Grid.Row>{product.name}</Grid.Row>
                                <Grid.Row>color: {product.colors}</Grid.Row>
                                <Grid.Row>size: {product.sizes}</Grid.Row>
                              </Grid>
                            </Grid.Column>
                            <Grid.Column width={1}>
                              <Popup
                                trigger={
                                  <Icon
                                    id={product.id}
                                    type={product.type}
                                    category={product.categories[0].name}
                                    onClick={self.handleSelectLess}
                                    name='minus'
                                    color='orange'
                                  />
                                }
                                content={
                                  <Grid centered divided>
                                    <Grid.Column textAlign='center'>
                                      <p>Do you want to delete it?</p>
                                      <Button
                                        id={product.id}
                                        color='teal'
                                        type={product.type}
                                        category={product.categories[0].name}
                                        onClick={self.onHandleDelete}
                                      >
                                        Yes
                                      </Button>
                                      <Button
                                        id={product.id}
                                        color='teal'
                                        basic
                                        onClick={self.close}
                                      >
                                        No
                                      </Button>
                                    </Grid.Column>
                                  </Grid>
                                }
                                style={style}
                                position='top center'
                                open={
                                  product.id === open[0] &&
                                  product.type === open[1] &&
                                  product.categories[0].name === open[2]
                                }
                              />
                            </Grid.Column>

                            {product.stock === '0' && (
                              <Grid.Column width={1}>0</Grid.Column>
                            )}
                            {product.stock !== '0' && (
                              <Grid.Column width={1}>
                                {product.quantity}
                              </Grid.Column>
                            )}
                            <Grid.Column width={2}>
                              <Popup
                                trigger={
                                  <Icon
                                    id={product.id}
                                    type={product.type}
                                    category={product.categories[0].name}
                                    onClick={self.handleSelectMore}
                                    name='plus'
                                    color='orange'
                                  />
                                }
                                content={
                                  <Grid centered divided>
                                    <Grid.Column textAlign='center'>
                                      <p>
                                        Sorry, not enough in stock, you can't
                                        keep adding
                                      </p>
                                      <Button
                                        id={product.id}
                                        color='teal'
                                        onClick={self.close1}
                                      >
                                        OK
                                      </Button>
                                    </Grid.Column>
                                  </Grid>
                                }
                                style={style}
                                position='top center'
                                open={
                                  product.id === open1[0] &&
                                  product.type === open1[1] &&
                                  product.categories[0].name === open1[2]
                                }
                              />
                            </Grid.Column>

                            <Grid.Column>{'$ ' + product.price}</Grid.Column>
                          </Grid.Row>
                        )
                      })}
                      

                      

                      {s.activeItem==='Buy'&&products.length > 0&&products.forEach(function (product) {
                        fee = fee + product.price * product.quantity
                        localStorage.setItem('fee', fee)
                      })}

                      
                      <Grid.Row>
                        <Grid.Column floated='right' width={11}>
                          Subtotal
                        </Grid.Column>

                        <Grid.Column floated='left' width={2}>
                          ${fee}
                        </Grid.Column>

                        <Grid.Column floated='right' width={11}>
                          Shipping Fee
                        </Grid.Column>

                        <Grid.Column floated='left' width={2}>
                          $ 0
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column floated='right' width={11}>
                          <Header>TOTAL</Header>
                        </Grid.Column>

                        <Grid.Column floated='left' width={2}>
                          <Header>${fee}</Header>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <br />
                    <br />
                    <Grid>
                      <Grid.Row columns={3}>
                        <Grid.Column width={5}></Grid.Column>

                        <Grid.Column width={5}>
                          <Button
                            fluid
                            color='teal'
                            content='Check Out'
                            onClick={this.onHandleCheckOut}
                          />
                        </Grid.Column>

                        <Grid.Column width={4}></Grid.Column>
                      </Grid.Row>
                    </Grid>

                    <br />
                    <br />
                  </Segment>
                </Container>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}

        {loading === false && login === false && (
          <Parallax
            bgImage='https://i.loli.net/2020/11/25/ZHjYm1wL9runqOz.png'
            bgImageAlt=''
            strength={200}
          >
            <div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />

              <Segment>
                <Grid>
                  <Grid.Row centered>
                    <Header> You Should login first</Header>
                  </Grid.Row>

                  <Grid.Row centered>
                    <Link to='/signin'>
                      <Button size='huge' color='orange'>
                        {' '}
                        Login
                      </Button>
                    </Link>
                  </Grid.Row>
                </Grid>
              </Segment>
            </div>

            <div style={{ height: '400px' }} />
          </Parallax>
        )}

        {loading === false && login === true && products.length === 0 && (
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={16}>
                <Parallax
                  bgImage='https://i.loli.net/2020/11/25/ZHjYm1wL9runqOz.png'
                  bgImageAlt=''
                  strength={200}
                >
                  <div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />

                    <Segment>
                      <Grid>
                        <Grid.Row centered>
                          <Header>You Don't Have Anything in Your Cart</Header>
                        </Grid.Row>

                        <Grid.Row centered>
                          <Link to='/clothing'>
                            <Button size='huge' color='orange'>
                              {' '}
                              Go Check Out Our Closet
                            </Button>
                          </Link>
                        </Grid.Row>
                      </Grid>
                    </Segment>
                  </div>

                  <div style={{ height: '400px' }} />
                </Parallax>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </div>
    )
  }
}

export default withRouter(CartPage)
