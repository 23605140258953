import React from 'react'
import {
    Grid, Icon, 
    Segment, Container, Header
} from 'semantic-ui-react'
import Foot from '../foot/Foot'
import { withRouter } from 'react-router-dom'


class Result extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
           result:''
        }
    }

    componentDidMount() {
      
        console.log(this)
    }




    render() {
        const result=this.props.result

        return <div>
            {/* <Nav /> */}

           
                <div className='bg_image'>
                    <br />
                    <br />

                    <Container>
                    {result === true &&
                            <Segment>

                                <Grid centered colums={2}>
                                    <Grid.Row></Grid.Row>
                                    <Grid.Row></Grid.Row>
                                    <Grid.Row>
                                        <Header size='huge' color='orange'>
                                            <Icon name='smile outline' />
                                            <Header.Content>Payment Success</Header.Content>

                                        </Header>
                                    </Grid.Row>

                                    <Grid.Row></Grid.Row>
                                    <Grid.Row></Grid.Row>
                                </Grid>
                            </Segment>

                        }
                        {result === false &&
                            <Segment>

                                <Grid centered colums={2}>
                                    <Grid.Row></Grid.Row>
                                    <Grid.Row></Grid.Row>
                                    <Grid.Row>
                                        <Header size='huge' color='orange'>
                                            <Icon name='exclamation triangle' />
                                            <Header.Content>Sorry, Payment Failed</Header.Content>

                                        </Header>
                                    </Grid.Row>

                                    <Grid.Row></Grid.Row>
                                    <Grid.Row></Grid.Row>
                                </Grid>
                            </Segment>

                        }
                    </Container>


                    <br />
                    <br />
                    <Foot />
                </div>
                </div>

         
    }

}

export default withRouter(Result)
