import React from 'react'
import {
    Grid, Icon, 
    Segment, Container,  Header
} from 'semantic-ui-react'
import Foot from '../foot/Foot'
import { withRouter } from 'react-router-dom'
// import timestamp from 'time-stamp'
// import PaypalSdk from 'Paypal-sdk';
import { PayPalScriptProvider ,
    PayPalButtons
} from "@paypal/react-paypal-js";

class Paypal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            paid: false,
            value: 0,
            token: '',
            identifier: '',
            password: '',
            membership: '',
            isAMember: '',
            loading: false,
            Paypal: '',
            PaypalIcon: '',
            wechatPayIcon: '',
            wechatPay: '',
            expireDate: ''
        }
        // this.onHandleSubmit = this.onHandleSubmit.bind(this)
    }

    componentDidMount() {
        // const APP_ID = "2021003115635504";
        // const URL='https://openapi.Paypal.com/gateway.do'
        // const APP_PRIVATE_KEY='MIIEpAIBAAKCAQEAlYqyyZu6zKxEpJ3KTkjHSlO/hSIr6Ip0m5gVO9N0cn6C4ATG/wMIzXcmCUzyrebKc/MRa49cWJiuHMdHY6nIuETps2SB3UBdOpWJQGKzs/AyZoasE4UT0YGwZ0lbyaL57xt7RidZHutap/pbQTm4x4HOO0aiNtDuVCF/8jX3p3VtJa1ctxQefLnsTkM5vf+JeNo+VVssUMJNk7fC/qDCIukBOO6Jk6dlHGHcHAIQDoouluBK4JczDti9S6rOT5dpQaLdL+jQxCznK2kXPjA04EjwiecP7egJPQtaqSTDRuk3G+TTabLqVl8leGM/7hjzEXeC5VMbTO4j63uRTUX2zwIDAQABAoIBAA8LOftAOGWYuvsYiJ6hgbL/g9ff3SzyqNJyVUiEeEWAZqgksuQ6qOsKnn/3Br7p5doOTOVWMcMT8QgiIBNtpCytehk69jmM3RQksP8SLuPeOSPJToxUKe88rcNmRN6fD6e3Swp+sN+Ea8VUUVPZe7ZOX7Hk5DLBiAhcqmNyfGyTIw7tQox4/AHOQTvAdFu0BTnQvnr6ZJ47Ag1X47YFs9vMpg30It+WfdqhBmRkeLmlSYkgbNI4iS5/iLd5dLFumYcIg8+zLG35mq3M9uVKQuCEFo2p6KPzELKWIajxj1w73rHaeRfUo9oO2t/vdG8zOv5f4vItgBtQD3oJ622NB4ECgYEA17BoHg7rPmeoxcTYoU8/ohuJkFua6PyyTsdGpymlFvsJeGJ6H98bk9HF4VFHNE5c3vlLeTKYMSu7niV3cpYXJf4x7Nf5Jgp1CTW30DU9xPcWCImzLoAph9IE9wWA4wvhrESslFn5C9IdMn2m+pOHLEVo0kDNnVmh4WO7nwhnnkECgYEAsX19aXT2VxAMQa2UD4PlkhouTexcI3beKqlrl8ca5Erqh2CtxSiNoBcgbL0vEbfx205zAD/c8EvYxjEG+F2K3Vn121QhrHeNXNZfDKBL7oCYP1ZEzFmacnGODGMs5FDNuoiVl+3qkrvpOjM4CymUhqDEg5k0cdfM6KMb4B4ZcQ8CgYEA0nnZ0GM8moQrRPqnbxpXfUikABP0YiGdmpSJ1Lwl1vwYLLrkc8+TKLePAyX+dwN1Sz04skd2Dp2fk176Zf4jDrc0XuwOvFl+BgO+oNYjtd5sSN6e1OTKIjkHEUr0kBtbGHFXe9kk160tQsUtOd7XDJObC3mjbEY4WyqovzNDckECgYEAi71vVe3+XQGHhNYyshx4TJVMS5GB4XIe7JvC2Basd3z8NWXKqmWJS7epkbNJMbwPMUkMhe/B9XbhzvieskCDqk6/oNUWRgFCTQuCfiBeaALa21MCi5koXl5FJj+XApMTxn7uuFVaz/geYfIdd7xe8bf1wF6IyfDLpYV11vgbs8UCgYAy8TezCXyvr6VyLKf7wbAvsFMWnejyXkeyCaxGgLXzC5maK+CXWqD4z1IoV9MMHI1ebaQ+yzJKaAdEWnIw4l1WnebLCnEA/H6WclnJqLrXwk+nO6og6N8AaCWUGyTmsP+eFGEx+6yJmlDmddoyk++kbsY0VXxEA6bZwZrxEQmN3w=='
        // const FORMAT='json'
        // const Paypal_PUBLIC_KEY='MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAoAYSP0CpbmpQlWFejUSoJGg84494Gn9z5fCSe6EylmvSIF0g73BxXPanU3we17k9jno0R8k7Njr7M9btMZ4j4kbEefZsuxUOjmF/9Br3uEltCejjF2HZYr51DT/yebZ5kI9cT9RG+2aQn3dms55M7xPTuH2Rgd11rBz4YHwXGYooXBx0WsgVsgKYo1umnRc2vtOv6i6XPMCV0UP1Xmv5/19FDt4O6LoAkHafCXjGyIbxG37c+a/QYjAsCIwzgEyhE+v2U1QdrbX7y+A5Mk9R3S4rw+nRtJlOVQedIKPOXX6lwo5hZ3fkJpSDVMuCFMwoM+V33On9s4Gq/7zU/XRlmQIDAQAB'
        // const SIGN_TYPE='RSA2'
        // const CHARSET='UTF-8'
        // const version=1.0
        // const out_trade_no=localStorage.getItem('orderId')
    }





    render() {



        const loading = this.state.loading

        return <div>
            {/* <Nav /> */}

            {loading ? (
                <Grid >
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>


                    <Grid.Row colums={2}>
                        <Grid.Column width={7}></Grid.Column>
                        <Grid.Column width={6}>
                            <Header size='huge'>
                                <Icon loading name='asterisk' size='massive' color='yellow' />
                                Loading...
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                </Grid>
            ) : (<>
                <div className='bg_image'>
                    <br />
                    <br />

                    <Container>
                        <Segment>
                            <Grid container>

                                <PayPalScriptProvider options={{ "client-id": 'test' }}>
                                    <PayPalButtons
                                        createOrder={(data, actions) => {
                                            return actions.order.create({
                                                purchase_units: [
                                                    {
                                                        amount: {
                                                            value: "1.99",
                                                        }
                                                    }
                                                ]
                                            });
                                        }}
                                        onApprove={(data, actions) => {
                                            return actions.order.capture().then((details) => {
                                                const name = details.payer.name.given_name;
                                                alert(`Transaction completed by ${name}`);
                                            });
                                        }}
                                    />
                                </PayPalScriptProvider>

                            </Grid>
                        </Segment>
                    </Container>


                    <br />
                    <br />
                    <Foot />
                </div>

            </>)}
        </div>
    }

}

export default withRouter(Paypal)
