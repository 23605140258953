import React from 'react'
import { Parallax } from 'react-parallax'
import {
  Container,
  Grid,
  Header,
  Segment,
  Label,
  Embed,
  Accordion,
  Icon,
  Image,
  Button
} from 'semantic-ui-react'
import MerchantSec from '../homePage/merchantSec/MerchantSecPhone'
// import { Link } from 'react-router-dom';
// import ReactPlayer from 'react-player'
// import Foot from '../foot/Foot'
import NavMobilePhone from '../nav/NavMobilePhone'
import '../../App.css'
import { withRouter } from 'react-router-dom'
import LoadingPhone from '../RedirectPages/LoadingPhone'
const userLang = navigator.language || navigator.userLanguage

class AboutPagePhone extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      activeItem: 'What is Clothes Shaing',
      activeIndex: -1,
      loading: true
    }
    this.toPlans = this.toPlans.bind(this)
    this.toShop = this.toShop.bind(this)
  }

  toPlans () {
    this.props.history.push('/merchantsLog')
  }

  toShop () {
    this.props.history.push('/clothing')
  }

  componentDidMount () {
    window.scrollTo(0, 0)

    const self = this

    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 500)
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  render () {
    const self = this
    const { activeIndex } = this.state
    const loading = this.state.loading

    return (
      <div>
        {/* <Nav /> */}

        {loading ? (
          <LoadingPhone />
        ) : (
          <>
            <Parallax
              bgImage={
                'https://res.cloudinary.com/sheyou/image/upload/v1654171401/cheerful_multiethnic_women_sitting_on_pier_2022_05_31_06_57_17_utc_2ec9b28908.jpg'
              }
              bgImageAlt='image'
              strength={100}
            >
              <Grid style={{ paddingTop: 150, paddingBottom: 100 }}>
                <Grid.Row centered style={{ fontSize: 20 }}>
                  {userLang === 'zh-CN' && (
                    <Header inverted>共享衣橱是怎么运作的</Header>
                  )}
                  {userLang !== 'zh-CN' && (
                    <Header inverted>How Clothes Sharing Works</Header>
                  )}
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={1}></Grid.Column>
                  <Grid.Column width={14}>
                    <Segment placeholder>
                      <Grid centered>
                        <Grid.Row centered columns={3}>
                          <Grid.Column>
                            <Header textAlign='center' as='h4'>
                              <Image
                                size='mini'
                                centered
                                src={
                                  'https://res.cloudinary.com/sheyou/image/upload/v1641199099/1_7334db6741.png?5303827.600000024'
                                }
                              />
                              <br />
                              {userLang === 'zh-CN' && '浏览'}
                              {userLang !== 'zh-CN' && 'Browse'}
                              <Header.Subheader style={{ fontSize: 10 }}>
                                {userLang === 'zh-CN' &&
                                  '在共享衣橱里尝试不同的穿搭风格！'}
                                {userLang !== 'zh-CN' &&
                                  'Try different styles in clothes sharing'}
                              </Header.Subheader>
                            </Header>
                          </Grid.Column>
                          <Grid.Column>
                            <Header textAlign='center' as='h4'>
                              <Image
                                size='mini'
                                centered
                                src={
                                  'https://res.cloudinary.com/sheyou/image/upload/v1641199099/1_7334db6741.png?5303827.600000024'
                                }
                              />
                              <br />
                              {userLang === 'zh-CN' && '租借'}
                              {userLang !== 'zh-CN' && 'Rent'}

                              <Header.Subheader style={{ fontSize: 10 }}>
                                {userLang === 'zh-CN' &&
                                  '穿戴您喜欢的所有服饰，不用为清洗与寄送操心'}
                                {userLang !== 'zh-CN' &&
                                  'Wear all the clothes witout worrying about cleaning and delivery      '}
                              </Header.Subheader>
                            </Header>
                          </Grid.Column>

                          <Grid.Column>
                            <Header textAlign='center' as='h5'>
                              <Image
                                size='mini'
                                centered
                                src={
                                  'https://res.cloudinary.com/sheyou/image/upload/v1641199099/3_2b4f73bf75.png?5295625.199999988'
                                }
                              />
                              <br />
                              {userLang === 'zh-CN' && '归还'}
                              {userLang !== 'zh-CN' && 'Return'}

                              <Header.Subheader style={{ fontSize: 10 }}>
                                {userLang === 'zh-CN' &&
                                  '选择一个您想要的时间，快递员会上门取件'}
                                {userLang !== 'zh-CN' &&
                                  ' Pick a time and place for delivery man to come'}
                              </Header.Subheader>
                            </Header>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row centered columns={4}>
                          <Grid.Column>
                            <Header textAlign='center' as='h5'>
                              <Image
                                size='mini'
                                centered
                                src={
                                  'https://res.cloudinary.com/sheyou/image/upload/v1641199100/infinite_c73324f586.png?5305877.400000036'
                                }
                              />
                              <br />
                              {userLang === 'zh-CN' && '重复'}
                              {userLang !== 'zh-CN' && 'Repeat'}

                              <Header.Subheader style={{ fontSize: 10 }}>
                                {userLang === 'zh-CN' &&
                                  '循环这一过程，祝您愉快！'}
                                {userLang !== 'zh-CN' &&
                                  'Enjoying Repeating this process'}
                              </Header.Subheader>
                            </Header>
                          </Grid.Column>
                          <Grid.Column>
                            <Image size='mini' src='' />
                            <Header textAlign='center' as='h5'>
                              {userLang === 'zh-CN' && '或'}
                              {userLang !== 'zh-CN' && 'OR'}
                            </Header>
                          </Grid.Column>
                          <Grid.Column>
                            <Header textAlign='center' as='h5'>
                              <Image
                                size='mini'
                                centered
                                src={
                                  'https://res.cloudinary.com/sheyou/image/upload/v1641199099/heart_2_a220ae99d6.png?5299756.199999988'
                                }
                              />
                              <br />
                              {userLang === 'zh-CN' && '折扣购买'}
                              {userLang !== 'zh-CN' && 'Buy with discount'}

                              <Header.Subheader style={{ fontSize: 10 }}>
                                {userLang === 'zh-CN' &&
                                  '您可以选择留下心仪的商品'}
                                {userLang !== 'zh-CN' &&
                                  'You can choose to keep the clothing'}
                              </Header.Subheader>
                            </Header>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Segment>
                  </Grid.Column>
                  <Grid.Column width={1}></Grid.Column>
                </Grid.Row>

                <Grid.Row centered columns={3}>
                  <Grid.Column width={6}>
                    <Button
                      onClick={this.toShop}
                      // inverted
                      size='large'
                    >
                      {userLang === 'zh-CN' && '开始租借'}
                      {userLang !== 'zh-CN' && ' Start Shopping'}
                    </Button>
                  </Grid.Column>

                  <Grid.Column width={6}>
                    <Button
                      onClick={this.toPlans}
                      // inverted
                      size='large'
                    >
                      {userLang === 'zh-CN' && '成为商户'}
                      {userLang !== 'zh-CN' && 'Become Merchant'}
                    </Button>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row></Grid.Row>
              </Grid>
            </Parallax>

            <div className='bg_image'>
              <br />
              <br />
              <Container style={{ marginTop: 10 }}>
                <Grid>
                  <Segment raised>
                    <Grid>
                      <Grid.Row></Grid.Row>
                      <Grid.Row centered style={{ fontSize: 20 }}>
                        <Header>
                          {userLang === 'zh-CN' && '为什么我们支持'}
                          {userLang !== 'zh-CN' && 'Why We Support'}
                        </Header>
                      </Grid.Row>

                      <Grid.Row centered style={{ fontSize: 20 }}>
                        <Header color='orange'>
                          {userLang === 'zh-CN' && '√ 可持续时尚'}
                          {userLang !== 'zh-CN' && 'Sustainable Fashion'}
                        </Header>
                      </Grid.Row>

                      <Grid.Row centered style={{ fontSize: 20 }}>
                        <Header>
                          {userLang === 'zh-CN' && '× 反对快时尚'}
                          {userLang !== 'zh-CN' && 'instead of Fast Fashion '}
                        </Header>
                      </Grid.Row>
                      <Label as='a' color='orange' ribbon size='large'>
                        {userLang === 'zh-CN' && '一起来看一个三分钟的视频'}
                        {userLang !== 'zh-CN' &&
                          "Let's watch a 3-minute-video first"}
                      </Label>
                      <Grid.Row></Grid.Row>
                    </Grid>
                    <Container>
                      <Embed
                        icon='play circle'
                        placeholder='https://i.loli.net/2020/12/15/DchTU5FgR9zpAeu.jpg'
                        url='http://v.qq.com/iframe/player.html?vid=m0980830f7h'
                      />
                    </Container>
                    <Grid>
                      <Grid.Row></Grid.Row>
                      <Grid.Row>
                        <Label as='a' color='orange' ribbon size='large'>
                          {userLang === 'zh-CN' && '了解更多'}
                          {userLang !== 'zh-CN' && 'Learn More'}
                        </Label>
                      </Grid.Row>
                    </Grid>
                    <Accordion fluid styled>
                      <Accordion.Title
                        active={activeIndex === 0}
                        index={0}
                        onClick={this.handleClick}
                      >
                        <Icon name='dropdown' />
                        {userLang === 'zh-CN' && '水污染'}
                        {userLang !== 'zh-CN' && 'Water Contamination'}
                      </Accordion.Title>
                      <Accordion.Content active={activeIndex === 0}>
                        <Grid stackable>
                          <Grid.Row columns={3} centered>
                            <Grid.Column width={12}>
                              <Image
                                src='http://23927233.s21i.faiusr.com/4/ABUIABAEGAAgtPiL9wUojKmOHjCIBTiIBQ.png'
                                size='medium'
                              />
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row columns={3} centered>
                            <Grid.Column width={12}>
                              {userLang !== 'zh-CN' && (
                                <p>
                                  Cotton is the most common natural fiber used
                                  to make clothing, accounting for about 33
                                  percent of all fibers found in textiles.
                                </p>
                              )}

                              {userLang !== 'zh-CN' && (
                                <p>
                                  In Central Asia, for instance, the Aral Sea
                                  has nearly disappeared because cotton farmers
                                  draw excessively from the Amu Darya and Syr
                                  Darya rivers.
                                </p>
                              )}
                              {userLang === 'zh-CN' && (
                                <p>
                                  图注：制作一件纯棉衬衣大概需要2700毫升的水，这是一个人2年半的饮水量。
                                </p>
                              )}
                              {userLang === 'zh-CN' && (
                                <p>
                                  棉是制作服装最常见的自然纤维，在纺织面料使用的纤维中大概占有33%的比例。
                                </p>
                              )}
                              {userLang === 'zh-CN' && (
                                <p>
                                  由于棉农从阿姆河与锡尔河中抽取了过多的河水，中亚地区的咸海（位于哈萨克斯坦和乌兹别克斯坦之间）已经快要消失了。
                                </p>
                              )}
                            </Grid.Column>
                          </Grid.Row>

                          <Grid.Row columns={3} centered>
                            <Grid.Column width={12}>
                              <Image
                                src='http://23927233.s21i.faiusr.com/4/ABUIABAEGAAgtPiL9wUogKTuvAYwwAI4kAI.png'
                                size='medium'
                              />
                            </Grid.Column>
                          </Grid.Row>

                          <Grid.Row columns={3} centered>
                            <Grid.Column width={12}>
                              {userLang !== 'zh-CN' && (
                                <p>
                                  Water use and pollution also take place during
                                  clothing production. About 20 percent of
                                  industrial water pollution is due to garment
                                  manufacturing.
                                </p>
                              )}
                              {userLang === 'zh-CN' && (
                                <p>
                                  图注：制作一件纯棉
                                  在服装的生产过程中，水资源的过度使用与污染也在发生。大致有20%的工业水污染是服装制造业产生的。
                                  衬衣大概需要2700毫升的水，这是一个人2年半的饮水量。
                                </p>
                              )}

                              {userLang !== 'zh-CN' && (
                                <p>
                                  In China, the factory of the world, it is
                                  estimated that 70 percent of the rivers and
                                  lakes are contaminated by the 2.5 billion
                                  gallons of wastewater produced by the textile
                                  industry.
                                </p>
                              )}
                              {userLang === 'zh-CN' && (
                                <p>
                                  据估算，作为“世界工厂”，中国有70%的河流与湖泊正在被纺织业产生的25亿加仑废水所污染。
                                </p>
                              )}
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Accordion.Content>

                      <Accordion.Title
                        active={activeIndex === 1}
                        index={1}
                        onClick={this.handleClick}
                      >
                        <Icon name='dropdown' />
                        {userLang === 'zh-CN' && '其他污染'}
                        {userLang !== 'zh-CN' && 'Other Contamination'}
                      </Accordion.Title>
                      <Accordion.Content active={activeIndex === 1}>
                        <Grid>
                          <Grid.Row columns={3} centered>
                            <Grid.Column width={12}>
                              {userLang === 'zh-CN' && (
                                <p>
                                  棉的种植过程中会用到大量的有害除虫剂与肥料，导致了土壤的污染。
                                  除此之外，纺织加工的过程会导致毒素进入到附近土壤。
                                </p>
                              )}

                              {userLang === 'zh-CN' && (
                                <p>
                                  17%到20%的工业污染来源于纺织品染色。每年服装制造产业会产生20吨的有害废水，
                                  含有硝酸盐，铜，砷，铅，镉，汞，镍等多种元素。
                                </p>
                              )}
                              {userLang === 'zh-CN' && (
                                <p>
                                  尼龙材料的生产过程会产生一氧化二氮，而这是一种比二氧化碳强大近300倍的温室气体。聚脂钎维和尼龙材料在洗衣机中会产生塑料微粒，流入我们地球的水系中。
                                </p>
                              )}
                              {userLang === 'zh-CN' && (
                                <p>
                                  纺织业工厂中的缝纫机和气泵等机器需要电力；清洗、干燥、染色布料则需要大量的热量。
                                </p>
                              )}
                              {userLang === 'zh-CN' && (
                                <p>
                                  由于大部分的服装通过轮船运输，这一过程中会继续产生更多的二氧化碳。这些轮船的船用燃料油含有比普通车用燃料多1800倍的硫，使轮船运输成为一个重要的污染生产源。
                                </p>
                              )}
                              {userLang !== 'zh-CN' && (
                                <p>
                                  The platation of cotton uses many harmful
                                  pesticides and chemical fertilizers, which
                                  eventually results in soil. In addition, the
                                  textile process puts toxins into nearby lands.
                                </p>
                              )}

                              {userLang !== 'zh-CN' && (
                                <p>
                                  17% to 20% of industrial wastes come from
                                  textile dyeing. Every year the industry has 20
                                  tons of toxic water, which contains nitrate,
                                  copper, arsenic, lead, cadmium, mercury,
                                  nickel...
                                </p>
                              )}

                              {userLang !== 'zh-CN' && (
                                <p>
                                  Production of nylon produces nitrous oxide
                                  which is a greenhouse gas 300 times more
                                  potent than carbon dioxide. Both polyester and
                                  nylon also break down in washing machines
                                  leading to the build up of microplastics in
                                  our water systems.
                                </p>
                              )}

                              {userLang !== 'zh-CN' && (
                                <p>
                                  Electricity is needed to run machinery such as
                                  sewing machines and air pumps in textile
                                  factories. Huge amounts of heat is needed for
                                  washing, drying and dying the cloth.
                                </p>
                              )}

                              {userLang !== 'zh-CN' && (
                                <p>
                                  The further costs of transporting the garments
                                  produces additional CO2 as the vast majority
                                  of garments have travelled by ship. Ships burn
                                  bunker fuel which contains 1800 times more
                                  sulfur than US domestic vehicle fuel, making
                                  shipping a significant polluting sector.
                                </p>
                              )}
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Accordion.Content>

                      <Accordion.Title
                        active={activeIndex === 2}
                        index={2}
                        onClick={this.handleClick}
                      >
                        <Icon name='dropdown' />
                        {userLang === 'zh-CN' && '一起参与改变！'}
                        {userLang !== 'zh-CN' && "Let's make a difference!"}
                      </Accordion.Title>
                      <Accordion.Content active={activeIndex === 2}>
                        <Grid>
                          <Grid.Row columns={3} centered>
                            <Grid.Column width={12}>
                              <Grid>
                                <Grid.Row>
                                  <p>
                                    {userLang === 'zh-CN' && '我们可以做什么：'}
                                    {userLang !== 'zh-CN' && 'What can we do: '}
                                  </p>
                                </Grid.Row>
                                <Grid.Row>
                                  <p>
                                    {userLang === 'zh-CN' &&
                                      '1. 降低对全新服饰、传统时尚的消费。'}
                                    {userLang !== 'zh-CN' &&
                                      '1. Consume less new, conventional fashion.'}
                                  </p>
                                </Grid.Row>

                                <Grid.Row>
                                  <p>
                                    {userLang === 'zh-CN' &&
                                      ' 2. 尽量购买二手衣物。'}
                                    {userLang !== 'zh-CN' &&
                                      '2. Try to buy second hand if you can.'}
                                  </p>
                                </Grid.Row>

                                <Grid.Row>
                                  <p>
                                    {userLang === 'zh-CN' &&
                                      ' 3.在需要购买全新服饰时，选择更为可持续的品牌（更可持续指该品牌使用天然环保的、再生的服装面料与供应商）。'}
                                    {userLang !== 'zh-CN' &&
                                      '3. When you buy new clothes , buy it from a more sustainable label that uses organic, natural, or recycled fibers and manufactures.'}
                                  </p>
                                </Grid.Row>

                                <Grid.Row></Grid.Row>
                              </Grid>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Accordion.Content>
                    </Accordion>
                  </Segment>
                </Grid>
              </Container>
              <br />
              <br />
            </div>

            <Parallax
              bgImage={
                'https://res.cloudinary.com/sheyou/image/upload/v1654174982/beautiful_women_posing_in_underwear_2021_09_01_23_55_47_utc_28ba089d41.jpg'
              }
              bgImageAlt='image'
              strength={100}
            >
              <Segment style={{ backgroundColor: 'rgba(0,0,0,0.3)' }}>
                <Grid style={{ paddingTop: 150, paddingBottom: 150 }}>
                  <Grid.Row centered columns={3}>
                    <Grid.Column width={12}>
                      <Header inverted style={{ fontSize: 20 }}>
                        {userLang === 'zh-CN' && '" 我没有衣服穿'}
                        {userLang !== 'zh-CN' && '" I Have Nothing To Wear'}
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row centered columns={3}>
                    <Grid.Column width={12}>
                      <Header inverted as='h4'>
                        {userLang === 'zh-CN' &&
                          '研究表明，80%的女性平均仅将衣柜里的衣服穿五次'}
                        {userLang !== 'zh-CN' &&
                          'Research shows 80% of women wear the clothing in their closets an average of 5 times.'}
                      </Header>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row centered columns={3}>
                    <Grid.Column width={12}>
                      <Header as='h4' inverted>
                        {userLang === 'zh-CN' &&
                          '租用可以减少购买服装的高额支出。既能满足想穿新衣服的愿望，又能减轻整理衣橱和洗衣服造成的负担。'}
                        {userLang !== 'zh-CN' &&
                          'Renting can reduce high expenses on garments. Satisfy both the want to wear something new and release the stress of closet organizing and laundry.'}
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Parallax>

            
            <Parallax
                  bgImage={
                    'https://res.cloudinary.com/sheyou/image/upload/v1653733448/Screen_Shot_2022_05_28_at_6_23_46_PM_f3d08f7d9e.png?4640704.6000000015'
                  }
                  bgImageAlt='merchant page image'
                  strength={100}
                >
                   <Segment style={{ backgroundColor: 'rgba(0,0,0,0.3)' }}>
                <Grid style={{ paddingTop: 150, paddingBottom: 150 }}>
                  <Grid.Row centered columns={3}>
                    <Grid.Column width={12}>
                      <Header inverted style={{ fontSize: 20 }}>
                        {userLang === 'zh-CN' && ' 商户面对用户租借'}
                        {userLang !== 'zh-CN' && 'Business To Customer Renting'}
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row centered columns={3}>
                    <Grid.Column width={12}>
                      <Header inverted as='h4'>
                        {userLang === 'zh-CN' &&
                          '舍予共享衣柜为个人品牌及商户提供平台以出租及销售他们的设计'}
                        {userLang !== 'zh-CN' &&
                          'SHEYOU Provide individual brands, designers and Merchants with a platform to rent out and sell their products.'}
                      </Header>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row centered columns={3}>
                    <Grid.Column width={12}>
                      <Header as='h4' inverted>
                        {userLang === 'zh-CN' &&
                          '我们与专业的洗衣服务机构合作，只要每件大约20元左右的成本让商户出租更加方便'}
                        {userLang !== 'zh-CN' &&
                          'We also cooperates with professional laundry services to make renting for merchants easy with around 20 CYN a piece cost.'}
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
                
                </Parallax>
              <MerchantSec />
              <NavMobilePhone />
          </>
        )}
      </div>
    )
  }
}

export default withRouter(AboutPagePhone)
