import React from 'react'
import 'semantic-ui-css/semantic.min.css'
import { Message, Icon, Grid } from 'semantic-ui-react'

import { HashRouter as Router } from 'react-router-dom'

import { ParallaxProvider } from 'react-scroll-parallax'
import timestamp from 'time-stamp'
import Nav from './component/nav/Nav'
import NavMobile from './component/nav/NavMobile'

import { isMobileOnly } from 'react-device-detect'
import PcPadVersion from './diffApp/PcPadVersion'
import MobileVersion from './diffApp/MobileVersion'

const userLang = navigator.language || navigator.userLanguage

class App extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      log: '',
      logExpire: false,
      windowWidth: 1440,
      windowHeight: 420
    }
    this.closeMessage = this.closeMessage.bind(this)
  }

  componentDidMount () {
    const log = localStorage.getItem('login')
    const self = this
    self.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    })
    window.addEventListener(
      'resize',
      function (event) {
        // console.log(event)
        self.setState({
          windowWidth: window.innerWidth,
          windowHeight: window.innerHeight
        })
      },
      true
    )
    this.setState({
      log: log
    })
    var now = timestamp.utc('YYYY-MM-DD-HH')

    var year = now.substring(0, 4)
    var month = now.substring(5, 7)
    var day = now.substring(8, 10)
    var hour = now.substring(11, 13)

    year = parseInt(year)
    month = parseInt(month)
    day = parseInt(day)
    hour = parseInt(hour)

    const loginTime = localStorage.getItem('loginTime')
    if (loginTime !== null && loginTime !== undefined) {
      var logYear = loginTime.substring(0, 4)
      var logMonth = loginTime.substring(5, 7)
      var logDay = loginTime.substring(8, 10)
      var logHour = loginTime.substring(11, 13)

      logYear = parseInt(logYear)
      logMonth = parseInt(logMonth)
      logDay = parseInt(logDay)
      logHour = parseInt(logHour)
      // console.log(day > logDay)
      if (year > logYear) {
        localStorage.removeItem('userInfo')
        localStorage.setItem('logExpire', true)
        localStorage.setItem('log', false)
        localStorage.setItem('login', false)
        this.setState({
          log: false,
          logExpire: true
        })
      } else if (year === logYear) {
        if (month > logMonth) {
          localStorage.removeItem('userInfo')
          localStorage.setItem('logExpire', true)
          localStorage.setItem('log', false)
          localStorage.setItem('login', false)
          this.setState({
            log: false,
            logExpire: true
          })
        } else if ((month = logMonth)) {
          if (day > logDay) {
            localStorage.removeItem('userInfo')
            localStorage.setItem('logExpire', true)
            localStorage.setItem('log', false)
            localStorage.setItem('login', false)
            this.setState({
              log: false,
              logExpire: true
            })
          } else if (day === logDay) {
            if (hour - logHour > 24) {
              localStorage.removeItem('userInfo')
              localStorage.setItem('logExpire', true)
              localStorage.setItem('log', false)
              localStorage.setItem('login', false)
              this.setState({
                log: false,
                logExpire: true
              })
            }
          }
        }
      }
    }

    // var data={
    //   data:'hello',
    //   tada:"good",
    // }
    // var d = JSON.stringify(data)
    // localStorage.setItem('name',d)
    //   // var json=localStorage.getItem('data')
    //   console.log(d)
    //   localStorage.setItem('test',d)
    //   // var jsonObj
    //   // const name=localStorage.getItem("name")
    //  const test = localStorage.getItem('test')
    // //  console.log(JSON.parse(test))
    // localStorage.removeItem('buyItem')
    // localStorage.removeItem('buyProducts')
    // localStorage.removeItem('rentItem')
    // localStorage.removeItem('rentProducts')
    // const j=[{ll:33}]
    // const l={h:111}
    // j.push(l)
    // console.log(j)
  }
  closeMessage () {
    localStorage.setItem('logExpire', false)
    localStorage.setItem('login', false)
    this.setState({
      logExpire: false
    })
  }

  render () {
    var logExpirel = localStorage.getItem('logExpire')
    var logExpire = this.state.logExpire
    const self = this

    // const breakpoint = 925
    const windWidth = this.state.windowWidth
    // const windHeight = this.state.windowHeight
    // console.log(windWidth)

    return (
      <div>
        <Router>
          {/* browserName */}
          {(isMobileOnly !== true || windWidth > 500) &&
            (logExpirel === 'true' || logExpire === true) && (
              <Grid>
                {userLang === 'zh-CN' && (
                  <Grid.Row columns={1}>
                    <Grid.Column>
                      <Message>
                        <Message.Header>登录已失效</Message.Header>

                        {isMobileOnly && (
                          <Icon
                            name='close'
                            circular
                            onClick={self.closeMessage}
                            style={{ style: 'relative', left: 225 }}
                          />
                        )}
                        {isMobileOnly !== true && (
                          <Icon
                            name='close'
                            circular
                            onClick={self.closeMessage}
                          />
                        )}

                        <p>您的登录时间已失效，请重新登录.</p>
                      </Message>
                    </Grid.Column>
                  </Grid.Row>
                )}
                {userLang !== 'zh-CN' && (
                  <Grid.Row columns={1}>
                    <Grid.Column>
                      <Message>
                        <Message.Header>Log In Expired</Message.Header>

                        {isMobileOnly && (
                          <Icon
                            name='close'
                            circular
                            onClick={self.closeMessage}
                            style={{ style: 'relative', left: 225 }}
                          />
                        )}
                        {isMobileOnly !== true && (
                          <Icon
                            name='close'
                            circular
                            onClick={self.closeMessage}
                          />
                        )}

                        <p>Your time has expired, log in again.</p>
                      </Message>
                    </Grid.Column>
                  </Grid.Row>
                )}
              </Grid>
            )}

          {isMobileOnly !== true && windWidth < 980 && <NavMobile />}
          {isMobileOnly !== true && windWidth >= 980 && <Nav />}
          {(isMobileOnly !== true || windWidth > 500) && <PcPadVersion />}

          {/* {browserName==='Chrome'&& <NavMobilePhone />}    */}
          {/* isMobileOnly === true */}
          {(isMobileOnly === true || windWidth < 500) && (
            <ParallaxProvider>
              <MobileVersion />
            </ParallaxProvider>
          )}
        </Router>
      </div>
    )
  }
}

export default App
