import React from 'react'
import { Parallax } from 'react-parallax'

import { Grid, Header, Button, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
const userLang = navigator.language || navigator.userLanguage

class IntroSec extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
    
    }
  }

  componentDidMount () {
   
  }

  render () {
    return (
      <div>
        <Parallax
                  bgImage={
                    'https://res.cloudinary.com/sheyou/image/upload/v1654418469/snapshot_of_colorful_summer_stylish_outfits_and_pa_2021_09_02_23_57_46_utc_fa385d6696.jpg'
                  }
                  bgImageAlt='merchant page image'
                  strength={-200}
                >
                  <Grid
                    divided
                    style={{
                      paddingTop: 150,
                      paddingBottom: 70,
                      paddingLeft: 50
                    }}
                  >
                    <Header
                      style={{
                        color: 'white',
                        paddingLeft: 100,
                        fontSize:60
                      }}
                      
                    >
                      {userLang === 'zh-CN' && '成为舍予商户'}
                      {userLang !== 'zh-CN' && 'Become Merchant in SHEYOU'}
                    </Header>
                    <Grid.Row columns={6}>
                      <Grid.Column width={1}></Grid.Column>
                      <Grid.Column width={2}>
                        <div>
                          <>
                            <Header
                              style={{
                                color: 'white'
                              }}
                              size='large'
                            >
                              {userLang === 'zh-CN' && '长期利润'}

                              {userLang !== 'zh-CN' && 'Higher Long Term Profit'}
                            </Header>
                            <Header
                               size='small'
                              style={{
                                color: 'white'
                              }}
                            >
                              {userLang === 'zh-CN' &&
                                '租借不会产生退货，租金加卖出的钱意味着更多的利润'}
                                {userLang !== 'zh-CN' &&
                                'Renting means no sales return, and rent plus purchase produce more profit in the long term.'}
                            </Header>
                          </>

                        
                        </div>
                      </Grid.Column>
                      <Grid.Column width={2}>
                        <div>
                          
                            <>
                              <Header
                                style={{
                                  color: 'white'
                                }}
                                size='large'
                              >
                                {userLang === 'zh-CN' && ('租借自由')}
                                {userLang !== 'zh-CN' && ('Freedom To Rent Out')}
                                
                              </Header>
                              <Header
                                size='small'
                                style={{
                                  color: 'white'
                                }}
                              >
                                {userLang === 'zh-CN' && ('商户自定出租时间与租金')}
                                {userLang !== 'zh-CN' && ('Merchants customzie their renting time and money charge')}
                                
                              </Header>
                            </>

                          
                        </div>
                      </Grid.Column>
                      <Grid.Column width={2}>
                        <div>
                         
                            <>
                              <Header
                                style={{
                                  color: 'white'
                                }}
                                size='large'
                              >
                                 {userLang === 'zh-CN' && ('吸引用户')}
                                 {userLang !== 'zh-CN' && ('Attract Customers')}
                                
                              </Header>
                              <Header
                                style={{
                                  color: 'white'
                                }}
                                size='small'
                              >
                                {userLang === 'zh-CN' && ('租借对于用户更划算，也意味着更多笔交易')}
                                 {userLang !== 'zh-CN' && ('Renting is more cost effective for customers, which means more orders')}
                                
                              </Header>
                            </>
                   
                        </div>
                      </Grid.Column>
                      <Grid.Column width={2}>
                        <div>
                          
                            <>
                              <Header
                                style={{
                                  color: 'white'
                                }}
                                size='large'
                              >
                                {userLang === 'zh-CN' && ('更少成本')}
                                {userLang !== 'zh-CN' && ('Lower Production Costs')}
                              </Header>
                              <Header
                                size='small'
                                style={{
                                  color: 'white'
                                }}
                              >
                                {userLang === 'zh-CN' && ('租借需要生产更少的商品，每个季节也有更少的剩余')}
                                {userLang !== 'zh-CN' && ('Renting requires less production, which reduces out-of-season leftovers')}
                                
                              </Header>
                            </>
                         
                        </div>
                      </Grid.Column>

                      <Grid.Column width={1}></Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Grid style={{ paddingLeft: 150, paddingBottom: 150 }}>
                    <Grid.Row columns={4}>
                      <Grid.Column>
                        {/* <Link to='/individualMerchant'> */}
                        <Link to='/merchantOpenShop'>
                          <Button
                            size='huge'
                            circular
                            inverted
                            style={{
                              paddingLeft: 50,
                              paddingRight: 50,
                              width: 300
                            }}
                          >
                            {userLang === 'zh-CN' && '开店'}
                            {userLang !== 'zh-CN' && 'Open Shop'}

                            <Icon
                              name='chevron right'
                              inverted
                              style={{ position: 'relative', top: 2 }}
                            />
                          </Button>
                        </Link>

                        {/* </Link> */}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Parallax>
      </div>
    )
  }
}

export default IntroSec

