import React from 'react'
import { Menu } from 'semantic-ui-react'
// import {
//   Link
// } from "react-router-dom";
import { withRouter } from 'react-router-dom'
const userLang = navigator.language || navigator.userLanguage



class HeartMainFilter extends React.Component {
    constructor(props){
    super(props)
    this.state = { 
      activeItem: 'All' ,
  }
  this.handleItemClick=this.handleItemClick.bind(this)
}


componentDidMount() {
  const self=this
  const activeItem = this.props.activeItem
  // const activeItem=localStorage.getItem('filter')
  if(activeItem!==undefined||activeItem!==null)
  {self.setState({activeItem:activeItem})}
}

handleItemClick(event,data){
  console.log(data)
  var name=data.name
  const link=data.link
  console.log(link)
  const self=this
  window.location.assign('https://sheyoufashion.com/#'+link)
  if (name === undefined||name === null)
  {
    self.props.history.push('/heart')
    window.location.reload(true)
  }
  else
  {
    self.props.history.push(link)
    window.location.reload(true)
    // window.reload()
  }
  
}

  render() {
    const  activeItem  = this.state.activeItem

    return (
      <>
      {userLang === 'zh-CN' && (
        <Menu  fluid vertical  >
          <Menu.Item header>类别</Menu.Item>

          <Menu.Item
            name='全部'
            link='/heart'
            active={activeItem === 'All'}
            onClick={this.handleItemClick}
          />

          <Menu.Item
            name='女性化'
            link='/heart/feminine'
            active={activeItem === 'Feminine'}
            onClick={this.handleItemClick}
          />

          <Menu.Item
            name='男性化'
            link='/heart/masculine'
            active={activeItem === 'Masculine'}
            onClick={this.handleItemClick}
          />

          <Menu.Item
            name='配饰'
            link='/heart/accessaries'
            active={activeItem === 'Accessaries'}
            onClick={this.handleItemClick}
          />
        </Menu>
      )}
      {userLang !== 'zh-CN' && (
        <Menu  fluid vertical  >
          <Menu.Item header>Categories</Menu.Item>

          <Menu.Item
            name='All'
            link='/heart'
            active={activeItem === 'All'}
            onClick={this.handleItemClick}
          />

          <Menu.Item
            name='Feminine'
            link='/heart/feminine'
            active={activeItem === 'Feminine'}
            onClick={this.handleItemClick}
          />

          <Menu.Item
            name='Masculine'
            link='/heart/masculine'
            active={activeItem === 'Masculine'}
            onClick={this.handleItemClick}
          />

          <Menu.Item
            name='Accessaries'
            link='/heart/accessaries'
            active={activeItem === 'Accessaries'}
            onClick={this.handleItemClick}
          />
        </Menu>
      )}
     </>
    )
  }
}
 
export default withRouter(HeartMainFilter)