import React from 'react'
import {
  Grid,
  Menu,
  Header,
  Segment,
  Icon,
} from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import { Parallax } from 'react-parallax'
import Foot from '../../../foot/Foot'
import ContactAddress from './ContactAddress'
import MobilePhone from './MobilePhone'
import IdentityDetail from './IdentityDetail'
import CreditCard from './CreditCard'

class MerchantVerify extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      log: '',
      token: '',
      identifier: '',
      // userInfo: [],
      userID: '',
      // merchantInfo: {},

      activeItem: 'address',

      loading: true
    }
    this.handleMenuClick = this.handleMenuClick.bind(this)
    // this.getUserInfo = this.getUserInfo.bind(this)
    
  }
  componentDidMount () {
    window.scrollTo(0, 0)
    const self = this
    const p = this.props
    const activeItem = p.activeItem
    
    const log = localStorage.getItem('login')
    self.setState({log:log})
    if(log==='true'){
      // self.getUserInfo()
      if (activeItem !== undefined || activeItem !== null) {
        self.setState({
          activeItem: activeItem
        })
      }
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 7000)
  }


  // getUserInfo(){
  //   const self = this
  //   const identifier = localStorage.getItem('identifier')
  //   const token = localStorage.getItem('token')
    
  //   self.setState({
  //     identifier: identifier,
  //     token: token
  //   })
  //   axios('https://sheyou-backend.herokuapp.com/users',{
  //     method: 'get',
  //     headers: {
  //       'Authorization': 'Bearer '+token
  //     }
  //   })
  //     .then(function(res){
  //       console.log(res)
  //       const userInfo = res.data.filter(function(user){
  //         if(user.username === identifier){
  //           return user
  //         }else{
  //           return false
  //         }
  //       })
  //       if(userInfo === null){
  //         self.setState({
  //           userInfo: [],
  //           userID: userInfo[0].id
  //         })
  //       }else if(userInfo.length > 0){
  //         self.setState({
  //           userInfo: userInfo,
  //           userID: userInfo[0].id,
  //         })
  //       }

  //       const merchantInfo = userInfo[0].merchantInfo
  //       console.log(merchantInfo)
  //       if(merchantInfo !== null){
  //         self.setState({merchantInfo: merchantInfo})
  //       }

  //       console.log(merchantInfo)
  //     })

  //     .catch(function(err){
  //       alert('something is wrong, maybe log in again, '+err)
  //       self.props.history.push('/login')
  //     })

  // }

  handleMenuClick (event, data) {
    var name = data.name
    const link = data.link
    const self = this
    if(this.state.activeItem === 'id'){
      window.location.assign('https://sheyoufashion.com/#' + link)
      if (name === undefined || name === null) {
        self.props.history.push('/merchantVerify/address')
        window.location.reload(true)
      } else {
        self.props.history.push(link)
        window.location.reload(true)
      }
    }
  }


  render () {
    const self = this
    const s = self.state
    const loading = s.loading
    const activeItem = s.activeItem
    return (
      <div>
        {loading === true && (
          <Grid>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>

            <Grid.Row colums={2}>
              <Grid.Column width={7}></Grid.Column>
              <Grid.Column width={6}>
                <Header size='huge'>
                  <Icon loading name='asterisk' size='massive' color='yellow' />
                  Loading...
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid>
        )}
        {loading === false && (
          <Parallax
            bgImage='https://i.loli.net/2020/11/25/ZHjYm1wL9runqOz.png'
            bgImageAlt=''
            strength={200}
          >
            <div>
              <br />
              <br />
              <Segment placeholder>
                <Grid>
                  <Grid.Row columns={2}>
                    <Grid.Column width={4}>

                      <Menu stackable fluid tabular vertical icon='labeled'>
                        <Menu.Item
                          name='address'
                          link='/merchantVerify/address'
                          active={activeItem === 'address'}
                          onClick={this.handleMenuClick}
                        >
                          <Icon name='address book' color='teal' />
                          Contact Address
                        </Menu.Item>
                        <Menu.Item
                          name='card'
                          link='/merchantVerify/card'
                          active={activeItem === 'card'}
                          onClick={this.handleMenuClick}
                        >
                          <Icon name='credit card' color='teal' />
                          Credit Card
                        </Menu.Item>

                        <Menu.Item
                          name='phone'
                          link='/merchantVerify/phone'
                          active={activeItem === 'phone'}
                          onClick={this.handleMenuClick}
                        >
                          <Icon name='mobile alternate' color='teal' />
                          Mobile Phone
                        </Menu.Item>

                        <Menu.Item
                          name='id'
                          link='/merchantVerify/id'
                          active={activeItem === 'id'}
                          onClick={this.handleMenuClick}
                        >
                          <Icon name='id card' color='teal' />
                          Identity Detail
                        </Menu.Item>
                      </Menu>
                     
                    </Grid.Column>

                    {activeItem === 'address' && (
                      <Grid.Column  width={12}>
                        <Segment>
                          <ContactAddress />
                        </Segment>
                      </Grid.Column>
                    )}

                    {activeItem === 'phone' && (
                      <Grid.Column  width={12}>
                        <Segment>
                          <MobilePhone />
                        </Segment>
                      </Grid.Column>
                    )}

                    {activeItem === 'card' && (
                      <Grid.Column  width={12}>
                        <Segment>
                          <CreditCard />
                        </Segment>
                      </Grid.Column>
                    )}

                    {activeItem === 'id' && (
                      <Grid.Column  width={12}>
                        <Segment>
                          <IdentityDetail />
                        </Segment>
                      </Grid.Column>
                    )}
                  </Grid.Row>
                </Grid>
              </Segment>
              <br />
              <br />
            </div>
          </Parallax>
        )}

        <Foot />
      </div>
    )
  }
}

export default withRouter(MerchantVerify)
