import React from 'react'
import axios from 'axios'
import { Grid, Header, Icon, Label, Segment, Pagination } from 'semantic-ui-react'
import MainFilter from './AccessoryFilter'
import { withRouter } from 'react-router-dom'
import CardTemplete from './AccessoryCardTemplete'
import Foot from '../foot/Foot'
import { Parallax } from 'react-parallax';
import round from '@stdlib/math-base-special-round'
const userLang = navigator.language || navigator.userLanguage


class AccessaryPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      products: [],
      loading: true,
      activePage: 1,
      pageNum: 0
      // productId
    }
    this.handlePaginationChange = this.handlePaginationChange.bind(this)

  }
  componentDidMount() {

    window.scrollTo(0, 0)
    const self = this
    const p = this.props
    var page = p.location.search.substring(3)
    console.log(page)
    if (page === null || page === undefined || page === '') {
      page = 1
    }
    const activeItem = p.activeItem

    self.setState({
      activeItem: activeItem,
    })
    if (page !== undefined && page !== null) {
      self.setState({
        activePage: page
      })
      this.getProducts()
    }
    window.onload = (event) => {
      self.setState({
        loading: false
      })
    };

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }

    }, 500);
    this.getProducts()

  }
  handlePaginationChange(e, { activePage }) {
    this.setState({ activePage })
    const thisPath = this.props.history.location.pathname
    this.props.history.push(thisPath + '?p=' + activePage)
    window.location.reload(true)


  }

  getProducts() {
    const self = this

    const p = this.props
    var activeItem = p.activeItem
    if (activeItem === undefined) {
      activeItem = "All Accessories"
    }

    if (activeItem !== 'All Accessories') {
      axios.get('https://sheyou-backend.herokuapp.com/accessory-categories?name=' + activeItem)
        .then(function (res) {
          if (res.status === 200) {
            console.log(res)
            const data = res.data[0].accessories
            const itemNum = data.length
            var activePage = self.state.activePage
            activePage = parseInt(activePage)
            self.setState({
              products: data,
              loading: false
            })

            var dataShow = []
            for (var i = activePage * 9 - 9; i < activePage * 9; i++) {
              if (i < itemNum) {
                dataShow.push(data[i])
              }
            }

            var roundPageNum = 0
            var pageNum = 0
            roundPageNum = round(itemNum / 9)
            pageNum = itemNum / 9.0

            if(pageNum - roundPageNum  > 0){
              pageNum = roundPageNum + 1
            }

            console.log(dataShow)
            self.setState({
              products: dataShow,
              pageNum: pageNum,
              loading: false,
              itemNum: itemNum
            })
          }
          else {
            alert('something went wrong :(')
            localStorage.setItem('login', false)
          }

        })
    } else {
      axios.get('https://sheyou-backend.herokuapp.com/accessaries')
        .then(function (res) {
          if (res.status === 200) {
            console.log(res)
            const data = res.data

            const itemNum = data.length
            var activePage = self.state.activePage
            activePage = parseInt(activePage)


            var dataShow = []
            for (var i = activePage * 9 - 9; i < activePage * 9; i++) {
              if (i < itemNum) {
                dataShow.push(data[i])
              }
            }

            var roundPageNum = 0
            var pageNum = 0
            roundPageNum = round(itemNum / 9)
            pageNum = itemNum / 9.0

            if (pageNum - roundPageNum > 0) {
              pageNum = roundPageNum + 1
            }

            self.setState({
              products: dataShow,
              pageNum: pageNum,
              loading: false,
              itemNum: itemNum
            })
          }
          else {
            alert('something went wrong :(')
            localStorage.setItem('login', false)
          }

        })
    }

  }

  // onHandleClick(event,data){
  //   const self=this
  //   const productId= data.id
  //   event.preventDefault()
  //   localStorage.setItem('productId',productId)
  //   self.props.history.push('/product')

  // }

  render() {
    const s = this.state
    const self = this
    const loading = this.state.loading

    // const self=this
    return <div>
      {/* <Nav /> */}


      {loading ? (
        <Grid >
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>


          <Grid.Row colums={2}>
            <Grid.Column width={7}></Grid.Column>
            <Grid.Column width={6}>
              <Header size='huge'>
                <Icon loading name='asterisk' size='massive' color='yellow' />
                Loading...
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
        </Grid>
      ) : (
        <Grid stackable style={{marginTop:10}}>
          <Grid.Column floated='left' width={4} style={{ paddingLeft: 80 }}>


            {userLang === 'zh-CN' && (
              <Label tag size='big'>
                配饰
              </Label>
            )}

            {userLang !== 'zh-CN' && (
              <Label tag size='big'>
                Accessory
              </Label>
            )}

            <MainFilter activeItem={this.state.activeItem} />

            {/* <Segment> */}


          </Grid.Column>

          {s.products.length === 0 &&

            <Grid.Column width={12} style={{ paddingRight: 50 }}>

              <Parallax
                bgImage='https://i.loli.net/2020/11/25/ZHjYm1wL9runqOz.png'
                bgImageAlt=''
                strength={200}>

                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />

                <div>
                  <Segment>
                    <Grid>
                      <Grid.Row centered>
                        {/* <Header>Sorry, we couldn’t find any matches for your search criteria</Header> */}
                        <Header>
                          <Icon loading name='asterisk' size='massive' color='yellow' />

                          Loading...
                        </Header>
                      </Grid.Row>

                      {/* <Grid.Row centered>
                        <Button size='huge' color='orange' onClick={this.handleItemClick}> Browse All</Button>
                      </Grid.Row> */}
                    </Grid>
                  </Segment>
                </div>

                <div style={{ height: "270px" }} />
              </Parallax>

            </Grid.Column>

          }
          {s.products.length > 0 && (
            <Grid.Column width={12}>


              <CardTemplete products={s.products} />
              <Grid>
                <Grid.Row centered>
                  <Pagination

                    onPageChange={this.handlePaginationChange}
                    activePage={self.state.activePage}
                    pointing
                    secondary
                    ellipsisItem={false}
                    totalPage = {this.state.pageNum}
                  />
                </Grid.Row>
              </Grid>

            </Grid.Column>
          )}
          

        </Grid>
      )}
      <Foot />

    </div>


  }

}

export default withRouter(AccessaryPage)

