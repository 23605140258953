import React from 'react'
import { Header } from 'semantic-ui-react'
const userLang = navigator.language || navigator.userLanguage

const HomeTextPhone = () => (
  <div>
    <Header as='h5' style={{ color: 'white' }}>
      {userLang === 'zh-CN' && 'SHEYOU共享衣橱 = 租借 + 购买'}
      {userLang !== 'zh-CN' && 'SHEYOU Clothes Sharing = Clothes Rental + Sale'}
    </Header>

    <Header style={{ color: 'white', fontSize: 30 }}>
      {userLang === 'zh-CN' && '新一代时尚租赁平台'}
      {userLang !== 'zh-CN' && 'Next-generation Fashion Renting platform'}
    </Header>

    {/* <Header as='h5' style={{ color: 'white' }}>
      Renting multiple clothes and return whenever you want, or try purchasing
      them with a discount, you can have more styles with less budget
    </Header> */}
  </div>
)

export default HomeTextPhone
