import React from 'react'
import { Parallax} from 'react-parallax';

 
class SusParall extends React.Component{
    render(){
        const p=this.props
        const image=p.image
        const name=p.name
        const content1=p.content1
        const strength=p.strength

        return<Parallax
            bgImage={image}
            bgImageAlt={name}
            strength={strength}>
            
            <div 
            style={{ position:"absolute", top:200, left:150} }
            cenetred
            >
                {content1}
            
            </div>
            

            <div style={{ height:"800px"} }/>
        </Parallax>
    
}
    
} 

export default SusParall