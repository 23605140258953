import React from 'react'
import {
    Grid, Form, Button, Message,
    Container, Segment, Header, Icon
} from 'semantic-ui-react'

import { Parallax, } from 'react-parallax';
import axios from 'axios'
import { withRouter } from 'react-router-dom'
// import Nav from '../nav/Nav'
import ReactCodeInput from 'react-verification-code-input'
const userLang = navigator.language || navigator.userLanguage






class VerifyEmail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            verificationCode: '',
            code: '',
            codeCorrect: '',
            EmailChangeSuccess: '',
            email: '',
            userInfo: ''
        }
        // this.onHandleNewPassword=this.onHandleNewPassword.bind(this)
        // this.onHandleConfirm=this.onHandleConfirm.bind(this)
        this.onHandleCode = this.onHandleCode.bind(this)
        this.onHandleSubmit = this.onHandleSubmit.bind(this)

    }

    componentDidMount() {

        const self = this

        var userInfo = localStorage.getItem('userInfo')
        userInfo=JSON.parse(userInfo)
        const token = localStorage.getItem('token')
        const email = localStorage.getItem('email')
        self.setState({
            email: email,
            id: userInfo[0].id,
            token: token
        })

        const verificationCode = localStorage.getItem('verificationCode')
        self.setState({
            verificationCode: verificationCode
        })

        window.onload = (event) => {
            self.setState({
                loading: false
            })
        };

        window.setTimeout(() => {
            if (self.state.loading === true) {
                this.setState({
                    loading: false
                })
            }

        }, 700);
    }


    onHandleCode(event) {
        this.setState({
            code: event
        })

    }


    onHandleSubmit() {
        const self = this
        const code = self.state.code
        const verificationCode = self.state.verificationCode
        const email = self.state.email
        const id = this.state.id
        const token = this.state.token

        if (code === verificationCode) {

            axios('https://sheyou-backend.herokuapp.com/users/' + id, {
                method: 'put',
                data: {
                    email: email
                },
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            }).then(function (res) {
                console.log(res)
                if (res.status === 200) {
                    self.setState({
                        EmailChangeSuccess: true
                    })

                } else {
                    self.setState({
                        EmailChangeSuccess: false
                    })
                }

            }).catch(function (err) {
                self.setState({
                    EmailChangeSuccess: false
                })
            })

        } else {
            self.setState({
                codeCorrect: false
            })
        }



    }

    render() {
        const EmailChangeSuccess = this.state.EmailChangeSuccess
        const codeCorrect = this.state.codeCorrect
        const loading=this.state.loading
        return <div>
            {/* <Nav /> */}

            {loading === true &&
                <Grid >
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>


                    <Grid.Row colums={2}>
                        <Grid.Column width={7}></Grid.Column>
                        <Grid.Column width={6}>
                            <Header size='huge'>
                                <Icon loading name='asterisk' size='massive' color='yellow' />
                                Loading...
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                </Grid>
            }
            {loading === false && <Parallax
                bgImage='https://i.loli.net/2020/11/25/ZHjYm1wL9runqOz.png'
                bgImageAlt=''
                strength={400}>


                <div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    {userLang === 'zh-CN' && (
                        <Container>
                            <Segment>
                                <Form stackable >
                                    <Message info>
                                        <Message.Header>验证码已发送至您的邮箱</Message.Header>
                                    </Message>
                                    <Grid centered columns={2}>
                                        <Grid.Column>
                                        <ReactCodeInput onChange={this.onHandleCode} />
                                        </Grid.Column>
                                    </Grid>

                                    {codeCorrect === false &&
                                        <Message negative>
                                            <Message.Header>您的验证码错误</Message.Header>
                                        </Message>}
                                    {EmailChangeSuccess === true &&
                                        <Message positive>
                                            <Message.Header>您的邮箱地址已成功更换</Message.Header>
                                        </Message>}
                                    {EmailChangeSuccess === false &&
                                        <Message warning>
                                            <Message.Header>出错了</Message.Header>
                                        </Message>}


                                </Form>

                                <br />
                                <br />


                                <Grid columns={4} centered>

                                    <Grid.Column width={4}>
                                        <Button onClick={this.onHandleSubmit} type='submit' fluid size='large' floated='right' content='Continue' color='orange' />
                                    </Grid.Column>

                                </Grid>
                            </Segment>
                        </Container>
                    )}
                    {userLang !== 'zh-CN' && (
                        <Container>

                            <Segment>
                                <Form stackable >
                                    <Message info>
                                        <Message.Header>We have sent a verification code in your new email</Message.Header>
                                    </Message>
                                    <Grid centered columns={2}>
                                        <Grid.Column>
                                        <ReactCodeInput onChange={this.onHandleCode} />
                                        </Grid.Column>
                                    </Grid>

                                    {codeCorrect === false &&
                                        <Message negative>
                                            <Message.Header>Your Verification Code Is Not Correct</Message.Header>
                                        </Message>}
                                    {EmailChangeSuccess === true &&
                                        <Message positive>
                                            <Message.Header>Your Email Has Changed Successfully</Message.Header>
                                        </Message>}
                                    {EmailChangeSuccess === false &&
                                        <Message warning>
                                            <Message.Header>Something Went wrong</Message.Header>
                                        </Message>}


                                </Form>

                                <br />
                                <br />


                                <Grid columns={4} centered>

                                    <Grid.Column width={4}>
                                        <Button onClick={this.onHandleSubmit} type='submit' fluid size='large' floated='right' content='Continue' color='orange' />
                                    </Grid.Column>

                                </Grid>
                            </Segment>


                            {/* {sentEmail === true &&
                                <Segment>

                                    <Grid centered colums={2}>
                                        <Grid.Row></Grid.Row>
                                        <Grid.Row></Grid.Row>
                                        <Grid.Row>
                                            <Header size='huge' color='orange'>
                                                <Icon name='smile outline' />
                                                <Header.Content>Rest Link Has Sent To Your Email</Header.Content>

                                            </Header>
                                        </Grid.Row>

                                        <Grid.Row></Grid.Row>
                                        <Grid.Row></Grid.Row>
                                    </Grid>
                                </Segment>

                            }

                            {sentEmail === false &&
                                <Segment>

                                    <Grid centered colums={2}>
                                        <Grid.Row></Grid.Row>
                                        <Grid.Row></Grid.Row>
                                        <Grid.Row>
                                            <Header size='huge' color='orange'>
                                                <Icon name='exclamation triangle' />
                                                <Header.Content>Something Went Wrong, Please Try Again</Header.Content>

                                            </Header>
                                        </Grid.Row>

                                        <Grid.Row></Grid.Row>
                                        <Grid.Row></Grid.Row>
                                    </Grid>
                                </Segment>
                            } */}

                        </Container>
                    )}
                </div>

                <div style={{ height: "300px" }} />
            </Parallax>}



        </div>
    }

}

export default withRouter(VerifyEmail)









