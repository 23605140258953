import React from 'react'

import {
    Grid,
    Image,
    Header,
    Icon,
    Segment,
    Button,
    Form,
    Input,
    Divider
} from 'semantic-ui-react'

import { withRouter } from 'react-router-dom'

class MerchantStore extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            loading: true
        }
    }
    
    componentDidMount() {
        const self = this
        window.onload = event => {
            self.setState({
                loading: false
            })
        }

        window.setTimeout(() => {
            if(self.state.loading === true) {
                this.setState({
                    loading: false
                })
            }
        }, 700)
    }


    render(){
        const self = this
        const options = [{}] //这个之后应该会找一个专门的地址package from github
        
        return (
            <>
            {this.state.loading ? (
                <Grid>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>

                    <Grid.Row colums={2}>
                    <Grid.Column width={7}></Grid.Column>
                    <Grid.Column width={6}>
                        <Header size='huge'>
                        <Icon loading name='asterisk' size='massive' color='yellow' />
                        Loading...
                        </Header>
                    </Grid.Column>
                    </Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                    <Grid.Row></Grid.Row>
                </Grid>
            ) : (
                <Segment>
                    <Grid container columns='equal'>
                        <Grid.Row columns={2}>
                            <Grid.Column width={10}>
                                <Header>Store Detail</Header>
                                <Divider />
                                <Form>
                                    <Form.Field>
                                        <label>Store Name</label>
                                        <Input placeholder='Store Name'/>
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Store ID</label>
                                        {/* 先这样，之后placeholder直接get ID display*/}
                                        <Input 
                                            action={{
                                                color: 'teal',
                                                labelPosition: 'left',
                                                icon: 'copy',
                                                content: 'Copy'
                                            }}
                                            actionPosition='right'
                                            fluid 
                                            placeholder='Store ID' 
                                            readOnly
                                        />
                                    </Form.Field>
                                </Form>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={1}>
                            <Grid.Column width={5}>
                                <Header >Store Logo</Header>
                                <Divider/>
                                <Image src='https://res.cloudinary.com/sheyou/image/upload/v1649165084/_b7d786a54e.png?21902375.5' />
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={1}>
                            <Grid.Column width={16}>
                                <Header>Address</Header>
                                <Divider/>
                                <Form>
                                    <Form.Group widths='equal'>
                                        <Form.Select fluid options={[{}]} placeholder='Country' label='Country'/>
                                        <Form.Input fluid placeholder='Province' label='Province'/>
                                        <Form.Input fluid placeholder='City' label='City' />
                                    </Form.Group>
                                    <Form.Input fluid label='Exact Address' placeholder='Exact Address'/>
                                </Form>
                            </Grid.Column>
                        </Grid.Row>
                   
                    
                        <Grid.Row centered>
                            <Button color='orange' style={{ paddingLeft: 40, paddingRight: 40 }}>SAVE</Button>
                        </Grid.Row>

                    </Grid>
                </Segment>
            )}
            </>
        )
    }
}


export default withRouter(MerchantStore)