import React from 'react'
import {
  Grid,
  Icon,
  Image,
  Segment,
  Container,
  Button,
  Header,
  Popup,
  Checkbox,
  GridColumn,
  GridRow
} from 'semantic-ui-react'
import axios from 'axios'
import Foot from '../foot/Foot'
import Nav from './Nav'
import { withRouter } from 'react-router-dom'
import timestamp from 'time-stamp'

class LoggingPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount () {
    // window.setTimeout(() => {
    //     window.location.reload(true)

    // }, 100);
    window.setTimeout(() => {
      this.props.history.push('/user')
      window.location.reload(true)
    }, 1000)
  }

  render () {
    return (
      <Grid>
        <Grid.Row style={{ position: 'absolute', top: '50%' }} centered>
          <Header size='huge'>
            <Icon loading name='asterisk' size='huge' color='yellow' />
            Logging You In...
          </Header>
        </Grid.Row>
      </Grid>
    )
  }
}

export default withRouter(LoggingPage)
