import React from 'react'
import { Menu } from 'semantic-ui-react'
// import {
//   Link
// } from "react-router-dom";
import { withRouter } from 'react-router-dom'
const userLang = navigator.language || navigator.userLanguage



class SaleMainFilter extends React.Component {
    constructor(props){
    super(props)
    this.state = { 
      activeItem: 'All' ,
  }
  this.handleItemClick=this.handleItemClick.bind(this)
}


componentDidMount() {
  const self=this
  const activeItem = this.props.activeItem
  if(activeItem!==undefined||activeItem!==null)
  {self.setState({activeItem:activeItem})}
}

handleItemClick(event,data){
  var name=data.name
  const link=data.link
  const self=this
  window.location.assign('https://sheyoufashion.com/#'+link)
  if (name===undefined||name===null)
  {
    self.props.history.push('/sale')
    window.location.reload(true)
  }
  else
  {
    self.props.history.push(link)
    window.location.reload(true)
  }
}

  render() {
    const  activeItem  = this.state.activeItem

    return (
      <>
        {userLang === 'zh-CN' && (
          <Menu  fluid vertical  >
            <Menu.Item header>类别</Menu.Item>
    
            <Menu.Item
              name='全部'
              link='/sale'
              active={activeItem === 'All'}
              onClick={this.handleItemClick}
            />
    
            <Menu.Item
              name='女性化'
              link='/sale/feminine'
              active={activeItem === 'Feminine'}
              onClick={this.handleItemClick}
            />
    
            <Menu.Item
              name='男性化'
              link='/sale/masculine'
              active={activeItem === 'Masculine'}
              onClick={this.handleItemClick}
            />
    
          </Menu>
        )}
        {userLang !== 'zh-CN' && (
          <Menu  fluid vertical  >
            <Menu.Item header>Categories</Menu.Item>
    
            <Menu.Item
              name='All'
              link='/sale'
              active={activeItem === 'All'}
              onClick={this.handleItemClick}
            />
    
            <Menu.Item
              name='Feminine'
              link='/sale/feminine'
              active={activeItem === 'Feminine'}
              onClick={this.handleItemClick}
            />
    
            <Menu.Item
              name='Masculine'
              link='/sale/masculine'
              active={activeItem === 'Masculine'}
              onClick={this.handleItemClick}
            />
    
          </Menu>
        )}
      </>
     
    )
  }
}
 
export default withRouter(SaleMainFilter)