import React from 'react'
import axios from 'axios'
import {
  Grid,
  Header,
  Icon,
  Pagination,
  Segment,
  Label,
  // Button
} from 'semantic-ui-react'
import MainFilter from './MainFilter'
import SizeFilter from './SizeFilter'
import { withRouter } from 'react-router-dom'
// import Nav from '../nav/Nav'
import CardTemplete from './CardTemplete'
import Foot from '../foot/Foot'
import { Parallax } from 'react-parallax'
import round from '@stdlib/math-base-special-round'
const userLang = navigator.language || navigator.userLanguage


class ClothingPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      products: [],
      loading: true,
      activePage: 1,
      pageNum: 0

      // productId
    }
    this.handlePaginationChange = this.handlePaginationChange.bind(this)
  }
  componentDidMount () {
    const self = this
    const p = this.props
    var page = p.location.search.substring(3)

    console.log(page === '')
    if (page === null || page === undefined || page === '') {
      page = 1
    }

    const is = p.is
    const activeItem = p.activeItem

    self.setState({
      activeItem: activeItem,
      is: is
    })
    console.log(page)
    if (page !== undefined && page !== null) {
      self.setState({
        activePage: page
      })
      this.getProducts()
    }

    window.scrollTo(0, 0)

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 500)
  }
  handlePaginationChange (e, { activePage }) {
    this.setState({ activePage })
    const thisPath = this.props.history.location.pathname
    this.props.history.push(thisPath + '?p=' + activePage)
    window.location.reload(true)
  }

  getProducts () {
    const self = this

    const p = this.props
    const is = p.is
    var activeItem = p.activeItem

    if (activeItem !== 'All') {
      activeItem.toLowerCase()
      axios
        .get(
          'https://sheyou-backend.herokuapp.com/' + is + '?name=' + activeItem
        )
        .then(function (res) {
          if (res.status === 200) {
            const data = res.data[0].products
            console.log(data)
            const itemNum = data.length
            const activePage = self.state.activePage

            var dataShow = []
            for (var i = activePage * 9 - 9; i < activePage * 9; i++) {
              if (i < itemNum) {
                dataShow.push(data[i])
              }
            }

            var roundPageNum = 0
            var pageNum = 0
            roundPageNum = round(itemNum / 9)
            pageNum = itemNum / 9.0

            if (pageNum - roundPageNum > 0) {
              pageNum = roundPageNum + 1
            }
            self.setState({
              products: dataShow,
              pageNum: pageNum,
              loading: false,
              itemNum: itemNum
            })
          } else {
            alert('something went wrong :(')
            localStorage.setItem('login', false)
          }
        })
    } else {
      axios
        .get('https://sheyou-backend.herokuapp.com/products')
        .then(function (res) {
          console.log(res)
          if (res.status === 200) {
            const data = res.data
            const itemNum = data.length
            const activePage = self.state.activePage
            console.log(activePage)

            var dataShow = []
            for (var i = activePage * 9 - 9; i < activePage * 9; i++) {
              if (i < itemNum) {
                dataShow.push(data[i])
              }
            }

            var roundPageNum = 0
            var pageNum = 0
            roundPageNum = round(itemNum / 9)
            pageNum = itemNum / 9.0

            if (pageNum - roundPageNum > 0) {
              pageNum = roundPageNum + 1
            }

            console.log(data)
            self.setState({
              products: dataShow,
              pageNum: pageNum,
              loading: false,
              itemNum: itemNum
            })
          } else {
            alert('something went wrong :(')
            localStorage.setItem('login', false)
          }
        })
      // .catch(function(e){
      //   console.log(e)
      // })
    }
  }

  render () {
    const s = this.state
    const loading = s.loading
    const self = this
    // const n = { position: 'absolute', top: 1000 }
    return (
      <div>
        {/* <Nav /> */}

        {loading ? (
          <Grid >
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>


          <Grid.Row colums={2}>
              <Grid.Column width={7}></Grid.Column>
              <Grid.Column width={6}>
                  <Header size='huge'>
                      <Icon loading name='asterisk' size='massive' color='yellow' />
                      Loading...
                  </Header>
              </Grid.Column>
          </Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
          <Grid.Row></Grid.Row>
      </Grid>
        ) : (
          <Grid stackable style={{marginTop:10}}>
            <Grid.Column floated='left' width={4} style={{ paddingLeft: 80 }}>
              {userLang === 'zh-CN' && (
                <Label tag size='big'>
                  服装
                </Label>
              )}
              {userLang !== 'zh-CN' && (
                <Label tag size='big'>
                  Clothing
                </Label>
              )}

              <MainFilter activeItem={this.state.activeItem} />
              <SizeFilter activeItem={this.state.activeItem} />

              {/* <Segment> */}
            </Grid.Column>

            {s.products.length === 0 && (
              <Grid.Column width={12} style={{ paddingRight: 50 }}>
                <Parallax
                  bgImage='https://i.loli.net/2020/11/25/ZHjYm1wL9runqOz.png'
                  bgImageAlt=''
                  strength={200}
                >
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />

                  <div>
                    <Segment>
                      <Grid>
                        <Grid.Row centered>
                          <Header>
                            <Icon
                              loading
                              name='asterisk'
                              size='massive'
                              color='yellow'
                            />
                            Loading ...
                          </Header>
                        </Grid.Row>

                        {/* <Grid.Row centered>
                      <Button size='huge' color='orange' onClick={this.handleItemClick}> Browse All</Button>
                    </Grid.Row> */}
                      </Grid>
                    </Segment>
                  </div>

                  <div style={{ height: '270px' }} />
                </Parallax>
              </Grid.Column>
            )}
            <Grid.Column width={12}>
              <CardTemplete products={s.products} />
              <Grid>
                <Grid.Row centered>
                  <Pagination
                    onPageChange={this.handlePaginationChange}
                    activePage={self.state.activePage}
                    pointing
                    secondary
                    ellipsisItem={false}
                    totalPages={this.state.pageNum}
                  />
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid>

        )}
        <Foot />
      </div>
    )
  }
}

export default withRouter(ClothingPage)
