import React, { useEffect, useState } from 'react'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { Button, Form } from 'semantic-ui-react'
const userLang = navigator.language || navigator.userLanguage

export default function CheckoutForm () {
  const self = this
  const stripe = useStripe()
  const elements = useElements()

  const [message, setMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!stripe) {
      return
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    )

    if (!clientSecret) {
      return
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!')
          break
        case 'processing':
          setMessage('Your payment is processing.')
          break
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.')
          break
        default:
          setMessage('Something went wrong.')
          break
      }
    })
  }, [stripe])

  const handleSubmit = async e => {
    e.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setIsLoading(true)

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: 'https://sheyoufashion.com/#/result/success'
      }
    })

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message)
      self.props.history.push('/result/fail')
    } else {
      setMessage('An unexpected error occured.')
      self.props.history.push('/result/fail')
    }

    setIsLoading(false)
  }

  return (
    <Form id='payment-form' onSubmit={handleSubmit}>
      <br />
      <br />
      <PaymentElement id='payment-element' />

      <br />
      <br />

      {userLang === 'zh-CN' && (
        <Button
          color='teal'
          disabled={isLoading || !stripe || !elements}
          id='submit'
        >
          <span id='button-text'>
            {isLoading ? (
              <div className='spinner' id='spinner'></div>
            ) : (
              '支付'
            )}
          </span>
        </Button>
      )}

      {userLang !== 'zh-CN' && (
        <Button
          color='teal'
          disabled={isLoading || !stripe || !elements}
          id='submit'
        >
          <span id='button-text'>
            {isLoading ? (
              <div className='spinner' id='spinner'></div>
            ) : (
              'Pay now'
            )}
          </span>
        </Button>
      )}

      {/* Show any error or success messages */}
      {message && <div id='payment-message'>{message}</div>}
      <br />
      <br />
    </Form>
  )
}
