import React from 'react'
import { Button } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'


class SendSMSCode extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
    this.sendSMSCode = this.sendSMSCode.bind(this)
  }

  componentDidMount () {}

  sendSMSCode () {

  

  }

  render () {
    return (
      <div>
        <Button onClick={this.sendSMSCode}>Send Code</Button>
      </div>
    )
  }
}

export default withRouter(SendSMSCode)
