import React from 'react'
import axios from 'axios'
import {
  Grid,
  Button,
  Header,
  Pagination,
  Segment,
  Icon,
  Label,
  Menu,
  Sidebar,
  Image
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import round from '@stdlib/math-base-special-round'
// import Nav from '../nav/Nav'
import { Parallax } from 'react-parallax'
import { withRouter } from 'react-router-dom'
// import MainFilter from './HeartMainFilter'
// import SizeFilter from './HeartSizeFilter'
import CardTempletePhone from '../clothingPage/CardTempletePhone'
// import HandleBuyCartDelete from '../cartFunction/HandleBuyCartDelete'
import LoadingPhone from '../RedirectPages/LoadingPhone'
import NavMobilePhone from '../nav/NavMobilePhone'
import LogInPagePhonePop from '../mypage/LogInPagePhonePop'

class HeartPagePhone extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      userInfo: [],
      userID: '',
      heartList: [],
      heartProducts: [],
      membership: [],
      loading: true,
      dataloading: true,
      accessoryHeartList: [],
      accessoryHeartProducts: [],
      activePage: 1,
      pageNum: 0,
      dataShow: [],
      login: false,

      categoriesSelected: 'Select',
      categoriesVisible: '',
      sizesVisible: '',
      sizesSelected: 'Select',
      activeItemPath: 'H',
      activeItem: 'All',
      cart: [],
      bag: [],
      accessoryCart: [],
      accessoryBag: [],
      signinVisible: ''
    }
    this.getProductInHeartList = this.getProductInHeartList.bind(this)
    this.getAccessoryInHeartList = this.getAccessoryInHeartList.bind(this)
    this.getPageNum = this.getPageNum.bind(this)

    this.signinVisible = this.signinVisible.bind(this)
    this.handlePaginationChange = this.handlePaginationChange.bind(this)
    this.categoriesVisible = this.categoriesVisible.bind(this)
    this.sizesVisible = this.sizesVisible.bind(this)
    this.handleItemClick = this.handleItemClick.bind(this)
    this.handleItemClickCart = this.handleItemClickCart.bind(this)
  }

  signinVisible () {
    const signinVisible = this.state.signinVisible
    console.log(signinVisible)

    if (signinVisible !== true) {
      this.setState({
        signinVisible: true
      })
    } else if (signinVisible === true) {
      this.setState({
        signinVisible: false
      })
    }
  }

  componentDidMount () {
    window.scrollTo(0, 0)
    const self = this
    const p = this.props

    const is = p.is
    const activeItem = p.activeItem

    self.setState({
      activeItem: activeItem,
      is: is //这个is是为了区分category和sizes
    })
    const login = localStorage.getItem('login')
    self.setState({
      login: login
    })
    var page = p.location.search.substring(3)
    console.log(page === '')
    if (page === null || page === undefined || page === '') {
      page = 1
    }
    console.log(page)
    if (page !== undefined && page !== null) {
      self.setState({
        activePage: page
      })
      this.getProductInHeartList()
    }

    window.onload = event => {
      self.setState({
        loading: false
      })
    }

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 2000)
    const identifier = localStorage.getItem('identifier')
    const token = localStorage.getItem('token')

    self.setState({
      identifier: identifier,
      token: token
    })

    axios('https://sheyou-backend.herokuapp.com/users/me', {
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (res) {
      if (res.status === 200) {
        const userInfo = [res.data]
        if (userInfo[0].heart === null) {
          userInfo[0].heart = []
        }
        if (userInfo[0].accessoryHeart === null) {
          userInfo[0].accessoryHeart = []
        }
        self.setState({
          userInfo: userInfo,
          userID: userInfo[0].id,
          heartList: userInfo[0].heart,
          accessoryHeartList: userInfo[0].accessoryHeart,
          membership: userInfo[0].membership
        })
        self.getProductInHeartList()
        self.getAccessoryInHeartList()
        // self.getPageNum()
        self.ifLog()
      } else {
        alert('Something went wrong, maybe log in again :(')
        localStorage.setItem('login', 'false')
      }
    })
  }

  handlePaginationChange (e, { activePage }) {
    this.setState({ activePage })
    const thisPath = this.props.history.location.pathname
    this.props.history.push(thisPath + '?p=' + activePage)
    window.location.reload(true)
  }

  getProductInHeartList () {
    const self = this
    const p = this.props
    const is = p.is
    const activeItem = p.activeItem
    console.log(activeItem)

    const cartIt = self.state.heartList
    const heartProducts = []

    if (cartIt === [] || cartIt == null) {
      self.setState({ heartEmpty: true })
    } else {
      // console.log(cartIt)
      self.setState({ heartEmpty: false })

      // self.setState({rentProducts:''})

      cartIt.forEach(function (cart, cartIdx, array) {
        activeItem.toLowerCase()
        // console.log(cart.type)
        if (cart.type === 'sale') {
          axios
            .get(
              'https://sheyou-backend.herokuapp.com/second-hand-products?id=' +
                cart.id
            )
            .then(function (res) {
              // console.log(res)
              if (res.status === 200) {
                res.data[0].type = 'sale'
                // console.log(is)
                if (is === 'categories') {
                  res.data[0].categories.forEach(function (c) {
                    if (c.name === activeItem) {
                      heartProducts.push(res.data[0])
                    }
                  })
                } else {
                  res.data[0].sizes.forEach(function (c) {
                    if (c.name === activeItem) {
                      heartProducts.push(res.data[0])
                    }
                  })
                }

                self.setState({
                  heartProducts: heartProducts
                  // loading: false,
                })
                if (cartIdx === array.length - 1) {
                  self.setState({
                    dataloading: false
                  })
                }
                self.getPageNum()
              } else {
                alert('something went wrong :(')
                localStorage.setItem('login', false)
              }
            })
        } else {
          axios
            .get('https://sheyou-backend.herokuapp.com/products?id=' + cart.id)
            .then(function (res) {
              if (res.status === 200) {
                if (is === 'categories') {
                  res.data[0].categories.forEach(function (c) {
                    if (c.name === activeItem) {
                      heartProducts.push(res.data[0])
                    }
                  })
                } else {
                  res.data[0].sizes.forEach(function (c) {
                    if (c.name === activeItem) {
                      heartProducts.push(res.data[0])
                    }
                  })
                }

                self.setState({
                  heartProducts: heartProducts
                  // loading: false,
                })
                if (cartIdx === array.length - 1) {
                  self.setState({
                    dataloading: false
                  })
                }
                self.getPageNum()
              } else {
                alert('something went wrong :(')
                localStorage.setItem('login', 'false')
              }
            })
        }
      })
    }
  }

  getAccessoryInHeartList () {
    const self = this

    const cartIt = self.state.accessoryHeartList
    const heartProducts = []
    const accessoryHeartProducts = []

    if (cartIt === [] || cartIt === null) {
      self.setState({ heartEmpty: true })
    } else {
      self.setState({ heartEmpty: false })

      // self.setState({rentProducts:''})

      cartIt.forEach(function (cart, cartIdx, array) {
        axios
          .get('https://sheyou-backend.herokuapp.com/accessaries?id=' + cart.id)
          .then(function (res) {
            if (res.status === 200) {
              heartProducts.push(res.data[0])

              self.setState({
                accessoryHeartProducts: heartProducts
                // loading: false,
              })
              if (cartIdx === array.length - 1) {
                self.setState({
                  dataloading: false
                })
              }
              self.getPageNum()
            } else {
              alert('something went wrong :(')
              localStorage.setItem('login', 'false')
            }
          })
      })
      console.log(accessoryHeartProducts)
    }
  }

  getPageNum () {
    const s = this.state
    const self = this
    const activeItem = s.activeItem
    const activePage = s.activePage
    var products = s.heartProducts.sort(function (a, b) {
      return a.id - b.id
    })
    var accessories = s.accessoryHeartProducts.sort(function (a, b) {
      return a.id - b.id
    })
    var dataShow = []
    var allProducts = ''
    if (activeItem === 'All') {
      allProducts = products.concat(accessories)
    } else if (activeItem === 'Accessaries') {
      allProducts = accessories
    } else {
      allProducts = products
    }

    const itemNum = allProducts.length

    for (var i = activePage * 8 - 8; i < activePage * 8; i++) {
      if (i < itemNum) {
        dataShow.push(allProducts[i])
      }
    }

    var roundPageNum = 0
    var pageNum = 0
    roundPageNum = round(itemNum / 8)
    pageNum = itemNum / 8.0

    if (itemNum === 0) {
      pageNum = 1
    } else if (itemNum > 0 && pageNum - roundPageNum > 0) {
      pageNum = roundPageNum + 1
    } else {
      pageNum = roundPageNum
    }
    self.setState({
      heartProducts: products,
      accessoryHeartProducts: accessories,
      dataShow: dataShow,
      pageNum: pageNum
    })
  }

  categoriesVisible () {
    const categoriesVisible = this.state.categoriesVisible
    const self = this
    console.log(categoriesVisible)
    if (categoriesVisible !== true) {
      self.setState({
        categoriesVisible: true
      })
    } else if (categoriesVisible === true) {
      self.setState({
        categoriesVisible: false
      })
    }
  }

  sizesVisible () {
    const sizesVisible = this.state.sizesVisible
    if (sizesVisible !== true) {
      this.setState({
        sizesVisible: true
      })
    } else if (sizesVisible === true) {
      this.setState({
        sizesVisible: false
      })
    }
  }

  signinVisible () {
    const signVisible = this.state.signinVisible
    console.log(signVisible)

    if (signVisible !== true) {
      this.setState({
        signinVisible: true
      })
    } else if (signVisible === true) {
      this.setState({
        signinVisible: false
      })
    }
  }

  handleItemClickCart (event, data) {
    // var name = data.name
    // const link = data.link
    const self = this
    const log = localStorage.getItem('login')
    if (log !== 'true') {
      self.signinVisible()
    } else {
      self.props.history.push('/cart')
    }
  }

  handleItemClick (event, data) {
    // var name = data.name
    const link = data.link
    const self = this
    window.location.assign('https://www.sheyoufashion.com/#' + link)
    if (link === undefined || link === null) {
      self.props.history.push('/heart')
      window.location.reload(true)
    } else {
      self.props.history.push(link)
      window.location.reload(true)
    }
  }

  ifLog () {
    const log = localStorage.getItem('login')

    const self = this
    if (log === 'false') {
      self.setState({ login: false })
    } else {
      self.setState({ login: true })
    }
  }

  render () {
    const s = this.state
    const self = this
    // const login = s.login
    const len = self.state.heartList.length
    const accessoryLen = self.state.accessoryHeartList.length
    const loading = s.loading

    var products = s.heartProducts
    var accessories = s.accessoryHeartProducts
    var dataShow = s.dataShow

    const cartNum = s.cart.length + s.accessoryCart.length
    // const bagNum = s.bag.length + s.accessoryBag.length

    console.log(products)
    console.log(accessories)
    console.log(s.pageNum)
    console.log(dataShow)

    // var products1=localStorage.getItem('rentProducts')
    // products1=JSON.parse('['+products1+']')

    return (
      <div>
        {/* <Nav /> */}

        {loading ? (
          <LoadingPhone/>
        ) : (
          <Grid>
            {s.login !== 'true' && (
                <div className='bg_color_image' style={{height:'100vh',width:'100%'}}>
               
                  <Segment style={{position:'absolute', top: '25%',height:'45%',width:'100%',paddingTop:20,paddingBottom:20}}>
                        <Grid>
                        <Grid.Row ></Grid.Row>
                        <Grid.Row centered>
                              <Icon name='smile outline' color='yellow' size='huge'/>
                            
                          </Grid.Row>
                          <Grid.Row centered>
                            <Header>
                              Please Log In First
                            </Header>
                          </Grid.Row>
                          <Grid.Row centered>
                            <Button style={{paddingLeft:20,paddingRight:20}} color='orange' basic onClick={self.signinVisible}>
                              Log In
                            </Button>
                          </Grid.Row>
                        </Grid>
                      </Segment>
                  </div>
             
            )}

            {s.login === 'true' && (
              <>
                <Grid.Row
                  centered
                  style={{ paddingBottom: 0, marginBottom: 0, marginTop: 10 }}
                >
                  <Menu borderless secondary>
                    <Menu.Item
                      as={Link}
                      link='/clothing'
                      name='Clothing'
                      active={s.activeItemPath === 'Clothing'}
                      onClick={this.handleItemClick}
                    />

                    <Menu.Item
                      as={Link}
                      link='/accessory'
                      name='Accessory'
                      active={s.activeItemPath === 'Accessory'}
                      onClick={this.handleItemClick}
                    />

                    <Menu.Item
                      as={Link}
                      link='/sale'
                      name='Sale'
                      active={s.activeItemPath === 'Sale'}
                      onClick={this.handleItemClick}
                    />
                    <Menu.Item
                      name='Cart'
                      linkto='/cart'
                      active={s.activeItemPath === 'Cart'}
                      onClick={this.handleItemClickCart}
                    >
                      <Icon name='shopping cart' color='teal'>
                        <Label floating circular>
                          {cartNum}
                        </Label>
                      </Icon>

                      {/* heart */}
                    </Menu.Item>
                  </Menu>
                </Grid.Row>
                <Grid.Row
                  columns={3}
                  style={{
                    paddingBottom: 0,
                    marginBottom: 0,
                    paddingTop: 0,
                    marginTop: 5
                  }}
                >
                  <Grid.Column width={1}></Grid.Column>
                  <Grid.Column width={14}>
                    <Segment style={{ height: 70 }}>
                      <Grid divided style={{ height: 70 }}>
                        <Grid.Row columns={2}>
                          <Grid.Column width={2}></Grid.Column>
                          <Grid.Column width={6}>
                            <Grid style={{ height: 70 }}>
                              <Grid.Row
                                style={{ paddingBottom: 0, marginBottom: 0 }}
                              >
                                <Header as='h6' style={{ paddingLeft: 10 }}>
                                  Categories
                                </Header>
                              </Grid.Row>
                              <Grid.Row>
                                {/* <Select placeholder='Select your country' options={countryOptions} /> */}
                                {this.state.activeItem === 'All' && (
                                  <Header
                                    as='h6'
                                    color='orange'
                                    style={{ paddingLeft: 10 }}
                                    onClick={self.categoriesVisible}
                                  >
                                    <Icon corner name='list' />
                                    Select
                                  </Header>
                                )}
                                {this.state.activeItem !== 'All' && (
                                  <Header
                                    as='h6'
                                    color='orange'
                                    style={{ paddingLeft: 10 }}
                                    onClick={self.categoriesVisible}
                                  >
                                    <Icon corner name='list' />

                                    {this.state.activeItem}
                                  </Header>
                                )}

                                <Sidebar
                                  as={Menu}
                                  animation='overlay'
                                  // onHide={this.categoriesVisible}
                                  vertical
                                  visible={
                                    this.state.categoriesVisible === true
                                  }
                                  width='thin'
                                  direction='bottom'
                                >
                                  <Menu.Item
                                    onClick={this.handleItemClick}
                                    link='/heart'
                                  >
                                    All
                                  </Menu.Item>
                                  <Menu.Item
                                    onClick={this.handleItemClick}
                                    link='/heart/feminine'
                                  >
                                    Feminine
                                  </Menu.Item>
                                  <Menu.Item
                                    onClick={this.handleItemClick}
                                    link='/heart/masculine'
                                  >
                                    Masculine
                                  </Menu.Item>
                                  <Menu.Item
                                    onClick={this.handleItemClick}
                                    link='/heart/accessaries'
                                  >
                                    Accessories
                                  </Menu.Item>
                                </Sidebar>

                                {/* <Button.Group style={{marginLeft:10}} basic size='mini' color='orange'>
                            <Button >
                              <Icon name='list' />
                              Select
                            </Button>
                            <Dropdown
                              className='button icon'
                              floating
                              options={optionsCategories}
                              trigger={<></>}
                            />
                          </Button.Group> */}
                              </Grid.Row>
                            </Grid>
                          </Grid.Column>

                          <Grid.Column width={6}>
                            <Grid style={{ height: 70 }}>
                              <Grid.Row
                                style={{ paddingBottom: 0, marginBottom: 0 }}
                              >
                                <Header as='h6' style={{ paddingLeft: 10 }}>
                                  Sizes
                                </Header>
                              </Grid.Row>
                              <Grid.Row>
                                <Header
                                  style={{ paddingLeft: 10 }}
                                  as='h6'
                                  color='orange'
                                  onClick={self.sizesVisible}
                                >
                                  <Image
                                    style={{
                                      height: 18,
                                      width: 18,
                                      position: 'relative',
                                      top: -5
                                    }}
                                    src='https://res.cloudinary.com/sheyou/image/upload/v1645878606/4_4417bd5612.png?441339.6999999881'
                                    size='mini'
                                  />
                                  <p
                                    style={{
                                      position: 'relative',
                                      top: -20,
                                      left: 27
                                    }}
                                  >
                                    Select
                                  </p>
                                </Header>

                                <Sidebar
                                  as={Menu}
                                  animation='overlay'
                                  // onHide={this.sizesVisible}
                                  vertical
                                  visible={this.state.sizesVisible === true}
                                  width='thin'
                                  direction='bottom'
                                >
                                  <Menu.Item
                                    onClick={this.handleItemClick}
                                    link='/heart/average'
                                  >
                                    Average
                                  </Menu.Item>
                                  <Menu.Item
                                    onClick={this.handleItemClick}
                                    link='/heart/m'
                                  >
                                    M
                                  </Menu.Item>
                                  <Menu.Item
                                    onClick={this.handleItemClick}
                                    link='/heart/s'
                                  >
                                    S
                                  </Menu.Item>
                                  <Menu.Item
                                    onClick={this.handleItemClick}
                                    link='/heart/l'
                                  >
                                    L
                                  </Menu.Item>
                                  <Menu.Item
                                    onClick={this.handleItemClick}
                                    link='/heart/xxsxs'
                                  >
                                    XXS XS
                                  </Menu.Item>
                                  <Menu.Item
                                    onClick={this.handleItemClick}
                                    link='/heart/xxlxl'
                                  >
                                    XXL XL
                                  </Menu.Item>
                                </Sidebar>
                              </Grid.Row>
                            </Grid>
                          </Grid.Column>

                          <Grid.Column width={1}></Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Segment>
                  </Grid.Column>
                  <Grid.Column width={1}></Grid.Column>
                </Grid.Row>

                {s.dataloading === true && (
                  <Grid.Row>
                    <Grid.Column width={16}>
                      <Parallax
                        bgImage='https://i.loli.net/2020/11/25/ZHjYm1wL9runqOz.png'
                        bgImageAlt=''
                        strength={200}
                      >
                        <div>
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <Segment>
                            <Grid>
                              <Grid.Row centered>
                                <Header>Loading...</Header>
                              </Grid.Row>
                            </Grid>
                          </Segment>
                        </div>

                        <div style={{ height: '200px' }} />
                      </Parallax>
                    </Grid.Column>
                  </Grid.Row>
                )}
                {s.dataloading !== true && (
                  <Grid.Row>
                    {dataShow.length > 0 && (
                      <Grid.Column width={16}>
                        <CardTempletePhone products={dataShow} />
                        <Grid>
                          <Grid.Row centered>
                            <Pagination
                              onPageChange={this.handlePaginationChange}
                              activePage={self.state.activePage}
                              pointing
                              secondary
                              ellipsisItem={false}
                              totalPages={s.pageNum}
                            />
                          </Grid.Row>
                        </Grid>
                      </Grid.Column>
                    )}
                    {len + accessoryLen === 0 && (
                      <Grid.Column width={16}>
                        <Parallax
                          bgImage='https://i.loli.net/2020/11/25/ZHjYm1wL9runqOz.png'
                          bgImageAlt=''
                          strength={200}
                        >
                          <div>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <Segment>
                              <Grid>
                                <Grid.Row centered>
                                  <Header>
                                    You Don't Have Anything In Your Heart List
                                  </Header>
                                </Grid.Row>

                                <Grid.Row centered>
                                  <Link to='/clothing'>
                                    <Button size='huge' color='orange'>
                                      {' '}
                                      Go Check Out Our Closet
                                    </Button>
                                  </Link>
                                </Grid.Row>
                              </Grid>
                            </Segment>
                          </div>

                          <div style={{ height: '100px' }} />
                        </Parallax>
                      </Grid.Column>
                    )}
                    {dataShow.length === 0 && (
                      <Grid.Column width={16}>
                        <Parallax
                          bgImage='https://i.loli.net/2020/11/25/ZHjYm1wL9runqOz.png'
                          bgImageAlt=''
                          strength={200}
                        >
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />

                          <div>
                            <Segment>
                              <Grid>
                                <Grid.Row centered>
                                  <Header>
                                    Sorry, we couldn’t find any matches for your
                                    search criteria
                                  </Header>
                                </Grid.Row>

                                <Grid.Row centered>
                                  <Button
                                    size='huge'
                                    color='orange'
                                    onClick={this.handleItemClick}
                                  >
                                    {' '}
                                    Browse All
                                  </Button>
                                </Grid.Row>
                              </Grid>
                            </Segment>
                          </div>

                          <div style={{ height: '100px' }} />
                        </Parallax>
                      </Grid.Column>
                    )}
                  </Grid.Row>
                )}
              </>
            )}

            <Sidebar
              as={Menu}
              animation='overlay'
              // onHide={this.signinVisible}
              vertical
              visible={this.state.signinVisible === true}
              direction='bottom'
            >
              <Segment
                style={{ width: '100%', paddingBottom: 7, paddingTop: 7 }}
              >
                <Grid>
                  <Grid.Row columns={4}>
                    <Grid.Column width={1}>
                      <Icon
                        name='close'
                        size='small'
                        onClick={self.signinVisible}
                      />
                    </Grid.Column>
                    <Grid.Column width={5}></Grid.Column>
                    <Grid.Column width={4}>
                      <Header
                        as='h4'
                        color='teal'
                        textAlign='centered'
                        style={{
                          paddingBottom: 0,
                          marginBottom: 0,
                          fontWeight: 500
                        }}
                        onClick={self.signinVisible}
                      >
                        Sign In
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>

                {/* <Header as='h6'  textAlign='centered' style={{paddingTop:0,marginTop:0,color:'#384D5A'}}>
                             Next Generation Fashion Platform 
                         </Header> */}
              </Segment>
              <LogInPagePhonePop />
            </Sidebar>

            <Grid.Row></Grid.Row>
            <Grid.Row></Grid.Row>
          </Grid>
        )}

        <NavMobilePhone />
      </div>
    )
  }
}

export default withRouter(HeartPagePhone)
