import React from 'react'
import { Header } from 'semantic-ui-react'
const userLang = navigator.language || navigator.userLanguage

const HomePageText = () => (
  <div>
    
      <Header
        textAlign='center'
        
        style={{ fontSize: '50px', fontWeight: '1000', marginBottom: 0 ,color:'white'}}
      >
        {userLang === 'zh-CN' && ('新一代时尚租赁平台')}
        {userLang !== 'zh-CN' && ('Next-generation Fashion Renting platform')}
      </Header>

    
      <Header
        textAlign='center'
        size='large'
        style={{color:'white'}}
      >
        {userLang === 'zh-CN' && ('舍予共享衣橱 = 租借 + 购买')}
        {userLang !== 'zh-CN' && ('SHEYOU Clothes Sharing = Clothes Rental + Sale')}
      </Header>
    

 


  </div>
)

export default HomePageText

//      <Header inverted textAlign='center' style={{fontSize:"40px" , fontWeight:"1000", marginBottom:0}} >Change Your Way of Clothing</Header>
