import React from 'react'
import { Button} from 'semantic-ui-react'
import {Link} from 'react-router-dom'
const userLang = navigator.language || navigator.userLanguage


const IntroPageButton = () => (
   
  <div>
    <Link to ='/clothing'>
    {userLang === 'zh-CN' && (
      <Button color='orange' circular size='big'
      style={{paddingTop:20,paddingBottom:20, paddingLeft:50,paddingRight:50}}
      >
        查看衣橱
     </Button>
    )
    }
    {userLang !== 'zh-CN' && (
      <Button color='orange' circular size='big'
      style={{paddingTop:20,paddingBottom:20, paddingLeft:50,paddingRight:50}}
      >
        View Our Closet
     </Button>
    )
    }
    </Link>
    
  </div>
  
)

export default IntroPageButton