import React from 'react'
import axios from 'axios'
import {  Grid, Image, Icon, Header, Divider } from 'semantic-ui-react'
// import {
//     Link
//   } from "react-router-dom"; 
// import Nav from '../nav/Nav'
// import {Parallax} from 'react-parallax';
import {withRouter} from 'react-router-dom'
// import HandleBuyCartDelete from '../cartFunction/HandleBuyCartDelete'
// import Foot from '../foot/Foot';


class DeliveryPageShipping extends React.Component{
      constructor(props){
    super(props)
    this.state={
        deliveryData:[],
        orderInfo:[],
        loading:true
        }

      
    }
      


      componentDidMount(){
          const self=this
        const p=this.props
        const orderInfo=p.orderInfo
        self.setState({orderInfo:orderInfo})
        const deliveryNum=orderInfo.DeliveryNum
        console.log(orderInfo)
        window.setTimeout(() => {
          if(self.state.loading===true){
           this.setState({
               loading:false
           })
          }
       
        }, 1000);
        axios.post('https://api.jisuapi.com/express/query?appkey=7ce376abb8f0b002&type=auto&number='+deliveryNum)
             .then(function(res){
                  const data=res.data.result
                  self.setState({deliveryData:data,loading:false})
              })

        // axios.post('https://api.jisuapi.com/express/query?appkey=7ce376abb8f0b002&type=auto&number='+deliveryNum,
        //       function (res) {
        //         console.log(res)
        //       }
        // )

        
      }

    


     

       

    render(){
      
       const deliveryData=this.state.deliveryData
       console.log(deliveryData)
       const orderInfo=this.state.orderInfo
      
       

      
       

        
   return <>{this.state.loading ? (
    <>
      <Grid.Row></Grid.Row>
      <Grid.Row></Grid.Row>
      <Grid.Row></Grid.Row>
      <Grid.Row></Grid.Row>


      <Grid.Row colums={2}>
        <Grid.Column width={7}></Grid.Column>
        <Grid.Column width={6}>
          <Header size='huge'>
            <Icon loading name='asterisk' size='massive' color='yellow' />
            Loading...
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row></Grid.Row>
      <Grid.Row></Grid.Row>
      <Grid.Row></Grid.Row>
      <Grid.Row></Grid.Row>
    </>
  ) : (<>
     {deliveryData.length!==0&&deliveryData.list.map(function(it){
    return  <Grid.Row columns={5}>
                  <Grid.Column width={2}>
                      
                  </Grid.Column>
                  <Grid.Column width={1}>
                      <Icon name='shipping' circular inverted color='grey'/>
                  </Grid.Column>
                  <Grid.Column>
                  {deliveryData.length!==0&&<p> {it.time.substring(0,10)} </p>}
                          
                  </Grid.Column>
                  <Grid.Column>
                  {deliveryData.length!==0&&<p> {it.time.substring(11,19)} </p>} 
                  </Grid.Column>
                  <Grid.Column>
                  {deliveryData.length!==0&&<p> {it.status}</p>} 
                  </Grid.Column>

          </Grid.Row>

      })}
       <Grid.Row columns={5}>
            <Grid.Column width={2}>
                
            </Grid.Column>
            <Grid.Column width={1}>
                <Icon name='shopping cart'  circular inverted color='grey' />
            </Grid.Column>
            <Grid.Column>
            {orderInfo.length!==0&&<p> {orderInfo.created_at.substring(0,10)} </p>}
                    
            </Grid.Column>
            <Grid.Column>
            {orderInfo.length!==0&&<p> {orderInfo.created_at.substring(11,19)} </p>} 
            </Grid.Column>
            <Grid.Column>
            {orderInfo.length!==0&&<p> The order has been placed</p>} 
            </Grid.Column>

        </Grid.Row>
        {deliveryData.length!==0&&
        <Divider/>
        
        }

        {deliveryData.length!==0&&
        <Grid.Row columns={4}>
          <Grid.Column width={5}></Grid.Column>

          <Grid.Column width={3}>
            <Image size='tiny'  src={deliveryData.logo}/>
          </Grid.Column>

          <Grid.Column width={4}>
            <Header as='h5' style={{paddingTop:30}}>{deliveryData.typename} :{deliveryData.number}</Header>
          </Grid.Column>


          <Grid.Column width={3}></Grid.Column>

        </Grid.Row>
        
        }
      
      </>
  )}</>
} }


export default withRouter(DeliveryPageShipping)


