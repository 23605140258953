import React from 'react'
import { Card, Image, Label, Placeholder } from 'semantic-ui-react'
import axios from 'axios'
import { withRouter } from 'react-router-dom'


class MerchantCardTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true

    }
    this.onHandleClick = this.onHandleClick.bind(this)
    
  }


  componentDidMount() {

    const self = this

    window.setTimeout(() => {
      if (self.state.loading === true) {
        this.setState({
          loading: false
        })
      }
    }, 5000);
  }


  onHandleClick(event, data) {
    const self = this
    // const productId = data.id
    const productId = event.target.id
    event.preventDefault()
    localStorage.setItem('productId', productId)
    self.props.history.push('/product?id='+productId)

  }


  render() {
    const p = this.props
    const self = this
    const products = p.products
    const loading = self.state.loading
    const log = self.state.log
    // const color=self.state.color
    // const heartList = self.state.heartList


    return (
      <Card.Group centered >
                                                  
        {products.map(function (product) {
          return <>{product.stock!=='0'&&<Card id={product.id} >
            {loading ? (
              <>
                <Placeholder>
                  <Placeholder.Image square />

                </Placeholder>

                <Card.Content>
                  <Placeholder>
                    <Placeholder.Paragraph>
                      <Placeholder.Line length='very short' />
                      <Placeholder.Line length='medium' />
                      <Placeholder.Line length='short' />
                    </Placeholder.Paragraph>

                  </Placeholder>
                </Card.Content>
              </>

            ) : (<>
              <Image
                id={product.id}
                onClick={self.onHandleClick}
                style={{height:300}}
                // label={{
                //   as: 'a',
                //   color: heart,
                //   corner: 'right',
                //   icon: 'heart',

                //   }
                // }
                src={product.picture[0].url}
              />

              <Card.Content id={product.id} onClick={self.onHandleClick}>


                <Card.Header id={product.id}>
                  {product.name}
                </Card.Header>

              {
                product.description!==null&&
                <Card.Meta id={product.id}>{product.description.substring(0,20)+"..."}</Card.Meta>
              }




                <Card.Description id={product.id}>
                  {product.price + 'RMB/ Free for Member'}
                </Card.Description>
              </Card.Content> </>)}
          </Card>
            }</>

        })}
      </Card.Group>
    )
  }
}

export default withRouter(MerchantCardTemplate)